import { Icon } from "@chakra-ui/react";
export const Wallet = props => {
  let {color = '#565656'} = props;
  return <Icon  viewBox="0 0 41 40" {...props}><rect id="wallet" x={0.079} y={0} width={40} height={40} style={{
    fill: "none"
  }} /><path id="path71" d="M25.139,11.605l8.38,-0c1.866,-0 3.38,1.513 3.38,3.38l-0,16.82c-0,1.834 -1.487,3.32 -3.32,3.32l-27,-0c-1.834,-0 -3.32,-1.486 -3.32,-3.32l-0,-23.56" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path72" d="M6.659,4.885l21.85,-0c1.85,-0 3.35,1.5 3.35,3.35l-0,1.88c-0,0.823 -0.667,1.49 -1.49,1.49l-23.71,-0c-1.856,-0 -3.36,-1.504 -3.36,-3.36c-0,-1.856 1.504,-3.36 3.36,-3.36Z" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path73" d="M30.179,23.365c-0,0.928 -0.752,1.68 -1.68,1.68c-0.928,-0 -1.68,-0.752 -1.68,-1.68c-0,-0.928 0.752,-1.68 1.68,-1.68c0.928,-0 1.68,0.752 1.68,1.68Z" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /></Icon>
};