/** Imports */
import {
    Box,
    Grid,
    Heading,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Text
  } from '@chakra-ui/react';
import { useErrors } from '../../helpers/integer';
import { useContext, useState, useEffect } from 'react';
import api from "../../helpers/api";
import { Page } from '../../components/templates/Page';
import { ReportApproval } from '../../components/ReportApproval';
import { GlobalDataContext } from '../../components/context/GlobalData';
import { Loader } from '../../components/Loader';
// import { analytics } from 'firebase-functions/v1';
// import { logEvent } from 'firebase/analytics';

  const ApprovalsBPHistoricalPage = () => {

    /** Hooks */
    const [dataFreeTime, setDataFreeTime] = useState(false);
    const [dataExchange, setDataExchange] = useState(false);

    /** Variables */
    const showErrors  = useErrors();
    const url_service = 'ReportServices/getReportByFilter';

    /**
     * Reporte permisos
     */
    const getReport = async () => {

      try {
        const response = await api.post(url_service, {  'report_id': 101163, 'filter_name': '', 'filter_value': ''});
        showErrors(response, false);
        setDataFreeTime(response.data.response.data);
      } catch (err) {
        showErrors(err);
      }
    }

    /**
     * Reporte canjes dependientes
     */
    const getReportExchange = async () => {
      try {
        const response = await api.post(url_service, {  'report_id': 101189, 'filter_name': '', 'filter_value': ''})
        setDataExchange(response.data.response.data);
      } catch (err) {
        showErrors(err);
      }
    }

    useEffect(() => {
      getReport();
      getReportExchange();
    }, [])

    if(dataFreeTime === false) {
      return <Loader />;
    }

    return (
      <Page title="Histórico aprobaciones" color={'transparent'} p="0" description="Historial de aprobaciones de dependientes.">
        <Tabs>
          <TabList>
            <Tab _selected={{ bg: 'white' }} borderTopRadius="md">Canjes</Tab>
            <Tab _selected={{ bg: 'white' }} borderTopRadius="md">Permisos</Tab>
          </TabList>
          <TabPanels bg={'white'}>
            <TabPanel>
              <ReportApproval approval={dataExchange} />
            </TabPanel>
            <TabPanel>
              <ReportApproval approval={dataFreeTime} />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Page>
    );
  }

  export default ApprovalsBPHistoricalPage;