import { Icon } from "@chakra-ui/react";
export const Credential = props => {
  let {color = '#565656'} = props;
  return <Icon  viewBox="0 0 41 40" {...props}><rect id="credential" x={0.079} y={0} width={40} height={40} style={{
    fill: "none"
  }} /><path id="path109" d="M25.099,16.708c-0,2.784 -2.257,5.04 -5.04,5.04c-2.784,0 -5.04,-2.256 -5.04,-5.04c-0,-2.783 2.256,-5.04 5.04,-5.04c2.783,0 5.04,2.257 5.04,5.04Z" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path140" d="M18.029,35.728c-4.43,-2.74 -14.35,-10.72 -14.74,-27.61c-0.007,-0.232 0.095,-0.453 0.276,-0.599c0.18,-0.145 0.419,-0.197 0.644,-0.141c2.09,0.55 7.24,1.21 14.67,-3.35c0.736,-0.45 1.663,-0.45 2.4,0c7.43,4.56 12.58,3.9 14.67,3.35c0.225,-0.056 0.463,-0.004 0.644,0.141c0.181,0.146 0.283,0.367 0.276,0.599c-0.4,16.72 -10.32,24.81 -14.75,27.59c-1.246,0.795 -2.837,0.803 -4.09,0.02Z" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path141" d="M13.339,31.828l-0,-3.36c-0.011,-3.707 2.982,-6.723 6.69,-6.74c3.711,0 6.72,3.009 6.72,6.72l-0,3.36" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /></Icon>
};