import { Icon } from "@chakra-ui/react";
export const Show = props => {
  let {color = '#565656'} = props;
  return <Icon  viewBox="0 0 40 41" {...props}><rect id="show" x={0} y={0.8} width={40} height={40} style={{
    fill: "none"
  }} /><path id="path239" d="M25.69,21.809c-0,3.16 -2.562,5.723 -5.723,5.723c-3.161,-0 -5.724,-2.563 -5.724,-5.723c0,-3.161 2.563,-5.724 5.724,-5.724c3.161,0 5.723,2.563 5.723,5.724Z" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke:color,
    strokeWidth: "3.82px"
  }} /><path id="path52" d="M3.212,19.204c10.47,-5.592 23.039,-5.592 33.509,-0" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke:color,
    strokeWidth: "3.36px"
  }} /><path d="M19.933,14.22l0,-6.641" style={{
    fill: "none",
    stroke:color,
    strokeWidth: "3.36px"
  }} /><path d="M29.565,14.94l2.803,-4.855" style={{
    fill: "none",
    stroke:color,
    strokeWidth: "3.36px"
  }} /><path d="M10.368,14.94l-2.803,-4.855" style={{
    fill: "none",
    stroke:color,
    strokeWidth: "3.36px"
  }} /></Icon>
};