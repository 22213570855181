import { Icon } from "@chakra-ui/react";
export const Web = props => {
  let {color = '#565656'} = props;
  return <Icon  viewBox="0 0 41 40" {...props}><rect id="web" x={0.079} y={0} width={40} height={40} style={{
    fill: "none"
  }} /><path id="path58" d="M8.809,6.961l22.6,0c1.182,0 2.14,0.958 2.14,2.14l-0,16.34l-26.91,0l-0,-16.34c-0,-0.573 0.229,-1.121 0.637,-1.524c0.408,-0.402 0.96,-0.624 1.533,-0.616Z" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path59" d="M3.279,30.481l33.6,0" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /></Icon>
};