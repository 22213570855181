import { Icon } from "@chakra-ui/react";
export const Question = props => {
  let {color = '#565656'} = props;
  return <Icon  viewBox="0 0 41 40" {...props}><rect id="question" x={0.079} y={0} width={40} height={40} style={{
    fill: "none"
  }} /><clipPath id="_clip1"><rect x={0.079} y={0} width={40} height={40} /></clipPath><g clipPath="url(#_clip1)"><path id="path236" d="M17.899,25.46l-0,-0.63c-0.027,-0.811 0.106,-1.62 0.39,-2.38c0.239,-0.571 0.578,-1.096 1,-1.55c0.36,-0.39 0.744,-0.757 1.15,-1.1c0.366,-0.299 0.701,-0.634 1,-1c0.266,-0.346 0.403,-0.774 0.39,-1.21c0.035,-0.476 -0.095,-0.95 -0.37,-1.34c-0.263,-0.306 -0.611,-0.526 -1,-0.63c-0.443,-0.123 -0.901,-0.184 -1.36,-0.18c-0.705,0.003 -1.404,0.121 -2.07,0.35c-0.626,0.207 -1.214,0.514 -1.74,0.91l-0,-3c0.316,-0.198 0.651,-0.366 1,-0.5c0.48,-0.193 0.975,-0.347 1.48,-0.46c0.591,-0.131 1.194,-0.194 1.8,-0.19c0.768,-0.014 1.532,0.104 2.26,0.35c0.628,0.21 1.204,0.55 1.69,1c0.452,0.445 0.794,0.99 1,1.59c0.242,0.639 0.364,1.317 0.36,2c0.028,0.649 -0.106,1.295 -0.39,1.88c-0.264,0.477 -0.602,0.909 -1,1.28l-1.15,1.09c-0.402,0.357 -0.74,0.78 -1,1.25c-0.291,0.565 -0.428,1.196 -0.4,1.83l-0,0.53l-3.04,0.11Zm-0.18,4.86l-0,-3l3.46,0l-0,3l-3.46,0Z" style={{
      fill: color,
      fillRule: "nonzero"
    }} /><path id="path237" d="M36.879,21.56c-0,9.278 -7.522,16.8 -16.8,16.8c-9.279,-0 -16.8,-7.522 -16.8,-16.8c-0,-9.278 7.521,-16.8 16.8,-16.8c9.278,0 16.8,7.522 16.8,16.8Z" style={{
      fill: "none",
      fillRule: "nonzero",
      stroke: color,
      strokeWidth: "3.36px"
    }} /></g></Icon>
};