import { Icon } from "@chakra-ui/react";
export const Support = props => {
  let {color = '#565656'} = props;
  return <Icon  viewBox="0 0 41 40" {...props}><rect id="support" x={0.079} y={0} width={40} height={40} style={{
    fill: "none"
  }} /><path id="path163" d="M34.009,24.22c-1.735,4.087 -5.174,7.209 -9.41,8.54" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path164" d="M5.609,13.98c1.918,-6.42 7.833,-10.811 14.533,-10.79c6.7,0.021 12.588,4.449 14.467,10.88" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path165" d="M33.519,13.29c1.855,-0 3.36,1.504 3.36,3.36l-0,5.04c-0,1.856 -1.505,3.36 -3.36,3.36c-1.856,-0 -3.36,-1.504 -3.36,-3.36l-0,-5.04c-0,-1.856 1.504,-3.36 3.36,-3.36Z" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path166" d="M6.639,13.29c1.855,-0 3.36,1.504 3.36,3.36l-0,5.04c-0,1.856 -1.505,3.36 -3.36,3.36c-1.856,-0 -3.36,-1.504 -3.36,-3.36l-0,-5.04c-0,-1.856 1.504,-3.36 3.36,-3.36Z" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path167" d="M18.399,30.09l3.36,-0c1.855,-0 3.36,1.504 3.36,3.36c-0,1.856 -1.505,3.36 -3.36,3.36l-3.36,-0c-1.856,-0 -3.36,-1.504 -3.36,-3.36c-0,-1.856 1.504,-3.36 3.36,-3.36Z" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /></Icon>
};