import { Button, HStack, Icon, Text, VStack, useDisclosure, Collapse, Spacer, Stack, Link, textDecoration } from '@chakra-ui/react';
import React, { createElement } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { Fragment, Children, cloneElement } from 'react'

import {Banner}                from '../box/Banner';
import {BoxCollectiveContract} from '../box/BoxCollectiveContract';
import {BoxGiftCard}           from '../box/BoxGiftCard';
import {BoxGym}                from '../box/BoxGym';
import {BoxPoints}             from '../box/BoxPoints';
import {BoxScheduler}          from '../box/BoxScheduler';
import {BoxNews}               from '../box/BoxNews';
import {BoxDiary}              from '../box/BoxDiary';
import {BoxRoulette}           from '../box/BoxRoulette';
import {BoxScholarship}        from '../box/BoxScholarship';
import { BoxSchoolHelp }       from '../box/BoxSchoolHelp';
import { BoxPrevent }          from '../box/BoxPrevent';
import { BoxFantasilandia }    from '../box/BoxFantasilandia';

/**
 * box_banner
 * box_scheduler
 * box_giftcard
 * box_gym
 * box_collective_agreement
 * box_my_points
 * box_annex
 * box_news
 * box_scholarship
 * @param {*} type
 * @param {*} content
 * @returns
 */
export const Boxes = ({type, content}) => {
  if(!type) return false;

  const components = {
    test                    : Banner,
    box_scheduler           : BoxScheduler,
    box_giftcard            : BoxGiftCard,
    box_gym                 : BoxGym,
    box_collective_agreement: BoxCollectiveContract,
    box_my_points           : BoxPoints,
    box_news                : BoxNews,
    box_diary               : BoxDiary,
    box_rulette             : BoxRoulette,
    box_scholarship         : BoxScholarship,
    box_schoolhelp          : BoxSchoolHelp,
    box_prevent             : BoxPrevent,
    box_fantasilandia       : BoxFantasilandia,
  };

  if(!components.hasOwnProperty(type)) {
    return <>{type}</>;
  }

  let BoxInstance = components[type];

  return <BoxInstance type={type} content={content} />;
}