import { Icon } from "@chakra-ui/react";
export const Clip = props => {
  let {color = '#565656'} = props;
  return <Icon  viewBox="0 0 40 40" {...props}><rect id="clip" x={0} y={0} width={40} height={40} style={{
    fill: "none"
  }} /><path d="M21.061,30.96l9.899,-9.899c4.098,-4.098 4.732,-10.118 1.414,-13.435c-3.317,-3.318 -9.337,-2.684 -13.435,1.414l-12.02,12.021" style={{
    fill: "none",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path d="M16.662,28.49l10.574,-10.575c1.863,-1.862 2.151,-4.599 0.643,-6.106c-1.508,-1.508 -4.244,-1.22 -6.107,0.642" style={{
    fill: "none",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path d="M21.858,12.366l-11.624,11.624c-3.034,3.034 -3.503,7.49 -1.047,9.946c2.455,2.455 6.912,1.986 9.945,-1.047l7.382,-7.382" style={{
    fill: "none",
    stroke: color,
    strokeWidth: "3.36px"
  }} /></Icon>
};