import { Icon } from "@chakra-ui/react";
export const Star = props => {
  let {color = '#565656'} = props;
  return <Icon  viewBox="0 0 40 40" {...props}><rect id="star" x={0} y={0} width={40} height={40} style={{
    fill: "none"
  }} /><path d="M17.755,6.944c0.473,-0.789 1.325,-1.271 2.245,-1.271c0.92,-0 1.772,0.482 2.245,1.271c1.173,1.956 2.515,4.195 3.259,5.436c0.367,0.61 0.965,1.045 1.659,1.205c1.41,0.324 3.954,0.909 6.177,1.42c0.896,0.206 1.619,0.867 1.903,1.742c0.284,0.875 0.088,1.835 -0.516,2.529c-1.497,1.719 -3.212,3.688 -4.162,4.779c-0.467,0.537 -0.696,1.241 -0.633,1.95c0.127,1.441 0.357,4.041 0.558,6.313c0.081,0.916 -0.325,1.808 -1.069,2.348c-0.745,0.541 -1.718,0.651 -2.564,0.291c-2.099,-0.893 -4.501,-1.915 -5.832,-2.481c-0.655,-0.279 -1.395,-0.279 -2.05,-0c-1.331,0.566 -3.733,1.588 -5.832,2.481c-0.846,0.36 -1.819,0.25 -2.564,-0.291c-0.744,-0.54 -1.15,-1.432 -1.069,-2.348c0.201,-2.272 0.431,-4.872 0.558,-6.313c0.063,-0.709 -0.166,-1.413 -0.633,-1.95c-0.95,-1.091 -2.665,-3.06 -4.162,-4.779c-0.604,-0.694 -0.8,-1.654 -0.516,-2.529c0.284,-0.875 1.007,-1.536 1.903,-1.742c2.223,-0.511 4.767,-1.096 6.177,-1.42c0.694,-0.16 1.292,-0.595 1.659,-1.205c0.744,-1.241 2.086,-3.48 3.259,-5.436Z" style={{
    fill: "none",
    stroke: color,
    strokeWidth: "3.7px"
  }} /></Icon>
};