/** Imports */
import {
    Stack,
    Button,
    Text,
    useColorModeValue,
    useToast,
    Center,
    HStack,
  } from '@chakra-ui/react';
  import { formatRut, useErrors, validateEmail, validateRut } from '../../helpers/integer';
  import { useState, useEffect } from 'react';
  import { useNavigate, Link as LinkRRD } from "react-router-dom";
  import axios from 'axios';
  import api from "../../helpers/api";
  import { useForm } from 'react-hook-form';
  import { AuthPage } from '../templates/AuthPage';
  import { InputField } from '../input/InputField';
  import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
  import TokenService from "../../helpers/TokenService";
  import { analytics, logEvent } from '../../firebase';

  // Iconos
  import { ArrowRight } from '../icons/ArrowRight.js';
  import { ArrowLeft } from '../icons/ArrowLeft';
  import { LogoFIF } from '../logos/LogoFIF';
  // import ReCaptcha from './ReCaptcha';

  /**
   * Página recuperación de clave
   *
   * @returns JXS
   */
  const ForgotForm = () => {

    /** Hooks */
    const showErrors = useErrors();
    const {
      register,
      handleSubmit,
      formState: { errors, isSubmitting }
    } = useForm();

    let   navigate             = useNavigate();
    const toast                = useToast();
    const baseUrl              = "https://vivetusbeneficios.custhelp.com/cc/API/";
    const { executeRecaptcha } = useGoogleReCaptcha();

    const components = {
      arrow_right: ArrowRight,
      arrow_left : ArrowLeft,
    };

    const Icons = props => {
      const {...iconProps}  = props;

      if(components.hasOwnProperty(props.icon)) {
        let Icons = components[props.icon];
        return <Icons {...iconProps} />;
      } else {
        let Icons = components['arrow'];
        return <Icons {...iconProps} />;
      }
    }

    useEffect(() => {
    }, [])

    // Métodos

    /**
     * Recuperción clave
     */
    const handleLogin = async (data) => {
      try {
        const token    = await executeRecaptcha('recovery');
        const response = await api.post('AuthenticationServices/recovery', {'login': data.login, token: token});
        if (response.data.result != false) {
          //Generación de evento Google Analytics
          logEvent(analytics,'event_forgot',{solicitudes_clave: data.login});
          toast({
            title: 'Se ha enviado un correo electrónico con la información para iniciar sesión.',
            status: 'success',
            duration: 6000,
            isClosable: true,
          })
          TokenService.setData(data.login);
          navigate('/2FA');
        } else {
          toast({
            title: response?.data?.error?.message,
            status: 'warning',
            duration: 6000,
            isClosable: true,
          })
        }
      } catch (err) {
        console.log(err);
        showErrors(err);
      }
    }

    return (
      <>
        <form onSubmit={handleSubmit(handleLogin)}>
          <Stack spacing={4}>
            <InputField
              label="Correo electrónico coporativo"
              name="login"
              type="rut"
              placeholder="usuario@dominio"
              required={true}
              register={register}
              validate={
                (value) => {
                  value = value.replace(/\s/gi, '')

                  if(/^[^\d]/.test(value)) {
                    if(/@((banco)?falabella\.(cl|com|com.ar)|integer.cl)$/.test(value)) {
                      return validateEmail(value) ? true : 'Correo Electrónico inválido';
                    } else {
                      return 'Debes ingresar un Correo Electrónico corporativo';
                    }
                  } else {
                    return validateRut(value) ? true : 'RUT inválido';
                  }
                }
              }
              setValueAs = {
                (value) => {
                  value = value.replace(/\s/gi, '');

                  if(/^[^\d]/.test(value)) {
                    return value;
                  } else {
                    return formatRut(value, 2);
                  }
                }
              }
              errors={errors} />

            <Button
              isLoading={isSubmitting}
              loadingText="Solicitando..."
              type="submit"
              disabled={!!errors.login || isSubmitting}
            >
              Restablecer clave
            </Button>

            <Text
              fontSize={{ base: 'sm', sm: 'md' }}
              align="center"
            >
              Recibirás un correo electrónico con más información para establecer tu nueva clave
            </Text>

            <LinkRRD
              color={'blue.400'}
              to="/login"
              align={'center'}
            >
              <Center>
                <HStack spacing={4} align='center'>
                  <Icons icon={'arrow_right'} w={'20px'} h={'20px'} color='grey' />
                  <Text>Volver al inicio de sesión</Text>
                </HStack>
              </Center>
            </LinkRRD>
          </Stack>
        </form>
      </>
    );
  }

  export default ForgotForm;