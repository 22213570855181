/** Imports */
import {
    Box,
    Button,
    Grid,
    GridItem,
    Heading,
    HStack,
    SimpleGrid,
    Stack,
    useToast
  } from '@chakra-ui/react';
import { useErrors } from '../../helpers/integer';
import { useState } from 'react';
import api from "../../helpers/api";
import { useEffect } from 'react';
import { Page } from '../../components/templates/Page';
import { Title } from '../../components/templates/Title';
import { InputField } from '../../components/input/InputField';
import { ReportProcedures } from '../../components/ReportProcedures';
import { Legend } from '../../components/templates/Legend';
import { Loader } from '../../components/Loader';
import { useForm } from 'react-hook-form';
import { CustomTable } from '../../components/CustomTable';
// import { analytics } from 'firebase-functions/v1';
// import { logEvent } from 'firebase/analytics';
  
  const ProceduresAdminPage = () => {
  
  /** Hooks */
  const showErrors = useErrors();
  const [data, setData] = useState([]);
  const [dataStatus, setDataStatus] = useState(false);
  const toast = useToast();
  
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    control,
    setValue
  } = useForm({});
  
  
    /**
     * Reporte
     */
    const getReportExchange = async () => {
      try {
        const response = await api.post('ReportServices/getReport', {'report_id' : 101107})
        showErrors(response, false);
        setData(response?.data?.response);
        if(response.data.result === true){
          setDataStatus(true)
        } else {
          toast({
            title: "Error al obtener los datos",
            status: 'warning',
            duration: 3000,
            isClosable: true,
          })
          setDataStatus(true)
        }
      } catch (err) {
        showErrors(err);
      }
    }

    useEffect(() => {
      getReportExchange();
    }, []);

    if(dataStatus === false) {
      return <Loader />;
    }
  
    return (
      <Page title="Administrador de Anticipos">
        <Legend>Cargar Documento CSV</Legend>
        <Stack py={5} align={'left'}>
          <InputField name="files" label="" type="file_csv" required={true} register={register} errors={errors} control={control} setValue={setValue} acceptedFileTypes={['image/jpeg', 'image/png', 'application/pdf']} />
        </Stack>
        <Stack>
          <CustomTable procedure={data} title ={"Mis anticipos"} />
        </Stack>
      </Page>
    );
  }
  
  export default ProceduresAdminPage;