import { Icon } from "@chakra-ui/react";
export const Fingerprint = props => {
  let {color = '#565656'} = props;
  return <Icon  viewBox="0 0 41 40" {...props}><rect id="fingerprint" x={0.079} y={0} width={40} height={40} style={{
    fill: "none"
  }} /><path id="path122" d="M11.045,35.096c3.36,-1.68 9.88,-6.26 8.81,-14.67" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path123" d="M17.825,36.706c2.93,-2.25 7.92,-5.74 8.34,-13.37c0.25,-4.52 -2.14,-9 -6.72,-8.4c-5.12,0.7 -5,5 -6.72,10.08c-1.19,3.56 -4.55,6.31 -6.72,6.72" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path124" d="M26.175,36.776c4,-4.95 6.78,-14 4.61,-20c-1.25,-4.081 -4.723,-7.09 -8.94,-7.745c-4.218,-0.656 -8.44,1.156 -10.87,4.665c-1.47,2 -1.75,6 -2.38,7.84c-0.63,1.84 -1.47,3.64 -4.34,4.41" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path125" d="M34.905,31.456c1.54,-5 3.18,-13 0.28,-19.25c-2.46,-5.28 -7.45,-8.1 -13.19,-8.8c-4.989,-0.484 -9.947,1.19 -13.62,4.6c-2.92,3.316 -4.675,7.494 -5,11.9" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /></Icon>
};