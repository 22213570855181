import { Icon } from "@chakra-ui/react";
export const Shirts = props => {
  let {color = '#565656'} = props;
  return <Icon  viewBox="0 0 40 41" {...props}><rect id="shirts" x={0} y={0.8} width={40} height={40} style={{
    fill: "none"
  }} /><path d="M11.505,17.193c0.815,1.955 2.744,3.33 4.995,3.33c2.249,-0 4.177,-1.373 4.993,-3.326l8.613,4.143l0,5.568l-5.266,-0l0.01,7.854c0.02,0.781 -0.281,1.536 -0.834,2.089c-0.552,0.552 -1.308,0.854 -2.089,0.834l-10.854,0.013c-0.781,0.02 -1.537,-0.281 -2.089,-0.834c-0.553,-0.552 -0.854,-1.307 -0.834,-2.088l0,-7.868l-5.256,-0l-0,-5.568l8.611,-4.147Z" style={{
    fill: "none",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path d="M6.394,16.567l-0,-5.48l8.611,-4.147c0.815,1.955 2.744,3.33 4.995,3.33c2.249,-0 4.177,-1.373 4.993,-3.326l8.613,4.143l0,5.568l-5.266,-0l0.003,2.61" style={{
    fill: "none",
    stroke: color,
    strokeWidth: "3.36px"
  }} /></Icon>
};