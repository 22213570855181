import { Icon } from "@chakra-ui/react";
export const Close = props => {
  let {color = '#565656'} = props;
  return <Icon viewBox="0 0 40 41" {...props}><rect id="close" x={0} y={0.8} width={40} height={40} style={{
    fill: "none"
  }} /><path id="path136" d="M5.91,6.62l14.18,14.18l-14.18,14.18" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path1361" d="M34.09,34.98l-14.18,-14.18l14.18,-14.18" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /></Icon>;
}