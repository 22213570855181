/** Imports */
import {
  Box,
  Stack,
  VStack,
  StackDivider,
  Button,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  SimpleGrid,
  useToast
} from '@chakra-ui/react';
import { useErrors } from '../../helpers/integer';
import { useContext, useState, useEffect } from 'react';
import api from "../../helpers/api";
import { Page } from '../../components/templates/Page';
import { Loader } from '../../components/Loader';
import { useForm } from 'react-hook-form';
import { GlobalDataContext } from '../../components/context/GlobalData';
import { Legend } from '../../components/templates/Legend';
import { InputField } from '../../components/input/InputField';

const BikerackPage = () => {
  /** Constantes */
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    control
  } = useForm({});

  /** Hooks */
  const showErrors                              = useErrors();
  const [globalData, setGlobalData]             = useContext(GlobalDataContext)
  const [bikeAvailability, setBikeAvailability] = useState([]);
  const toast                                   = useToast()

  // URLs
  const getBikeAvailability = 'BikeRackServices/getBikeAvailability';
  const saveBikeRack        = 'BikeRackServices/saveBikeRack';

  /**
   * Manejo de errores del formulario
   */
   const onError = (errors, e) => {
    console.log(errors, e);
  }

  /**
   * Preecargado de Informacion BikeRack
   */
  const geBikeAvailability = async () => {
    try {
      const response = await api.post(getBikeAvailability)
      setBikeAvailability(response.data.availability);
    } catch (err) {
      showErrors(err);
    }
  }

  /**
   * Evento onClick del formulario
   */
  const onSubmit = async (data) => {
    try {
      const response = await api.post(saveBikeRack, data);
      if(response.data.result !== false) {
        toast({
          title: response.data.response,
          status: 'success',
          duration: 4000,
          isClosable: true,
        });
      } else {
        toast({
          title: response.data.response,
          status: 'warning',
          duration: 4000,
          isClosable: true,
        });
      }
    } catch (err) {
      showErrors(err);
    }
  }

  useEffect(() => {
    geBikeAvailability();
  }, [])

  return (
    <Page title="Inscripción bicicletero" description="Recuerda que este espacio quedara reservado para que lo uses todos los días de la semana y es solo para colaboradores de Casa Matriz.">
      {true
        ?
        <Box w="100%" mx="auto" align={'center'}>
          <VStack w="100%" mx="auto" align={'center'} py={10}>
            {/* Datos del Bicicletero */}
            <TableContainer>
              <Table>
                <Thead>
                  <Tr>
                    <Th>Dirección</Th>
                    <Th>Disponibilidad</Th>
                  </Tr>
                </Thead>
                {bikeAvailability.length != 0
                  ?
                  bikeAvailability.map((bikeRack) => (
                    <Tbody key={bikeRack.value}>
                      <Tr>
                        <Td>{bikeRack.label}</Td>
                        <Td>{bikeRack.cantidad}</Td>
                      </Tr>
                    </Tbody>
                  ))
                  :
                  <Tbody>
                    <Tr>
                      <Td>Sin Direcciónes Disponibles</Td>
                      <Td>Sin Disponibilidad</Td>
                    </Tr>
                  </Tbody>
                }
              </Table>
            </TableContainer>
          </VStack>

          {/* DATOS DEL SOLICITANTE*/}
          <VStack align='left'>
            <Legend>Datos del solicitante</Legend>
            <SimpleGrid columns={{ base: '1', lg: '2', xl: '3' }} spacing={4} p={5}>
              <InputField disabled defaultValue={globalData?.contact?.Name?.First} label="Nombre" />
              <InputField disabled defaultValue={globalData?.contact?.Name?.Last} label="Apellido" />
              <InputField disabled defaultValue={globalData?.contact?.Login} label="RUT" />
              <InputField disabled defaultValue={globalData?.contact?.Emails && globalData?.contact?.Emails[0]} label="Correo Electrónico" />
            </SimpleGrid>
          </VStack>

          {/* Datos de la Solicitud */}
          <VStack divider={<StackDivider borderColor='gray.200' />} align='stretch'>
            <form onSubmit={handleSubmit(onSubmit, onError)}>
              <Legend>Datos del la solicitud</Legend>
              <SimpleGrid columns={{ base: '1', lg: '2', xl: '3' }} spacing={4} p={5}>
                <InputField name="Contact.CustomFields.c.phone_number" label="Teléfono móvil de contacto" type="text" register={register} errors={errors} required={true} control={control} />
                <InputField name="bicycle_color" label="Color de la bicicleta" type="text" register={register} errors={errors} required={true} control={control} />
                <InputField name="bicycle_brand" label="Marca de la bicicleta" type="text" register={register} errors={errors} required={true} control={control} />
                <InputField placeholder="Selecciona una dirección" name="bicycle_address_pref" label="Dirección de preferencia" type="select" register={register} errors={errors} required={true} options={bikeAvailability} />
              </SimpleGrid>

              <Stack maxW={'200'} py={'6'}>
                <Button type='submit' isLoading={isSubmitting} loadingText="Enviando..." disabled={isSubmitting} >
                  Enviar
                </Button>
              </Stack>
            </form>
          </VStack>
        </Box>
        :
        <Loader />
      }
    </Page>
  );
}

export default BikerackPage;