import axios from "axios";
import { Navigate, useNavigate } from "react-router-dom";
import TokenService from "./TokenService";


const instance = axios.create({
  baseURL: "https://vivetusbeneficios.custhelp.com/cc/API/",
  headers: {
    "Content-Type": "application/json"
  },

});
 
/**
 * Interceptor para peticiones
 */
instance.interceptors.request.use(
  (config) => {
    const token = TokenService.getLocalAccessToken();
    if (token) {
      config.headers["X-Access-Token"] = token.response.accessToken;
    }
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);

/**
 * Interceptor para Respuestas
 */
instance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    if(err?.response?.status === 401){
      window.location.href = '/logout';
    }
    return Promise.reject(err);
  }
);
export default instance;