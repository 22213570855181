/**
 * @version 1.0.0
 * @description Template de página de autenticación
 * 
 * @param {Object} props - props
 * 
 * @returns {Object} - Page
 * 
 * @example <AuthPage>...</AuthPage>
 */
import {
  Box,
  Flex,
  Spacer,
  Stack,
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
  Tab,
  Image
} from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import { LogoFIF } from '../logos/LogoFIF';
import { LogoVive } from '../logos/LogoVive';

export const AuthPage = ({...params}) => {

  /** Variables */
  const { color = 'white' } = params;

  useEffect(() => {
  }, []);

  return (
    <Flex
      minH={'100vh'}
      align={'center'}
      justify={{
        sm: 'center',
        md: 'flex-start',
      }}
      bgColor="#137375"
      bgGradient="radial-gradient(circle, rgba(255,255,255,0.4038209033613446) 0%, rgba(255,255,255,0) 100%)"
      bgImage={`url('./bg.jpg')`}
      bgPos={'90% center'}
      bgSize={'cover'}
      >
      <Flex
        backdropFilter="auto"
        backdropBlur={'5px'}
        bgColor={'whiteAlpha.300'}
        width={{
          xl: 'full',
          sm: 'full',
          md: '50%',
        }}
        align={'center'}
        minH={'100vh'}
        p={'5'}
        maxW={{
          sm: '100%',
          md: '500px',
        }}
        >
        <Stack
          spacing={8}
          mx={'auto'}
          w={'full'}
          maxW={'md'}
          py={8}
          px={6}
          rounded={'lg'}
          bg={'white'}
          boxShadow={'lg'}
          // justify={'center'}
        >
          <Stack>
            <LogoVive
              rounded="xl"
              height="100px"
              draggable="false"
              loading="lazy"
              w={'100%'}
            />
          </Stack>

          <Stack
            minH="300"
          >
            {params.children}
          </Stack>


          {/* <LogoFIF
            rounded="lg"
            height="60px"
            draggable="false"
            loading="lazy"
            w={'100%'}
          /> */}

          <Box>
            <Image width={'100%'} loading="lazy" src='./logoFif.png' />
          </Box>
        </Stack>
      </Flex>
    </Flex>
  );
}
