import { Icon } from "@chakra-ui/react";
export const Colors = props => {
  let {color = '#565656'} = props;
  return <Icon  viewBox="0 0 41 40" {...props}><rect id="colors" x={0.079} y={0} width={40} height={40} style={{
    fill: "none"
  }} /><path id="Trazado" d="M4.959,36.8l30.24,-0" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: "#6d6ddc",
    strokeWidth: "3.36px"
  }} /><path id="path1" d="M4.959,30.08l30.24,0" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: "#0093ff",
    strokeWidth: "3.36px"
  }} /><path id="path2" d="M4.959,23.38l30.24,0" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: "#00d978",
    strokeWidth: "3.36px"
  }} /><path id="path3" d="M4.959,16.64l30.24,0" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: "#ffbf00",
    strokeWidth: "3.36px"
  }} /><path id="path4" d="M4.959,9.92l30.24,-0" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: "#ff9d00",
    strokeWidth: "3.36px"
  }} /><path id="path5" d="M4.959,3.2l30.24,0" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: "#ff5d4e",
    strokeWidth: "3.36px"
  }} /></Icon>
};