'use strict'

import { Box, Heading, Text, useToast } from "@chakra-ui/react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const useErrors = (error = null) => {
  const toast = useToast()
  let navigate = useNavigate();

  const showMessage = (error, redirect = true, showToast = true) => {
    let message = '';

    if(error?.data?.result === false) {
      if(error.data.response?.message) {
        message = error.data.response.message;
      }

      if(error.data.error?.message) {
        message = error.data.error?.message;
      }

    }

    if(!message && error?.data?.response) {
      error = error.data.response;
    }

    if (error) {
      if(error.hasOwnProperty('code')) {
        switch (error.code) {
          case 'ERR_NETWORK':
            message = 'Error en conexión de internet';
            break;
          case 204:
            message = 'Recurso no encontrado';
            break;
        }
      }

      if(message) {
        if (showToast) {
          toast({
            title: "Error",
            description: message,
            status: "error",
            duration: 2000,
            isClosable: true,
            // render: () => (
            //   <Box color="white" p={3} bg="red.500">
            //     <Text size="md">{message}</Text>
            //   </Box>
            // ),
          })
        }

        // if (redirect) {
        //   setTimeout(() => {
        //     navigate("/");
        //   }, 2000);
        // }
      }
    }
  }

  return showMessage;

  // useEffect(() => {

  // }, [error])
}

/**
 * Integer v1.0
 */

export const clarInvalidCharRuts = (value) => {
  return value.replace(/[^\dkK]/g, '');
}

/**
 * Tipos de formato
 * 
 * - 1 ->  00.000.000-0
 * - 2 ->  00000000-0
 * - 3 ->  000000000
 */
export const formatRut = (rut, type) => {
  var clearValue = rut.replace(/[^\dkK]/g, ''),
    result;
  type = type || 1;

  if(clearValue.length < 8) {
    return clearValue.toUpperCase();
  }

  if (type === 1) {
    result = clearValue.slice(-4, -1) + '-' + clearValue.substr(clearValue.length - 1);
    for (var i = 4; i < clearValue.length; i += 3) result = clearValue.slice(-3 - i, -i) + '.' + result;
  } else if (type === 2) {
    result = clearValue.slice(0, -1) + '-' + clearValue.substr(clearValue.length - 1);
  } else {
    result = clearValue;
  }

  return result.toUpperCase();
}

/**
 * Tipos de formato
 * - 1 ->  00.000.000
 * - 2 ->  00.000.000,00
 */
export const formatNumber = (value, type) => {
  let decimal;

  value = numberClean(value);

  if(!type) type = 1;

  if(type === 2){
    value = value.toFixed(2);
  }

  decimal = value.toString().replace(/(\d*\.?)/, '');
  if(decimal) decimal = ',' + decimal.replace(/\D*/, '');

  value = ("" + parseInt(value)).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, function($1) {
    return $1 + "."
  });

  return value + decimal;
}

/**
 * Limpia todo carácter que no sea número
 */
export const numberClean = (num, type) => {
  if(!num || num === Infinity) return 0;
  if(typeof num == 'number') return num;
  return parseInt(num.replace(/[\D,]/g, ''));
}

/**
 * Valida RUT
 */
export const validateRut = (rut) => {
  let arr_rut, T;
  let clearValue = rut.replace(/[^\dkK]/g, '').toUpperCase();

  if(clearValue.length < 8) {
    return false;
  }

  if (/^[0-9]{6,9}\-?[0-9kK]{1}$/.test(clearValue)) {
    arr_rut = [clearValue.substr(0, clearValue.length - 1), clearValue.substr(clearValue.length - 1)];
    T = arr_rut[0];
    var M = 0,
      S = 1;
    for (; T; T = Math.floor(T / 10))
      S = (S + T % 10 * (9 - M++ % 6)) % 11;
    if (arr_rut[1] == (S ? S - 1 : 'K')) {
      return true;
    } else {
      return false;
    }
  }

  return false;
}

export const validateEmail = (email, report, field) => {
  const re = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, 'i');

  if (!re.test(email)) {
    return false;
  }

  return true;
}

/**
 * Permite en base a un Array retornar una lista HTML
 *
 * @param {Array} Array a convertir
 * @return {String} Lista HTML
 */
export const _arrayToList = (obj) => {
  var list;
  if (obj.length)
    list = '<ul><li>' + obj.join('</li><li>') + '</li></ul>';

  return list;
}

/**
 * Determina si es una fecha valida en formato DD[-/]MM[-/]YYYY
 *
 * @param {String} Fecha en formato DD[-/]MM[-/]YYYY
 * @return {Boolean} Contra el último día del mes del parámetro
 */
export const _isValidDate = (date) => {
  var part = date.split(/[\-\/]/),
    lastDays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

  // Validacion de formato
  if (!date.match(/^\d{1,2}[\-\/]\d{1,2}[\-\/]\d{4}$/))
    return false;

  // Validaciones básicas
  // if(part[0] < 1 && part[1] < 1 && part[1] >= 12)
  //   return false;

  // Si es divisible por 4 o divisible por 100 y 400
  if ((!(part[2] % 4) && part[2] % 100) || !(part[2] % 400))
    lastDays[1]++;

  return (part[0] <= lastDays[--part[1]]);
}

/**
 * Define la fecha en formato formato DD/MM/YYYY
 *
 * @param {String} Fecha en formato DD[-/]MM[-/]YYYY
 * @return {String} Fecha en formato DD/MM/YYYY
 */
export const _formatDate = (date) => {
  var part = date.split(/[\-\/]/);

  // Validacion de formato
  if (!date.match(/^\d{1,2}[\-\/]\d{1,2}[\-\/]\d{4}$/))
    return false;

  return part[0] + '-' + part[1] + '-' + part[2];
}

/**
 * Transforma la matriz a una jerarquía de elementos padre e hijos
 * 
 * @param {*} arr_elements 
 */
export const matrixToMenu = () => {
  let profiling = localStorage.getItem('profiling') !== 'undefined'?JSON.parse(localStorage.getItem('profiling')):null;

  if(!profiling) {
    return null;
  }

  return profiling.filter((item_element) => {
    if(!item_element.parent_menu && item_element.show_sidebar) {
      item_element.children = filterArray(profiling, 'parent_menu', item_element.element);

      return item_element;
    }
  }).sort((a, b) => {
    return a.priority - b.priority;
  })
}

/**
 * Transforma la matriz a una jerarquía de elementos padre e hijos ordenada por prioridad
 * 
 * @param {*} arr_elements 
 */
export const matrixToPriority = () => {
  let profiling = localStorage.getItem('profiling') !== 'undefined'?JSON.parse(localStorage.getItem('profiling')):null;

  if(!profiling) {
    return null;
  }

  return profiling.filter((item_element) => {
    if(!item_element.show_sidebar) {
      item_element.children = filterArray(profiling, 'priority', item_element.element);

      return item_element;
    }
  }).sort((a, b) => {
    return a.priority - b.priority;
  })
}

/**
 * Transforma la matriz a una jerarquía de elementos padre e hijos ordenada por prioridad
 * 
 * @param {*} arr_elements 
 */
export const matrixToCategories = () => {
  let profiling = localStorage.getItem('profiling') !== 'undefined'?JSON.parse(localStorage.getItem('profiling')):null;

  if(!profiling) {
    return null;
  }

  return profiling.filter((item_element) => {
    if(!item_element.group) {
      item_element.children = filterArray(profiling, 'group', item_element.element);

      return item_element;
    }
  }).sort((a, b) => {
    return a.priority - b.priority;
  })
}

/**
 * Filtra un array por un atributo y valor
 * 
 * @param {array} array 
 * @param {string} key 
 * @param {*} value 
 * @returns 
 */
export const filterArray = (array, key, value, operator = '===') => {
  if(!array || !array.length) {
    return [];
  }

  return array.filter((item) => {
    if(operator === '===') return (item[key] === value);
    if(operator === '!==') return (item[key] !== value);
    if(operator === '==')  return (item[key] == value);
    if(operator === '!=') return (item[key] != value);
    if(operator === '||') return (item[key] != value);
  });
}

/**
 * Conviete códificado en HTML a HTML en texto plano
 * 
 * @param {string} html 
 * @returns 
 */
export const htmlfy = (html) => {
  var textarea = document.createElement("textarea");
  textarea.innerHTML = html;
  return textarea.value;
}


/**
 * Transforma la matriz a una jerarquía de elementos padre e hijos
 * 
 * @param {string} path 
 */
 export const matrixToBreadcrumb = (path) => {
  let profiling = localStorage.getItem('profiling') !== 'undefined'?JSON.parse(localStorage.getItem('profiling')):null;

  if(!profiling || !path) {
    return null;
  }

  let items = [];
  let item_child = getMatrixItem(path, 'path');
  let item = [];

  if(item_child[0]?.element !== 'home') {
    if(item_child.length) {
      item_child[0].is_last = true;
      items.push(item_child[0]);
      item = getMatrixItem(item_child[0].parent_menu, 'element');
      if(item.length) {
        item[0].is_last = false;
        items.push(item[0]);
      } else {
        item = getMatrixItem(item_child[0].group, 'element');
        if(item.length) {
          item_child[0].is_last = false;
          items.push(item[0]);
        }
      }
    }

    // Elemento 'Principal'
    if(items.length) {
      item = getMatrixItem('home', 'element');
      if(item.length) {
        item[0].label = '';
        item.is_last = false;
        items.push(item[0]);
      }
    }


    items.reverse();
  }

  return items;
}

/**
 * Obtiene un elemento de la matriz de elementos
 * 
 * @param {*} value valor a buscar
 * @param {string} attribute atributo a buscar
 * @returns 
 */
export const getMatrixItem = (value, attribute = 'path') => {
  let profiling = localStorage.getItem('profiling') !== 'undefined'?JSON.parse(localStorage.getItem('profiling')):null;

  if(!profiling) {
    return null;
  }

  return profiling.filter((item_element) => {
    if(item_element?.[attribute] === value) {
      return item_element;
    }

    return null;
  })
}

/**
 * Divide un array en subarrays por un atributo
 * 
 * @param {array} array
 * @param {string} attribute
 */
export const groupBy = (array, attribute) => {
  if(!array || !array.length) {
    return [];
  }

  return array.reduce((acc, obj) => {
    let key = obj[attribute];
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(obj);
    return acc;
  }, {});
}

/**
 * Convierte un objeto en un array
 * 
 * @param {object} json 
 * @returns 
 */
const json2array = (json) => {
  var result = [];
  var keys   = Object.keys(json);

  keys.forEach(function(key){
      result.push(json[key]);
  });

  return result;
}

/**
 * Descarga un archivo en base a un string base64
 * 
 * @param {string} base64Data 
 * @param {string} filename 
 * @param {string} mimeType 
 */
export const downloadBase64File = (base64Data, filename, mimeType = 'application/pdf') => {
  var element = document.createElement('a');
  element.setAttribute('href', 'data:' + mimeType + ';base64,' + base64Data);
  element.setAttribute('download', filename);

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}

/**
 * Obtiene la estructura del los ítems de Boxes, para la pantalla principal
 * 
 * @param {*} dataset 
 * @returns 
 */
export const fix_datasetBoxes = (dataset) => {
  let profiling     = localStorage.getItem('profiling') !== 'undefined'?JSON.parse(localStorage.getItem('profiling')):null;
  let dataset_fixed = [];

  if(!profiling || !dataset) {
    return null;
  }

  dataset.map(dataset_item => {
    let box = {};
    box.content = {};

    Object.keys(dataset_item).map((attribute, i) => {
      let item = getMatrixItem(dataset_item['element'], 'element');
      box.priority = item[0]?.priority;

      if(['ID', 'element'].indexOf(attribute) !== -1) {
        box[attribute] = dataset_item[attribute];
      } else {
        box.content[attribute] = dataset_item[attribute];
      }
    });

    dataset_fixed.push(box);
  });

  dataset_fixed.sort((a, b) => {
    return a.priority - b.priority;
  });

  return dataset_fixed;
}

/**
 * Reconoce estructuras de tags y los convierte en HTML
 * 
 * @param {string} text 
 * @returns 
 */
export const addTags = (text) => {
  if(!text) return text;

  return text.replace(/(#[A-Z][a-zA-Z]+)/g, '<span class="tag">$1</span>')
}

/**
 * valida la cantidad de caracteres de un string
 * 
 * @param {string} text 
 * @returns 
 */
export const countCharacters = (text) => {
  let number_characters = text.length;
  return number_characters;
}

/**
 * valida la cantidad de ocurrencias de un string
 * 
 * @param {string} text 
 * @returns 
 */
export const repetitionsCharacters = (text) => {
  return (text.split('').filter((c, i, a)=>a.indexOf(c) !== i).length)
}

/**
 * Valida la clave con varios requerimientos
 * 
 * @param {string} password 
 * @returns 
 */
export const validatePassword = (password, password_validate) => {
  if(countCharacters(password) < 10){
    // return 'La clave debe tener 10 o más caracteres.'
    return false;
  }

  if(repetitionsCharacters(password) > 5){
    // return 'No puedes repetir caracteres más de 4 veces.'
    return false;
  }
  

  if(validationNumbersAndCharacters(password) === false){
    // return 'La clave debe contener números y caracteres especiales.'
    return false;
  }

  if(validateLowerCase(password) === false){
    // return 'La clave debe contener minúsculas.'
    return false;
  }

  if(validateUpperCase(password) === false){
    // return 'La clave debe contener mayúsculas.'
    return false;
  }

  if(password !== password_validate){
    // return 'La nueva clave no coincide con el campo de verificación.'
    return false;
  } else {
    return true;
  }
}

/**
 * 10 - Longitud de claves          => OK
 * 4  - Repetición de caracteres         => -
 * 4  - Ocurrencia de caracteres         => Validar
 * 0  - Carácteres en minúsculas         => -
 * 0  - Carácteres en mayúsculas         => -
 * 0  - Caracteres especiales            => -
 * 2  - Números o caracteres especiales  => Validar
 * 0  - Número de claves anteriores => 
 */

// 123!@^$%

/**
 * Validar que la clave contenga carácteres especiales y números
 * 
 * @param {string} text 
 * @returns 
 */
export const validationNumbersAndCharacters = (text) => {
  if(/\W/.test(text) && /\d/.test(text)) {
    return true;
  }

  return false;
}

/**
 * Validar que la clave contenga minúsculas
 * 
 * @param {string} text 
 * @returns 
 */
export const validateLowerCase = (text) => {
  if(/[a-z]/.test(text)) {
    return true;
  }

  return false;
}

/**
 * Validar que la clave contenga mayúsculas
 * 
 * @param {string} text 
 * @returns 
 */
export const validateUpperCase = (text) => {
  if(/[A-Z]/.test(text)) {
    return true;
  }

  return false;
}

