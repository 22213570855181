/** Imports */
import {Box, Stack} from '@chakra-ui/react';
import {filterArray, groupBy, useErrors} from '../../helpers/integer';
import {useEffect, useState} from 'react';
import {ProductCard} from '../../components/catalog/ProductCard';
import {ProductGrid} from '../../components/catalog/ProductGrid';
import {Loader} from '../../components/Loader';
import api from "../../helpers/api";
import {Page} from '../../components/templates/Page';
import {Message} from '../../components/templates/Message';
import {Subtitle} from '../../components/templates/Subtitle';
import confetti from 'canvas-confetti';
// import { analytics } from 'firebase-functions/v1';
// import { logEvent } from 'firebase/analytics';

const CatalogBirthdayPage = () => {

  /** Hooks */
  const showErrors                        = useErrors();
  const [dataGroupBy, setDataGroupBy]     = useState(false);
  const [reportMessage, setReportMessage] = useState();

  /** Variables */

  /** Configuración */
  const show_grouped    = false;          // Determina si se muestran los productos agrupados según criterio
  const group_attribute = 'subcategory';  // Criterio de agrupación

  /**
   * Obtiene el reporte de productos
   */
  const getCatalogReport = async () => {
    try {
      const response = await api.post('ReportServices/getCatalog')
      if (response.data.result == false) {
        setReportMessage(response.data.response.message);
      } else {
        setDataGroupBy(groupBy(filterArray(response.data.response.result, 'idCategory', '80', '==='), (show_grouped)?group_attribute:null));
      }

      // Confetti
      fire(0.25, {
        spread: 26,
        startVelocity: 55,
      });
      fire(0.2, {
        spread: 60,
      });
      fire(0.35, {
        spread: 100,
        decay: 0.91,
        scalar: 0.8
      });
      fire(0.1, {
        spread: 120,
        startVelocity: 25,
        decay: 0.92,
        scalar: 1.2
      });
      fire(0.1, {
        spread: 120,
        startVelocity: 45,
      });
    } catch (err) {
      showErrors(err);
    }
  }

  var count = 200;
  var defaults = {
    origin: { y: 0.7 }
  };

  const fire = (particleRatio, opts) => {
    confetti(Object.assign({}, defaults, opts, {
      particleCount: Math.floor(count * particleRatio)
    }));
  }

  useEffect(() => {
    getCatalogReport();
  }, [])

  if(dataGroupBy === false) {
    return <Loader />
  }

  return (
    <Page title="Regalo de cumpleaños" color="transparent" p={null} description="Queremos que disfrutes tu día de cumpleaños y lo celebres con toda la energía de un Gennial. Elige tu regalo de cumpleaños durante todo el año calendario y no olvides pedir tu día libre en Mi Gestión." >
      {(Object.keys(dataGroupBy).length)?<Stack>
        {
          Object.keys(dataGroupBy).map(
            (dataGroupBy_key, dataGroupBy_i) => {
              return <Box key={dataGroupBy_i}>
                {show_grouped && <Subtitle>{dataGroupBy[dataGroupBy_key][0]?.subcategory}</Subtitle>}
                <ProductGrid>
                  {
                    dataGroupBy[dataGroupBy_key].map((product, i) => {
                      return <ProductCard key={i} product={product} route_origin="birthday" />
                    })
                  }
                </ProductGrid>
              </Box>
            }
          )
        }
      </Stack>:<Message type="info">Sin stock disponible</Message>}
    </Page>
  );
}

export default CatalogBirthdayPage;
