/** Imports */
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  AspectRatio,
  Box, 
  Text
} from '@chakra-ui/react';
import { useErrors, htmlfy, addTags } from '../../helpers/integer';
import { Loader } from '../../components/Loader';
import { motion } from "framer-motion";
import { useNavigate, useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import api from '../../helpers/api';
import { Page } from '../../components/templates/Page';
import { Icons } from '../../components/templates/Icons';
// import { analytics } from 'firebase-functions/v1';
// import { logEvent } from 'firebase/analytics';

const MyBenefitsPage = () => {

  /** Hooks */
  const showErrors                            = useErrors();
  const [answerPrimary, setAnswerPrimary]     = useState(false);
  const [answerSecundary, setAnswerSecundary] = useState(false);

  let navigate = useNavigate();
  const { id } = useParams('id');

  const url_service = 'ReportServices/getReportHomeBoxesByID';

  const request = {
    'report_id': 101167,
    'filter_name': 'answer_id',
    'filter_value': id
  };

  /**
   * Reporte de Answers Seguro Complementario
   */
  const getReportAnswer = async () => {
    try {
      const response = await api.post(url_service, request)
      setAnswerPrimary(response.data.response.result_father_box[0]);
      setAnswerSecundary(response.data.response.result_son_box);
      showErrors(response, false);
    } catch (err) {
      console.log(err);
      showErrors(err);
    }
  }

  useEffect(() => {
    getReportAnswer();
  }, [])

  if(answerPrimary === false) {
    return <Loader />;
  }

  return (
    <Page
      title={answerPrimary?.summary}
      description="Revisa tus beneficios que clasificamos para ti."
      path_name="/benefits/home"
    >
      <>
        <Accordion allowToggle>
        {
          answerSecundary?.map((answer, answer_i) => (
            <AccordionItem key={answer_i}>
              {
                ({isExpanded}) => (
                  <>
                    <AccordionButton>
                      <Box>
                        {answer?.summary}
                      </Box>
                      <Icons icon={(isExpanded)?'arrow_up':'arrow_down'} color={(isExpanded)?'white':'grey'} />
                    </AccordionButton>
                    <AccordionPanel pb={4}>
                      <Text align={'left'} dangerouslySetInnerHTML={{ __html: htmlfy(addTags(answer?.solution)) }} />
                    </AccordionPanel>
                  </>
                )
              }
            </AccordionItem>
          ))
        }
        </Accordion>
      </>
    </Page>
  );
}

export default MyBenefitsPage;