import { Icon } from "@chakra-ui/react";
export const Boxes = props => {
  let {color = '#565656'} = props;
  return <Icon  viewBox="0 0 40 40" {...props}><rect id="boxes" x={0} y={0} width={40} height={40} style={{
    fill: "none"
  }} /><path id="path6" d="M14.783,22.399c0.618,-0.016 1.216,0.223 1.653,0.66c0.437,0.437 0.675,1.034 0.66,1.652l0.01,8.567c0.016,0.618 -0.222,1.215 -0.66,1.652c-0.437,0.438 -1.034,0.676 -1.652,0.66l-8.588,0.011c-0.618,0.016 -1.215,-0.223 -1.652,-0.66c-0.438,-0.437 -0.676,-1.034 -0.66,-1.652l-0,-8.578c-0.016,-0.618 0.222,-1.215 0.66,-1.652c0.437,-0.437 1.034,-0.676 1.652,-0.66l8.577,0Z" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path61" d="M24.39,4.399c0.618,-0.016 1.216,0.223 1.653,0.66c0.437,0.437 0.675,1.034 0.659,1.652l0.011,8.567c0.016,0.618 -0.223,1.215 -0.66,1.652c-0.437,0.438 -1.034,0.676 -1.652,0.66l-8.588,0.011c-0.618,0.016 -1.215,-0.223 -1.653,-0.66c-0.437,-0.437 -0.675,-1.034 -0.659,-1.652l-0,-8.578c-0.016,-0.618 0.222,-1.215 0.659,-1.652c0.438,-0.437 1.035,-0.676 1.653,-0.66l8.577,0Z" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path62" d="M33.783,22.399c0.618,-0.016 1.216,0.223 1.653,0.66c0.437,0.437 0.675,1.034 0.66,1.652l0.01,8.567c0.016,0.618 -0.222,1.215 -0.66,1.652c-0.437,0.438 -1.034,0.676 -1.652,0.66l-8.588,0.011c-0.618,0.016 -1.215,-0.223 -1.652,-0.66c-0.438,-0.437 -0.676,-1.034 -0.66,-1.652l-0,-8.578c-0.016,-0.618 0.222,-1.215 0.66,-1.652c0.437,-0.437 1.034,-0.676 1.652,-0.66l8.577,0Z" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /></Icon>
};