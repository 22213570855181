'use strict';

// import reportWebVitals from './reportWebVitals';
import React, { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import { ChakraProvider, ColorModeScript, extendTheme } from '@chakra-ui/react';
import { BrowserRouter } from "react-router-dom";
import myTheme from './theme';
import { createContext } from 'react';
import GlobalData from './components/context/GlobalData';

createRoot(
  document.getElementById('root')
).render(
  <ChakraProvider theme={myTheme} colorScheme="teal">
    <BrowserRouter>
        <GlobalData>
          <App />
        </GlobalData>
    </BrowserRouter>
  </ChakraProvider>
)
