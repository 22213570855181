import { Icon } from "@chakra-ui/react";
export const Triangle = props => {
  let {color = '#565656'} = props;
  return <Icon  viewBox="0 0 40 40" {...props}><rect id="triangle" x={0} y={0} width={40} height={40} style={{
    fill: "none"
  }} /><path d="M17.409,10.668c0.586,-0.854 1.555,-1.364 2.591,-1.364c1.036,-0 2.005,0.51 2.591,1.364c3.168,4.613 8.538,12.432 12.028,17.515c0.661,0.962 0.734,2.21 0.191,3.243c-0.544,1.033 -1.615,1.679 -2.782,1.679c-6.666,0 -17.39,0 -24.056,0c-1.167,0 -2.238,-0.646 -2.782,-1.679c-0.543,-1.033 -0.47,-2.281 0.191,-3.243c3.49,-5.083 8.86,-12.902 12.028,-17.515Z" style={{
    fill: "none",
    stroke: color,
    strokeWidth: "3.36px"
  }} /></Icon>
};