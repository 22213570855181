import { Stack, CircularProgress, HStack, Spinner, useMultiStyleConfig } from '@chakra-ui/react';
import * as React from 'react'
import { useEffect } from 'react'

export const Loader = (props) => {
  const styles = useMultiStyleConfig('Progress', {})

  useEffect(() => {
  }, [])

  return (
    <Stack
      w="full"
      h="full"
      align={'center'}
      justify={'center'}
      bg="blackAlpha.200"
      position={'fixed'}
      left={0}
      top={0}
    >
      <CircularProgress
        isIndeterminate
        size='150px'
        color={styles.filledTrack.backgroundColor}
        trackColor={styles.track.backgroundColor}
      />
    </Stack>
  )
}