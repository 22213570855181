import { Icon } from "@chakra-ui/react";
export const Heart = props => {
  let {color = '#565656'} = props;
  return <Icon  viewBox="0 0 40 40" {...props}><rect id="heart" x={0} y={0} width={40} height={40} style={{
    fill: "none"
  }} /><path id="path289" d="M13.231,6.106c-2.299,0.009 -4.498,0.938 -6.108,2.58c-1.61,1.641 -2.497,3.858 -2.463,6.156c0,6.107 7.744,13.685 12.176,17.768c1.753,1.752 4.593,1.752 6.346,-0c4.414,-4.083 12.157,-11.661 12.157,-17.768c0.04,-2.3 -0.845,-4.52 -2.456,-6.162c-1.611,-1.642 -3.814,-2.569 -6.115,-2.574c-2.786,-0.263 -5.436,1.254 -6.621,3.789c-1.377,-2.496 -4.071,-3.973 -6.916,-3.789Z" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /></Icon>
};