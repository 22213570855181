import { Icon } from "@chakra-ui/react";
export const Tree = props => {
  let {color = '#565656'} = props;
  return <Icon  viewBox="0 0 41 40" {...props}><rect id="tree" x={0.079} y={0} width={40} height={40} style={{
    fill: "none"
  }} /><path id="path20" d="M20.079,34.26l-0,-10.06" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path21" d="M33.519,23.46c-0,3.711 -3.009,6.72 -6.72,6.72c-3.712,-0 -6.72,-3.009 -6.72,-6.72c-0,-3.711 3.008,-6.72 6.72,-6.72c3.711,0 6.72,3.009 6.72,6.72Z" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path211" d="M20.079,23.46c-0,3.711 -3.009,6.72 -6.72,6.72c-3.712,-0 -6.72,-3.009 -6.72,-6.72c-0,-3.711 3.008,-6.72 6.72,-6.72c3.711,0 6.72,3.009 6.72,6.72Z" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path212" d="M26.799,11.46c-0,3.711 -3.009,6.72 -6.72,6.72c-3.712,-0 -6.72,-3.009 -6.72,-6.72c-0,-3.711 3.008,-6.72 6.72,-6.72c3.711,0 6.72,3.009 6.72,6.72Z" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /></Icon>
};