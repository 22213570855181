/** Imports */
import {
  Box,
  Grid,
  Heading,
  Link,
  Text
} from '@chakra-ui/react';
import { useErrors } from '../../helpers/integer';
import { useState } from 'react';
import api from "../../helpers/api";
import { useEffect } from 'react';
import { Page } from '../../components/templates/Page';
import { ProductGrid } from '../../components/catalog/ProductGrid';
import { Loader } from '../../components/Loader';
import { Card } from '../../components/card/Card';
import { Title } from '../../components/templates/Title';
// import { analytics } from 'firebase-functions/v1';
// import { logEvent } from 'firebase/analytics';

const AgreementCompensationPage = () => {

  /** Hooks */
  const showErrors = useErrors();
  const [data, setData] = useState(false);

  const url_service = 'ReportServices/getReportHomeBoxes';

  const report = {
    'report_id': 101126
  };

  /**
   * Reporte Answer Caja de Compensación
   */
  const getReportCompensation = async () => {
    try {
      const response = await api.post(url_service, report)
      setData((!!response.data.response.result_father_box)?response.data.response.result_father_box:[]);
      showErrors(response, false);
    } catch (err) {
      showErrors(err);
    }
  }

  useEffect(() => {
    getReportCompensation();
  }, [])

  if(data === false) {
    return <Loader />
  }

  if(!data) {
    debugger;
  }

  return (
    <Page title="Caja de compensación" p="0" color="transparent" description={
      <Text>
        Nuestra empresa se encuentra afiliada a la Caja de Compensación Los Héroes. A través de ella podrás incorporar a tu cónyuge, conviviente civil o hijos como cargas legales, recibir beneficios y disfrutar de los convenios y actividades que la Caja tiene para sus afiliados. Encuentra en esta sección cómo incorporar cargas y las campañas de beneficios vigentes. Para más información visita el sitio <Link href="https://www.losheroes.cl/" isExternal>www.cajalosheroes.cl</Link></Text>
    }>
      {(!!data && data?.length !== 0)?
        <ProductGrid>
          {data?.map((benefit) => (
            <Card key={benefit?.id} data={benefit} src={benefit?.answer_image} click={'/agreements/compensation/'} />
          ))}
        </ProductGrid>
      :<Text>Sin Datos</Text>}
    </Page>
  );
}

export default AgreementCompensationPage;