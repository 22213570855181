/** Imports */
import { Box, Heading, Stack, Table, TableContainer, Tbody, Td, Text, Tfoot, Th, Thead, Tr } from '@chakra-ui/react';
import { useErrors } from '../../helpers/integer';
import { useEffect, useState } from 'react';
import { ProductCard } from '../../components/catalog/ProductCard';
import { ProductGrid } from '../../components/catalog/ProductGrid';
import { Loader } from '../../components/Loader';
import { motion } from "framer-motion";
import api from "../../helpers/api";
import { Page } from '../../components/templates/Page';
import { Title } from '../../components/templates/Title';
import { Legend } from '../../components/templates/Legend';

const ThemePage = () => {

  /** Hooks */
  const showErrors = useErrors();

  useEffect(() => {

  }, [])

  return (
    <Page title="Tablas">
      <Title>Tabla ChakraUI</Title>
      <Table>
        <Thead>
          <Tr>
            <Th>Producto</Th>
            <Th>Descripción</Th>
            <Th>Valor</Th>
            <Th>Donar</Th>
          </Tr>
        </Thead>
        <Tbody>
          <Tr>
            <Td>Producto 1</Td>
            <Td>Descripción 1</Td>
            <Td>Valor 1</Td>
            <Td>Donar 1</Td>
          </Tr>
          <Tr>
            <Td>Producto 2</Td>
            <Td>Descripción 2</Td>
            <Td>Valor 2</Td>
            <Td>Donar 2</Td>
          </Tr>
          <Tr>
            <Td>Producto 3</Td>
            <Td>Descripción 3</Td>
            <Td>Valor 3</Td>
            <Td>Donar 3</Td>
          </Tr>
        </Tbody>
        <Tfoot>
          <Tr>
            <Td>Producto 4</Td>
            <Td>Descripción 4</Td>
            <Td>Valor 4</Td>
            <Td>Donar 4</Td>
          </Tr>
        </Tfoot>
      </Table>

      <Title>Tabla HTML</Title>
      <table>
        <thead>
          <tr>
            <th>producto</th>
            <th>descripción</th>
            <th>valor</th>
            <th>donar</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>producto 1</td>
            <td>descripción 1</td>
            <td>valor 1</td>
            <td>donar 1</td>
          </tr>
          <tr>
            <td>producto 2</td>
            <td>descripción 2</td>
            <td>valor 2</td>
            <td>donar 2</td>
          </tr>
          <tr>
            <td>producto 3</td>
            <td>descripción 3</td>
            <td>valor 3</td>
            <td>donar 3</td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td>producto 4</td>
            <td>descripción 4</td>
            <td>valor 4</td>
            <td>donar 4</td>
          </tr>
        </tfoot>
      </table>

    </Page>
  );
}

export default ThemePage;
