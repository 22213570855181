import { Icon } from "@chakra-ui/react";
export const Circles = props => {
  let {color = '#565656'} = props;
  return <Icon  viewBox="0 0 40 40" {...props}><rect id="circles" x={0} y={0} width={40} height={40} style={{
    fill: "none"
  }} /><path id="path117" d="M23.36,14.12c-0,1.856 -1.504,3.36 -3.36,3.36c-1.856,0 -3.36,-1.504 -3.36,-3.36c-0,-1.856 1.504,-3.36 3.36,-3.36c1.856,0 3.36,1.504 3.36,3.36Z" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path119" d="M11.6,25.88c-0,1.856 -1.504,3.36 -3.36,3.36c-1.856,0 -3.36,-1.504 -3.36,-3.36c0,-1.856 1.504,-3.36 3.36,-3.36c1.856,0 3.36,1.504 3.36,3.36Z" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path1191" d="M11.6,25.88c-0,1.856 -1.504,3.36 -3.36,3.36c-1.856,0 -3.36,-1.504 -3.36,-3.36c0,-1.856 1.504,-3.36 3.36,-3.36c1.856,0 3.36,1.504 3.36,3.36Z" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path121" d="M35.12,25.88c-0,1.856 -1.504,3.36 -3.36,3.36c-1.856,0 -3.36,-1.504 -3.36,-3.36c-0,-1.856 1.504,-3.36 3.36,-3.36c1.856,0 3.36,1.504 3.36,3.36Z" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path1192" d="M11.6,25.88c-0,1.856 -1.504,3.36 -3.36,3.36c-1.856,0 -3.36,-1.504 -3.36,-3.36c0,-1.856 1.504,-3.36 3.36,-3.36c1.856,0 3.36,1.504 3.36,3.36Z" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /></Icon>
};