/** Imports */
import {
  Box,
  Grid,
  Heading,
  Text
} from '@chakra-ui/react';
import { useErrors } from '../../helpers/integer';
import { useState } from 'react';
import api from "../../helpers/api";
import { useEffect } from 'react';
import { Page } from '../../components/templates/Page';
import { ProductGrid } from '../../components/catalog/ProductGrid';
import { Loader } from '../../components/Loader';
import { Card } from '../../components/card/Card';
import { Title } from '../../components/templates/Title';
// import { analytics } from 'firebase-functions/v1';
// import { logEvent } from 'firebase/analytics';

const DiscountBoxes = () => {

  /** Hooks */
  const showErrors = useErrors();
  const [data, setData] = useState(false);

  const url_service = 'ReportServices/getReportHomeBoxes';

  const report = {
    'report_id': 101235
  }

  /**
   * Reporte Answer Caja de Compensación
   */
  const getReportCompensation = async () => {

    try {
      const response = await api.post(url_service, report)
      setData((!!response.data.response.result_father_box)?response.data.response.result_father_box:[]);
      showErrors(response, false);
    } catch (err) {
      showErrors(err);
    }
  }

  useEffect(() => {
    getReportCompensation();
  }, [])

  if(data === false) {
    return <Loader />
  }

  return (
    <Page title="Descuentos de Invierno" p="0" color="transparent" path_name="/agreements/discounts">
      {!!data && data.length != 0
        ?
        <ProductGrid templateColumns={ {base: '1', sm:'repeat(1, 1fr)',  md:'repeat(2, 1fr)', xl:'repeat(4, 1fr)'} }>
          {
            data.map((item, i) => (
                <Card key={i} data={item} src={item.answer_image} click={'/agreements/agreement/'} />
            ))
          }
        </ProductGrid>
        :
        <Text>Sin Datos</Text>
      }
    </Page>
  );
}

export default DiscountBoxes;
