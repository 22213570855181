/** Imports */
import {
  AspectRatio,
  Box,
  Button,
  Grid,
  GridItem,
  Heading,
  HStack,
  Image,
  Stack,
  Text
} from '@chakra-ui/react';
import { useErrors } from '../../helpers/integer';
import { useState } from 'react';
import api from '../../helpers/api';
import { useEffect } from 'react';
import { Page } from '../../components/templates/Page';
import { Loader } from '../../components/Loader';
import { Title } from '../../components/templates/Title';
import { Card } from '../../components/card/Card';
import { ProductGrid } from '../../components/catalog/ProductGrid';
// import { analytics } from 'firebase-functions/v1';
// import { logEvent } from 'firebase/analytics';

const BenefitsPage = () => {

  /** Hooks */
  const showErrors = useErrors();
  const [data, setData] = useState(false);

  // URL API
  const url_answer = 'ReportServices/getReportHomeBoxes';


  const request = {
    'report_id': 101167
  };

  /**
 * Obtenemos el Objeto Answer
 */
  const getReportAnswer = async () => {
    try {
      const response = await api.post(url_answer, request);

      if (response?.data?.result !== false) {
        setData(response?.data?.response?.result_father_box);
      } else {
        setData(response.data.response.result);
      }
      showErrors(response, false);
    } catch (err) {
      showErrors(err);
    }
  }

  useEffect(() => {
    getReportAnswer();
  }, [])

  if(data === false) {
    return <Loader />;
  }

  return (
    <Page
      title="Mis beneficios"
      color="transparent"
      p={null}
      description="Vive nuestro propósito de Simplificar y Disfrutar más la vida con nuestros beneficios. Encuentra todos tus beneficios, requisitos y cómo pedirlos en la sección Beneficios. Sabemos que a lo largo de tu vida tienes diferentes experiencias y necesidades, por esto hemos preparado la sección Disfruta tus beneficios en donde los encontrarás segmentados por tus distintas necesidades."
    >

      {
        !!data?
        <>
          <ProductGrid>
            {
              data?.map((answer, i) => {
                  return <Card key={i} data={answer} click={'/benefits/detail/'} />
                }
              )
            }
          </ProductGrid>
        </>
        :
        <Text>Sin Datos</Text>
      }
    </Page>
  );
}

export default BenefitsPage;