import {
    Text,
    GridItem,
    Link,
    Skeleton,
    Image,
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    useDisclosure,
    ModalHeader,
    ModalCloseButton,
    ModalFooter,
    useToast
  } from '@chakra-ui/react';
  import * as React from 'react'
  import { useState } from 'react';
  import { Link as LinkRRD, useNavigate } from 'react-router-dom';
  import api from "../helpers/api";
  import { useErrors } from '../helpers/integer';

  export const ApprovalButton = (props) => {

    // Props
    const { name }             = props;
    const { modaldescription } = props;
    const { modalnamebutton }  = props;
    const { incident_id }      = props;
    const { is_free_time }     = props;
    const { id_exchange }      = props;
    const { status_id }        = props;
    const { color }            = props;

    /** Hooks */
    const [isLoading, setIsLoading]   = useState(false);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [reportData, setReportData] = useState([]);
    const showErrors                  = useErrors();
    const navigate                    = useNavigate()
    const toast                       = useToast();

    /** Constantes */
    const url_service = 'IncidentServices/updateStatusIncident';

    /**
    * Actualizar estado de incidente
    */
    const setStatusIncident = async () => {
      try {
        setIsLoading(true);
        const response = await api.post(url_service, {"incident_id": incident_id ,"status_id"  : status_id, "is_free_time": is_free_time, "id_exchange": id_exchange})
        setReportData(response?.data?.response?.result);
        setIsLoading(false);
        toast({
        title: response.data.response.message,
        status: 'success',
        duration: 4000,
        isClosable: true,
      })
      navigate('/admin/approvalshistorical');
      } catch (err) {
      setIsLoading(false);
      onclose();
        showErrors(err);
      }
    }

    return <>
    <Button onClick={onOpen} {...props}>{name}</Button>
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{modaldescription}</ModalHeader>
        <ModalCloseButton />
        <ModalFooter>
          <Button
            mr={1}
            isLoading= {isLoading}
            loadingText="Actualizando..."
            onClick={setStatusIncident}
          >
          {modalnamebutton}
          </Button>
          <Button
            onClick={onClose}
            variant="outline"
            >Cancelar</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
    </>
  }