import { Icon } from "@chakra-ui/react";
export const User = props => {
  let {color = '#565656'} = props;
  return <Icon  viewBox="0 0 41 40" {...props}><rect id="user" x={0.079} y={0} width={40} height={40} style={{
    fill: "none"
  }} /><path id="path224" d="M25.119,16.64c-0,2.783 -2.257,5.04 -5.04,5.04c-2.784,-0 -5.04,-2.257 -5.04,-5.04c-0,-2.784 2.256,-5.04 5.04,-5.04c2.783,0 5.04,2.256 5.04,5.04Z" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path225" d="M13.359,35.12l-0,-6.72c-0,-3.711 3.008,-6.72 6.72,-6.72c3.711,-0 6.72,3.009 6.72,6.72l-0,6.72" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path226" d="M36.879,20c-0,9.278 -7.522,16.8 -16.8,16.8c-9.279,0 -16.8,-7.522 -16.8,-16.8c-0,-9.278 7.521,-16.8 16.8,-16.8c9.278,-0 16.8,7.522 16.8,16.8Z" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /></Icon>
};