import { Icon } from "@chakra-ui/react";
export const Here = props => {
  let {color = '#565656'} = props;
  return <Icon  viewBox="0 0 41 40" {...props}><rect id="here" x={0.079} y={0} width={40} height={40} style={{
    fill: "none"
  }} /><path id="path105" d="M25.709,15.917c-0,3.248 -2.633,5.88 -5.88,5.88c-3.248,0 -5.88,-2.632 -5.88,-5.88c-0,-3.247 2.632,-5.88 5.88,-5.88c3.247,0 5.88,2.633 5.88,5.88Z" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path172" d="M6.889,16.377c-0,-7.284 5.905,-13.19 13.19,-13.19c7.284,0 13.19,5.906 13.19,13.19c-0,3.59 -2.31,7.84 -5.28,11.76c-2.424,3.121 -5.067,6.066 -7.91,8.81c-2.844,-2.746 -5.491,-5.69 -7.92,-8.81c-2.96,-3.92 -5.27,-8.17 -5.27,-11.76Z" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /></Icon>
};