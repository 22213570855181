/**
 * @version 1.0.0
 * @description Template sombra de tarjeta
 * 
 * @param {Object} props - props
 * 
 * @returns {Object} - ShadowCard
 * 
 * @example <ShadowCard>...</ShadowCard>
 */
import {
  Box
} from '@chakra-ui/react';
import { useState, useEffect } from 'react';

export const ShadowCard = ({...params}) => {

  /** Variables */
  const { type } = params;
  const { color = 'white' } = params;

  useEffect(() => {
  }, []);

  return (
    <Box
      borderRadius={'20'}
      borderTopRadius={'20'}
      boxShadow={'lg'}
      _hover={{ boxShadow: 'xl' }}
      transition={'box-shadow 0.1s ease-in-out'}
      border="1px solid #eee"
      {...color && {bg:color}}
    >
      {params.children}
  </Box>
  );
}
