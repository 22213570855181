/** Imports */
import {
  Box,
  Button,
  Grid,
  Heading,
  SimpleGrid,
  Stack,
  Text,
  useToast,
  VStack
} from '@chakra-ui/react';
import { useErrors, validateEmail } from '../../helpers/integer';
import { useContext, useState } from 'react';
import { useEffect } from 'react';
import { Page } from '../../components/templates/Page';
import { Loader } from '../../components/Loader';
import { Title } from '../../components/templates/Title';
import { useForm }              from 'react-hook-form';
import { GlobalDataContext }    from '../../components/context/GlobalData';
// import { analytics } from 'firebase-functions/v1';
// import { logEvent } from 'firebase/analytics';

// Helpers
import api from "../../helpers/api";
import { Legend } from '../../components/templates/Legend';
import { InputField } from '../../components/input/InputField';


const EmailsPage = () => {

  /** Hooks */
  const showErrors = useErrors();
  const [data, setData] = useState({});
  const [globalData, setGlobalData] = useContext(GlobalDataContext);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    isDirty,
    control,
    setValue
  } = useForm({});

  const toast = useToast();
  const updateEmails = 'ContactServices/updateContactEmails';

  useEffect(() => {
  }, [])

  /**
   * Envio del formulario
   */
   const onSubmit = async (data) => {
    setData(data);

    try {
      const response = await api.post(updateEmails, data);

      if(response.data.result === true) {
        toast({
          title     : 'Formulario enviado con Éxito',
          status    : 'success',
          duration  : 3000,
          isClosable: true,
        })
      }
      showErrors(response, false);
    } catch (err) {
      showErrors(err);
    }
  }

  if(!globalData?.contact) {
    return <Loader />;
  }

  return (
    <Page title="Mis correos electrónicos">
      <Legend>Actualiza tu información</Legend>

      <form onSubmit={handleSubmit(onSubmit)}>
        <SimpleGrid columns={{ base: '1', lg: '2', xl: '3' }} spacing={4} p={5}>
          <InputField
            label="Correo electrónico principal"
            defaultValue={globalData?.contact?.Emails[0]}
            name="Contact.Emails[0]"
            type="text"
            placeholder=""
            register={register}
            validate={
              (value) => {
                value = value.replace(/\s/gi, '')

                if(/@((banco)?falabella\.(cl|com)|integer.cl)$/.test(value)) {
                  return validateEmail(value) ? true : 'Correo Electrónico inválido';
                } else {
                  return 'Debes ingresar un correo corporativo';
                }
              }
            }
            setValueAs = {
              (value) => {
                value = value.replace(/\s/gi, '');
                return value;
              }
            }
            errors={errors}
            required={true} />
          <InputField label="Correo electrónico secundario" defaultValue={globalData?.contact?.Emails[1]} name="Contact.Emails[1]" type="email" placeholder="" register={register} errors={errors} required={true} />
        </SimpleGrid>
        <Stack maxW={'200'} py={'6'}>
          <Button
            type="submit"
            isLoading={isSubmitting}
            loadingText="Actualizando..."
            disabled={isSubmitting}
          >
            Actualizar mis Datos
          </Button>
        </Stack>
      </form>
    </Page>
  );
}

export default EmailsPage;