
import {
  Box,
  Button,
  Divider,
  Flex,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  Progress,
  Stack,
  CloseButton,
  Text,
  Icon,
  Code
} from '@chakra-ui/react';
import { useNavigate, Link as LinkRRD } from "react-router-dom";
import { NavButton } from './NavButton.jsx'
import { LogoVive  } from '../logos/LogoVive';
import { useEffect, useState } from 'react';
import { matrixToMenu } from '../../helpers/integer';
import { Icons } from '../templates/Icons.jsx';

function Sidebar({onClose, ...params}) {
  let navigate = useNavigate();
  const [profiling, setProfiling] = useState();

  useEffect(() => {
    setProfiling(matrixToMenu(null));
  }, []);

  return (
    <Flex
      // bg="white"
      bg="white"
      w={{ base: "full", xl: '300px', md: '250px' }}
      pos="fixed"
      h="full"
      {...params}>
      <Stack justify="flex-start" h="full" spacing="0" w="full">
        <Box
          display={{ base: "flex", md: "none" }}
          onClick={onClose}
          variant="outline"
          aria-label="open menu"
          w="40px"
          h="40px"
          m="2"
          position={'fixed'}
          p="1.5"
          borderRadius="sm"
        >
          <Icons icon="close" w="24px" h="24px" />
        </Box>
        <Box w="full" m="0 auto" p="3" cursor={'pointer'} onClick={() => { navigate('/home'); }}>
          <LogoVive
            height="80px"
            draggable="false"
            loading="lazy"
            w={'100%'}
          />
        </Box>

        {/* Menú superior */}
        <Stack
          p={{base: 1, xl: 2.5}}
          h="full"
          overflowY="auto"
          overflowX={'hidden'}
          css={{
            '&::-webkit-scrollbar': {
              width: '4px',
            },
            '&::-webkit-scrollbar-track': {
              width: '6px',
            },
            '&::-webkit-scrollbar-thumb': {
              background: 'grey',
              borderRadius: '24px',
            },
          }}
        >
          <Stack spacing="1px">
            {(profiling)?profiling.map((item, index) => {
              return (
                <NavButton
                  key={index}
                  icon={item.icon}
                  label={item.label}
                  path={item.path}
                  {...!item.children.length && {callback:() => onClose()}}
                  {...(item.children.length && {children:item.children.map((d, i) => {
                    return <NavButton
                    key={i}
                    icon={d.icon}
                    label={d.label}
                    path={d.path}
                    callback={() => onClose()}
                  />
                  })})}
                />
              )
            }
            ):null}
          </Stack>
        </Stack>

        {/* Menú inferior */}
        <Stack
          p={1}
        >
            <NavButton label="Cerrar Sesión" icon={'singout'} path='/logout' />
        </Stack>
      </Stack>
    </Flex>
  );
}

export default Sidebar;
