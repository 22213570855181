import {
    Text,
    GridItem,
    Link,
    Skeleton,
    Image,
    HStack,
    Stack,
    Grid,
    VStack
  } from '@chakra-ui/react';
  import * as React from 'react'
  import { htmlfy, useErrors } from '../../helpers/integer';
  import { Link as LinkRRD } from 'react-router-dom';
  import { ShadowBox } from '../templates/ShadowBox';
  import { GlobalDataContext } from '../../components/context/GlobalData';
  import { useContext } from 'react';

  export const BoxDiary = (props) => {

    const [globalData, setGlobalData] = useContext(GlobalDataContext);
    const  answer  = props;
    const { link = '/diary' } = props;

    return <ShadowBox colSpan={{base: 1, lg: 1, xl: 1}}>
      Diario: Desarrollo Fase II
    </ShadowBox>;

    return <ShadowBox colSpan={{base: 1, lg: 2}}>
        <Text fontSize='xl' fontWeight={'semibold'}>{answer?.content?.summary}</Text>
        <Grid
          gap={3}
          >
          <GridItem>
          {(link) 
            ?
            <>
              <Text py={5} dangerouslySetInnerHTML={{ __html: htmlfy(answer?.content?.solution) }} />
              <Link as={LinkRRD} to={link}>
                {/* {button} */}
              </Link>
            </>
            : null
          }
          </GridItem>
        </Grid>
    </ShadowBox>;
  }
