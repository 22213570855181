import { Icon } from "@chakra-ui/react";
export const Configuration = props => {
  let {color = '#565656'} = props;
  return <Icon  viewBox="0 0 41 40" {...props}><rect id="configuration" x={0.079} y={0} width={40} height={40} style={{
    fill: "none"
  }} /><path id="path238" d="M29.265,29.404l-0.64,0.47c-1.172,0.913 -2.486,1.629 -3.89,2.12l-0.7,0.29l-0.18,1.36c-0.239,1.802 -1.772,3.151 -3.59,3.16l-0.44,0c-1.816,-0.013 -3.346,-1.36 -3.59,-3.16l-0.18,-1.36l-0.61,-0.24c-1.424,-0.541 -2.77,-1.271 -4,-2.17l-0.65,-0.47l-1.54,0.62c-1.635,0.655 -3.506,0.044 -4.44,-1.45l0,-0.06c-1.004,-1.63 -0.565,-3.759 1,-4.86l1.09,-0.77l-0.15,-0.75c-0.266,-1.407 -0.266,-2.853 0,-4.26l0.15,-0.75l-1.1,-0.78c-1.56,-1.1 -1.997,-3.222 -1,-4.85c0.936,-1.504 2.816,-2.12 4.46,-1.46l1.55,0.62l0.65,-0.47c1.171,-0.917 2.485,-1.634 3.89,-2.12l0.74,-0.29l0.18,-1.36c0.244,-1.8 1.774,-3.147 3.59,-3.16l0.44,0c1.818,0.009 3.351,1.358 3.59,3.16l0.18,1.36l0.75,0.29c1.343,0.491 2.611,1.164 3.77,2l0.76,0.55l1.53,-0.62c1.642,-0.65 3.513,-0.036 4.45,1.46l0,0.06c0.998,1.627 0.56,3.75 -1,4.85l-1.08,0.77l0.15,0.75c0.117,0.705 0.183,1.416 0.2,2.13c0.008,0.715 -0.059,1.429 -0.2,2.13l-0.15,0.75l1.1,0.78c1.556,1.103 1.993,3.222 1,4.85l0,0.06c-0.927,1.502 -2.799,2.121 -4.44,1.47l-1.65,-0.65Z" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path239" d="M25.085,20.004c0,2.784 -2.256,5.04 -5.04,5.04c-2.783,0 -5.04,-2.256 -5.04,-5.04c0,-2.783 2.257,-5.04 5.04,-5.04c2.784,0 5.04,2.257 5.04,5.04Z" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /></Icon>
};