import { Icon } from "@chakra-ui/react";
export const Cards = props => {
  let {color = '#565656'} = props;
  return <Icon  viewBox="0 0 41 40" {...props}><rect id="cards" x={0.079} y={0} width={40} height={40} style={{
    fill: "none"
  }} /><path id="path149" d="M34.158,35.478l-21.67,0c-1.544,-0.117 -2.707,-1.454 -2.61,-3l0,-14.15c-0.097,-1.545 1.066,-2.882 2.61,-3l21.67,0c1.541,0.123 2.698,1.458 2.6,3l0,14.14c0.104,1.546 -1.055,2.888 -2.6,3.01Z" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path151" d="M9.248,29.488c-1.083,-0.292 -1.912,-1.164 -2.15,-2.26l-3.57,-13.69c-0.462,-1.444 0.296,-2.997 1.72,-3.52l21,-5.45c1.526,-0.28 2.992,0.726 3.28,2.25l2.21,8.5" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path152" d="M14.918,20.358l5.04,0" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path153" d="M23.318,28.758c0,0.928 -0.752,1.68 -1.68,1.68c-0.928,0 -1.68,-0.752 -1.68,-1.68c0,-0.927 0.752,-1.68 1.68,-1.68c0.928,0 1.68,0.753 1.68,1.68Z" style={{
    fill: color,
    fillRule: "nonzero"
  }} /><path id="path155" d="M28.358,28.758c0,0.928 -0.752,1.68 -1.68,1.68c-0.928,0 -1.68,-0.752 -1.68,-1.68c0,-0.927 0.752,-1.68 1.68,-1.68c0.928,0 1.68,0.753 1.68,1.68Z" style={{
    fill: color,
    fillRule: "nonzero"
  }} /><path id="path156" d="M33.398,28.758c0,0.928 -0.752,1.68 -1.68,1.68c-0.928,0 -1.68,-0.752 -1.68,-1.68c0,-0.927 0.752,-1.68 1.68,-1.68c0.928,0 1.68,0.753 1.68,1.68Z" style={{
    fill: color,
    fillRule: "nonzero"
  }} /></Icon>
};