/**
 * @version 1.0.0
 * @description Componente para crear un input de tipo variable
 *
 * @param {Object} props - props
 *
 * @returns {Object} - InputField
 *
 * @example <InputField label="RUT" name="login" type="rut" placeholder="RUT" register={register} required={true} errors={errors} minLength={{value: 3, message: 'Campo muy corto'}} />
 * @example <InputField label="Contraseña" type="password" name="password" register={register} />
 */
import {
  FormControl,
  FormLabel,
  Input,
  Button,
  FormErrorMessage,
  InputRightElement,
  InputGroup,
  GridItem,
  Select,
  Radio,
  RadioGroup,
  Stack,
  Checkbox,
  CheckboxGroup,
  Textarea,
  useControllableState,
  Tag,
  VStack,
  TagLabel,
  TagCloseButton,
  Flex,
  Spacer,
  HStack,
  Text,
  useToast,
  useRadioGroup,
  Grid,
  SimpleGrid
} from '@chakra-ui/react';
import { useNavigate, Link as LinkRRD } from "react-router-dom";
import { useState, useEffect, forwardRef, useCallback, useRef } from 'react';
import { validateRut, formatRut, clarInvalidCharRuts } from '../../helpers/integer.js'
import { Controller, useForm } from 'react-hook-form';
import ReactDatePicker from 'react-datepicker';
import moment from 'moment';
import api from "../../helpers/api";
import {Icons} from '../../components/templates/Icons';
import { RadioCard } from './RadioCard.jsx';

const _customDateInput = ({ value, onClick, onChange }, ref) => {

  // debugger;

  return <Input
    autoComplete="off"
    background="white"
    value={value || ""}
    // {...ref && {ref: ref}}
    {...onClick && {onClick: onClick}}
    {...onChange && {onChange: onChange}}
  />
};
const CustomInput = forwardRef(_customDateInput);

const _get = (obj, path) => {
  try {
    return new Function('_', 'return _.' + path)(obj);
  } catch (e) {
    return null;
  }
}

/** Constantes */
const MAX_FILE_SIZE = 1000;

const validateFiles = (files) => {
  if (files.length < 1) {
    return 'Archivos son requeridos'
  }
  for (const file of Array.from(files)) {
    const fsMb = file.size / (1024 * 1024)
    const MAX_FILE_SIZE = 10
    if (fsMb > MAX_FILE_SIZE) {
      return 'El tamaño máximo es 10mb'
    }
  }
  return true
}

export const InputField = ({...params}) => {

  /** Hooks */
  const [valueDate, setValueDate] = useState(new Date());
  const [fileList, setFileList] = useState([]);
  const toast = useToast();
  const {
    formState: { isSubmitting }
  } = useForm();

  /** Variables */
  const { name }              = params;
  let   { type }              = params;
  const { required = false }  = params;
  let   { register = null }   = params;
  let   { control = null }    = params;
  const { minLength }         = params;
  const { maxLength }         = params;
  const { placeholder }       = params;
  const { label }             = params;
  const { errors }            = params;
  const { hidden }            = params;
  const { size = 1 }          = params;
  let   { options }           = params;
  const { setValueAs }        = params;
  const { validate }          = params;
  const { setValue }          = params;
  const { readOnly }          = params;
  const { disabled }          = params;
  let   { value }             = params;
  const { defaultValue }      = params;
  const { acceptedFileTypes } = params;
  const { selectedDate }      = params;
  const { onChange }          = params;
  const { dateFormat }        = params;
  const { minDate }           = params;

  /** Hooks */
  const [show, setShow] = useState(false);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: 'framework',
    defaultValue: 'react'
  })
  const group = getRootProps();


  /** Variables */
  var   field            = null;
  const url_file_service = 'https://vivetusbeneficios.custhelp.com/cc/API/FilesServices/generateGenericFiles';
  const url_service      = 'ProcessedAdvanceServices/uploadCsv';
  let   navigate         = useNavigate();
  const inputRef         = useRef();

  /*** Validaciones */
  let config_register = {
    required: required?required === true?`Obligatorio`:required:false,
    ...(minLength && { minLength }),
    ...(maxLength && { maxLength }),
  };

  // Validaciones de RUT
  if(type === 'rut') {
    config_register.validate = (value) => {
      return validateRut(value) ? true : 'RUT inválido';
    }

    config_register.setValueAs = (value) => {
      return formatRut(value);
    }
  }

  // Métodos
  const handlerShowPassword = () => setShow(!show);

  /**
   * Envía un archivos al servidor
   *
   * @param {object} - request
   * @returns {object} - response
   */
  const uploadFile = async (request) => {
    try {
      const response = await api.post(url_file_service, request);
      return response;
    } catch (err) {
      return false;
    }
  }

  if(setValueAs) {
    config_register.setValueAs = setValueAs;
  }

  if(validate) {
    config_register.validate = validate;
  }

  if(type) {
    type = type.toLowerCase()
  }

  if(type === 'textarea') {
    field = <>
      <Textarea
        type="textarea"
        {...(placeholder && {placeholder})}
        {...(register && register(name, config_register))}
      />
      </>
  } else if(type === 'textolibre') {
    field = <>
      <Textarea
        // defaultValue={'Sin comentarios'}
        placeholder={'Sin comentarios'}
        type="textarea"
        {...(hidden && {hidden})}
        {...(placeholder && {placeholder})}
        {...(register && register(name, config_register))}
      />
    </>
  }
  else if(type === 'radio' || type === 'bool' || type === 'boolean') {
    if(!options) {
      options = [
        {"value": 1, "label": "Sí", "colorScheme": "green"},
        {"value": 0, "label": "No", "colorScheme": "red"},
      ];
    }

    field = <Controller
      name={name}
      control={control}
      render={ ({field, fieldState, formState}) => (
        <RadioGroup
          {...field}
          {...fieldState}
          {...formState}
        >
          <Stack direction="row">
            {options.map((option, index) => {
              return <Radio
                key={index}
                value={option.value.toString()}

                {...(option?.colorScheme && {colorScheme:option.colorScheme})}
              >
                {option.label}
              </Radio>
            })}
          </Stack>
        </RadioGroup>
      )}
      rules={config_register}
    />
  } else if(type === 'multiple1' || type === 'bool' || type === 'boolean') {
    if(!options) {
      options = [];

    }

    field = <Select
    type='select'
    {...register(name, config_register)}
    {...onChange && {onChange: onChange}}
    children={
      options.map((option, index) => {
        return <option  key={index} value={option.value}>{option.label}</option>
      })
    }
      rules={config_register}
    />
  }else if(type === 'multiple2' || type === 'bool' || type === 'boolean') {
    if(!options) {
      options = [];
    }

    field = <Controller
      name={name}
      bg= {'#56AF31'}
      control={control}

      render={ ({field, fieldState, formState}) => (
        <RadioGroup defaultValue={defaultValue}
          {...field}
          {...fieldState}
          {...formState}
        >
          <Stack direction="row">
            {options.map((option, index) => {
              return <Radio
                key={index}
                value={option.value.toString()}

                {...(option && {colorScheme:'green'})}
              >
                {option.label}
              </Radio>
            })}
          </Stack>
        </RadioGroup>
      )}
      rules={config_register}
    />
  }else if(type === 'multiple3' || type === 'bool' || type === 'boolean') {
    if(!options) {
      options = [];
    }

    field = <Controller
      name={name}
      control={control}
      render={ ({field, fieldState, formState}) => (
        <RadioGroup>
          <Stack
          {...field}
          {...fieldState}
          {...formState}
          {...group} direction="row" align={'center'} >
            {options.map(( option, index ) => {
              return <RadioCard
                key={index}
                {...getRadioProps({ value: option?.value})}

              >
                {option?.label}
              </RadioCard>
            })}
          </Stack>
          <SimpleGrid columns={{ base: '2', lg: '2', xl: '2' }} py={2}>
            <Text align={'left'}> Nada probable </Text>
            <Text align={'right'}> Muy probable </Text>
           </SimpleGrid>
        </RadioGroup>
      )}
      rules={config_register}
    />
  }
  else if(type === 'multiple4' || type === 'bool' || type === 'boolean') {
    if(!options) {
      options = [];
    }

    field = <Controller
      name={name}
      control={control}
      render={ ({field, fieldState, formState}) => (
        <RadioGroup>
          <Stack
          {...field}
          {...fieldState}
          {...formState}
          {...group} direction="row" align={'center'} >
            {options.map(( option, index ) => {
              return <RadioCard
                key={index}
                {...getRadioProps({ value: option?.value})}

              >
                {option?.label}
              </RadioCard>
            })}
          </Stack>
          <SimpleGrid columns={{ base: '2', lg: '2', xl: '2' }} py={2}>
            <Text align={'left'}> Nada importante </Text>
            <Text align={'right'}> Muy importante </Text>
           </SimpleGrid>
        </RadioGroup>
      )}
      rules={config_register}
    />
  }
  else if(type === 'multiple5' || type === 'bool' || type === 'boolean') {
    if(!options) {
      options = [];
    }

    field = <Controller
      name={name}
      control={control}
      render={ ({field, fieldState, formState}) => (
        <RadioGroup>
          <Stack
          {...field}
          {...fieldState}
          {...formState}
          {...group} direction="row" align={'center'} >
            {options.map(( option, index ) => {
              return <RadioCard
                key={index}
                {...getRadioProps({ value: option?.value})}

              >
                {option?.label}
              </RadioCard>
            })}
          </Stack>
        </RadioGroup>
      )}
      rules={config_register}
    />
  }
  else if(type === 'checkbox' || type === 'check') {
    field = <Controller
      name={name}
      control={control}
      render={ ({field}) => (
        <CheckboxGroup {...field}>
          <Stack direction="row">
            {options.map((option, index) => {
              return <Checkbox
                key={index}
                value={option.value.toString()}
              >
                {option.label}
              </Checkbox>
            })}
          </Stack>
        </CheckboxGroup>
      )}
      rules={config_register}
    />
  } else if(type === 'select') {
    field = <Select
      {...(placeholder && {placeholder})}
      type='select'
      {...register(name, config_register)}
      {...onChange && {onChange: onChange}}
      children={
        options.map((option, index) => {
          return <option key={index} value={option.value}>{option.label}</option>
        })
      }
    />
  } else if(type === 'file') {
    field = <>
      <Controller
      control={control}
      name={name}
      rules={config_register}
      render={({field, fieldState, formState}) => {
        return <>
        <input type='file'
          accept={acceptedFileTypes}
          name={name}
          ref={inputRef}
          onChange={async (e) => {

            if(!e.target.files.length) return false;

            const reader = new FileReader();

            reader.readAsDataURL(e.target.files[0]);

            reader.onload = async (evt_reader) => {
              let file_request           = {};
                  file_request.name      = e.target.files[0].name;
                  file_request.extension = e.target.files[0].name.replace(/.*?\.(\w{3,4})$/, '$1');
                  if (file_request?.extension !== 'pdf') {
                    toast({
                      title: "Solo se admiten archivos de tipo PDF",
                      status: 'error',
                      duration: 5000,
                      isClosable: true,
                    })
                  }
                  else{
                    file_request.mimetype  = e.target.files[0].type;
                    file_request.content   = evt_reader.target.result.split(',')[1];

                    setLoading(true);
                    const file_response = await uploadFile({files: [file_request]});
                    setLoading(false);

                    if(file_response?.status === 200) {
                      if(file_response?.data?.result === true){
                        const { data }     = file_response;
                        const { result }   = data;
                        const { response } = data;

                        let fileList_item            = file_request;
                            fileList_item.id         = response.file.id;
                            fileList_item.uniquename = response.file.uniquename;

                        if(result) {
                          setFileList((fileList) => {
                            const newFileList = [...fileList, fileList_item];
                            field.onChange(newFileList.map(d => (d.uniquename)));
                            return newFileList;
                          });
                        }
                      }
                      else{
                        toast({
                          title: file_response?.data?.response?.message,
                          status: 'error',
                          duration: 3000,
                          isClosable: true,
                        })
                      }
                    }
                  };

                  reader.onerror = (error) => {
                    setLoading(false);
                  };
                }
          }}
          {...field.inputProps}
          // inputRef={field.ref}
          style={{ display: 'none' }}
        ></input>
        <VStack>

        <Button
          {...(placeholder && {placeholder})}
          onClick={() => inputRef.current.click()}
          readOnly={true}
          value={field && field.value || ''}
          {...(disabled && {disabled})}
          isLoading={loading}
          loadingText="Subiendo..."
          variant="outline"
        >
          <HStack>
            <Text>Subir Archivo</Text>
            <Icons icon="clip" w="6" h="6" color="#56AF31" />
          </HStack>
        </Button>

        {
          fileList.map((file, index) => {
            return <Tag
              key={index}
              size="xl"
              borderRadius="full"
              p={2}
              w={'full'}
            >
              <Flex
                w={'full'}
              >

                <TagLabel
                  cursor={'default'}
                  w={'full'}
                >{file.name}</TagLabel>

                {setValue && <TagCloseButton
                onClick={() => {
                  // Elimina un ítem de la lista de archivos
                  setFileList((fileList) => {
                    const newFileList = fileList.filter((d) => d.uniquename !== file.uniquename);
                    setValue(name, newFileList.map(d => (d.uniquename)));
                    return newFileList;
                  })
                }} />}

              </Flex>
            </Tag>
          } )
        }
        </VStack>

        </>
      }}
      />

    </>
  }else if(type === 'file_csv') {
    field = <>
      <Controller
      control={control}
      name={name}
      rules={config_register}
      render={({field, fieldState, formState}) => {
        return <>
        <input type='file'
          accept={acceptedFileTypes}
          name={name}
          ref={inputRef}
          onChange={async (e) => {

            if(!e.target.files.length) return false;

            const reader = new FileReader();

            reader.readAsDataURL(e.target.files[0]);

            reader.onload = async (evt_reader) => {
              let file_request           = {};
                  file_request.name      = e.target.files[0].name;
                  file_request.extension = e.target.files[0].name.replace(/.*?\.(\w{3,4})$/, '$1');
                  file_request.mimetype  = e.target.files[0].type;
                  file_request.content   = evt_reader.target.result.split(',')[1];

                  const file_response = await api.post(url_service, {files : [file_request]});

                  if(file_response.data.result === true) {
                    toast({
                      title: file_response?.data?.response,
                      status: 'success',
                      duration: 4000,
                      isClosable: true,
                    })
                  }else{
                    toast({
                      title: file_response?.data?.response?.response,
                      status: 'warning',
                      duration: 4000,
                      isClosable: true,
                    })
                  }
            };

            reader.onerror = (error) => {
              setLoading(false);
            };

          }}
          {...field.inputProps}
          // inputRef={field.ref}
          style={{ display: 'none' }}
        ></input>
        <VStack>

        <Button
          {...(placeholder && {placeholder})}
          onClick={() => inputRef.current.click()}
          readOnly={true}
          value={field && field.value || ''}
          isLoading={loading}
          loadingText="Subiendo..."
          variant="outline"
        >
          <HStack>
            <Text>Cargar Documentos</Text>
            <Icons icon="clip" w="6" h="6" color="#56AF31" />
          </HStack>
        </Button>

        {
          fileList.map((file, index) => {
            return <Tag
              key={index}
              size="xl"
              borderRadius="full"
              p={2}
              w={'full'}
            >
              <Flex
                w={'full'}
              >

                <TagLabel
                  cursor={'default'}
                  w={'full'}
                >{file.name}</TagLabel>

                {setValue && <TagCloseButton
                onClick={() => {
                  // Elimina un ítem de la lista de archivos
                  setFileList((fileList) => {
                    const newFileList = fileList.filter((d) => d.uniquename !== file.uniquename);
                    setValue(name, newFileList.map(d => (d.uniquename)));
                    return newFileList;
                  })
                }} />}

              </Flex>
            </Tag>
          } )
        }
        </VStack>

        </>
      }}
      />

    </>
  }else if(type === 'date') {
    field = <Controller
      control={control}
      name={name}
      rules={config_register}
      render={({field, fieldState, formState}) => {
        // debugger;
        return <ReactDatePicker
          dateFormat={dateFormat}
          name={name}
          showPopperArrow={false}
          selected={field.value || ''}
          minDate={minDate}
          onChange={(date, event) => {
            if(onChange) onChange(date);
            if(field.onChange) field.onChange(date);
          }}
          customInput={<CustomInput
            {...field}
            {...fieldState}
            {...formState}
          />}
        />
      }}
    />
  } else if(type === 'time') {
    field = <Controller
      control={control}
      name={name}
      rules={config_register}
      render={({field, fieldState, formState}) => {
        // debugger;
        return <ReactDatePicker
          dateFormat={dateFormat}
          name={name}
          showPopperArrow={false}
          selected={field.value || ''}
          onChange={(date, event) => {
            if(onChange) onChange(date);
            if(field.onChange) field.onChange(date);
          }}
          timeFormat="HH:mm"
          showTimeSelect
          showTimeSelectOnly
          customInput={<CustomInput
            {...field}
            {...fieldState}
            {...formState}
          />}
        />
      }}
    />

  } else { // text, password, email, number, url
    field = <>
      <Input
        {...(type === 'password' && {pr:'4.5rem'})}
        type={(type === 'password')?(show) ? 'text' : 'password':type}
        {...(placeholder && {placeholder})}
        {...(readOnly && {readOnly})}
        {...(disabled && {disabled})}
        {...((disabled || readOnly) && {variant: 'filled'})}
        {...(register && register(name, config_register))}
        {...(defaultValue && {defaultValue:defaultValue})}
        {...onChange && {onChange: onChange}}
        focusBorderColor="green.500"
      />

      {(type === 'password')?<InputRightElement w="3em">
            {show ?
            <Icons
              icon="show"
              w="6"
              h="6"
              {...(type?{onClick:handlerShowPassword}:{} )}
             />
            :
            <Icons
              icon="hide"
              w="6"
              h="6"
              {...(type?{onClick:handlerShowPassword}:{} )}
             />
            }
        </InputRightElement>
      :null}
    </>
  }

  return (
    <GridItem {...(size && {colSpan: size})}>
      <FormControl id={name} isInvalid={!!_get(errors, name)}>

        {label && <FormLabel hidden={hidden} htmlFor={name}>{label}</FormLabel>}

        <InputGroup size='md'>
          {field}
        </InputGroup>

        { _get(errors, name)?.message && <FormErrorMessage>{_get(errors, name)?.message}</FormErrorMessage> }

      </FormControl>
    </GridItem>
  );
}
