/** Imports */
import {
    Box,
    Button,
    Grid,
    Heading,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text,
    useDisclosure,
    useSafeLayoutEffect
  } from '@chakra-ui/react';
import { useErrors } from '../../helpers/integer';
import { forwardRef, Fragment, useRef, useState } from 'react';
import api from "../../helpers/api";
import { useEffect } from 'react';
import { ReportGrid } from '../../components/ReportGrid';
import { Loader } from '../../components/Loader';
import { Page } from '../../components/templates/Page';
import { Title } from '../../components/templates/Title';
import { Roulette } from '../../components/roulette/Roulette';
import confetti from 'canvas-confetti';
import { useLayoutEffect } from 'react';
import { functions } from '../../firebase';
import { getFunctions, httpsCallable } from 'firebase/functions';
import TokenService from '../../helpers/TokenService';
// import { analytics } from 'firebase-functions/v1';
// import { logEvent } from 'firebase/analytics';

const RoulettePage = () => {
  /** Hooks */
  const showErrors                  = useErrors();
  const [stockData, setStockData]   = useState([]);
  const [data, setData]             = useState(null);
  const [message, setMessage]       = useState(null);
  const [title, setTitle]           = useState(null);
  const [prizeData, setPrizeData]   = useState(null);
  const [disabled, setDisabled]   = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();

  /**
   * Obtiene la información del premio
   */
  const getData = async () => {
    let   data  = null;
    const token = TokenService.getLocalAccessToken();

    const addPrize = httpsCallable(getFunctions(), 'addPrize');

    try {
      data = await addPrize({ token: token.response.accessToken });

      return data;
    } catch (err) {
      showErrors(err);
    }
  }

  /**
   * Presenta un mensaje en un diálogo
   * 
   * @param {string} title 
   * @param {string} message 
   */
  const showMessage = (title, message) => {
    setTitle(title);
    setMessage(message);
    onOpen();
  }

  /**
   * Callback para cuando se gana un premio
   */
  const win = () => {
    var end = Date.now() + (15 * 1000);

    var colors = ['#ef044e', '#56AF31'];

    (function frame() {
      confetti({
        particleCount: 2,
        angle: 60,
        spread: 55,
        origin: { x: 0 },
        colors: colors
      });
      confetti({
        particleCount: 2,
        angle: 120,
        spread: 55,
        origin: { x: 1 },
        colors: colors
      });

      if (Date.now() < end) {
        requestAnimationFrame(frame);
      }

      let user = TokenService.getUser();

      if(!user?.response?.check_roulette) {
        user.response.check_roulette = true;
        TokenService.setUser(user);
      }

      // onOpen();
    }());
  };

  useEffect(() => {
    const user = TokenService.getUser();
    if(user?.response?.check_roulette) {
      setDisabled(true);
    }
  }, []);

  // if(prizeData === null) {
  //   return <Loader />;
  // }

  return (
    <Fragment>
      <Page
        title="Ruleta"
        description={<Text>Celebra con nosotros nuestro <span className='tag'>#ViveRenovado</span> y juega por una Gift Card</Text>}
      >
        <Roulette
          data={data}
          getData_callback={getData}
          setData_callback={setPrizeData}
          win_callback={win}
          showMessage_callback={showMessage}
          disabled={disabled}
          setDisabled_callback={setDisabled}
        />
        
        <>
          <Modal onClose={onClose} isOpen={isOpen} isCentered>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>{title}</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                {message}
              </ModalBody>
              <ModalFooter>
                <Button onClick={onClose}>Close</Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </>
      </Page>
    </Fragment>
  );
}

export default RoulettePage;