import { Icon } from "@chakra-ui/react";
export const Square = props => {
  let {color = '#565656'} = props;
  return <Icon  viewBox="0 0 40 40" {...props}><rect id="square" x={0} y={0} width={40} height={40} style={{
    fill: "none"
  }} /><path id="path6" d="M28.577,6.798c1.236,-0.032 2.431,0.446 3.305,1.32c0.874,0.874 1.352,2.069 1.32,3.305l0.021,17.133c0.032,1.236 -0.445,2.431 -1.32,3.305c-0.874,0.874 -2.069,1.351 -3.305,1.319l-17.175,0.022c-1.236,0.032 -2.431,-0.446 -3.305,-1.32c-0.874,-0.874 -1.352,-2.069 -1.32,-3.305l0,-17.154c-0.031,-1.236 0.446,-2.431 1.32,-3.305c0.874,-0.874 2.069,-1.351 3.305,-1.32l17.154,0Z" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /></Icon>
};