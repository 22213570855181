/**
 * @version 1.0.0
 * @description Template de leyenda de formulario
 * 
 * @param {Object} props - props
 * 
 * @returns {Object} - Legend
 * 
 * @example <Legend>...</Legend>
 */
import {
  Box,
  Heading,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useState, useEffect } from 'react';

export const Legend = ({...params}) => {

  /** Variables */
  const { type } = params;

  useEffect(() => {
  }, []);

  return (
    <VStack spacing={4} align='stretch' >
      <Box bg={'brand.500'} align={'left'} px={3} py={2} borderRadius={'5'}>
        <Text color={'white'}>
          {params.children}
        </Text>
      </Box>
    </VStack>
  );
}
