import { Image, Stack } from '@chakra-ui/react';
import { ShadowBox } from '../templates/ShadowBox';
import { GlobalDataContext } from '../../components/context/GlobalData';
import { useContext } from 'react';

export const Banner = (props) => {
  const { content } = props;

  const [globalData, setGlobalData] = useContext(GlobalDataContext);
  let company = globalData.contact.Empresa_interna.ID;

  return(
    <>
      {company === 21 || company === 13 || company === 22 || company === 19 || company === 18 || company === 17
        ?
          <ShadowBox
            colSpan={{base: 1, md: 2, lg: 2, xl: 2}}
            align={'center'}
            justify={'center'}
            h={'100%'}
          >
            <Stack
              direction={{base: 'column', xl: 'row'}}
              align={'center'}
              justify={'center'}
              h={'100%'}
            >
              <Image
                src={content?.answer_image}
                draggable={false}
              />
            </Stack>
          </ShadowBox>
        :
          null
      }
    </>
  )
}
