/** Imports */
import {
    Box,
    Grid,
    Heading,
    Text
  } from '@chakra-ui/react';
import { useErrors } from '../../helpers/integer';
import { useState } from 'react';
import api from "../../helpers/api";
import { useEffect } from 'react';
import { Page } from '../../components/templates/Page';
import { ReportGrid } from '../../components/ReportGrid';
import { Loader } from '../../components/Loader';
import { ReportGiftcard } from '../../components/giftcard/ReportGiftcard';
import { Title } from '../../components/templates/Title';
import { GiftCard } from '../../components/giftcard/GiftCard';
import Barcode from '../../components/giftcard/Barcode';
import { useParams } from 'react-router-dom';
// import { analytics } from 'firebase-functions/v1';
// import { logEvent } from 'firebase/analytics';

const GiftCardPage = () => {

  /** Hooks */
  const showErrors      = useErrors();
  const [data, setData] = useState(false);

  const url_service = 'ReportServices/getReportByFilter';
  const { id }      = useParams('id');

  const request = {
    'report_id'    : 101160,
    'filter_name'  : 'giftcard_id',
    'filter_value' : id
  };

  /**
   * Consultamos por la Giftcard solicitada
   */
  const getGiftCard = async () => {
    try {
      const response = await api.post(url_service, request)
      showErrors(response, false);
      setData(response?.data?.response?.result);
    } catch (err) {
      showErrors(err);
    }
  }

  useEffect(() => {
    getGiftCard();
  }, []);

  if(data === false) {
    return <Loader />;
  }

  return (
    <Page title="Gift Card">
      <GiftCard giftCard={data[0]}/>
    </Page>
  )
}

export default GiftCardPage;