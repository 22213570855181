import { Icon } from "@chakra-ui/react";
export const Pencil = props => {
  let {color = '#565656'} = props;
  return <Icon  viewBox="0 0 40 40" {...props}><rect id="pencil" x={0} y={0} width={40} height={40} style={{
    fill: "none"
  }} /><path id="path291" d="M5.62,11.643l6.066,-6.066c0.558,-0.558 1.463,-0.558 2.022,-0l18.149,18.149c0.558,0.559 2.942,11.116 2.942,11.116c-0,-0 -10.471,-2.47 -11.03,-3.028l-18.149,-18.149c-0.558,-0.559 -0.558,-1.464 -0,-2.022Z" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /></Icon>
};