import {
    Table,
    Thead,
    Tbody,
    Tr,
    TableCaption,
    TableContainer,
    Th,
    Td
  } from '@chakra-ui/react';
  import * as React from 'react'

  export const ReportProcedures = (props) => {

    const { procedure } = props

    return (
      <TableContainer>
        <Table >
          <TableCaption>Mis anticipos</TableCaption>
          <Thead>
            <Tr>
              <Th>RUT</Th>
              <Th>Nombre</Th>
              <Th>Apellido </Th>
              <Th>Apellido Materno</Th>
              <Th>Monto Bruto </Th>
              <Th>Monto Liquido </Th>
              <Th>Concepto</Th>
              <Th>Periodo de Pago </Th>
              <Th>Fecha de Pago</Th>
            </Tr>
          </Thead>
          <Tbody>
            {procedure && procedure.map((procedure) => (
              <Tr key={procedure.ID} align={'center'}>
                <Td>{procedure.rut}</Td>
                <Td>{procedure.Nombre}</Td>
                <Td>{procedure.Apellido}</Td>
                <Td>{procedure.apellido_materno}</Td>
                <Td>{procedure.monto_bruto}</Td>
                <Td>{procedure.monto_liquido}</Td>
                <Td>{procedure.concepto}</Td>
                <Td>{procedure.periodo_pago}</Td>
                <Td>{procedure.fecha_pago}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    )
  }