import { Icon } from "@chakra-ui/react";
export const Bluetooth = props => {
  let {color = '#565656'} = props;
  return <Icon  viewBox="0 0 41 40" {...props}><rect id="bluetooth" x={0.079} y={0} width={40} height={40} style={{
    fill: "none"
  }} /><path id="path162" d="M9.999,30.08l20.16,-18.48l-8.4,-8.4l-0,33.6l8.4,-8.4l-20.16,-18.48" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /></Icon>
};