import { Icon } from "@chakra-ui/react";
export const Other = props => {
  let {color = '#565656'} = props;
  return <Icon  viewBox="0 0 41 40" {...props}><rect id="other" x={0.079} y={0} width={40} height={40} style={{
    fill: "none"
  }} /><path id="path284" d="M10.834,27.958c-0,0.928 -0.752,1.68 -1.68,1.68c-0.928,0 -1.68,-0.752 -1.68,-1.68c-0,-0.927 0.752,-1.68 1.68,-1.68c0.928,0 1.68,0.753 1.68,1.68Z" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path2841" d="M21.759,27.958c-0,0.928 -0.752,1.68 -1.68,1.68c-0.928,0 -1.68,-0.752 -1.68,-1.68c-0,-0.927 0.752,-1.68 1.68,-1.68c0.928,0 1.68,0.753 1.68,1.68Z" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path2842" d="M32.684,27.958c-0,0.928 -0.752,1.68 -1.68,1.68c-0.928,0 -1.68,-0.752 -1.68,-1.68c-0,-0.927 0.752,-1.68 1.68,-1.68c0.928,0 1.68,0.753 1.68,1.68Z" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /></Icon>
};