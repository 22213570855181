import { Icon } from "@chakra-ui/react";
export const World = props => {
  let {color = '#565656'} = props;
  return <Icon  viewBox="0 0 41 40" {...props}><rect id="world" x={0.079} y={0} width={40} height={40} style={{
    fill: "none"
  }} /><path id="path40" d="M26.799,4.88l-2.65,6.61c-0.14,0.14 -0.28,0.28 -0.43,0.28l-2.53,0c-0.157,-0.021 -0.316,0.03 -0.43,0.14l-0.56,0.57c-0.139,0.213 -0.139,0.487 -0,0.7l4.51,4.52c0.175,0.192 0.274,0.44 0.28,0.7l-0,3.22c-0.006,0.26 -0.105,0.508 -0.28,0.7l-2.68,2.68c-0.14,0.14 -0.14,0.14 -0.14,0.29l-1.83,3.66c-0.11,0.256 -0.314,0.46 -0.57,0.57l-1.27,0.42c-0.324,0.147 -0.702,0.111 -0.993,-0.095c-0.29,-0.206 -0.449,-0.55 -0.417,-0.905l-0,-3.52c0.02,-0.157 -0.032,-0.315 -0.14,-0.43l-2.82,-2.82c-0.108,-0.111 -0.16,-0.266 -0.14,-0.42l-0,-3.63c-0.02,-0.154 0.032,-0.309 0.14,-0.42l2.54,-2.54c0.14,-0.216 0.14,-0.494 -0,-0.71l-1.55,-1.55c-0.115,-0.109 -0.273,-0.16 -0.43,-0.14l-7.72,-2.84" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path67" d="M36.879,20c-0,9.278 -7.522,16.8 -16.8,16.8c-9.279,-0 -16.8,-7.522 -16.8,-16.8c-0,-9.278 7.521,-16.8 16.8,-16.8c9.278,0 16.8,7.522 16.8,16.8Z" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /></Icon>
};