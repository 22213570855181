import { Icon } from "@chakra-ui/react";
export const Key = props => {
  let {color = '#565656'} = props;
  return <Icon  viewBox="0 0 41 40" {...props}><rect id="key" x={0.079} y={0} width={40} height={40} style={{
    fill: "none"
  }} /><path id="path110" d="M10.009,30.03l5,5" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path111" d="M4.939,35.07l13.47,-13.46" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path112" d="M26.029,23.31c5.075,0 9.19,-4.114 9.19,-9.19c-0,-5.075 -4.115,-9.19 -9.19,-9.19c-5.076,0 -9.19,4.115 -9.19,9.19c-0.027,2.446 0.932,4.799 2.662,6.528c1.729,1.73 4.082,2.689 6.528,2.662Z" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /></Icon>
};