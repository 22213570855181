/**
 * @version 1.0.0
 * @description Template sombra de la box
 * 
 * @param {Object} props - props
 * 
 * @returns {Object} - ShadowBox
 * 
 * @example <ShadowBox>...</ShadowBox>
 */
import {
  Box, GridItem
} from '@chakra-ui/react';
import { useState, useEffect } from 'react';

export const ShadowBox = ({...params}) => {

  /** Variables */
  const { type }        = params;
  const { color }       = params;
  const { colSpan = 1 } = params;
  const { rowSpan }     = params;
  const { p = 2 }       = params;

  useEffect(() => {
  }, []);

  return (
    <GridItem
      borderRadius={'2xl'}
      borderTopRadius={'2xl'}
      boxShadow={'lg'}
      _hover={{ boxShadow: 'xl' }}
      transition={'box-shadow 0.1s ease-in-out'}
      border="1px solid #eee"
      bg={(color)?color:'white'}
      p={p}
      {...colSpan && {colSpan}}
      {...rowSpan && {rowSpan}}
    >
      {params.children}
  </GridItem>
  );
}
