import { Icon } from "@chakra-ui/react";
export const Healt = props => {
  let {color = '#565656'} = props;
  return <Icon  viewBox="0 0 41 40" {...props}><rect id="healt" x={0.079} y={0} width={40} height={40} style={{
    fill: "none"
  }} /><path id="path83" d="M15.293,14.94l0,6.72" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path84" d="M18.653,18.3l-6.72,0" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path85" d="M23.693,30.06l-8.4,0" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path86" d="M6.873,30.06c-0.802,-0.001 -1.56,-0.371 -2.054,-1.004c-0.494,-0.632 -0.669,-1.457 -0.476,-2.236l3.74,-15c0.278,-1.172 1.326,-2 2.53,-2l14.76,-0l-2.41,8.44c-0.222,0.787 -0.063,1.632 0.43,2.284c0.493,0.652 1.263,1.036 2.08,1.036l7.81,0c0.865,-0.003 1.675,0.425 2.16,1.141c0.486,0.716 0.583,1.627 0.26,2.429l-1.27,3.19c-0.398,0.988 -1.355,1.636 -2.42,1.64" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path87" d="M25.373,11.58l3.75,0c1.718,0.002 3.182,1.246 3.46,2.94l1.19,7.14" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path88" d="M25.373,9.9l0,-2.52c0,-1.392 -1.128,-2.52 -2.52,-2.52c-1.391,0 -2.52,1.128 -2.52,2.52l0,2.52" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path89" d="M30.453,30.06c0,1.878 -1.522,3.4 -3.4,3.4c-1.877,0 -3.4,-1.522 -3.4,-3.4c0,-1.878 1.523,-3.4 3.4,-3.4c1.878,0 3.4,1.522 3.4,3.4Z" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path90" d="M15.333,30.06c0,1.878 -1.522,3.4 -3.4,3.4c-1.877,0 -3.4,-1.522 -3.4,-3.4c0,-1.878 1.523,-3.4 3.4,-3.4c1.878,0 3.4,1.522 3.4,3.4Z" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /></Icon>
};