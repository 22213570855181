import { Box } from '@chakra-ui/react';
import * as React from 'react'
import { createContext, useState } from 'react';
import api from "../../helpers/api";
import { useNavigate, Link as LinkRRD } from "react-router-dom";
import TokenService from '../../helpers/TokenService';

const GlobalData = (props) => {

  /** Hooks */
  const [globalData, setGlobalData] = useState(null)
  const [maxTime, setMaxTime] = useState(null)
  // const [intervalRefresh, setIntervalRefresh] = useState(null)

  const url_service = 'ContactServices/getContactByToken';
  const navigate = useNavigate();
  let intervalRefresh = null;

  /**
  * Inicia sesión
  */
  const login = async (user, password, token) => {
    const response = await api.post('AuthenticationServices/login', {'login': user, 'password': password, token: token});
    
    if (response.data.result) {
      response.data.expire_time = getTime();
      TokenService.setUser(response.data);
      TokenService.setProfiling(response.data.profiling);
    }

    if (response.data?.response?.expire_at) {
      intervalRefresh = setInterval(async () => {
        await refreshToken();
      }, response.data.response.expire_at * 100);
    }

    return response;
  }

  const getTime = () => {
    return Math.floor(new Date().getTime() / 1000);
  }

  /**
  * Refresca el token
  */
  const refreshToken = async () => {
    const response = await api.post('AuthenticationServices/refreshToken', {});

    if(response.data.result) {
      response.data.expire_time = getTime();
      TokenService.setUser(response.data);
      if(!intervalRefresh) {
        intervalRefresh = setInterval(async () => {
          await refreshToken();
        }, response.data.response.expire_at * 100);
      }
      
    } else {
      // window.location.href = '/logout';
    }

    return response;
  }

  /**
  * Obtiene información del Contacto
  */
  const getContact = async () => {
    try {
      const response = await api.post(url_service);
      
      setGlobalData(response.data);

      if(response?.data?.contact?.CustomFields?.c?.initial_password_changed === true) {
        navigate("/account/password");
      }

      if(!intervalRefresh) {
        refreshToken();
      }

    } catch (err) {
      console.log(err);
    }
  }

  return (
    <GlobalDataContext.Provider value={[globalData, setGlobalData, getContact, login]}>
      {props.children}
    </GlobalDataContext.Provider>
  )
}

export default GlobalData;
export const GlobalDataContext = createContext();