import { Icon } from "@chakra-ui/react";
export const Agreements = props => {
  let {color = '#565656'} = props;
  return <Icon viewBox="0 0 40 40" {...props}><rect id="agreements" x={0} y={0} width={40} height={40} style={{fill: "none"}} /><path id="path158" d="M6.667,24.005l6.721,-1.68l8.689,1.24c0.76,0.109 1.432,0.549 1.835,1.201c0.404,0.653 0.497,1.451 0.255,2.179c-0.397,1.212 -1.621,1.952 -2.88,1.74l-7.899,-1.32" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path159" d="M25.147,27.365l1.68,-0l5.881,-1.05c1.422,-0.352 2.876,0.449 3.339,1.84c0.479,1.434 -0.264,2.989 -1.679,3.52l-9.88,1.81c-1.78,0.399 -3.623,0.419 -5.411,0.06l-14.119,-2.82" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path1581" d="M34.986,15.32l-6.72,1.68l-8.69,-1.24c-0.759,-0.109 -1.431,-0.549 -1.835,-1.202c-0.403,-0.652 -0.497,-1.45 -0.255,-2.178c0.398,-1.212 1.622,-1.952 2.88,-1.74l7.9,1.32" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path1591" d="M16.506,11.96l-1.68,0l-5.88,1.05c-1.423,0.352 -2.877,-0.449 -3.34,-1.84c-0.478,-1.434 0.264,-2.99 1.68,-3.52l9.88,-1.81c1.779,-0.399 3.623,-0.42 5.41,-0.06l14.12,2.82" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /></Icon>
};