import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, toast, useDisclosure, useToast } from '@chakra-ui/react';
import { useEffect } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useNavigate } from 'react-router-dom';
import { LoginForm } from '../components/auth/LoginForm';
import LoginPage from '../pages/auth/LoginPage';
import TokenService from "./TokenService";

const Idle = () => {
  const toast = useToast()
  let navigate = useNavigate();

  const onPrompt = () => {
    // Fire a Modal Prompt
  }

  // Elimina el token y redirecciona a Login
  const onIdle = () => {
    try {
      if (window.location.pathname != '/login' && window.location.pathname != '/forgot') {
        TokenService.removeUser();
        TokenService.removeProfiling();
        toast({
          title: 'Sesión Expirada',
          status: 'warning',
          duration: 3000,
          isClosable: true,
        })
        navigate('/login');
      }
    } catch (err) {
      console.log(err);
    }
  }

  const onActive = (event) => {
    // Close Modal Prompt
    // Do some active action
  }

  const onAction = (event) => {
    // Do something when a user triggers a watched event
  }

  const {
    start,
    reset,
    activate,
    pause,
    resume,
    isIdle,
    isPrompted,
    isLeader,
    getTabId,
    getRemainingTime,
    getElapsedTime,
    getLastIdleTime,
    getLastActiveTime,
    getTotalIdleTime,
    getTotalActiveTime
  } = useIdleTimer({
    onPrompt,
    onIdle,
    onActive,
    onAction,
    timeout: (1000 * 60) * 30, // (1 segundo x 60) x 60 = 1 hora
    promptTimeout: 0,
    events: [
      'mousemove',
      'keydown',
      'wheel',
      'DOMMouseScroll',
      'mousewheel',
      'mousedown',
      'touchstart',
      'touchmove',
      'MSPointerDown',
      'MSPointerMove',
      'visibilitychange'
    ],
    immediateEvents: [],
    debounce: 0,
    throttle: 0,
    eventsThrottle: 200,
    element: document,
    startOnMount: true,
    startManually: false,
    stopOnIdle: false,
    crossTab: false,
    name: 'idle-timer',
    syncTimers: 0,
    leaderElection: false
  })

  return (
    <>
    </>
  )
}

export default Idle;
