import { Icon } from "@chakra-ui/react";
export const Update = props => {
  let {color = '#565656'} = props;
  return <Icon  viewBox="0 0 41 40" {...props}><rect id="update" x={0.079} y={0} width={40} height={40} style={{
    fill: "none"
  }} /><path id="path248" d="M34.854,15.46c-1.98,-6.301 -7.82,-10.589 -14.425,-10.589c-6.605,0 -12.446,4.288 -14.425,10.589" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path249" d="M3.624,9.92l1.68,6.72l6.72,-0" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path250" d="M5.304,24.54c1.979,6.301 7.82,10.589 14.425,10.589c6.605,-0 12.445,-4.288 14.425,-10.589" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path251" d="M36.534,30.08l-1.68,-6.72l-6.72,-0" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /></Icon>
};