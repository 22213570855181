/** Imports */
import {
  Box,
  Grid,
  Heading,
  Text,
  Select,
  Stack,
  HStack,
  FormControl,
  FormLabel,
  Input,
  GridItem,
  VStack,
  StackDivider,
  Textarea,
  RadioGroup,
  Radio,
  Button,
  useRadioGroup,
  useToast,
  Divider,
  SimpleGrid
} from '@chakra-ui/react';
import { useErrors } from '../../helpers/integer';
import { useContext, useState } from 'react';
import api from "../../helpers/api";
import { useEffect } from 'react';
import { Page } from '../../components/templates/Page';
import { Loader } from '../../components/Loader';
import { InputField } from '../../components/input/InputField';
import { useForm } from 'react-hook-form';
import { Title } from '../../components/templates/Title';
import { Success }              from '../../components/Success';
import { Legend } from '../../components/templates/Legend';
import { GlobalDataContext } from '../../components/context/GlobalData';
// import { analytics } from 'firebase-functions/v1';
// import { logEvent } from 'firebase/analytics';

const SymptomaticPage = () => {

  /** Hooks */
  const showErrors = useErrors();
  const [data, setData] = useState({});
  const [globalData, setGlobalData] = useContext(GlobalDataContext);
  const [success, setSuccess] = useState(false);
  const [dataSuccess, setdataSuccess] = useState({});

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    isDirty,
    control,
    setValue
  } = useForm({});

  const toast = useToast();

  // URLs
  const sendSurveyUrl = 'SurveyServices/saveSurvey';

  /**
   * Enviar encuesta con la información contestada
   */
  const onSubmit = async (data) => {
    setData(data);

    try {
      const response = await api.post(sendSurveyUrl, data);
      
      if(response.data.success == true){
        setdataSuccess(response.data.message);
        setSuccess(true);
        toast({
          title     : 'Encuesta enviada satisfactoriamente',
          status    : 'success',
          duration  : 3000,
          isClosable: true,
        })
      } else {
        toast({
          title     : 'Hubo un problema al enviar tu encuesta',
          status    : 'warning',
          duration  : 3000,
          isClosable: true,
        })
      }
    } catch (err) {
      showErrors(err);
    }
  }

  useEffect(() => {
  }, [])

  if(!globalData?.contact) {
    return <Loader />;
  }

  if(success) {
    return <Success dataSuccess={dataSuccess}/>;
  }

  return (
    <Page title="Juntos nos cuidamos" description="Porque hoy más que nunca es fundamental que nos cuidemos y estemos comunicados. A través de este cuestionario queremos saber cómo estás de salud y poder tomar las medidas necesarias para cuidarte a ti y tu equipo. Los resultados son confidenciales y sólo tendrán acceso tu jefe directo y RRHH. Recuerda que debes completarlo todos los días antes de salir de tu casa.">
      <VStack
        spacing={4}
        align='stretch'
      >
      </VStack>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Legend>Datos de colaborador</Legend>

        <SimpleGrid columns={{ base: '1', lg: '2', xl: '3' }} spacing={4} p={5}>
          <InputField disabled defaultValue={globalData?.contact?.Name?.First} label="Nombres" />
          <InputField disabled defaultValue={`${globalData?.contact?.Name?.Last} ${globalData?.contact?.CustomFields?.c?.apellido_materno}`} label="Apellidos" />
          <InputField label="Teléfono colaborador" defaultValue={globalData?.contact?.Phones && globalData?.contact?.Phones[0]} name="Contact.Phones[0].Number" type="phone" placeholder="+56 9 0000 0000" register={register} errors={errors} required={true} />
        </SimpleGrid>
        <Legend>Datos contacto de emergencia</Legend>

        <SimpleGrid columns={{ base: '1', lg: '2', xl: '3' }} spacing={4} p={5}>
          <InputField label="Nombre completo" defaultValue={globalData?.contact?.CustomFields?.c?.emergency_contact_full_name} name="Contact.CustomFields.c.emergency_contact_full_name" type="text" placeholder="" register={register} errors={errors} required={true} />
          <InputField label="Teléfono"   defaultValue={globalData?.contact?.CustomFields?.c?.emergency_contact_phone_number} name="Contact.CustomFields.c.emergency_contact_phone_number" type="phone" placeholder="" register={register} errors={errors} required={true} />
          <InputField label="Parentesco" defaultValue={globalData?.contact?.CustomFields?.c?.emergency_contact_relationship}  name="Contact.CustomFields.c.emergency_contact_relationship.ID" type="select" register={register} errors={errors} required={true} options={
            [
              {'value': 623, 'label': 'Cónyuge / Pareja'},
              {'value': 624, 'label': 'Padre / Madre / Hermano'},
              {'value': 625, 'label': 'Amigo/a'},
              {'value': 626, 'label': 'Otro'}
            ]
          } />
        </SimpleGrid>

        <Legend>Preguntas</Legend>

        <SimpleGrid columns={{ base: '1', lg: '2', xl: '3' }} spacing={4} p={5}>
          <InputField label="¿Cuántas dósis te has puesto?" defaultValue={globalData?.contact?.CustomFields?.c?.how_many_doses_have_you_put?.ID} name="Contact.CustomFields.c.how_many_doses_have_you_put.ID" type="select" register={register} errors={errors} required={true} options={
            [
              {'value': 662, 'label': '1 Dosis'},
              {'value': 663, 'label': '2 Dosis'},
              {'value': 664, 'label': '3 dosis de refuerzo'},
              {'value': 665, 'label': '4 dosis de refuerzo'}
            ]
          } />
          <InputField label="¿Te has vacunado?" defaultValue={globalData?.contact?.CustomFields?.c?.have_you_had_vaccine} name="Contact.CustomFields.c.have_you_had_vaccine" type="radio" register={register} errors={errors} required={true} control={control} />
          <InputField name="PreguntaEncuesta.ID_17" size={{ base: '1', lg: '2', xl: '3' }} label="1) ¿Has sido notificado como alerta covid de un caso positivo confirmado?" type="radio" register={register} errors={errors} required={true} control={control} />
          <InputField name="PreguntaEncuesta.ID_18" size={{ base: '1', lg: '2', xl: '3' }} label="2) ¿Has presentado al menos 2 o más síntomas relacionados a Covid-19? Tos, dificultad respiratoria, congestión nasal, aumento de la frecuencia respiratoria, dolor de garganta, Dolor muscular, Fatiga, Dolor Torácico, calofríos, dolor de cabeza, diarrea, nauseas o vómitos" type="radio" register={register} errors={errors} required={true} control={control} />
          <InputField name="PreguntaEncuesta.ID_19" size={{ base: '1', lg: '2', xl: '3' }} label="3) ¿Has presentado al menos un síntoma cardinal fiebre superior a 37, 8, perdida brusca del olfato o gusto?" type="radio" register={register} errors={errors} required={true} control={control} />
          <InputField name="PreguntaEncuesta.ID_21" size={{ base: '1', lg: '2', xl: '3' }} label="4) Confirmo que con fecha de hoy me informé del protocolo Covid-19 publicado en workplace el 26 de Abril 2022" type="radio" register={register} errors={errors} required={true} control={control} />
          <InputField name="PreguntaEncuesta.ID_20" label="Comentarios" type="textarea" placeholder="" register={register} errors={errors} required={false} size={{ base: '1', lg: '2', xl: '3' }} />
        </SimpleGrid>

        <Stack maxW={'200'} py={'6'}>
          <Button
            type="submit"
            isLoading={isSubmitting}
            loadingText="Enviando..."
            disabled={isSubmitting}
          >
            Enviar Encuesta
          </Button>
        </Stack>

      </form>
    </Page>
  );
}

export default SymptomaticPage;