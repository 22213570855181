/** Imports */
import {
  Stack,
  Text
} from '@chakra-ui/react';
import { useErrors, htmlfy } from '../../helpers/integer';
import { Loader } from '../../components/Loader';
import { useState, useEffect } from 'react';
import api from '../../helpers/api';
import { Page } from '../../components/templates/Page';

const Renovation = () => {
  /** Hooks */
  const showErrors = useErrors();
  const [data, setData] = useState(false);
  const url_service = 'ReportServices/getReportHomeBoxesByID';

  const request = {
    'report_id': 101238,
    'filter_name': 'ID',
    'filter_value': '1409'
  };

  const getReportAnswer = async () => {
    try {
      const response = await api.post(url_service, request)
      setData(response?.data?.response?.result_father_box[0]);
      showErrors(response, false);
    } catch (err) {
      showErrors(err);
    }
  }

  useEffect(() => {
    getReportAnswer();
  }, [])

  if(data === false) {
    return <Loader />;
  }

  return (
    <Page title={data?.summary}  path_name="/">
        <Stack>
          <Stack>
            <Text align={'left'} dangerouslySetInnerHTML={{ __html: htmlfy(data.solution) }} />
          </Stack>
        </Stack>
      </Page>
  );
}

export default Renovation;