import { Icon } from "@chakra-ui/react";
export const Expend = props => {
  let {color = '#565656'} = props;
  return <Icon  viewBox="0 0 41 40" {...props}><rect id="expend" x={0.079} y={0} width={40} height={40} style={{
    fill: "none"
  }} /><path id="path290" d="M11.829,15.78l-1.68,-0c-1.854,-0.005 -3.355,-1.507 -3.36,-3.36l-0,-3.34c0.005,-1.853 1.506,-3.355 3.36,-3.36l20.16,0c1.855,0 3.36,1.504 3.36,3.36l-0,3.35c-0,1.861 -1.509,3.37 -3.37,3.37l-1.67,-0" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path291" d="M15.189,10.74l10.08,0c0.928,0 1.68,0.752 1.68,1.68l-0,20.16c-0,0.928 -0.752,1.68 -1.68,1.68l-10.08,-0c-0.928,-0 -1.68,-0.752 -1.68,-1.68l-0,-20.16c-0,-0.928 0.752,-1.68 1.68,-1.68Z" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path292" d="M21.909,22.5c-0,0.928 -0.752,1.68 -1.68,1.68c-0.928,-0 -1.68,-0.752 -1.68,-1.68c-0,-0.928 0.752,-1.68 1.68,-1.68c0.928,-0 1.68,0.752 1.68,1.68Z" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /></Icon>
};