import { Icon } from "@chakra-ui/react";
export const Plant = props => {
  let {color = '#565656'} = props;
  return <Icon  viewBox="0 0 41 40" {...props}><rect id="plant" x={0.079} y={0} width={40} height={40} style={{
    fill: "none"
  }} /><path id="path22" d="M8.029,16.64l23.52,0c1.855,0 3.36,1.504 3.36,3.36c-0,1.856 -1.505,3.36 -3.36,3.36l-23.52,-0c-1.856,-0 -3.36,-1.504 -3.36,-3.36c-0,-1.856 1.504,-3.36 3.36,-3.36Z" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path23" d="M25.639,36.8l-11.69,-0c-1.505,0.003 -2.817,-1.02 -3.18,-2.48l-2.74,-11l23.52,0l-2.74,11c-0.36,1.458 -1.669,2.482 -3.17,2.48Z" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.17px"
  }} /><path id="path24" d="M18.079,16.64c0.08,-5 -1.6,-11.76 -10,-11.76c-0,5.06 3.31,11.76 10,11.76Z" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.17px"
  }} /><path id="path25" d="M18.079,16.64c0.08,-6.72 3.44,-13.44 13.52,-13.44c-0,10.08 -6.72,13.44 -13.44,13.44" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.17px"
  }} /></Icon>
};