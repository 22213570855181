import { Icon } from "@chakra-ui/react";
export const Pay = props => {
  let {color = '#565656'} = props;
  return <Icon  viewBox="0 0 41 40" {...props}><rect id="pay" x={0.079} y={0} width={40} height={40} style={{
    fill: "none"
  }} /><path id="path41" d="M2.219,23.924l7.76,-9.74c0.393,-0.533 1.018,-0.845 1.68,-0.84l1.32,0" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path42" d="M3.899,36.194l10.75,-3.51c0.977,-0.319 1.81,-0.974 2.35,-1.85l2,-3.36c0.17,-0.17 0.17,-0.33 0.34,-0.33l4.53,-3.87c1.02,-0.834 1.172,-2.338 0.34,-3.36l-0.17,-0.17c-0.395,-0.544 -0.994,-0.904 -1.659,-0.998c-0.666,-0.095 -1.341,0.085 -1.871,0.498l-7.89,6.05" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path43" d="M35.319,9.984l-20.34,4.37" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path44" d="M15.319,22.084l-2.34,-10.76c-0.187,-0.654 -0.187,-1.346 -0,-2c0.122,-0.484 0.422,-0.905 0.84,-1.18c0.594,-0.429 1.278,-0.716 2,-0.84l15.62,-3.36c0.654,-0.184 1.346,-0.184 2,0c0.461,0.164 0.867,0.455 1.17,0.84c0.437,0.589 0.725,1.275 0.84,2l2.36,10.75c0.173,0.655 0.173,1.345 -0,2c-0.125,0.481 -0.425,0.898 -0.84,1.17c-0.589,0.438 -1.276,0.726 -2,0.84l-11.76,2.52" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /></Icon>
};