/** Imports */
import {
  AspectRatio,
  Box, Button, Heading, Image, Skeleton, Spacer, Stack, Text
} from '@chakra-ui/react';
import { useErrors, htmlfy } from '../../helpers/integer';
import { Loader } from '../../components/Loader';
import { motion } from "framer-motion";
import { useNavigate, useParams } from 'react-router-dom';
import { useState, useEffect, Fragment } from 'react';
import api from "../../helpers/api";
import { Page } from '../../components/templates/Page';
import { Title } from '../../components/templates/Title';
// import { analytics } from 'firebase-functions/v1';
// import { logEvent } from 'firebase/analytics';

const AgreementsDetailPage = () => {

  /** Hooks */
  const showErrors                        = useErrors();
  const [data, setData]                   = useState(false);

  const { id }      = useParams('id');
  let   navigate    = useNavigate();
  const url_service = 'ReportServices/getReportHomeBoxesByID';

  const request = {
    'report_id': 101139,
    'filter_name': 'answer_id',
    'filter_value': id
  };

  /**
   * Obtiene reporte
   */
  const getReportAnswer = async () => {
    try {
      const response = await api.post(url_service, request)
      setData(response.data.response.result_father_box[0]);
      showErrors(response, false);
    } catch (err) {
      showErrors(err);
    }
  }

  useEffect(() => {
    getReportAnswer();
  }, [])

  if(data === false) {
    return <Loader />;
  }

  return (
    <Page title={data.summary} path_name="/agreements/discounts">
      <Stack>
        <Stack>
          <Text align={'left'} dangerouslySetInnerHTML={{ __html: htmlfy(data.solution) }} />
        </Stack>
      </Stack>
    </Page>
  );
}

export default AgreementsDetailPage;