import { Icon } from "@chakra-ui/react";
export const Call = props => {
  let {color = '#565656'} = props;
  return <Icon  viewBox="0 0 41 40" {...props}><rect id="call" x={0.079} y={0} width={40} height={40} style={{
    fill: "none"
  }} /><path id="path169" d="M23.157,36.277c-4.519,-1.495 -8.627,-4.021 -12,-7.38c-3.383,-3.313 -5.938,-7.376 -7.46,-11.86c-1,-2.579 -0.433,-5.503 1.46,-7.52c2.63,-2.6 5.12,-0.86 7.6,1.59c2.48,2.45 4.24,5.07 1.61,7.52c-0.809,0.75 -1.752,1.341 -2.78,1.74c1.011,1.569 2.188,3.024 3.51,4.34c1.333,1.313 2.805,2.477 4.39,3.47c0.298,-1.069 0.907,-2.026 1.75,-2.75c2.63,-2.6 5.12,-0.87 7.61,1.59c2.49,2.46 4.23,5.06 1.6,7.52c-1.759,2.159 -4.746,2.872 -7.29,1.74Z" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path170" d="M18.337,9.857c6.495,0 11.76,5.265 11.76,11.76" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path171" d="M19.887,3.007c9.422,0 17.06,7.638 17.06,17.06" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /></Icon>
};