import {
    Table,
    Thead,
    Tbody,
    Tr,
    TableCaption,
    TableContainer,
    Th,
    Td
  } from '@chakra-ui/react';
  import * as React from 'react'

  export const ReportDependent = (props) => {

    const { dependent } = props

    return (
      <TableContainer>
        <Table>
          <TableCaption>Historial de Dependientes</TableCaption>
          <Thead>
            <Tr>
              <Th>Nombre Completo</Th>
              <Th>RUT</Th>
              <Th>DV </Th>
              <Th>Puntos</Th>
              <Th>Cargo</Th>
              <Th>Correo Electrónico</Th>
              <Th>Jefe Directo</Th>
            </Tr>
          </Thead>
          <Tbody>
          {dependent.map((dependent) => (
          <Tr key={dependent?.RUT}>
              <Td>{dependent?.NombreColaborador}</Td>
              <Td>{dependent?.RUT}</Td>
              <Td>{dependent?.DV}</Td>
              <Td>{dependent?.points}</Td>
              <Td>{dependent?.Cargo}</Td>
              <Td>{dependent?.CorreoElectronico}</Td>
              <Td>{dependent?.NombreJefe}</Td>
            </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    )
  }