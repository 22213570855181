/** Imports */
import {
    Box,
    Grid,
    Heading,
    HStack,
    Image,
    Skeleton,
    Stack,
    Text,
    VStack
  } from '@chakra-ui/react';
import { useErrors, htmlfy } from '../../helpers/integer';
import { useState } from 'react';
import api from "../../helpers/api";
import { useEffect } from 'react';
import { ReportGrid } from '../../components/ReportGrid';
import { Loader } from '../../components/Loader';
import { Page } from '../../components/templates/Page';
import { Title } from '../../components/templates/Title';
import { useParams } from 'react-router-dom';
// import { analytics } from 'firebase-functions/v1';
// import { logEvent } from 'firebase/analytics';

const MyPointsPage = () => {

  /** Hooks */
  const showErrors = useErrors();
  const [data, setData] = useState(false);
  const { id } = useParams('id');

  /**
   * Obtiene el reporte de productos
   */
  const getReport = async () => {
    try {
      const response = await api.post('ReportServices/getReportHomeBoxesByID', { 'report_id': 101192, 'filter_name': 'id', 'filter_value': id})
      showErrors(response, false);
      setData(response?.data?.response?.result_father_box[0]);
    } catch (err) {
      showErrors(err);
    }
  }

  useEffect(() => {
    getReport();
  }, []);

  if(data === false) {
    return <Loader />
  }

  return (
    <Page title="¿Qué son mis puntos Vive?">
      <Text>
        <Grid
          templateColumns={ {base: '1', sm:'repeat(1, 1fr)',  md:'repeat(1, 1fr)', xl:'repeat(1, 1fr)'} }
        >
          <Stack>
            <Box align={'left'} dangerouslySetInnerHTML={{ __html: htmlfy(data?.solution) }} />
          </Stack>
          <Stack>
          </Stack>
        </Grid>
      </Text>
    </Page>
  );
}

export default MyPointsPage;
