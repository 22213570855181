/**
 * @version 1.0.0
 * @description Template sombra de tarjeta
 * 
 * @param {Object} props - props
 * 
 * @returns {Object} - Message
 * 
 * @example <Message>...</Message>
 */
import {
  Alert,
  Box, Heading, Stack, Text
} from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import { Icons } from './Icons';

/**
 * 
 * 
 * type (error | info | loading | success | warning)
 * @param {*} param0 
 * @returns 
 */
export const Message = (props) => {

  const { type } = props;

  /** Variables */
  const icon = (type === 'info')?'doll':null;

  useEffect(() => {
  }, []);

  return (
    <Alert status={type} borderRadius={'md'} mb="1" bg="brand.50">
      <Stack
        flex="1"
        direction={{ base: 'column', md: 'row' }}
        justify="center"
        align={{ md: 'center' }}
      >
        {icon && <Box><Icons icon={icon} w="5" h="5" /></Box>}
        {props.children}
      </Stack>
    </Alert>
  );
}
