/** Imports */
import {
    Box,
    Grid,
    Heading,
    Text,
    useDisclosure
  } from '@chakra-ui/react';
import { useErrors } from '../../helpers/integer';
import { useContext, useState } from 'react';
import api from "../../helpers/api";
import { useEffect } from 'react';
import { Page } from '../../components/templates/Page';
import { Approvals } from '../../components/Approvals';
import { Loader } from '../../components/Loader';
import { Title } from '../../components/templates/Title';
import { GlobalDataContext } from '../../components/context/GlobalData';
// import { analytics } from 'firebase-functions/v1';
// import { logEvent } from 'firebase/analytics';

const ApprovalsBP = () => {

  /** Hooks */
  const showErrors = useErrors();
    const [data, setData] = useState(false);

    const url_service = 'ReportServices/getReportByFilter';

    /**
     * Reporte
     */
    const getReport = async () => {
      try {
        const response = await api.post(url_service, {'report_id': 101234, 'filter_name': '', 'filter_value': ''})
        showErrors(response, false);
        setData(response.data.response.data, false);
      } catch (err) {
        showErrors(err);
      }
    }

    useEffect(() => {
      getReport();
    }, [])

    if(data === false) {
      return <Loader />;
    }

    return (
      <Page title="Aprobaciones BP">
        {data?<Approvals approvals={data} />:<Text>No tienes solicitudes por aprobar</Text>}
      </Page>
    );
  }

  export default ApprovalsBP;