import { Icon } from "@chakra-ui/react";
export const Accessibility = props => {
  let {color = '#565656'} = props;
  return <Icon  viewBox="0 0 41 40" {...props}><rect id="accessibility" x={0.079} y={0} width={40} height={40} style={{
    fill: "none"
  }} /><path id="path204" d="M22.599,12.494c-0,1.392 -1.129,2.52 -2.52,2.52c-1.392,0 -2.52,-1.128 -2.52,-2.52c-0,-1.392 1.128,-2.52 2.52,-2.52c1.391,-0 2.52,1.128 2.52,2.52Z" style={{
    fill: color,
    fillRule: "nonzero"
  }} /><path id="path240" d="M36.879,20.054c-0,9.278 -7.522,16.8 -16.8,16.8c-9.279,0 -16.8,-7.522 -16.8,-16.8c-0,-9.278 7.521,-16.8 16.8,-16.8c9.278,0 16.8,7.522 16.8,16.8Z" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path241" d="M15.039,18.374l10.08,0" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path242" d="M20.079,18.494l-0,4.92" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path243" d="M25.119,30.134l-5.04,-6.72" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path244" d="M15.039,30.134l5.04,-6.72" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /></Icon>
};