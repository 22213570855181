import { Icon } from "@chakra-ui/react";
export const Truck = props => {
  let {color = '#565656'} = props;
  return <Icon  viewBox="0 0 41 40" {...props}><rect id="truck" x={0.079} y={0} width={40} height={40} style={{
    fill: "none"
  }} /><path id="path257" d="M21.781,30.09l-8.4,-0" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path258" d="M5.841,30.17c-0.794,-0.018 -1.536,-0.397 -2.016,-1.03c-0.479,-0.634 -0.643,-1.451 -0.444,-2.22l2,-15c0.259,-1.155 1.276,-1.982 2.46,-2l20.66,-0l-1.68,16.79" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path259" d="M28.501,30.09c0,1.856 -1.504,3.36 -3.36,3.36c-1.856,-0 -3.36,-1.504 -3.36,-3.36c0,-1.856 1.504,-3.36 3.36,-3.36c1.856,-0 3.36,1.504 3.36,3.36Z" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path260" d="M13.381,30.09c0,1.856 -1.504,3.36 -3.36,3.36c-1.856,-0 -3.36,-1.504 -3.36,-3.36c0,-1.856 1.504,-3.36 3.36,-3.36c1.856,-0 3.36,1.504 3.36,3.36Z" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path261" d="M28.501,14.97l5,-0c1.017,0.001 1.979,0.462 2.617,1.255c0.637,0.793 0.881,1.831 0.663,2.825l-1.59,11l-5,-0" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /></Icon>
};