/** Imports */
import {
    Box,
    Button,
    Grid,
    Heading,
    SimpleGrid,
    Stack,
    Text,
    useToast,
    VStack
  } from '@chakra-ui/react';
import { useErrors }            from '../../helpers/integer';
import { useContext, useState } from 'react';
import { useEffect }            from 'react';
import { Page }                 from '../../components/templates/Page';
import { Loader }               from '../../components/Loader';
import { Title }                from '../../components/templates/Title';
import { useForm }              from 'react-hook-form';
import { GlobalDataContext }    from '../../components/context/GlobalData';
import { Success }              from '../../components/Success';
import { formatRut, validateEmail, validateRut } from '../../helpers/integer';
// import { analytics } from 'firebase-functions/v1';
// import { logEvent } from 'firebase/analytics';

// Helpers
import api from "../../helpers/api";
import { Legend } from '../../components/templates/Legend';
import { InputField } from '../../components/input/InputField';

const BossPage = () => {

  /** Hooks */
  const showErrors = useErrors();
  const [data, setData] = useState({});
  const [success, setSuccess] = useState(false);
  const [dataSuccess, setdataSuccess] = useState({});
  const [globalData, setGlobalData] = useContext(GlobalDataContext);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    isDirty,
    control,
    setValue
  } = useForm({});

  const toast = useToast();
  const updateBossUrl = '/';

  useEffect(() => {
  }, [])

  /**
   * Envio del formulario
   */
  const onSubmit = async (data) => {

    data.subject  = 'Solicitud de Cambio Jefe Directo';
    data.Incident = {};

    data.Incident.product    = {};
    data.Incident.product.id = 25;

    data.Incident.category    = {};
    data.Incident.category.id = 180;
    data.status_id            = {};
    data.status_id            = 1;

    setData(data);
    try {
      const response = await api.post('IncidentServices/createIncident', data);

      if(response.data.result == true){
        toast({
          title: response.data.response.message,
          status: 'success',
          duration: 3000,
          isClosable: true,
        })

        setdataSuccess(response.data.response.incident);
        setSuccess(true);
      } else {
        toast({
          title: response.data.response.message,
          status: 'warning',
          duration: 3000,
          isClosable: true,
        })
      }
    } catch (err) {
      console.log(err);
    }
  }

  if(!globalData?.contact) {
    return <Loader />;
  }

  if(success) {
    return <Success dataSuccess={dataSuccess?.ref_number}  message={'Solicitud creada Exitosamente'}/>;
  }

  return (
    <Page title="Mi jefe directo" description="Revisa que tu jefatura sea la correcta, de lo contrario actualiza con su RUT o correo electrónico y presiona en Actualizar." >
      <Box
        w="100%"
        mx="auto"
        align={'center'}
      >
        <VStack
          spacing={4}
          align='stretch'
        >
        </VStack>

        <Legend>Mi jefatura</Legend>

        <form onSubmit={handleSubmit(onSubmit)}>
          <SimpleGrid columns={{ base: '1', lg: '2', xl: '3' }} spacing={4} p={5}>
            <InputField disabled defaultValue={(globalData?.contact?.JefeDirecto?.Name?.First)?globalData?.contact?.JefeDirecto?.Name?.First + ' ' + globalData?.contact?.JefeDirecto?.Name?.Last:'- Sin asignación -'} label="Tu Jefe Directo es" />
          </SimpleGrid>
        <Legend>Corregir Jefatura</Legend>
        <SimpleGrid columns={{ base: '1', lg: '2', xl: '3' }} spacing={4} p={5}>
        <InputField label="RUT jefatura" name="CustomFields.CO.OtherContactInIncident" type="text" placeholder="11111111-1" required={true} register={register} errors={errors}
            validate={
              (value) => {
                value = value.replace(/\s/gi, '')
                if(/^[^\d]/.test(value)) {

                } else {
                  return validateRut(value) ? true : 'RUT inválido';
                }
              }
            }
            setValueAs = {
              (value) => {
                value = value.replace(/\s/gi, '')

                if(/^[^\d]/.test(value)) {
                  return value;
                } else {
                  return formatRut(value, 2);
                }
              }
            }
          />
            {/* <InputField label="RUT jefatura" name="CustomFields.CO.OtherContactInIncident" type="RUT" placeholder="RUT" register={register} errors={errors} required={true} /> */}
          </SimpleGrid>
          <Stack maxW={'200'} py={'6'}>
            <Button
              type="submit"
              isLoading={isSubmitting}
              loadingText="Solicitando..."
              disabled={isSubmitting}
            >
              Corregir Jefatura
            </Button>
          </Stack>
        </form>
      </Box>
    </Page>
  );
}

export default BossPage;