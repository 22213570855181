/** Imports */
import {
  Box,
  Grid,
  Heading,
  Text
} from '@chakra-ui/react';
import { useErrors } from '../../helpers/integer';
import { useState } from 'react';
import api from '../../helpers/api';
import { Page } from '../../components/templates/Page';
import { useEffect } from 'react';
import { ProductGrid } from '../../components/catalog/ProductGrid';
import { Loader } from '../../components/Loader';
import { Card } from '../../components/card/Card';
import { Title } from '../../components/templates/Title';
// import { analytics } from 'firebase-functions/v1';
// import { logEvent } from 'firebase/analytics';

const BenefitTransversePage = () => {

  /** Hooks */
  const showErrors                        = useErrors();
  const [data, setData]                   = useState(false);

  const url_service = 'ReportServices/getReportHomeBoxes';

  const request = {
    'report_id': 101123
  };

  /**
   * Reporte Answer Beneficios Transversales
   */
  const getReportExchange = async () => {
    try {
      const response = await api.post(url_service, request)
      showErrors(response, false);
      setData(response.data.response.result_father_box);
    } catch (err) {
      showErrors(err);
    }
  }

  useEffect(() => {
    getReportExchange();
  }, []);

  if(data === false) {
    <Loader />
  }
  
  return (
    <Page title="Beneficios Falabella" p="0" color="transparent" description={"Queremos que sigas disfrutando de la experiencia de ser parte de Falabella utilizando los beneficios transversales en Mallplaza, Banco Falabella y Seguros Falabella, y en las plataformas de compra digital de Falabella.com, Linio y Fazil. Ingresa a cada sección para revisar el detalle de cada uno, cómo utilizarlo y en caso de requerir apoyo, contamos como canal único de atención para las inquietudes que tengas la Mesa de Servicio"}>
      {!!data && data.length !== 0?
        <ProductGrid>
          {data.map((benefit) => (
            <Card key={benefit.id} data={benefit} src={benefit.answer_image} click={'/benefits/Transverse/'} />
          ))}
        </ProductGrid>
        :
        <Loader/>
      }
    </Page>
  );
}

export default BenefitTransversePage;