/** Imports */
import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    AspectRatio,
    Box, Button, Heading, Image, Skeleton, Spacer, Stack, Text
  } from '@chakra-ui/react';
import { useErrors, htmlfy } from '../../helpers/integer';
import { Loader } from '../../components/Loader';
import { motion } from "framer-motion";
import { useNavigate, useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import api from '../../helpers/api';
import { Page } from '../../components/templates/Page';
// import { analytics } from 'firebase-functions/v1';
// import { logEvent } from 'firebase/analytics';
  
  const BetterflyDetailPage = () => {
    /** Hooks */
    const showErrors = useErrors();
    const [data, setData] = useState(false);
  
    let navigate = useNavigate();
    const { id } = useParams('id');
  
    const url_service = 'ReportServices/getReportHomeBoxesByID';
  
    const request = {
      'report_id': 101122,
      'filter_name': 'ID',
      'filter_value': id
    };
    
  
    /**
     * Reporte de Answers Seguro Complementario
     */
    const getReportAnswer = async () => {
      try {
        const response = await api.post(url_service, request)
        setData(response?.data?.response?.result_father_box[0]);
        showErrors(response, false);
      } catch (err) {
        showErrors(err);
      }
    }
  
    useEffect(() => {
      getReportAnswer();
    }, [])  

    if(data === false) {
      return <Loader />;
    }

    return (
      <Page title={data?.summary}  path_name="/benefits/betterfly">
          <Stack>
            <Stack>
              <Text align={'left'} dangerouslySetInnerHTML={{ __html: htmlfy(data.solution) }} />
            </Stack>
          </Stack>
        </Page>
    );
  }
  
  export default BetterflyDetailPage;