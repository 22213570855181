import { Icon } from "@chakra-ui/react";
export const Doll = props => {
  let {color = '#565656'} = props;
  return <Icon  viewBox="0 0 41 40" {...props}><rect id="doll" x={0.079} y={0} width={40} height={40} style={{
    fill: "none"
  }} /><path id="path189" d="M6.639,30.21c2.23,-4.83 3.36,-8.45 3.36,-13.7l-0,-0.63c-0,-5.88 4.4,-10.84 10.08,-10.84c5.68,0 10.08,5 10.08,10.84l-0,0.42c-0.024,4.85 1.129,9.633 3.36,13.94c-0,0 -6.7,3.38 -13.46,3.32c-6.76,-0.06 -13.42,-3.35 -13.42,-3.35Z" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path190" d="M23.849,32.6c-0,2.07 -1.67,4.39 -3.74,4.39c-2.07,0 -3.74,-2.32 -3.74,-4.39" style={{
    fill: color,
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path191" d="M21.789,5.45c-0,-1.35 -0.75,-2.44 -1.68,-2.44c-0.93,-0 -1.68,1.09 -1.68,2.44" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /></Icon>
};