import { Icon } from "@chakra-ui/react";
export const Singout = props => {
  let {color = '#565656'} = props;
  return <Icon  viewBox="0 0 40 40" {...props}><rect id="singout" x={0} y={0} width={40} height={40} style={{
    fill: "none"
  }} /><path id="path74" d="M10.58,31.76l0,1.69c0,1.85 1.5,3.35 3.35,3.35l13.47,-0c1.85,-0 3.35,-1.5 3.35,-3.35l0,-26.9c0,-1.85 -1.5,-3.35 -3.35,-3.35l-13.46,0c-1.85,0 -3.35,1.5 -3.35,3.35l0,1.69" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path136" d="M16.072,28.18l-8.18,-8.18l8.18,-8.18" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path137" d="M9.552,19.98l13.44,-0" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /></Icon>
};