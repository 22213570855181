/* Imports */
import {
  Box,
  Stack,
  Text,
  useToast,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverBody,
  HStack,
  Button,
  ListItem,
  List,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Image
} from '@chakra-ui/react';
import { GlobalDataContext }                     from '../../components/context/GlobalData';
import { useErrors }                             from '../../helpers/integer';
import { formatRut, validateEmail, validateRut } from '../../helpers/integer';
import { useNavigate, Link as LinkRRD }          from "react-router-dom";
import { useContext, useState}                             from 'react';
import Moment                                    from 'moment';
import { InputField }                            from '../../components/input/InputField';
import { useForm }                               from 'react-hook-form';
import { Icons }                                 from '../../components/templates/Icons';
import { useGoogleReCaptcha }                    from 'react-google-recaptcha-v3';
// import { GoogleReCaptchaProvider }               from 'react-google-recaptcha-v3';

// Helpers
import TokenService from "../../helpers/TokenService";
import { trim } from 'lodash';
import { analytics, logEvent } from '../../firebase';

/*
 * Página de inicio de sesión
 *
 * @returns JSX
 */
 export const LoginForm = () => {

  /** Hooks */
  const showErrors                                     = useErrors();
  const [globalData, setGlobalData, getContact, login] = useContext(GlobalDataContext);
  const [company, setCompany]                          = useState();
  const { isOpen, onOpen, onClose }                    = useDisclosure()
  const { executeRecaptcha }                           = useGoogleReCaptcha();
  let navigate                                         = useNavigate();
  const toast                                          = useToast()
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting }
  } = useForm();

  /**
   * Inicio de sesión
   */
  const onSubmit = async (data) => {
    try{
      setCompany(false);
      const token   = await executeRecaptcha('login');
      let response  = await login(data.login, trim(data.password), token);
      TokenService.setPassword(trim(data.password));
      showErrors(response);

        if (response?.data?.result) {
          await getContact();
          navigate('/home');
          toast({
            title:      'Sesión Iniciada',
            status:     'success',
            duration:   2000,
            isClosable: true,
          });
          if (response.data?.profiling[0].element === "satisfaction") {
            navigate("/satisfaction");
          }
        } else {
          if(response?.data?.error?.message === "modalActive") {
            setCompany(true);
          }
        }

        //Eventos analytics
        logEvent(analytics, window.location.pathname, {rut: data.login});
        logEvent(analytics, "event_total_access", {event_total_access: data.login});
        logEvent(analytics, "event_" + Moment().format('dddd'), {day: Moment().format('dddd')});
        logEvent(analytics, "event_zone", {event_zone: globalData?.contact?.CustomFields?.CO?.Zona?.LookupName});
        logEvent(analytics, "event_" + globalData?.contact?.CustomFields?.c?.genero?.LookupName, {genero: globalData?.contact?.CustomFields?.c?.genero?.LookupName});
        logEvent(analytics, "event_segment", {event_segment: globalData?.contact?.CustomFields?.CO?.ContratoColectivo?.LookupName});
        logEvent(analytics, "event_company", {event_company: globalData?.contact?.Empresa_interna?.LookupName});
      return response.data;
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      {
        company === true
        ?
          <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
              <ModalCloseButton />
                  <Image sx={{borderRadius: 10}} src='https://vivetusbeneficios.custhelp.com/euf/assets/images/aviso.png' />
              <ModalFooter>
                <Button ><a href="https://mi.falabella.com/#/auth">Ir a Mi Falabella</a></Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        :
          null
      }
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={4}>
          <InputField label={
              <HStack>
                <Text>Correo electrónico corporativo</Text>
                <Popover>
                  <PopoverTrigger>
                    <Box>
                    <Icons icon="question" w="6" h="6" />
                    </Box>
                  </PopoverTrigger>
                  <PopoverContent>
                    <PopoverArrow bg="#ef044e" />
                    <PopoverCloseButton />
                    <PopoverBody>
                      <Text color="#ffffff">
                      Inicia sesión con tu <strong>Correo electrónico coportativo</strong> o con tu <strong>RUT</strong>.
                      </Text>
                    </PopoverBody>
                  </PopoverContent>
                </Popover>
              </HStack>
            }
            name="login"
            type="text"
            placeholder="usuario@dominio"
            required={true}
            register={register}
            errors={errors}
            validate={
              (value) => {
                value = value.replace(/\s/gi, '')

                if(/^[^\d]/.test(value)) {
                  if(/(@(banco)?falabella\.(cl|com|com.ar)|@integer.cl)$/.test(value)) {
                    return validateEmail(value) ? true : 'Correo Electrónico inválido';
                  } else {
                    return 'Debes ingresar un correo corporativo';
                  }
                } else {
                  return validateRut(value) ? true : 'RUT inválido';
                }
              }
            }
            setValueAs = {
              (value) => {
                value = value.replace(/\s/gi, '')

                if(/^[^\d]/.test(value)) {
                  return value;
                } else {
                  return formatRut(value, 2);
                }
              }
            }
          />
          <InputField label="Clave" type="password" name="password" required={true} register={register} errors={errors} />
            <Button
              type="submit"
              isLoading= {isSubmitting}
              loadingText="Iniciando..."
              disabled={!!errors.login || !!errors.password || isSubmitting}
              onClick={onOpen}
            >
              Iniciar Sesión
            </Button>
          <LinkRRD
            color={'blue.400'}
            to="/forgot"
            align={'center'}
            >
            ¿Olvidaste tu clave?
          </LinkRRD>
          <Stack align={'center'}>
          <HStack>
            <Text >¿Tienes problemas para ingresar?</Text>
            <Popover>
              <PopoverTrigger>
                <Box>
                  <Icons icon="question" w="7" h="6" />
                </Box>
              </PopoverTrigger>
              <PopoverContent w={500}>
                <PopoverArrow bg="#ef044e" />
                <PopoverCloseButton />
                  <PopoverBody>
                  <List>
                    <ListItem align={'left'} fontSize={14}>- Tu clave se bloquea después de 5 intentos fallidos. </ListItem>
                    <ListItem align={'left'} fontSize={14}>- Evita copiar y pegar tu clave.</ListItem>
                    <ListItem align={'left'} fontSize={14}>- Si solicitas tu clave más de una vez la anterior ya no será válida.</ListItem>
                  </List>
                  </PopoverBody>
              </PopoverContent>
            </Popover>
          </HStack>
          </Stack>
        </Stack>
      </form>
    </>
  );
}

export default LoginForm;