import { getApp, initializeApp } from 'firebase/app';

import { getAnalytics, logEvent } from 'firebase/analytics';
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';

const firebaseConfig = {
  apiKey           : "AIzaSyCMeWRGuLHV3DCtk2UXLtDuO71v6UUCogI",
  authDomain       : "vivetusbeneficios.firebaseapp.com",
  projectId        : "vivetusbeneficios",
  storageBucket    : "vivetusbeneficios.appspot.com",
  messagingSenderId: "828715016960",
  appId            : "1:828715016960:web:b4b4027df287aee21a89e5",
  measurementId    : "G-4G43752LHK"
};

const app = initializeApp(firebaseConfig);

const functions = getFunctions(app);
// connectFunctionsEmulator(functions, 'localhost', 5001); // comentar si se pasa a producción

const analytics = getAnalytics(app);

export { analytics, functions, logEvent };
