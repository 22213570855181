import { Icon } from "@chakra-ui/react";
export const Gift = props => {
  let {color = '#565656'} = props;
  return <Icon  viewBox="0 0 41 40" {...props}><rect id="gift" x={0.079} y={0} width={40} height={40} style={{
    fill: "none"
  }} /><path id="path252" d="M6.609,20l26.88,0l-0,13.67c-0,1.729 -1.402,3.13 -3.13,3.13l-20.58,-0c-1.729,-0 -3.13,-1.401 -3.13,-3.13l-0,-13.67l-0.04,0Z" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path253" d="M4.989,11.6l30.18,-0c0.944,-0 1.71,0.766 1.71,1.71l-0,4.98c-0,0.944 -0.766,1.71 -1.71,1.71l-30.18,0c-0.945,0 -1.71,-0.766 -1.71,-1.71l-0,-4.98c-0,-0.944 0.765,-1.71 1.71,-1.71Z" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path254" d="M24.279,3.2c2.319,0 4.2,1.88 4.2,4.2c-0,2.32 -1.881,4.2 -4.2,4.2l-4.2,-0l-0,-4.22c0.011,-2.312 1.888,-4.18 4.2,-4.18Z" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path255" d="M20.079,11.6l-4.2,-0c-2.32,-0 -4.2,-1.88 -4.2,-4.2c-0,-2.32 1.88,-4.2 4.2,-4.2c2.319,0 4.2,1.88 4.2,4.2l-0,4.2Z" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path256" d="M20.079,9.92l-0,26.88" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /></Icon>
};