import { Icon } from "@chakra-ui/react";
export const Clock = props => {
  let {color = '#565656'} = props;
  return <Icon  viewBox="0 0 41 40" {...props}><rect id="clock" x={0.079} y={0} width={40} height={40} style={{
    fill: "none"
  }} /><path id="path237" d="M36.879,20c-0,9.278 -7.522,16.8 -16.8,16.8c-9.279,-0 -16.8,-7.522 -16.8,-16.8c-0,-9.278 7.521,-16.8 16.8,-16.8c9.278,0 16.8,7.522 16.8,16.8Z" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path136" d="M21.079,8.733l-0,13.689l-7.286,0" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /></Icon>
};