import { Icon } from "@chakra-ui/react";
export const Gym = props => {
  let {color = '#565656'} = props;
  return <Icon  viewBox="0 0 40 41" {...props}><rect id="gym" x={0} y={0.8} width={40} height={40} style={{
    fill: "none"
  }} /><path d="M9.814,15.027l0,13.241" style={{
    fill: "none",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path d="M9.814,15.027l0,13.241" style={{
    fill: "none",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path d="M4.5,16.868l0,9.641" style={{
    fill: "none",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path d="M30.186,15.332l-0,13.242" style={{
    fill: "none",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path d="M30.186,15.332l-0,13.242" style={{
    fill: "none",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path d="M35.5,17.091l0,9.641" style={{
    fill: "none",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path d="M9.814,21.647l20.372,0" style={{
    fill: "none",
    stroke: color,
    strokeWidth: "3.36px"
  }} /></Icon>
};