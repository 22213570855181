/** Imports */
import { Box, Button, Heading, Stack, Table, TableContainer, Tbody, Td, Text, Tfoot, Th, Thead, Tr, useToast, Wrap, WrapItem } from '@chakra-ui/react';
import { useErrors } from '../../helpers/integer';
import { useEffect, useState } from 'react';
import { ProductCard } from '../../components/catalog/ProductCard';
import { ProductGrid } from '../../components/catalog/ProductGrid';
import { Loader } from '../../components/Loader';
import { motion } from "framer-motion";
import api from "../../helpers/api";
import { Page } from '../../components/templates/Page';
import { Title } from '../../components/templates/Title';
import { Legend } from '../../components/templates/Legend';

const ThemePage = () => {

  /** Hooks */
  const showErrors = useErrors();

  const toast = useToast()
  const statuses = ['success', 'error', 'warning', 'info']

  return (
    <Wrap>
      {statuses.map((status, i) => (
        <WrapItem key={i}>
          <Button
            onClick={() =>
              toast({
                title: `${status} toast`,
                status: status,
                isClosable: true,
              })
            }
          >
            Show {status} toast
          </Button>
        </WrapItem>
      ))}
    </Wrap>
  )
}

export default ThemePage;
