import { Icon } from "@chakra-ui/react";
export const Bike = props => {
  let {color = '#565656'} = props;
  return <Icon  viewBox="0 0 40 40" {...props}><rect id="bike" x={0} y={0} width={40} height={40} style={{
    fill: "none"
  }} /><path id="path259" d="M14.789,24.47c-0,3.419 -2.771,6.19 -6.19,6.19c-3.419,0 -6.19,-2.771 -6.19,-6.19c-0,-3.419 2.771,-6.19 6.19,-6.19c3.419,-0 6.19,2.771 6.19,6.19Z" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path2591" d="M37.591,24.462c0,3.418 -2.771,6.19 -6.19,6.19c-3.418,-0 -6.19,-2.772 -6.19,-6.19c0,-3.419 2.772,-6.19 6.19,-6.19c3.419,-0 6.19,2.771 6.19,6.19Z" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path d="M15.237,13.179l-6.638,11.291" style={{
    fill: "none",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path d="M19.733,24.47l-6.025,-15.13" style={{
    fill: "none",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path d="M13.708,9.34l2.292,-0" style={{
    fill: "none",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path d="M31.401,24.462l-3,-15.122l4.227,-0" style={{
    fill: "none",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path d="M19.733,24.47l8.291,-11.291l-12.787,-0" style={{
    fill: "none",
    stroke: color,
    strokeWidth: "3.36px"
  }} /></Icon>
};