import { Icon } from "@chakra-ui/react";
export const Apps = props => {
  let {color = '#565656'} = props;
  return <Icon  viewBox="0 0 41 40" {...props}><rect id="apps" x={0.079} y={0} width={40} height={40} style={{
    fill: "none"
  }} /><path id="path113" d="M11.679,8.29c-0,1.856 -1.505,3.36 -3.36,3.36c-1.856,-0 -3.36,-1.504 -3.36,-3.36c-0,-1.856 1.504,-3.36 3.36,-3.36c1.855,0 3.36,1.504 3.36,3.36Z" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path114" d="M23.439,8.29c-0,1.856 -1.505,3.36 -3.36,3.36c-1.856,-0 -3.36,-1.504 -3.36,-3.36c-0,-1.856 1.504,-3.36 3.36,-3.36c1.855,0 3.36,1.504 3.36,3.36Z" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path115" d="M35.199,8.29c-0,1.856 -1.505,3.36 -3.36,3.36c-1.856,-0 -3.36,-1.504 -3.36,-3.36c-0,-1.856 1.504,-3.36 3.36,-3.36c1.855,0 3.36,1.504 3.36,3.36Z" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path116" d="M11.679,20.05c-0,1.856 -1.505,3.36 -3.36,3.36c-1.856,0 -3.36,-1.504 -3.36,-3.36c-0,-1.856 1.504,-3.36 3.36,-3.36c1.855,0 3.36,1.504 3.36,3.36Z" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path117" d="M23.439,20.05c-0,1.856 -1.505,3.36 -3.36,3.36c-1.856,0 -3.36,-1.504 -3.36,-3.36c-0,-1.856 1.504,-3.36 3.36,-3.36c1.855,0 3.36,1.504 3.36,3.36Z" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path118" d="M35.199,20.05c-0,1.856 -1.505,3.36 -3.36,3.36c-1.856,0 -3.36,-1.504 -3.36,-3.36c-0,-1.856 1.504,-3.36 3.36,-3.36c1.855,0 3.36,1.504 3.36,3.36Z" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path119" d="M11.679,31.81c-0,1.856 -1.505,3.36 -3.36,3.36c-1.856,0 -3.36,-1.504 -3.36,-3.36c-0,-1.856 1.504,-3.36 3.36,-3.36c1.855,0 3.36,1.504 3.36,3.36Z" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path120" d="M23.439,31.81c-0,1.856 -1.505,3.36 -3.36,3.36c-1.856,0 -3.36,-1.504 -3.36,-3.36c-0,-1.856 1.504,-3.36 3.36,-3.36c1.855,0 3.36,1.504 3.36,3.36Z" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path121" d="M35.199,31.81c-0,1.856 -1.505,3.36 -3.36,3.36c-1.856,0 -3.36,-1.504 -3.36,-3.36c-0,-1.856 1.504,-3.36 3.36,-3.36c1.855,0 3.36,1.504 3.36,3.36Z" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /></Icon>
};