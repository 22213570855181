import { Icon } from "@chakra-ui/react";
export const Person = props => {
  let {color = '#565656'} = props;
  return <Icon  viewBox="0 0 40 40" {...props}><rect id="person" x={0} y={0} width={40} height={40} style={{
    fill: "none"
  }} /><path id="path262" d="M26.759,10.994c0,3.731 -3.024,6.755 -6.754,6.755c-3.73,-0 -6.754,-3.024 -6.754,-6.755c-0,-3.73 3.024,-6.754 6.754,-6.754c3.73,-0 6.754,3.024 6.754,6.754Z" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path263" d="M10.999,30.493l0,-3.739c0,-4.973 4.032,-9.005 9.006,-9.005c4.974,-0 9.006,4.032 9.006,9.005l-0,3.739c-0,2.909 -2.358,5.267 -5.267,5.267l-7.465,0c-1.399,0.004 -2.742,-0.55 -3.732,-1.538c-0.991,-0.988 -1.548,-2.33 -1.548,-3.729Z" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /></Icon>
};