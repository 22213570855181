/** Imports */
import {
    Box,
    Button,
    Grid,
    Heading,
    HStack,
    Image,
    Skeleton,
    Stack,
    Text,
    VStack
  } from '@chakra-ui/react';
import { useErrors, htmlfy } from '../../helpers/integer';
import { Fragment, useState } from 'react';
import api from "../../helpers/api";
import { useEffect } from 'react';
import { Page } from '../../components/templates/Page';
import { ReportGrid } from '../../components/ReportGrid';
import { Loader } from '../../components/Loader';
import { Title } from '../../components/templates/Title';
import { Card } from '../../components/card/Card';
import { ProductGrid } from '../../components/catalog/ProductGrid';
// import { analytics } from 'firebase-functions/v1';
// import { logEvent } from 'firebase/analytics';

const Support = () => {

  /** Hooks */
  const showErrors = useErrors();
  const [data, setData] = useState(false);

  const url_service = 'ReportServices/getReportHomeBoxes';

  const a_content = {
    'report_id': 101195
  };

  /**
   * Reporte Answer Betterfly
   */
  const getReport = async () => {

    try {
      const response = await api.post(url_service, a_content)
      setData(response.data.response.result_father_box[0]);
      showErrors(response, false);
    } catch (err) {
      showErrors(err);
    }
  }

  useEffect(() => {
    getReport();
  }, []);

  if (data === false) {
    return <Loader />;
  }

  return (
    <Fragment>
    {
      (!!data)?
      <Page title={data.summary}>
        <VStack>
          <Box align={'left'} dangerouslySetInnerHTML={{ __html: htmlfy(data.solution) }} />
          <Box boxSize='xs'>
            <Skeleton isLoaded={data?.answer_image}>
              <Image src={data?.answer_image} />
            </Skeleton>
          </Box>
        </VStack>
      </Page>
      :
      <Text>Sin Datos</Text>
    }
  </Fragment>
  );
}

export default Support;