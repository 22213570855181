import { Icon } from "@chakra-ui/react";
export const Compass = props => {
  let {color = '#565656'} = props;
  return <Icon  viewBox="0 0 41 40" {...props}><rect id="compass" x={0.079} y={0} width={40} height={40} style={{
    fill: "none"
  }} /><path id="path135" d="M36.879,20c-0,9.278 -7.522,16.8 -16.8,16.8c-9.279,-0 -16.8,-7.522 -16.8,-16.8c-0,-9.278 7.521,-16.8 16.8,-16.8c9.278,0 16.8,7.522 16.8,16.8Z" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path154" d="M21.759,20c-0,0.928 -0.752,1.68 -1.68,1.68c-0.928,-0 -1.68,-0.752 -1.68,-1.68c-0,-0.928 0.752,-1.68 1.68,-1.68c0.928,-0 1.68,0.752 1.68,1.68Z" style={{
    fill: color,
    fillRule: "nonzero"
  }} /><path id="path157" d="M28.399,11.66c-0,-0 -2.38,9.51 -4.75,11.88c-2.37,2.37 -11.84,4.78 -11.84,4.78c-0,-0 2.37,-9.51 4.75,-11.88c2.38,-2.37 11.84,-4.78 11.84,-4.78Z" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /></Icon>
};