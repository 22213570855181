import { Icon } from "@chakra-ui/react";
export const Bill = props => {
  let {color = '#565656'} = props;
  return <Icon  viewBox="0 0 41 40" {...props}><rect id="bill" x={0.079} y={0} width={40} height={40} style={{
    fill: "none"
  }} /><path id="path217" d="M31.849,16.64l-0,16.53c0.002,0.964 -0.379,1.888 -1.061,2.57c-0.681,0.681 -1.606,1.063 -2.569,1.06l-19.64,0c-2.005,0 -3.63,-1.625 -3.63,-3.63l-0,-23c-0,-2.005 1.625,-3.63 3.63,-3.63l14.85,0" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path218" d="M12.099,13.27l9.67,-0" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path219" d="M12.099,19.99l6.31,-0" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path220" d="M11.919,26.71l3.13,-0" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path221" d="M31.849,3.2l-0,6.72" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path222" d="M35.209,6.56l-6.72,-0" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path223" d="M26.939,27.96c-0.117,-0.279 -0.299,-0.526 -0.53,-0.72c-0.242,-0.199 -0.511,-0.361 -0.8,-0.48c-0.327,-0.135 -0.661,-0.252 -1,-0.35c-0.33,-0.11 -0.63,-0.2 -0.89,-0.3c-0.229,-0.073 -0.44,-0.192 -0.62,-0.35c-0.158,-0.151 -0.241,-0.362 -0.23,-0.58c-0.004,-0.191 0.064,-0.376 0.19,-0.52c0.136,-0.149 0.313,-0.254 0.51,-0.3c0.238,-0.063 0.484,-0.09 0.73,-0.08c0.293,0.007 0.584,0.061 0.86,0.16c0.272,0.099 0.536,0.22 0.79,0.36c0.235,0.144 0.462,0.301 0.68,0.47l-0,-2.06c-0.253,-0.124 -0.513,-0.231 -0.78,-0.32c-0.262,-0.084 -0.529,-0.148 -0.8,-0.19l-0.34,-0l-0,-1l-1.51,-0l-0,1c-0.215,0.029 -0.426,0.079 -0.63,0.15c-0.393,0.134 -0.754,0.349 -1.06,0.63c-0.274,0.248 -0.486,0.556 -0.62,0.9c-0.127,0.318 -0.195,0.657 -0.2,1c-0.008,0.342 0.053,0.682 0.18,1c0.102,0.26 0.258,0.496 0.46,0.69c0.191,0.193 0.415,0.352 0.66,0.47c0.243,0.117 0.493,0.217 0.75,0.3l1.1,0.38c0.279,0.09 0.545,0.218 0.79,0.38c0.184,0.135 0.292,0.351 0.29,0.58c0.018,0.272 -0.103,0.535 -0.32,0.7c-0.277,0.176 -0.603,0.26 -0.93,0.24c-0.498,-0.008 -0.991,-0.096 -1.46,-0.26c-0.501,-0.155 -0.966,-0.406 -1.37,-0.74l-0,2c0.288,0.143 0.589,0.256 0.9,0.34c0.327,0.095 0.661,0.162 1,0.2l0.46,-0l-0,1.14l1.51,0l-0,-1.19c0.347,-0.044 0.685,-0.145 1,-0.3c0.443,-0.227 0.814,-0.573 1.07,-1c0.244,-0.444 0.371,-0.943 0.37,-1.45c-0.015,-0.31 -0.086,-0.615 -0.21,-0.9Z" style={{
    fill: color,
    fillRule: "nonzero"
  }} /></Icon>
};