import {
    Flex,
    Avatar,
    Text,
    Box,
    Icon,
    Button,
    Heading
  } from '@chakra-ui/react';
import { useState } from 'react';
import { Table } from "react-chakra-pagination";

  export const CustomTable = (props) => {
    const [page, setPage] = useState(1);
    const {procedure}  = props;
    const {title}  = props;

    // Accessor to get a data in user object
    const tableColumns = [
      {
        Header: "RUT",
        accessor: "rut"
      },
      {
        Header: "Nombre",
        accessor: "Nombre" 
      },
      {
        Header: "Apellido",
        accessor: "Apellido" 
      },
      {
        Header: "Apellido Materno",
        accessor: "apellido_materno"
      },
      {
        Header: "Monto Bruto",
        accessor: "monto_bruto"
      },
      {
        Header: "Monto Líquido",
        accessor: "monto_liquido"
      },
      {
        Header: "Periodo de Pago",
        accessor: "periodo_pago"
      },
      {
        Header: "Fecha de Pago",
        accessor: "fecha_pago"
      }
    ];

  const tableData = procedure.map((p, index) => ({
    Nombre: (
      <Flex key={index} align="center">
        <Text key={index}>{p?.Nombre}</Text>
      </Flex>
    ),
    rut: p?.rut,
    Apellido: p?.Apellido,
    apellido_materno: p?.apellido_materno,
    monto_bruto: p?.monto_bruto,
    monto_liquido: p?.monto_liquido,
    periodo_pago: p?.periodo_pago,
    fecha_pago: p?.fecha_pago
  }));

  return (
    <Box mt="6">
      <Table
        colorScheme="#56AF31"
        // Fallback component when list is empty
        emptyData={{
          text: "Cargando datos"
        }}
        totalRegisters={procedure?.length}
        page={page}
        // Listen change page event and control the current page using state
        onPageChange={(page) => setPage(page)}
        columns={tableColumns}
        data={tableData}
      />
    </Box>
  )
}