/** Imports */
import {
    Box,
    Grid,
    Heading,
    Text
  } from '@chakra-ui/react';
import { useErrors } from '../../helpers/integer';
import { useState } from 'react';
import api from "../../helpers/api";
import { useEffect } from 'react';
import { Page } from '../../components/templates/Page';
import { ReportGrid } from '../../components/ReportGrid';
import { Loader } from '../../components/Loader';
import { Title } from '../../components/templates/Title';
// import { analytics } from 'firebase-functions/v1';
// import { logEvent } from 'firebase/analytics';

const BenefitActivitiesPage = () => {
  /** Hooks */
  const showErrors = useErrors();
  const [data, setData] = useState(false);

  const url_service = '';

  /**
   * Reporte
   */
  const getReportExchange = async () => {

    try {
      const response = await api.post(url_service)
      setData(response.data.response.result);
      showErrors(response, false);
    } catch (err) {
      showErrors(err);
    }
  }

  useEffect(() => {
    getReportExchange();
  }, [])

  if(data === false) {
    return <Loader />;
  }

  return (
    <Page title="Deporte y recreación">
      <Text>
        {!!data?'Deporte y recreación...':<Text>Sin Datos</Text>}
      </Text>
    </Page>
  );
}

export default BenefitActivitiesPage;