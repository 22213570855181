import { Icon } from "@chakra-ui/react";
export const Rectangle = props => {
  let {color = '#565656'} = props;
  return <Icon  viewBox="0 0 40 40" {...props}><rect id="rectangle" x={0} y={0} width={40} height={40} style={{
    fill: "none"
  }} /><path id="path6" d="M29.938,10.487c1.433,-0.037 2.819,0.516 3.833,1.53c1.014,1.014 1.568,2.4 1.531,3.834l0.024,8.274c0.037,1.433 -0.516,2.819 -1.53,3.833c-1.014,1.014 -2.4,1.567 -3.833,1.53l-19.922,0.025c-1.434,0.037 -2.82,-0.516 -3.833,-1.53c-1.015,-1.014 -1.568,-2.4 -1.531,-3.834l0,-8.298c-0.037,-1.434 0.516,-2.819 1.531,-3.834c1.013,-1.014 2.399,-1.567 3.833,-1.53l19.897,0Z" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.9px"
  }} /></Icon>
};