import {
    Text,
    Link,
    Image,
    useMultiStyleConfig,
    Flex,
    Box
  } from '@chakra-ui/react';
  import * as React from 'react'
  import { Link as LinkRRD } from 'react-router-dom';
  import { ShadowBox } from '../templates/ShadowBox';

  export const BoxSchoolHelp = (props) => {
    const { content } = props;

    const styles = useMultiStyleConfig("Boxes", {});

    return <ShadowBox
      colSpan={{base: 1, lg: 1}}
    >
      <Flex
        direction={'column'}
        align={'center'}
        justify={'center'}
        h={'100%'}
      >
        <Image
          boxSize='150px'
          src='/images/scholarship/medal.png'
          draggable={false}
        />
        <Box w={'full'}>
          <Text textAlign="center" fontSize={styles.title.fontSize} fontWeight={styles.title.fontWeight} color={styles.title.color} p={2}
          >
             Bono ayuda escolar
          </Text>
        </Box>
        <Link as={LinkRRD} to={'/family/schoolbonus'}>
          {`Conoce más aquí`}
        </Link>
      </Flex>
    </ShadowBox>
  }