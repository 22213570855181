'use strict';

// import './App.css';

import {
  Box,
  CircularProgress,
  CircularProgressLabel
} from '@chakra-ui/react';
// import { analytics } from 'firebase-functions/v1';
// import { logEvent } from 'firebase/analytics';
// import { analytics } from 'firebase';

import { Routes, Route, Navigate, BrowserRouter as Router } from "react-router-dom";
import React, { useContext,useEffect,Suspense,lazy, useState } from "react";
import { Fragment }   from 'react';

// Páginas
import Standard                     from './templates/Standard';

// Auth
import LoginPage                    from './pages/auth/LoginPage';
import LoginCodePage                from './pages/auth/LoginCodePage';
import ForgotPage                   from './pages/auth/ForgotPage';
import LogoutPage                   from './pages/auth/LogoutPage';
import TwoFAPage                    from './pages/auth/TwoFAPage';

// Home
import HomePage                  from './pages/home/HomePage';
import BannerPage                from './pages/home/BannerPage';
import SchedulerPage             from './pages/home/SchedulerPage';
import MyPointsPage              from './pages/home/MyPointsPage';
import MyCollectiveContractPage  from './pages/home/MyCollectiveContractPage';
import RoulettePage              from './pages/home/RoulettePage';

// Gym
import GymPage                   from './pages/gym/GymPage';
import Recreational              from './pages/gym/Recreational';
import Sport                     from './pages/gym/Sport';
import MinniGennialsPage         from './pages/gym/MinniGennialsPage';
import Gennials                  from './pages/gym/Gennials';

// Catalog
import FreetimePage              from './pages/catalog/FreetimePage';
import TicketsPage               from './pages/catalog/TicketsPage';
import DonationPage              from './pages/catalog/DonationPage';
import CatalogDetailPage         from './pages/catalog/CatalogDetailPage';
import CatalogBirthdayPage       from './pages/catalog/CatalogBirthdayPage';
import RecognitionPage           from './pages/catalog/RecognitionPage';
import HistoryExchangePage       from './pages/catalog/HistoryExchangePage';
import BirthdayDetailPage        from './pages/catalog/BirthdayDetailPage';

// Benefits
import OtherBenefitsPage         from './pages/benefits/OtherBenefitsPage';
import TransverseDetailPage      from './pages/benefits/TransverseDetailPage';
import BenefitsPage              from './pages/benefits/BenefitsPage';
import BenefitDetailPage         from './pages/benefits/BenefitDetailPage';
import BenefitInsurancePage      from './pages/benefits/BenefitInsurancePage';
import InsuranceDetail           from './pages/benefits/InsuranceDetail';
import BenefitBetterflyPage      from './pages/benefits/BenefitBetterflyPage';
import InsurancePage             from './pages/benefits/InsurancePage';
import BenefitTransversePage     from './pages/benefits/BenefitTransversePage';
import BenefitActivitiesPage     from './pages/benefits/BenefitActivitiesPage';
import MyBenefitsPage            from './pages/benefits/MyBenefitsPage';
import BetterflyDetailPage       from './pages/benefits/BetterflyDetailPage';
import Renovation                from './pages/benefits/Renovation';

// Agreements
import AgreementsPage            from './pages/agreements/AgreementsPage';
import AgreementCompensationPage from './pages/agreements/AgreementCompensationPage';
import AgreementIsaprePage       from './pages/agreements/AgreementIsaprePage';
import AgreementDiscountPage     from './pages/agreements/AgreementDiscountPage';
import SocialSecurityDetailPage  from './pages/agreements/SocialSecurityDetailPage';
import CompensationDetailPage    from './pages/agreements/CompensationDetailPage';
import AgreementsDetailPage      from './pages/agreements/AgreementsDetailPage';
import DiscountBoxes             from './pages/agreements/DiscountBoxes';

// Annexes
import AnnexesPage               from './pages/annexes/AnnexesPage';
import ProcedureAnnexesPage      from './pages/annexes/ProcedureAnnexesPage';
import MyAnnexesPage             from './pages/annexes/MyAnnexesPage';
import AnnexPage                 from './pages/annexes/AnnexPage';

// Uniforms
import UniformsPage              from './pages/uniform/UniformsPage';

// Procedures
import ProceduresPage            from './pages/procedures/ProceduresPage';
import ProcedureAdvancePage      from './pages/procedures/ProcedureAdvancePage';
import ProceduresAdminPage       from './pages/procedures/ProceduresAdminPage';
import ProcedureHistoryPage      from './pages/procedures/ProcedureHistoryPage';

// Family
import FamilyPage                from './pages/family/FamilyPage';
import MaternityPage             from './pages/family/MaternityPage';
import BabyRoomPage              from './pages/family/BabyRoomPage';
import BirthBonusPage            from './pages/family/BirthBonusPage';
import CompensatoryBonusPage     from './pages/family/CompensatoryBonusPage';
import ScholarshipPage           from './pages/family/ScholarshipPage';
import ScholarshipInsurancePage  from './pages/family/ScholarshipInsurancePage';
import SchoolBonusPage           from './pages/family/SchoolBonusPage';

// Admin
import DependentPage             from './pages/admin/DependentPage';
import SubstitutePage            from './pages/admin/SubstitutePage';
import ApprovalsPage             from './pages/admin/ApprovalsPage';
import ApprovalsBP               from './pages/admin/ApprovalsBP';
import SymptomaticHistoricalPage from './pages/admin/SymptomaticHistoricalPage';
import ApprovalsHistoricalPage   from './pages/admin/ApprovalsHistoricalPage';
import ApprovalsBPHistoricalPage from './pages/admin/ApprovalsBPHistoricalPage';

// Gift Cards
import GiftCardsPage             from './pages/giftcard/GiftCardsPage';
import GiftCardPage              from './pages/giftcard/GiftCardPage';

// Bikerack
import BikerackPage              from './pages/bikerack/BikerackPage';

// Survey
import SurveyPage                from './pages/survey/SurveyPage';
import SymptomaticPage           from './pages/survey/SymptomaticPage';
import SatisfactionSurvey        from './pages/survey/SatisfactionSurvey';

// Loans
import LoansForm                 from './pages/Loans/LoansForm';

// Profile
import EmailPage                 from './pages/profile/EmailsPage';
import PasswordPage              from './pages/profile/PasswordPage';
import BossPage                  from './pages/profile/BossPage';

// Suport
import Support                   from './pages/support/Support';
import Test                      from './pages/test/Test';

// Fantasilandia
// import FantasilandiaPage         from './pages/fantasilandia/FantasilandiaPage';

// Helpers
import Idle                      from './helpers/Idle';
import PrivateRoutes             from './helpers/PrivateRoutes';
import TokenService              from "././helpers/TokenService";

// Componentes
import ThemeTables               from './pages/dev/Tables';
import ThemeToast                from './pages/dev/Toast';
import { Loader }                from './components/Loader';

//Librerias
import "react-datepicker/dist/react-datepicker.css";
import { analytics, logEvent }   from './firebase';

// Context
import { GlobalDataContext }     from './components/context/GlobalData';
// import CancerPreventPage         from './pages/others/CancerPreventPage';

/**
 * @todo [OK] - Obtener datos del contacto al iniciar sesión y almacenarlas el GlobalDataContext
 * @todo [TODO] - Evitar multiples peticiones
 * @todo [TODO] - Obtener el valor al eliminarse, por ejemplo al actualizar la página
 *              - Petición Axios para obtener los datos del contacto con GlobalDataContext, sólo en caso de no existir
 * @todo [Ok] - Evaluar si es factible sincronizar los datos del colaborador en la actualización del token
 */
function App() {
  const [globalData, setGlobalData, getContact] = useContext(GlobalDataContext);
  // const [showLogin, setShowLogin] = useState(false);

  useEffect(() => {
    if(TokenService.getTokenStatus()) {
      getContact();
    }
  }, []);

  useEffect(() => {
    logEvent(analytics, window.location.pathname);
  }, [window.location.pathname]);

  // const Generic = (props) => {
  //     return props.children
  // }

  return (
    <Box id="top">
      <Fragment>
        <Idle />
        <Suspense fallback={<Loader />}>
          <Routes>
              {/* Rutas públicas */}
              <Route exact path="login" element={<LoginPage />} />
              <Route path="code" element={<LoginCodePage />} />
              <Route path="forgot" element={<ForgotPage />} />
              <Route path="/2FA" element={<TwoFAPage />} />

              {/* Rutas privadas */}
              <Route element={<PrivateRoutes />}>
                <Route path="/logout" element={<LogoutPage />} />
                <Route path="/" element={<Standard />}>
                  <Route path="/home" element={<HomePage />} />
                  <Route path="/" element={<HomePage />} />

                  {/* Catalog */}
                  <Route path="/catalog/freetime" element={<FreetimePage/>} />
                  <Route path="/catalog/product/:id" element={<CatalogDetailPage />} />
                  <Route path="/catalog/tickets" element={<TicketsPage />} />
                  <Route path="/catalog/tickets/:id" element={<CatalogDetailPage />} />
                  <Route path="/catalog/donation" element={<DonationPage />} />
                  <Route path="/catalog/product/:id" element={<CatalogDetailPage />} />
                  <Route path="/catalog/birthday" element={<CatalogBirthdayPage />} />
                  <Route path="/catalog/birthday/:id" element={<BirthdayDetailPage />} />
                  <Route path="/catalog/recognition" element={<RecognitionPage />} />
                  <Route path="/catalog/product/:id" element={<CatalogDetailPage />} />
                  <Route path="/catalog/history" element={<HistoryExchangePage />} />

                  {/* Benefits */}
                  <Route path="/benefits/home" element={<BenefitsPage />} />
                  <Route path="/benefits/detail/:id" element={<MyBenefitsPage />} />
                  <Route path="/betterfly/detail/:id" element={<BetterflyDetailPage />} />
                  <Route path="/benefits/insurance" element={<BenefitInsurancePage />} />
                  <Route path="/benefits/insurance/:id" element={<BenefitDetailPage />} />
                  <Route path="/benefits/complementary/:id" element={<InsuranceDetail />} />
                  <Route path="/renovation" element={<Renovation />} />
                  <Route path="/benefits/Transverse/:id" element={<TransverseDetailPage />} />
                  <Route path="/benefits/betterfly" element={<BenefitBetterflyPage />} />
                  <Route path="/benefits/transverse" element={<BenefitTransversePage />} />
                  <Route path="/benefits/activities" element={<BenefitActivitiesPage />} />
                  <Route path="/benefits/others" element={<OtherBenefitsPage />} />

                  {/* Agreements */}
                  <Route path="/agreements" element={<AgreementsPage />} />
                  <Route path="/agreements/agreement/:id" element={<AgreementsDetailPage />} />
                  <Route path="/agreements/compensation/:id" element={<CompensationDetailPage />} />
                  <Route path="/agreements/socialsecurity/:id" element={<SocialSecurityDetailPage />} />
                  <Route path="/agreements/compensation" element={<AgreementCompensationPage />} />
                  <Route path="/agreements/socialsecurity" element={<AgreementIsaprePage />} />
                  <Route path="/agreements/discounts" element={<AgreementDiscountPage />} />
                  <Route path="/agreements/discounts/:id" element={<DiscountBoxes />} />

                  {/* Procedures */}
                  <Route path="/procedures" element={<ProceduresPage />}/>
                  <Route path="/procedures/myadvances" element={<ProcedureAdvancePage />} />
                  <Route path="/procedures/advancesadmin" element={<ProceduresAdminPage />} />
                  <Route path="/procedures/annexes" element={<ProcedureAnnexesPage />} />
                  <Route path="/procedures/myannexes" element={<MyAnnexesPage />} />
                  <Route path="/procedures/history" element={<ProcedureHistoryPage />} />

                  {/* Family */}
                  <Route path="/family" element={<FamilyPage />} />
                  <Route path="/family/scholarship" element={<ScholarshipPage />} />
                  <Route path="/family/scholarship_insurance" element={<ScholarshipInsurancePage />} />
                  <Route path="/family/schoolbonus" element={<SchoolBonusPage />} />
                  <Route path="/family/babyroom" element={<BabyRoomPage />} />
                  <Route path="/family/birthbonus" element={<BirthBonusPage />} />
                  <Route path="/family/compensatorybonus" element={<CompensatoryBonusPage />} />
                  <Route path="/maternity" element={<MaternityPage />}/>

                  {/* Survey */}
                  <Route path="/survey" element={<SurveyPage />} />
                  <Route path="/symptomatic" element={<SymptomaticPage />} />

                  {/* Administration */}
                  <Route path="/admin/dependent" element={<DependentPage />} />
                  <Route path="/admin/substitute" element={<SubstitutePage />} />
                  <Route path="/admin/approvals" element={<ApprovalsPage />} />
                  <Route path="/admin/approvalsBP" element={<ApprovalsBP />} />
                  <Route path="/admin/symptomatichistorical" element={<SymptomaticHistoricalPage />} />
                  <Route path="/admin/approvalshistorical" element={<ApprovalsHistoricalPage />} />
                  <Route path="/admin/ApprovalsBPHistorical" element={<ApprovalsBPHistoricalPage />} />

                  {/* Profile */}
                  <Route path="/account/mycollectivecontract" element={<MyCollectiveContractPage />} />
                  <Route path="/account/emails" element={<EmailPage />} />
                  <Route path="/account/password" element={<PasswordPage />} />
                  <Route path="/account/boss" element={<BossPage />} />

                  {/* Gift Cards */}
                  <Route path="/giftcard/:id" element={<GiftCardPage />} />
                  <Route path="/giftcards" element={<GiftCardsPage />} />

                  {/* Bikerack */}
                  <Route path="/bikerack" element={<BikerackPage />} />

                  {/* Loans */}
                  <Route path="/loan" element={<LoansForm />} />

                  {/* Home */}
                  <Route path="/mypoints/:id" element={<MyPointsPage />} />
                  <Route path="/banner" element={<BannerPage />} />
                  <Route path="/scheduler" element={<SchedulerPage />} />
                  <Route path="/gym" element={<GymPage />} />
                  <Route path="/minigennials" element={<MinniGennialsPage />} />
                  <Route path="/gennials" element={<Gennials />} />
                  <Route path="/sport" element={<Sport />} />
                  <Route path="/recreational" element={<Recreational />} />
                  <Route path="/recreational" element={<Recreational />} />
                  <Route path="/annex" element={<AnnexPage />} />
                  <Route path="/uniforms" element={<UniformsPage />} />
                  <Route path="/insurance" element={<InsurancePage />} />
                  <Route path="/roulette" element={<RoulettePage />} />
                  <Route path="/support" element={<Support />} />
                  <Route path="/satisfaction" element={<SatisfactionSurvey />} />

                  {/* Home */}
                  {/* <Route path="/fantasilandia" element={<FantasilandiaPage />} /> */}
                  {/* <Route path="/prevent" element={<CancerPreventPage />} /> */}

                  <Route path="/test" element={<Test />} />

                  {/* Theme */}
                  <Route path="/theme/tables" element={<ThemeTables />} />
                  <Route path="/theme/toast"  element={<ThemeToast />} />

                  {/* <Route path="/" element={<LoginDialog />} /> */}
                </Route>
              </Route>
              <Route path="*" element={<Navigate to='/home' />} />
          </Routes>
        </Suspense>
      </Fragment>
    </Box>
  );
}

export default App;