import { Icon } from "@chakra-ui/react";
export const Hierarchy = props => {
  let {color = '#565656'} = props;
  return <Icon  viewBox="0 0 40 40" {...props}><rect id="hierarchy" x={0} y={0} width={40} height={40} style={{
    fill: "none"
  }} /><path id="path6" d="M13.663,25.428c0.456,-0.012 0.897,0.164 1.219,0.487c0.323,0.322 0.499,0.763 0.487,1.219l0.008,6.318c0.011,0.456 -0.165,0.897 -0.487,1.219c-0.322,0.323 -0.763,0.499 -1.219,0.487l-6.334,0.008c-0.456,0.011 -0.897,-0.164 -1.219,-0.487c-0.323,-0.322 -0.499,-0.763 -0.487,-1.219l0,-6.326c-0.012,-0.456 0.164,-0.897 0.487,-1.219c0.322,-0.323 0.763,-0.499 1.219,-0.487l6.326,0Z" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path61" d="M32.663,25.428c0.456,-0.012 0.897,0.164 1.219,0.487c0.323,0.322 0.499,0.763 0.487,1.219l0.008,6.318c0.011,0.456 -0.165,0.897 -0.487,1.219c-0.322,0.323 -0.763,0.499 -1.219,0.487l-6.334,0.008c-0.456,0.011 -0.897,-0.164 -1.219,-0.487c-0.323,-0.322 -0.499,-0.763 -0.487,-1.219l0,-6.326c-0.012,-0.456 0.164,-0.897 0.487,-1.219c0.322,-0.323 0.763,-0.499 1.219,-0.487l6.326,0Z" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path62" d="M22.917,5.428c0.456,-0.012 0.896,0.164 1.219,0.487c0.322,0.322 0.498,0.763 0.486,1.219l0.008,6.318c0.012,0.456 -0.164,0.897 -0.487,1.219c-0.322,0.323 -0.763,0.499 -1.218,0.487l-6.335,0.008c-0.456,0.011 -0.896,-0.164 -1.219,-0.487c-0.322,-0.322 -0.498,-0.763 -0.486,-1.219l-0,-6.326c-0.012,-0.456 0.164,-0.897 0.486,-1.219c0.323,-0.323 0.763,-0.499 1.219,-0.487l6.327,0Z" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path d="M20,15.166l0,4.834l4.078,0c1.439,-0 2.819,0.572 3.837,1.589c1.017,1.018 1.589,2.398 1.589,3.837c-0,0.001 -0,0.002 -0,0.002" style={{
    fill: "none",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path d="M20,20l-4.07,0c-1.439,-0 -2.819,0.572 -3.837,1.589c-1.017,1.018 -1.589,2.398 -1.589,3.837c-0,0.001 -0,0.002 -0,0.002" style={{
    fill: "none",
    stroke: color,
    strokeWidth: "3.36px"
  }} /></Icon>
};