import { Icon } from "@chakra-ui/react";
export const Sun = props => {
  let {color = '#565656'} = props;
  return <Icon  viewBox="0 0 41 40" {...props}><rect id="sun" x={0.079} y={0} width={40} height={40} style={{
    fill: "none"
  }} /><path id="path199" d="M22.599,6.24c-0,1.392 -1.129,2.52 -2.52,2.52c-1.392,-0 -2.52,-1.128 -2.52,-2.52c-0,-1.392 1.128,-2.52 2.52,-2.52c1.391,-0 2.52,1.128 2.52,2.52Z" style={{
    fill: color,
    fillRule: "nonzero"
  }} /><path id="path200" d="M22.599,34.8c-0,1.392 -1.129,2.52 -2.52,2.52c-1.392,-0 -2.52,-1.128 -2.52,-2.52c-0,-1.392 1.128,-2.52 2.52,-2.52c1.391,-0 2.52,1.128 2.52,2.52Z" style={{
    fill: color,
    fillRule: "nonzero"
  }} /><path id="path201" d="M36.879,20.52c-0,1.392 -1.129,2.52 -2.52,2.52c-1.392,-0 -2.52,-1.128 -2.52,-2.52c-0,-1.392 1.128,-2.52 2.52,-2.52c1.391,-0 2.52,1.128 2.52,2.52Z" style={{
    fill: color,
    fillRule: "nonzero"
  }} /><path id="path202" d="M8.319,20.52c-0,1.392 -1.129,2.52 -2.52,2.52c-1.392,-0 -2.52,-1.128 -2.52,-2.52c-0,-1.392 1.128,-2.52 2.52,-2.52c1.391,-0 2.52,1.128 2.52,2.52Z" style={{
    fill: color,
    fillRule: "nonzero"
  }} /><path id="path203" d="M32.699,30.62c-0,1.392 -1.129,2.52 -2.52,2.52c-1.392,-0 -2.52,-1.128 -2.52,-2.52c-0,-1.392 1.128,-2.52 2.52,-2.52c1.391,-0 2.52,1.128 2.52,2.52Z" style={{
    fill: color,
    fillRule: "nonzero"
  }} /><path id="path205" d="M12.499,10.42c-0,1.392 -1.129,2.52 -2.52,2.52c-1.392,-0 -2.52,-1.128 -2.52,-2.52c-0,-1.392 1.128,-2.52 2.52,-2.52c1.391,-0 2.52,1.128 2.52,2.52Z" style={{
    fill: color,
    fillRule: "nonzero"
  }} /><path id="path206" d="M12.499,30.62c-0,1.392 -1.129,2.52 -2.52,2.52c-1.392,-0 -2.52,-1.128 -2.52,-2.52c-0,-1.392 1.128,-2.52 2.52,-2.52c1.391,-0 2.52,1.128 2.52,2.52Z" style={{
    fill: color,
    fillRule: "nonzero"
  }} /><path id="path207" d="M32.699,10.42c-0,1.392 -1.129,2.52 -2.52,2.52c-1.392,-0 -2.52,-1.128 -2.52,-2.52c-0,-1.392 1.128,-2.52 2.52,-2.52c1.391,-0 2.52,1.128 2.52,2.52Z" style={{
    fill: color,
    fillRule: "nonzero"
  }} /><path id="path209" d="M28.479,20.52c-0,4.639 -3.761,8.4 -8.4,8.4c-4.639,-0 -8.4,-3.761 -8.4,-8.4c-0,-4.639 3.761,-8.4 8.4,-8.4c4.639,-0 8.4,3.761 8.4,8.4Z" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /></Icon>
};