/** Imports */
import {
  Button,
  HStack,
  PinInput,
  PinInputField,
  Stack,
  Text,
  useToast
} from '@chakra-ui/react';
import { useErrors } from '../../helpers/integer';
import { useContext, useState } from 'react';
import { useEffect } from 'react';
import { Page } from '../../components/templates/Page';
import { useForm }  from 'react-hook-form';
import { GlobalDataContext }  from '../../components/context/GlobalData';
import { useNavigate, Link as LinkRRD } from "react-router-dom";
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
  
// Helpers
import api from "../../helpers/api";
import { Message } from '../../components/templates/Message';
import { Legend } from '../../components/templates/Legend';
import { InputField } from '../../components/input/InputField';
import { AuthPage } from '../../components/templates/AuthPage';
import TokenService from "../../helpers/TokenService";
  
/**
* Página de verificación 2FA
* 
* @returns JSX
*/
const TwoFAForm = () => {
  /** Hooks */
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    isDirty,
    control,
    setValue,
    setError,
    watch
  } = useForm({});
  
  const showErrors                              = useErrors();
  const [globalData, setGlobalData, getContact] = useContext(GlobalDataContext);
  let navigate                                  = useNavigate();
  const toast                                   = useToast();
  const [TwofaCode, setTwofaCode]               = useState("");
  const [data, setData]                         = useState(TokenService.getData('data'));
  const [TwofaComplete, setTwofaComplete]       = useState(false);
  const { executeRecaptcha }                    = useGoogleReCaptcha();
    
  const handleChangeTwofaCode = (value) => {
    setTwofaCode(value)
    setTwofaComplete(false)
  }
  const handleChangeTwofaComplete = () => {
    setTwofaComplete(true)
  }

  /**
  * Evento submit
  */
  const onSubmit = async () => {
    try {
      const token    = await executeRecaptcha('recovery');
      const response = await api.post('AuthenticationServices/recoveryValidate', {'login': data, token: token, inputCode: TwofaCode});
      if (response.data.result != false) {
        toast({
          title: 'Se ha enviado una nueva clave para iniciar sesión.',
          status: 'success',
          duration: 6000,
          isClosable: true,
        })
        TokenService.removeData('data');
        navigate('/login');
      } else {
        toast({
          title: 'Error al validar el código ingresado',
          status: 'warning',
          duration: 6000,
          isClosable: true,
        })
      }
    } catch (err) {
      console.log(err);
      showErrors(err);
    }
  }
  
  return (
    <AuthPage>
      <Page title="">
        <Stack py={5}>
        <Message>
          <Text align={'left'} fontSize={14}>Se ha enviado un código de validación a su correo electrónico {globalData?.contact?.Emails[0]}</Text>
        </Message>
        </Stack>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Text align={'left'}>Digíta el Código</Text>
          <HStack p={5}>
            <PinInput value={TwofaCode} onChange={handleChangeTwofaCode} onComplete={handleChangeTwofaComplete}>
              <PinInputField />
              <PinInputField />
              <PinInputField />
              <PinInputField />
              <PinInputField />
              <PinInputField />
            </PinInput>
          </HStack>
          <Text
            fontSize={14}
            align="center">
            Una vez ingresado tu código, se enviará tu nueva clave a tu correo electrónico
          </Text>
          <Stack maxW={'200'} py={'6'}>
            <Button
              type="submit"
              isLoading={isSubmitting}
              loadingText="Verificando..."
              disabled={isSubmitting?
                isSubmitting
                :
                !TwofaComplete
            }>
              Verificar
            </Button>
          </Stack>
        </form>
      </Page>
    </AuthPage>
  );
}

export default TwoFAForm;
