import { Icon } from "@chakra-ui/react";
export const Substitute = props => {
  let {color = '#565656'} = props;
  return <Icon  viewBox="0 0 40 41" {...props}><rect id="substitute" x={0} y={0.8} width={40} height={40} style={{
    fill: "none"
  }} /><path id="path6" d="M13.663,26.228c0.456,-0.012 0.897,0.164 1.219,0.487c0.323,0.322 0.499,0.763 0.487,1.219l0.008,6.318c0.011,0.456 -0.165,0.897 -0.487,1.219c-0.322,0.323 -0.763,0.499 -1.219,0.487l-6.334,0.008c-0.456,0.012 -0.897,-0.164 -1.219,-0.487c-0.323,-0.322 -0.499,-0.763 -0.487,-1.219l0,-6.326c-0.012,-0.456 0.164,-0.897 0.487,-1.219c0.322,-0.323 0.763,-0.499 1.219,-0.487l6.326,0Z" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path61" d="M32.663,26.228c0.456,-0.012 0.897,0.164 1.219,0.487c0.323,0.322 0.499,0.763 0.487,1.219l0.008,6.318c0.011,0.456 -0.165,0.897 -0.487,1.219c-0.322,0.323 -0.763,0.499 -1.219,0.487l-6.334,0.008c-0.456,0.012 -0.897,-0.164 -1.219,-0.487c-0.323,-0.322 -0.499,-0.763 -0.487,-1.219l0,-6.326c-0.012,-0.456 0.164,-0.897 0.487,-1.219c0.322,-0.323 0.763,-0.499 1.219,-0.487l6.326,0Z" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path62" d="M32.663,6.228c0.456,-0.012 0.897,0.164 1.219,0.487c0.323,0.322 0.499,0.763 0.487,1.219l0.008,6.318c0.011,0.456 -0.165,0.897 -0.487,1.219c-0.322,0.323 -0.763,0.499 -1.219,0.487l-6.334,0.008c-0.456,0.012 -0.897,-0.164 -1.219,-0.487c-0.323,-0.322 -0.499,-0.763 -0.487,-1.219l0,-6.326c-0.012,-0.456 0.164,-0.897 0.487,-1.219c0.322,-0.323 0.763,-0.499 1.219,-0.487l6.326,0Z" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path119" d="M13.6,11.097c-0,1.856 -1.504,3.36 -3.36,3.36c-1.856,-0 -3.36,-1.504 -3.36,-3.36c0,-1.856 1.504,-3.36 3.36,-3.36c1.856,-0 3.36,1.504 3.36,3.36Z" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path d="M29.504,15.966l-0,10.262" style={{
    fill: "none",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path d="M29.504,20.8l-13.574,0c-2.997,0 -5.426,2.429 -5.426,5.426c-0,0.001 -0,0.002 -0,0.002" style={{
    fill: "none",
    stroke: color,
    strokeWidth: "3.36px"
  }} /></Icon>
};