import { Icon } from "@chakra-ui/react";
export const Atm = props => {
  let {color = '#565656'} = props;
  return <Icon  viewBox="0 0 41 40" {...props}><rect id="atm" x={0.079} y={0} width={40} height={40} style={{
    fill: "none"
  }} /><path id="path61" d="M20.449,18.1c-0.114,-0.261 -0.285,-0.493 -0.5,-0.68c-0.231,-0.194 -0.491,-0.349 -0.77,-0.46c-0.325,-0.137 -0.659,-0.25 -1,-0.34l-0.85,-0.28c-0.218,-0.072 -0.419,-0.187 -0.59,-0.34c-0.145,-0.145 -0.222,-0.345 -0.21,-0.55c-0.005,-0.182 0.055,-0.359 0.17,-0.5c0.137,-0.137 0.31,-0.234 0.5,-0.28c0.224,-0.063 0.457,-0.09 0.69,-0.08c0.279,0.008 0.555,0.059 0.82,0.15c0.259,0.096 0.51,0.213 0.75,0.35c0.229,0.131 0.446,0.282 0.65,0.45l-0,-2c-0.238,-0.123 -0.486,-0.226 -0.74,-0.31c-0.252,-0.081 -0.509,-0.141 -0.77,-0.18l-0.32,-0l-0,-0.93l-1.44,-0l-0,1c-0.205,0.027 -0.407,0.078 -0.6,0.15c-0.371,0.125 -0.711,0.326 -1,0.59c-0.254,0.241 -0.451,0.535 -0.58,0.86c-0.128,0.318 -0.193,0.657 -0.19,1c-0.017,0.341 0.038,0.681 0.16,1c0.102,0.246 0.252,0.471 0.44,0.66c0.188,0.179 0.4,0.33 0.63,0.45c0.232,0.114 0.473,0.211 0.72,0.29l1,0.36c0.267,0.079 0.52,0.201 0.75,0.36c0.181,0.123 0.287,0.331 0.28,0.55c0.019,0.265 -0.098,0.521 -0.31,0.68c-0.268,0.161 -0.578,0.237 -0.89,0.22c-0.47,-0.006 -0.936,-0.087 -1.38,-0.24c-0.478,-0.153 -0.922,-0.393 -1.31,-0.71l-0,1.94c0.271,0.139 0.556,0.25 0.85,0.33c0.327,0.093 0.661,0.157 1,0.19l0.44,-0l-0,1.08l1.44,0l-0,-1.16c0.32,-0.045 0.631,-0.143 0.92,-0.29c0.425,-0.226 0.773,-0.575 1,-1c0.24,-0.42 0.364,-0.896 0.36,-1.38c0.025,-0.322 -0.016,-0.645 -0.12,-0.95Z" style={{
    fill: color,
    fillRule: "nonzero"
  }} /><path id="path62" d="M6.719,3.2l26.72,0c1.9,0 3.44,1.54 3.44,3.44l-0,26.72c-0,1.9 -1.54,3.44 -3.44,3.44l-26.72,-0c-1.9,-0 -3.44,-1.54 -3.44,-3.44l-0,-26.72c-0,-1.9 1.54,-3.44 3.44,-3.44Z" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><rect id="path63" x={8.319} y={8.24} width={18.48} height={18.48} style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><rect id="path64" x={30.159} y={9.92} width={3.36} height={3.36} style={{
    fill: color,
    fillRule: "nonzero"
  }} /><rect id="path65" x={30.159} y={14.96} width={3.36} height={3.36} style={{
    fill: color,
    fillRule: "nonzero"
  }} /><rect id="path66" x={30.159} y={20} width={3.36} height={3.36} style={{
    fill: color,
    fillRule: "nonzero"
  }} /></Icon>
};