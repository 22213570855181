import { Icon } from "@chakra-ui/react";
export const ArrowUp = props => {
  let {color = '#565656'} = props;
  return <Icon  viewBox="0 0 41 40" {...props}><rect id="arrow_up" x={0.079} y={0} width={40} height={40} style={{
    fill: "none"
  }} /><path id="path136" d="M6.877,27.09l14.18,-14.18l14.18,14.18" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /></Icon>
};