/** Imports */
import {
  Box,
  Grid,
  Heading,
  Text
} from '@chakra-ui/react';
import { useErrors } from '../../helpers/integer';
import { Maternity } from '../../components/Maternity';
import { Page } from '../../components/templates/Page';
import { Title } from '../../components/templates/Title';
// import { analytics } from 'firebase-functions/v1';
// import { logEvent } from 'firebase/analytics';

const BirthBonusPage = () => {

  /** Hooks */
  const showErrors = useErrors();

  const apiService= '';

  return (
    <Page title="Bono de nacimiento" description={'Queremos acompañarte en este momento entregándote este Bono por el nacimiento o la adopción de tu hijo o hija. Este beneficio será pagado en la liquidación de sueldo del mes en curso en caso de ser ingresado hasta el 14 de cada mes y se pagará en la liquidación del mes siguiente si fue ingresado posterior a esa fecha.'}>
      <Maternity title="Bono de nacimiento" product_id={25} category_id={91} />
    </Page>

  );
}

export default BirthBonusPage;