/** Imports */
import {
  Box,
  Button,
  color,
  Grid,
  Heading,
  Icon,
  List,
  ListItem,
  OrderedList,
  SimpleGrid,
  Stack,
  Text,
  useToast,
  VStack
} from '@chakra-ui/react';
import { useErrors, countCharacters, validatePassword, repetitionsCharacters, validationNumbersAndCharacters, validateLowerCase, validateUpperCase} from '../../helpers/integer';
import { useContext, useState } from 'react';
import { useEffect } from 'react';
import { Page } from '../../components/templates/Page';
import { Loader } from '../../components/Loader';
import TokenService from "../../helpers/TokenService";
import { Title } from '../../components/templates/Title';
import { useForm, useWatch }              from 'react-hook-form';
import { GlobalDataContext }    from '../../components/context/GlobalData';
import { useNavigate, Link as LinkRRD } from "react-router-dom";
// import { analytics } from 'firebase-functions/v1';
// import { logEvent } from 'firebase/analytics';

// Helpers
import api from "../../helpers/api";
import { Message } from '../../components/templates/Message';
import { Legend } from '../../components/templates/Legend';
import { InputField } from '../../components/input/InputField';
import { Icons } from '../../components/templates/Icons';
import { trim } from 'lodash';


const PasswordPage = () => {

  /** Hooks */
  const showErrors = useErrors();
  let navigate = useNavigate();
  const [globalData, setGlobalData, getContact] = useContext(GlobalDataContext);
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    isDirty,
    control,
    setValue,
    setError,
    watch
  } = useForm({});

  const [validPassword, setValidPassword] = useState(true);
  const new_password = watch('new_password',0);
  const validate_new_password = watch('validate_new_password',0);
  let valid_password = true;

  const toast = useToast();
  const updateBossUrl = 'ContactServices/updatePassword';

  useEffect(() => {
  }, [])

  /**
 * Envio del formulario
 */
   const onSubmit = async (data) => {
    data.current_password      = trim(data.current_password)
    data.new_password          = trim(data.new_password)
    data.validate_new_password = trim(data.validate_new_password)

    valid_password = true;
    try {
      const response = await api.post(updateBossUrl, data)
      if(response.data.result === true) {
        TokenService.setProfiling(response.data.response.profiling);

        await getContact();

        toast({
          title     : response.data.response.message,
          status    : 'success',
          duration  : 3000,
          isClosable: true,
        })

        if(globalData?.contact?.CustomFields?.c?.initial_password_changed === true) {
          window.location.reload();
        } else {
          navigate("/home");
        }

      } else {
        setValidPassword(false);
        toast({
          title: response.data.response.message,
          status: 'warning',
          duration: 5000,
          isClosable: true,
        })
      }
    } catch (err) {
      showErrors(err);
    }
  }

  return (
    <Page title="Cambiar mi clave">
      <Legend>Mis datos actuales</Legend>
      <form onSubmit={handleSubmit(onSubmit)}>
        <SimpleGrid columns={{ base: '1', lg: '2', xl: '3' }} spacing={4} p={5}>
          <InputField label="Clave actual" name="current_password" type="password" placeholder="Ingresa tu clave actual" register={register} errors={errors} required={true} />
        </SimpleGrid>
        <Legend>Nueva clave</Legend>
        <Message>
          <Stack w={'full'} align={'left'}>
          <Text align={'left'} fontSize={14} fontWeight='semibold'>Para una clave segura considera:</Text>
            <List>
              <ListItem align={'left'} fontSize={14}><Icons icon="ok" color={countCharacters(new_password) > 0 ? validateLowerCase(new_password) === true && validateUpperCase(new_password) === true ? 'green' : 'red': 'red'} h="5" w="5" /> mayúsculas y minúsculas</ListItem>
              <ListItem align={'left'} fontSize={14}><Icons icon="ok" color={countCharacters(new_password) > 0 ? validationNumbersAndCharacters(new_password) === true ? 'green': 'red':'red'} h="5" w="5" /> Números y caracteres especiales</ListItem>
              <ListItem align={'left'} fontSize={14}><Icons icon="ok" color={countCharacters(new_password) > 0 ? countCharacters(new_password) >= 10 ? 'green': 'red':'red'} h="5" w="5" /> 10 caracteres como mínimo o más</ListItem>
              <ListItem align={'left'} fontSize={14}><Icons icon="ok" color={countCharacters(new_password) > 0 ? repetitionsCharacters(new_password) <= 3 ? 'green': 'red':'red'} h="5" w="5" /> No repetir caracteres más de 4 veces</ListItem>
              <ListItem align={'left'} fontSize={14}><Icons icon="ok" color={countCharacters(new_password) > 0 ? new_password === validate_new_password ? 'green': 'red':'red'} h="5" w="5" /> Verificar clave </ListItem>
            </List>
          </Stack>
        </Message>
        <SimpleGrid columns={{ base: '1', lg: '2', xl: '3' }} spacing={4} p={5}>
          <InputField label="Nueva clave" name="new_password" type="password" placeholder="Ingresa tu nueva clave" register={register} errors={errors} required={true} />
          <InputField label="Verificar clave" name="validate_new_password" type="password" placeholder="Verifica tu nueva clave" register={register} errors={errors} required={true} />
        </SimpleGrid>
        <Box fontSize={14}>
          {countCharacters(new_password) > 0
            ?
              <Stack>
                <Text color='red.400'>{validatePassword(new_password,validate_new_password)}</Text>
                {validatePassword(new_password,validate_new_password) === true
                  ?
                    <Stack>
                      {valid_password = false}
                    </Stack>
                  :
                    null
                }
              </Stack>
            :
              null
          }
        </Box>
        <Stack maxW={'200'} py={'6'}>
          <Button
            type="submit"
            isLoading={isSubmitting}
            loadingText="Actualizando..."
            disabled={isSubmitting?
              isSubmitting
              :
              valid_password
            }>
            Actualizar mi Clave
          </Button>
        </Stack>
      </form>
    </Page>
  );
}

export default PasswordPage;