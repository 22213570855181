/** Imports */
import {
  AspectRatio,
  Box, Button, Heading, Image, Skeleton, Spacer, Stack, Text
} from '@chakra-ui/react';
import { useErrors, htmlfy, addTags } from '../../helpers/integer';
import { Loader } from '../../components/Loader';
import { motion } from "framer-motion";
import { useNavigate, useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import api from '../../helpers/api';
import { Page } from '../../components/templates/Page';
// import { analytics } from 'firebase-functions/v1';
// import { logEvent } from 'firebase/analytics';

const BenefitDetailPage = () => {

  /** Hooks */
  const showErrors                        = useErrors();
  const [data, setData]                   = useState(false);
  const [reportMessage, setReportMessage] = useState();

  let navigate = useNavigate();
  const { id } = useParams('id');

  const url_service = 'ReportServices/getReportHomeBoxesByID';

  const request = {
    'report_id': 101181,
    'filter_name': 'answer_id',
    'filter_value': id
  };

  /**
   * Reporte de Answers Seguro Complementario
   */
  const getReportAnswer = async () => {
    try {
      const response = await api.post(url_service, request);

      if (response.data.result == false) {
        setReportMessage(response);
      } else {
        setData(response.data.response.result_father_box[0]);
      }
    } catch (err) {
      showErrors(err);
    }
  }

  useEffect(() => {
    getReportAnswer();
  }, [])

  if(data === false) {
    return <Loader />;
  }

  return (
    (!!data)?
      <Page title={data?.summary}  path_name="/benefits/insurance">
        <Stack>
          <Stack>
          </Stack>
          <Stack>
            <Text align={'left'} dangerouslySetInnerHTML={{ __html: htmlfy(addTags(data?.solution)) }} />
          </Stack>
        </Stack>
      </Page>
    :
      <Text>Sin Datos</Text>
  );
}

export default BenefitDetailPage;