/**
 * ==============================
 * Formato de tarjetas
 * ==============================
 * 686	Productos
 * 687	Productos sin Puntos
 * 688	Sólo Foto
 * 689	Sólo Botón
 * 690	Sólo Título
 * 691	Foto y Botón
 * 702	Foto y Descripción
 * 703	Foto y Título
 * 692	Foto y Descripción
 * 693	Foto, Descripción y Botón
 * 694	Foto, Descripción, Botón y Título
 * 695	Descripción y Botón
 * 696	Descripción y Título
 * 697	Desplegable y Descripción
 */
import {
  AspectRatio,
  Button,
  Heading,
  HStack,
  Image,
  Skeleton,
  Stack,
  Text,
} from '@chakra-ui/react';
import { useNavigate, Link as LinkRRD } from "react-router-dom";
import * as React from 'react'
import { ShadowCard } from '../templates/ShadowCard';

export const Card = ({...params}) => {
  const navigate = useNavigate()
  
  
  /** Variables */
  const { data }                = params;
  let   { click }               = params;
  let   { answer_format = 703 } = data;

  answer_format = parseInt(answer_format);

  if(isNaN(answer_format)) {
    answer_format = 703
  }
  
  const show_image       = ([688, 703, 702, 691, 692, 693, 694].indexOf(answer_format) !== -1);
  const show_button      = ([689, 691, 693, 694, 695].indexOf(answer_format) !== -1);
  const show_title       = ([690, 703, 694, 696].indexOf(answer_format) !== -1);
  const show_description = ([692, 702, 693, 694, 695, 696, 697].indexOf(answer_format) !== -1);
  const show_accordion   = ([697].indexOf(answer_format) !== -1);

  const watchCardDetail = () => {
    navigate(`${click + data?.id}`);
  }

  const watchCard = () => {
    navigate(`${click + data?.id}`);
  }

  return (
    <ShadowCard>        
      <Stack
        spacing={'1'}
        width={'full'}
        {...click && {onClick:watchCardDetail}}
        cursor="pointer"
      >
        {show_image && <AspectRatio ratio={9 / 6}>
          <Image
            src={data?.answer_image}
            draggable="false"
            fallback={<Skeleton />}
            borderTopRadius={'20'}
          />
        </AspectRatio>}

        <Stack h="full" p="2">
          <Stack>
            {show_title && <Text fontWeight="semibold" align={'center'}>
              {data?.summary}
            </Text>}

            {show_button && <Button>Ver Más</Button>}
          </Stack>
        </Stack>
      </Stack>
    </ShadowCard>
  )
}