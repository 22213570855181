/**
 * @version 1.0.0
 * @description Template camino de migas
 * 
 * @param {Object} props - props
 * 
 * @returns {Object} - BreadcrumbNav
 * 
 * @example <BreadcrumbNav>...</BreadcrumbNav>
 */
import {
  Box, Breadcrumb, BreadcrumbItem, BreadcrumbLink, Heading, HStack, Text
} from '@chakra-ui/react';
import { useState, useEffect, Fragment } from 'react';
import { Link, matchRoutes, useLocation } from 'react-router-dom';
import { matrixToMenu, matrixToBreadcrumb } from '../../helpers/integer';
import { Icons } from './Icons';

export const BreadcrumbNav = ({...params}) => {

  /** Variables */
  const { type } = params;
  const { path_name } = params;
  const location = useLocation();
  const arr_items = matrixToBreadcrumb((path_name)?path_name:location.pathname)
  // const [{ route }] = matchRoutes(routes, location)


  // debugger;

  useEffect(() => {
  }, []);

  return (
    <Fragment>
      {(arr_items && arr_items.length > 0) && 
        <Box textAlign={{base: "center", md: "left"}} p={2} mb="1" mx={{base: 8, sm: 0}}>
          <Breadcrumb separator={<Icons icon="arrow_left" w="3" h="4" />} w="full" className="Breadcrumb">
          {
          arr_items.map((item, index) => {
            return (
              <BreadcrumbItem key={index} className="BreadcrumbItem">
                {(item.path)?<BreadcrumbLink as={Link} to={item.path}>
                  <HStack>
                    {item.icon && <Icons icon={item.icon} w="5" h="5" />}
                    {
                      item.label && <Text fontSize={'sm'} {...!item.is_last && {display:{...{ base: "none", md: "block" }}}}>
                        {item.label}
                      </Text>
                    }
                  </HStack>
                </BreadcrumbLink>:
                  <HStack>
                    {item.icon && <Icons icon={item.icon} w="5" h="5" />}
                    {
                      item.label && <Text fontSize={'sm'} {...!item.is_last && {display:{...{ base: "none", md: "block" }}}}>
                        {item.label}
                      </Text>
                    }
                  </HStack>}
              </BreadcrumbItem>
            )
          })
        }
        </Breadcrumb>
      </Box>
      }
    </Fragment>
  );
}

{/* <BreadcrumbItem>
          <BreadcrumbLink as={Link} to={location.pathname}>
            {location.pathname}
          </BreadcrumbLink>
        </BreadcrumbItem> */}