'use strict';

/** Imports */
import {
  Grid,
} from '@chakra-ui/react';
import { fix_datasetBox, useErrors } from '../../helpers/integer';
import { Loader } from '../../components/Loader';
import { Page } from '../../components/templates/Page';
import { useContext, useState } from 'react';
import { GlobalDataContext } from '../../components/context/GlobalData';
import { useEffect } from 'react';
import api from "../../helpers/api";
import { fix_datasetBoxes } from '../../helpers/integer';
import { Boxes } from '../../components/templates/Boxes';
import PasswordPage from '../profile/PasswordPage';
import TokenService from '../../helpers/TokenService';
import SatisfactionSurvey from '../survey/SatisfactionSurvey';
import { useNavigate } from 'react-router-dom';
// import { analytics } from 'firebase-functions/v1';
// import { logEvent } from 'firebase/analytics';

const HomePage = () => {

  /** Hooks */
  const [globalData, setGlobalData] = useContext(GlobalDataContext);
  const [data, setData]             = useState(false);
  const [giftcard, setGiftcard]     = useState([]);
  const [matrix, setMatrix]         = useState(TokenService.getData('profiling'));
  const showErrors                  = useErrors();
  const navigate                    = useNavigate();

  /** Variables */
  const url_service = 'ReportServices/getReportHomeBoxes';

  /**
  * Reporte Boxes Home
  */
  const getReportBoxes = async () => {
    try {
      const response = await api.post(url_service, {'report_id': 101141});

      if(!response?.data?.result) {
        showErrors(response);
      }
      setData(response?.data?.response?.result_father_box);
    } catch (err) {
      console.log(err);
      showErrors(err);
    }
  }

  useEffect(() => {
    getReportBoxes();
    if (matrix.length <= 1){
      matrix.map((item, item_key) => {
        if(item?.element === "satisfaction") {
          navigate("/satisfaction");
        }
      })
    }
    TokenService.removeData("password");
  }, [])

  if(globalData?.contact?.CustomFields?.c?.initial_password_changed === true) {
    return <PasswordPage />;
  }

  if(!globalData?.contact || data === false) {
    return <Loader />;
  }

  return (
    <Page
      color="transparent"
      title={`¡Hola ${globalData?.contact?.Name?.First}!`}
      description="Bienvenido a nuestra plataforma de Beneficios, donde encontrarás toda la información necesaria para que puedas vivir nuestro propósito de Simplificar y Disfrutar más la vida."
      p={null}
    >
      <Grid templateColumns={ {base: '1', sm:'repeat(1, 1fr)',  md:'repeat(2, 1fr)', xl:'repeat(4, 1fr)'} } columnGap={6} gap={6}>
        {
          fix_datasetBoxes(data) && fix_datasetBoxes(data).map((box_item, box_i) => {
            return <Boxes key={box_i} type={box_item?.element} content={box_item?.content} />
          })
        }
      </Grid>
    </Page>
  );
}

export default HomePage;
