import { Icon } from "@chakra-ui/react";
export const Store = props => {
  let {color = '#565656'} = props;
  return <Icon  viewBox="0 0 41 40" {...props}><rect id="store" x={0.079} y={0} width={40} height={40} style={{
    fill: "none"
  }} /><path id="path92" d="M6.639,19.175l26.88,0l-0,12.09c-0,1.657 -1.343,3 -3,3l-20.82,0c-1.657,0 -3,-1.343 -3,-3l-0,-12.09l-0.06,0Z" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path93" d="M6.369,19.175l27.42,0c0.968,0.057 1.901,-0.376 2.483,-1.153c0.581,-0.776 0.734,-1.793 0.407,-2.707l-3.13,-7.73c-0.503,-1.142 -1.642,-1.871 -2.89,-1.85l-21.15,0c-1.251,-0.022 -2.394,0.707 -2.9,1.85l-3.13,7.73c-0.328,0.914 -0.175,1.931 0.407,2.707c0.581,0.777 1.514,1.21 2.483,1.153Z" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path94" d="M13.359,14.155l-0,5.04" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path95" d="M20.079,14.155l-0,5.04" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path96" d="M26.799,14.155l-0,5.04" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /></Icon>
};