import { Icon } from "@chakra-ui/react";
export const CmrPuntos = props => {
  let {color = '#565656'} = props;
  return <Icon  viewBox="0 0 41 40" {...props}><rect id="cmr_puntos" x={0.079} y={0} width={40} height={40} style={{
    fill: "none"
  }} /><path id="path28" d="M25.079,24.39c0.104,0.003 0.205,-0.037 0.279,-0.111c0.073,-0.074 0.114,-0.174 0.111,-0.279l-0,-1.11l-1.67,0l-0,-1.8l-1.43,0c-0.126,-0.006 -0.248,0.042 -0.336,0.132c-0.088,0.09 -0.133,0.213 -0.124,0.338l-0,1.33l-1.21,0l-0,1.5l1.21,0l-0,2.88c-0,1.33 1,1.84 2.23,1.84c0.451,0.012 0.9,-0.052 1.33,-0.19l-0,-1.57c-0.234,0.134 -0.502,0.196 -0.77,0.18c-0.75,0 -0.87,-0.4 -0.89,-1l-0,-2.16l1.27,0.02Z" style={{
    fill: color,
    fillRule: "nonzero"
  }} /><path id="path29" d="M6.449,27.48c-0.623,0.045 -1.201,-0.326 -1.42,-0.91c-0.083,-0.206 -0.124,-0.427 -0.12,-0.65c-0.047,-0.578 0.235,-1.133 0.729,-1.436c0.494,-0.304 1.117,-0.304 1.611,-0c0.494,0.303 0.776,0.858 0.73,1.436c0.002,0.222 -0.036,0.442 -0.11,0.65c-0.223,0.582 -0.799,0.951 -1.42,0.91m0.39,-4.78c-0.758,0.016 -1.466,0.386 -1.91,1l-0,-0.81l-1.9,0l-0,9.21l1.05,0c0.454,-0.038 0.812,-0.404 0.84,-0.86l-0,-2.91c0.545,0.582 1.325,0.884 2.12,0.82c1.44,-0.043 2.625,-1.146 2.77,-2.58c0.043,-0.231 0.067,-0.465 0.07,-0.7c0.047,-0.835 -0.256,-1.652 -0.837,-2.254c-0.581,-0.602 -1.387,-0.934 -2.223,-0.916" style={{
    fill: color,
    fillRule: "nonzero"
  }} /><path id="path30" d="M18.149,22.74c-0.634,-0.017 -1.239,0.26 -1.64,0.75l-0.1,0.15c0.005,-0.073 0.005,-0.146 -0,-0.22l-0,-0.15l-1.6,0l-0,3c0.001,0.215 -0.019,0.429 -0.06,0.64c-0.048,0.549 -0.531,0.956 -1.08,0.91c-0.66,0 -0.88,-0.42 -1,-0.91c-0.015,-0.229 -0.015,-0.46 -0,-0.69l-0,-3l-1.59,0c-0.275,-0.001 -0.504,0.207 -0.53,0.48l-0,3.18c-0,1.47 0.47,2.56 2.26,2.56c0.638,0.028 1.251,-0.251 1.65,-0.75l0.07,-0.09l0.1,-0.18l-0,0.48c0.075,0.2 0.266,0.331 0.48,0.33l1.3,0l-0,-3c-0,-0.79 0.15,-1.55 1.13,-1.55c0.98,0 1,0.9 1,1.6l-0,3l1.9,0l-0,-3.64c-0,-1.63 -0.36,-2.88 -2.28,-2.88" style={{
    fill: color,
    fillRule: "nonzero"
  }} /><path id="path31" d="M29.439,27.48c-0.622,0.041 -1.198,-0.328 -1.42,-0.91c-0.074,-0.202 -0.111,-0.415 -0.11,-0.63c-0.047,-0.574 0.233,-1.126 0.724,-1.427c0.491,-0.302 1.11,-0.302 1.601,-0c0.491,0.301 0.771,0.853 0.725,1.427c0.001,0.215 -0.037,0.428 -0.11,0.63c-0.214,0.585 -0.79,0.957 -1.41,0.91m-0,-4.74c-0.887,-0.061 -1.76,0.249 -2.41,0.856c-0.65,0.606 -1.02,1.455 -1.02,2.344c0.004,0.211 0.024,0.422 0.06,0.63c0.35,1.715 1.935,2.89 3.677,2.729c1.742,-0.162 3.084,-1.609 3.113,-3.359c0.002,-0.888 -0.365,-1.737 -1.014,-2.344c-0.649,-0.607 -1.52,-0.917 -2.406,-0.856" style={{
    fill: color,
    fillRule: "nonzero"
  }} /><path id="path32" d="M38.329,26.57c-0.47,-1.65 -3.12,-1.1 -3.12,-1.92c-0,-0.35 0.37,-0.49 0.69,-0.49c0.426,-0.013 0.834,0.176 1.1,0.51l1.16,-1.14c-0.619,-0.56 -1.437,-0.848 -2.27,-0.8c-1.29,0 -2.58,0.63 -2.58,2.05c-0,1.42 1,1.6 1.87,1.79c0.7,0.16 1.33,0.24 1.33,0.67c-0,0.43 -0.44,0.43 -0.86,0.43c-0.527,0.01 -1.027,-0.236 -1.34,-0.66l-1.16,1.26c0.667,0.582 1.536,0.88 2.42,0.83c1.32,0 2.83,-0.45 2.83,-2c-0.001,-0.179 -0.025,-0.357 -0.07,-0.53" style={{
    fill: color,
    fillRule: "nonzero"
  }} /><path id="path33" d="M8.639,17.59c0.483,0.009 0.965,-0.055 1.43,-0.19c0.42,-0.13 0.81,-0.26 1.16,-0.39c0.16,-0.07 0.27,-0.08 0.34,0c0.085,0.06 0.151,0.144 0.19,0.24l0.57,1.2c0.067,0.104 0.098,0.227 0.09,0.35c-0.035,0.089 -0.104,0.161 -0.19,0.2c-0.586,0.362 -1.234,0.613 -1.91,0.74c-0.658,0.135 -1.329,0.202 -2,0.2c-0.816,0.017 -1.627,-0.122 -2.39,-0.41c-0.639,-0.251 -1.206,-0.656 -1.65,-1.18c-0.465,-0.547 -0.806,-1.189 -1,-1.88c-0.218,-0.811 -0.322,-1.649 -0.31,-2.49c-0.011,-0.849 0.121,-1.694 0.39,-2.5c0.247,-0.703 0.624,-1.354 1.11,-1.92c0.472,-0.531 1.056,-0.951 1.71,-1.23c0.702,-0.294 1.458,-0.441 2.22,-0.43c0.566,-0.004 1.132,0.043 1.69,0.14c0.568,0.097 1.116,0.29 1.62,0.57c0.12,0.07 0.2,0.13 0.21,0.2c0.01,0.07 -0,0.19 -0.08,0.39l-0.52,1.15c-0.027,0.111 -0.091,0.21 -0.18,0.28c-0.06,0.04 -0.17,0.04 -0.33,0c-0.369,-0.135 -0.747,-0.245 -1.13,-0.33c-0.383,-0.071 -0.771,-0.105 -1.16,-0.1c-0.425,-0.007 -0.844,0.093 -1.22,0.29c-0.36,0.192 -0.668,0.466 -0.9,0.8c-0.252,0.369 -0.438,0.778 -0.55,1.21c-0.125,0.494 -0.186,1.001 -0.18,1.51c-0.005,0.498 0.045,0.994 0.15,1.48c0.083,0.413 0.249,0.804 0.49,1.15c0.222,0.329 0.53,0.592 0.89,0.76c0.439,0.186 0.913,0.274 1.39,0.26" style={{
    fill: color,
    fillRule: "nonzero"
  }} /><path id="path34" d="M23.079,12.98l-0.1,0l-0.69,1.56l-1.4,3.07c-0.078,0.222 -0.208,0.421 -0.38,0.58c-0.177,0.11 -0.383,0.163 -0.59,0.15l-0.92,0c-0.209,0.023 -0.418,-0.03 -0.59,-0.15c-0.151,-0.172 -0.273,-0.368 -0.36,-0.58l-1,-2.17l-1,-2.26l-0.1,0l-0,6.2c-0,0.16 -0,0.25 -0.09,0.28c-0.098,0.028 -0.202,0.028 -0.3,0l-1.72,0c-0.095,0.025 -0.195,0.025 -0.29,0c-0.07,0 -0.1,-0.12 -0.1,-0.28l-0,-10.2c-0.034,-0.324 0.055,-0.648 0.25,-0.91c0.229,-0.197 0.529,-0.291 0.83,-0.26l1,0c0.245,-0.021 0.491,0.039 0.7,0.17c0.189,0.167 0.336,0.376 0.43,0.61l1.84,4.07l1,2.38l0.07,0l1.15,-2.61l1.72,-3.81c0.09,-0.246 0.246,-0.464 0.45,-0.63c0.235,-0.139 0.507,-0.202 0.78,-0.18l0.83,0c0.287,-0.023 0.573,0.055 0.81,0.22c0.21,0.235 0.308,0.548 0.27,0.86l-0,10.29c-0,0.16 -0,0.25 -0.09,0.28c-0.098,0.029 -0.202,0.029 -0.3,0l-1.67,0c-0.092,0.025 -0.189,0.025 -0.28,0c-0.06,0 -0.09,-0.12 -0.09,-0.28l-0.07,-6.4Z" style={{
    fill: color,
    fillRule: "nonzero"
  }} /><path id="path35" d="M34.639,14.23c0.345,0.139 0.632,0.393 0.81,0.72c0.164,0.319 0.294,0.654 0.39,1l0.86,3.34c0.024,0.078 0.024,0.162 -0,0.24c-0,0.1 -0.11,0.14 -0.25,0.14l-1.77,0c-0.13,0.014 -0.261,0.014 -0.39,0c-0.144,-0.03 -0.255,-0.145 -0.28,-0.29l-0.75,-3c-0.048,-0.439 -0.269,-0.84 -0.615,-1.114c-0.346,-0.275 -0.787,-0.399 -1.225,-0.346l-1.63,0l-0,4.39c-0,0.16 -0,0.26 -0.07,0.29c-0.07,0.03 -0.15,0.06 -0.32,0.06l-1.8,0c-0.105,0.011 -0.211,-0.013 -0.3,-0.07c-0.07,-0.075 -0.103,-0.178 -0.09,-0.28l-0,-10.29c-0.02,-0.268 0.05,-0.536 0.2,-0.76c0.218,-0.203 0.514,-0.298 0.81,-0.26l4.13,0c1.032,-0.064 2.05,0.264 2.85,0.92c0.676,0.639 1.041,1.541 1,2.47c0.004,0.562 -0.134,1.116 -0.4,1.61c-0.265,0.493 -0.673,0.894 -1.17,1.15l0.01,0.08Zm-1.08,-2.67c-0.002,-0.152 -0.022,-0.303 -0.06,-0.45c-0.042,-0.156 -0.121,-0.3 -0.23,-0.42c-0.128,-0.135 -0.281,-0.244 -0.45,-0.32c-0.233,-0.088 -0.481,-0.132 -0.73,-0.13l-2.29,0l-0,2.68l2.28,0c0.412,0.027 0.818,-0.109 1.13,-0.38c0.268,-0.261 0.41,-0.626 0.39,-1" style={{
    fill: color,
    fillRule: "nonzero"
  }} /></Icon>
};