/** Imports */
import { Text, useToast }from '@chakra-ui/react';
import { Fragment, useState } from 'react';
import api from "../../helpers/api";
import { useEffect } from 'react';
import { Page } from '../../components/templates/Page';
import TokenService from '../../helpers/TokenService';
import { useErrors } from '../../helpers/integer';

const Test = () => {
  /** Hooks */
  const [data, setData] = useState({});
  const showErrors      = useErrors();
  const toast           = useToast();

  /**
   * Obtiene la información del premio
   */
  const getData = async () => {
    try {
      const response = await api.post('RouletteServices/getStockRoulette',{ });
      if(response.data.result === true){
        setData(response.data.response);
      }
      else{
        toast({
          title     : 'Hubo un problema al cargar la encuesta',
          status    : 'warning',
          duration  : 5000,
          isClosable: true,
        })
      }
    } catch (err) {
      showErrors(err);
    }
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    <Fragment>
      <Page
        title="Componente de Pruebas"
      >
      </Page>
    </Fragment>
  );
}

export default Test;