/** Imports */
import {
    Box,
    Grid,
    Heading,
    SimpleGrid,
    Text
  } from '@chakra-ui/react';
import { htmlfy, useErrors } from '../../helpers/integer';
import { useContext, useState } from 'react';
import api from "../../helpers/api";
import { useEffect } from 'react';
import { ReportGrid } from '../../components/ReportGrid';
import { Loader } from '../../components/Loader';
import { Page } from '../../components/templates/Page';
import { GlobalDataContext } from '../../components/context/GlobalData';
import { Title } from '../../components/templates/Title';
import { useParams } from 'react-router-dom';
import { Legend } from '../../components/templates/Legend';
import { InputField }           from '../../components/input/InputField';
// import { analytics } from 'firebase-functions/v1';
// import { logEvent } from 'firebase/analytics';

const MyCollectiveContractPage = () => {

  /** Hooks */
  const showErrors = useErrors();
  const [globalData, setGlobalData] = useContext(GlobalDataContext);

  useEffect(() => {
  }, [])

  return (
    <Page title="Mi convenio colectivo">
        {globalData
        ?
        <>
           <Legend>Mi Convenio Colectivo</Legend>
           <SimpleGrid columns={{ base: '1', lg: '2', xl: '3' }} spacing={4} p={5}>
             <InputField disabled defaultValue={globalData?.contact?.CustomFields?.CO?.ContratoColectivo?.LookupName} label="Convenio Colectivo" />
             <InputField disabled defaultValue={globalData?.contact?.CustomFields?.CO?.Empresa_interna.LookupName} label="Razón Social" />
             <InputField disabled defaultValue={globalData?.contact?.Empresa_interna?.RUT} label="RUT" />
           </SimpleGrid>
        </>
        :
        <Loader />
        }
    </Page>
  );
}

export default MyCollectiveContractPage;