/** Imports */
import {
  Box,
  GridItem,
  Heading,
  SimpleGrid,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text
} from '@chakra-ui/react';
import { useErrors } from '../../helpers/integer';
import { Maternity } from '../../components/Maternity';
import { Page } from '../../components/templates/Page';
import { Title } from '../../components/templates/Title';
import { Icons } from '../../components/templates/Icons';
import { useState } from 'react';
import { Legend } from '../../components/templates/Legend';
// import { analytics } from 'firebase-functions/v1';
// import { logEvent } from 'firebase/analytics';

const CompensatoryBonusPage = () => {
  
  //Hooks
  const [tabIndex, setTabIndex]           = useState(0)
  const [tabConditions, setTabConditions] = useState(false)
  const [tabForm, setTabForm]             = useState(false)
  const showErrors = useErrors();
  
  
  const handleTabsChange = (index) => {
    setTabIndex(index)
  }

  return (
    <Page title="Bono compensatorio" description={'Si tu hijo o hija no utilizará el beneficio de Sala Cuna podrás solicitar el beneficio Bono Compensatorio. Este beneficio tendrá inicio una vez terminado el postnatal parental y hayas retomando tus funciones. Este se verá reflejado en tu liquidación de sueldo todos los meses hasta los 2 años de edad de tu hijo o hija y será pagado en la liquidación de sueldo del mes en curso en caso de ser ingresado hasta el 14 de cada mes o, se pagará en la liquidación del mes siguiente si fue ingresado posterior a esa fecha.'}>
      <Tabs index={tabIndex} onChange={handleTabsChange}>
      <TabList>
        <Tab isDisabled={tabConditions}>
          Información
        <Icons icon="question" color={'black'} h="7" w="7" />
        </Tab>
        <Tab isDisabled={tabForm} >
          Bono Compensatorio
          <Icons icon="inform" color={'black'} h="7" w="7" />
          </Tab>
      </TabList>
      <TabPanels>
        <TabPanel>
          <SimpleGrid align={'left'} columns={{ base: '1', lg: '2', xl: '1' }} spacing={4} p={5}>
            <GridItem>
              <Text as='b'>Primero: </Text>
                Se deja constancia que la Empresa, de conformidad a lo establecido en el artículo 203 del Código del Trabajo, ha designado diversas Salas Cunas para que las madres trabajadoras de la empresa puedan llevar a sus hijos menores de dos años mientras se encuentren en el trabajo.
              </GridItem>
            <GridItem>
              <Text as='b'>Segundo: </Text>
                Al aceptar el pago del bono compensatorio, declara al empleador que no hará uso del beneficio Sala Cuna.
              </GridItem>
            <GridItem>
              <Text as='b'>Tercero: </Text>
              En virtud de lo expuesto y como una forma de ayuda a la trabajadora, la Empresa pagará en reemplazo del beneficio de sala cuna, una asignación compensatoria extraordinaria de sala de cuna mientras tenga derecho al beneficio legal de la Sala Cuna. Puedes revisar el monto en la sección Mis Beneficios.
              </GridItem>
            <GridItem>
              <Text as='b'>Cuarto: </Text>
                El beneficio Bono Compensatorio, será pagado desde el mes que la trabajadora retome sus labores en la Empresa.
            </GridItem>
            <GridItem>
              <Text as='b'>Quinto: </Text>
              El bono indicado precedentemente se dejará de otorgar en el evento que la trabajadora haga uso del beneficio de llevar al menor a alguna de las salas cuna designadas por la empresa y, hasta, cuando el menor cumpla dos años de edad.
            </GridItem>
            <GridItem>
              <Text as='b'>Sexto: </Text>
              Asimismo, se deja constancia que los colaboradores que se hayan acogido a los beneficios establecidos en la Ley N° 21.247 que Establece Beneficios para padres, madres y cuidadores de niños o niñas, denominada también “Ley de Crianza Protegida”, no tendrán derecho al pago del bono de Sala Cuna mientras hagan uso de dichos beneficios.
            </GridItem>
          </SimpleGrid>
        </TabPanel>
        <TabPanel>
          <Maternity  title="Bono compensatorio" product_id={25} category_id={60} />
        </TabPanel>
      </TabPanels>
      </Tabs>
    </Page>
  );
}

export default CompensatoryBonusPage;