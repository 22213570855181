import { Icon } from "@chakra-ui/react";
export const Up = props => {
  let {color = '#565656'} = props;
  return <Icon  viewBox="0 0 41 40" {...props}><rect id="up" x={0.079} y={0} width={40} height={40} style={{
    fill: "none"
  }} /><path id="path134" d="M36.879,20c-0,9.278 -7.522,16.8 -16.8,16.8c-9.279,0 -16.8,-7.522 -16.8,-16.8c-0,-9.278 7.521,-16.8 16.8,-16.8c9.278,-0 16.8,7.522 16.8,16.8Z" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path136" d="M11.879,21.48l8.18,-8.18l8.18,8.18" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path137" d="M20.079,14.96l-0,13.44" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /></Icon>
};