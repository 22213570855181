import {
    Text,
    GridItem,
    Link,
    Grid,
    VStack,
    HStack,
    Stack,
    Button,
    Divider,
    Center,
    Box
  } from '@chakra-ui/react';
import * as React from 'react'
import { Link as LinkRRD } from 'react-router-dom';
import { ShadowBox } from '../templates/ShadowBox';
import { Icons } from '../templates/Icons';
import moment from 'moment';
import { Loader } from '../Loader';
import { GlobalDataContext } from  '../context/GlobalData';
import { Fragment, useContext, useState } from 'react';
import { useEffect } from 'react';
import api from "../../helpers/api";
import { downloadBase64File, useErrors } from '../../helpers/integer';

export const Roulette = (props) => {

  /** Hooks */
  const showErrors                  = useErrors();
  const [globalData, setGlobalData] = useContext(GlobalDataContext);
  const [isWinner, setIsWinner]     = useState(null);

  /** Variables */
  const { giftCard }             = props;
  const { link }                 = props;
  const { button }               = props;
  const { win_callback }         = props;
  const { getData_callback }     = props;
  const { showMessage_callback } = props;
  const { disabled }             = props;
  const { setDisabled_callback } = props;
  const url_service              = 'https://vivetusbeneficios.custhelp.com/cc/API/';

  /**
   * Obtener información
   */
  // const getData = async () => {

  //   try {
  //     const response = await api.post(url_service,{});
      
  //   } catch (err) {
  //     showErrors(err);
  //   }
  // }

  const [launch, setLaunch] = useState(true);
  let canvasRef = React.useRef();
  let interval = null;
  // let   arr_start_angle = []

  // Object.keys(config).map((d, i) => {
  //   arr_start_angle.push(config[d]);
  // });
  
  let angle           = 360*100;
  let divition_factor = 0.023;
  let end_angle       = 0;

  // Imágenes
  let main        = new Image();
  let main_load = false;

  let over        = new Image();
  let over_load = false;

  let selector      = new Image();
  let selector_load = false;

  main.src     = '/images/roulette/main.png';
  over.src     = '/images/roulette/over.png';
  selector.src = '/images/roulette/selector.png';
  
  // Eventos de imágenes
  
  main.onload = () => {
    main_load = true;
  };
  
  over.onload = () => {
    over_load = true;
  };
  
  selector.onload = () => {
    selector_load = true;
  };

  // let win      = new Image();
  // win.src      = '/images/roulette/win.png';
  
  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');

    // analytics.logEvent('game');

    let image_loader_interval = setInterval(() => {
      if(main_load) {
        drawRandomlyColoredRectangle(context);
        window.clearInterval(image_loader_interval);
        setLaunch(false);
      }
    }, 300);
  }, [])

  /**
   * Dibuja la ruleta
   */
   const drawRandomlyColoredRectangle = (context) => {
    
    if(!context) {
      context = window.context;
    } else {
      window.context = context;
    }

    context.clearRect(0, 0, 1000, 500);

    incrementAngle();
    context.save();
    context.translate(250, 250);
    context.rotate(convertToRadians(angle));

    // Realiza la carga de la imagen
    context.drawImage(main, -250, -250);

    context.restore();
    context.rotate(0);
    
    // Realiza la carga de la imagen
    context.drawImage(over, 0, 0);
    context.drawImage(selector, 250-33, 15);
  }

  /**
   * Cambia el valor de rotación de la imagen
   * 
   * @returns 
   */
  const incrementAngle = () => {
    if((angle - 0.1) <= end_angle) {
      angle = end_angle;
      stop();

      return false;
    }

    if((angle - end_angle) <= 10) {
      divition_factor = 0.015;
    }

    angle -= (angle - end_angle) * divition_factor;
  }

  /**
   * Finaliza el juego
   */
  const stop = () => {

    window.clearInterval(interval);

    if(window.roulette_data.data.result.id === 0) {
      setIsWinner(false);

      let fail      = new Image();
      fail.src      = '/images/roulette/fail.png';
      let fail_load = false;
  
      fail.onload = () => {
        window.context.drawImage(fail, 0, 0);
      };
    } else {
      setIsWinner(true);
  
      win_callback();
  
      let win      = new Image();
      win.src      = '/images/roulette/win.png';
      let win_load = false;
  
      win.onload = () => {
        window.context.drawImage(win, 0, 0);
      };
    }
  }

  /**
   * Inicia la ruleta
   * 
   * @param {object} context 
   */
  const play = async (context) => {

    if(disabled) {
      return false;
    }

    setLaunch(true);

    try {
      const data = await getData_callback();
      window.roulette_data = data;
  
      if(!data.data.result) {
        showMessage_callback('Error', data.data.message);
        setDisabled_callback(true);
        return false;
      }
  
      var random_value = Math.floor(Math.random() * 36);
      end_angle   = data.data.result.end_angle + random_value; // 90 + random_value;
  
      interval = window.setInterval(() => { drawRandomlyColoredRectangle(context); }, 20);
    } catch(e) {
      showMessage_callback('Error', 'Error general');
      return false;
    }
  }

  /**
   * Convierte grados a radianes
   * 
   * @param {number} degree 
   * @returns 
   */
  const convertToRadians = (degree) => {
    return degree * (Math.PI / 180);
  }

  return <Box>

    <Box className='roulette' p="10">
      <canvas ref={ canvasRef } width="500" height="500" id="rn_RouletteCanvas" style={{maxWidth:'100%'}}>
        Tu navegador no es compatible, intenta con otro por favor.
      </canvas>
    </Box>

    {!isWinner && <Button onClick={() => { play(); }} {...(launch || disabled) && {"disabled": "disabled"}}>
      ¡Lanzar ruleta!
    </Button>}

    {/* {isWinner && <Button onClick={() => { play(); }}>
      ¡Ver premio!
    </Button>} */}
    
  </Box>;
}