/** Imports */
import {
  Box,
  Grid,
  Heading,
  HStack,
  Stack,
  Text
} from '@chakra-ui/react';
import { useErrors } from '../../helpers/integer';
import { useState } from 'react';
import api from '../../helpers/api';
import { Page } from '../../components/templates/Page';
import { useEffect } from 'react';
import { ReportGrid } from '../../components/ReportGrid';
import { ProductGrid } from '../../components/catalog/ProductGrid';
import { Loader } from '../../components/Loader';
import { Card } from '../../components/card/Card';
import { Title } from '../../components/templates/Title';
// import { analytics } from 'firebase-functions/v1';
// import { logEvent } from 'firebase/analytics';

const BenefitInsurancePage = () => {

  /** Hooks */
  const showErrors = useErrors();
  const [data, setData] = useState(false);

  const url_service = 'ReportServices/getReportHomeBoxes';
  const a_content = {
    'report_id': 101181
  };

  /**
   * Reporte de Answers Seguro Complementario
   */
  const getReportInsurance = async () => {
    try {
      const response = await api.post(url_service, a_content);
      setData(response.data.response.result_father_box);
      showErrors(response, false);
    } catch (err) {
      showErrors(err);
    }
  }

  useEffect(() => {
    getReportInsurance();
  }, [])

  if (data === false) {
    return <Loader />;
  }

  return (
    <Page title="Seguro complementario de salud" p="0" color="transparent">
      {!!data && data.length != 0
        ?
        <ProductGrid>
          {
            data.map((insurance) => (
              insurance?.id == "942"
              ?
              <Card  key={insurance.id} data={insurance} click={'/benefits/complementary/'} />
              :
              <Card key={insurance.id} data={insurance} click={'/benefits/insurance/'} src={insurance.answer_image} />
            ))
          }
        </ProductGrid>
        :
        <Text>Sin Datos</Text>
      }
    </Page>
  );
}

export default BenefitInsurancePage;