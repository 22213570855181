import { Icon } from "@chakra-ui/react";
export const Warning = props => {
  let {color = '#565656'} = props;
  return <Icon  viewBox="0 0 41 40" {...props}><rect id="warning" x={0.079} y={0} width={40} height={40} style={{
    fill: "none"
  }} /><path id="path150" d="M33.589,30.08l-8.52,-0l-5,6.72l-5.12,-6.72l-8.41,-0c-1.8,-0 -3.26,-1.9 -3.26,-4.25l-0,-18.37c-0,-2.35 1.46,-4.26 3.26,-4.26l27.09,-0c1.79,-0 3.25,1.91 3.25,4.26l-0,18.37c-0.04,2.35 -1.5,4.25 -3.29,4.25Z" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path182" d="M19.939,19.63l0.16,-0c0.82,0.001 1.497,-0.641 1.54,-1.46l0.38,-8.73c0.019,-0.418 -0.134,-0.825 -0.423,-1.127c-0.289,-0.303 -0.689,-0.473 -1.107,-0.473l-0.93,-0c-0.419,-0 -0.82,0.17 -1.111,0.472c-0.291,0.302 -0.446,0.709 -0.429,1.128l0.39,8.73c0.037,0.818 0.711,1.461 1.53,1.46Z" style={{
    fill: color,
    fillRule: "nonzero"
  }} /><path id="path183" d="M21.719,22.99c-0,0.928 -0.752,1.68 -1.68,1.68c-0.928,-0 -1.68,-0.752 -1.68,-1.68c-0,-0.928 0.752,-1.68 1.68,-1.68c0.928,-0 1.68,0.752 1.68,1.68Z" style={{
    fill: color,
    fillRule: "nonzero"
  }} /></Icon>
};