/** Imports */
import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Button,
  Heading,
  Text,
  useColorModeValue,
  useToast,
  FormErrorMessage,
  InputRightElement,
  InputGroup,
  Divider,
  Center,
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
  Tab,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverArrow,
  PopoverCloseButton,
  PopoverBody,
  HStack
} from '@chakra-ui/react';
import { Fragment }  from 'react';
import { AuthPage }  from '../../components/templates/AuthPage';
import { LoginForm } from '../../components/auth/LoginForm';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
// import { analytics } from 'firebase-functions/v1';
// import { logEvent } from 'firebase/analytics';

/**
 * Página de inicio de sesión
 * 
 * @returns JSX
 */
const LoginPage = () => {

  /** Hooks */

  return (
    <Fragment>
      <AuthPage>
        <GoogleReCaptchaProvider reCaptchaKey="6Ld9bU8jAAAAAM_ipnkoAmGugsqQz7uXutkKxqt6">
          <LoginForm />
        </GoogleReCaptchaProvider>
      </AuthPage>
    </Fragment>
  );
}

export default LoginPage;
