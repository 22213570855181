import { Button, HStack, Icon, Text, Box, VStack, useDisclosure, Collapse, Spacer, Stack, Link, textDecoration } from '@chakra-ui/react';
import React, { createElement } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { Fragment, Children, cloneElement } from 'react'
import { ShadowCard } from './../templates/ShadowCard';
import { Icons } from '../templates/Icons';

export const Group = (props) => {
  const { icon, label, path = null, children, isChild= false, ...buttonProps } = props
  const { isOpen, onToggle } = useDisclosure()
  let navigate = useNavigate();

  return <Fragment>
    <ShadowCard color={'white'}>
    <Stack
      direction={{ base: 'column', md: 'row' }}
      p="5"
      borderRadius={'md'}
      align="center"
      >
      <Stack direction={'column'} align="center" w="150px">
        <Icons icon={icon} w={'50px'} h={'50px'} />
        <Text>{label}</Text>
      </Stack>

      <Box
        w={'full'}
        justify="center"
      >
        {(Children.count(props.children))?
        <VStack m="0.5 0.5 0.5 1" spacing="2px" p="0 0 0 5px">
        {
          Children.map(children, (d) => {
            return cloneElement(d, {isChild: true});
          })
        }
        </VStack>:null}
      </Box>
    </Stack>
    </ShadowCard>

  </Fragment>

}