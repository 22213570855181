import { Icon } from "@chakra-ui/react";
export const Falabella = props => {
  let {color = '#565656'} = props;
  return <Icon  viewBox="0 0 40 41" {...props}><rect id="falabella" x={0} y={0.8} width={40} height={40} style={{
    fill: "none"
  }} /><path d="M8.697,25.215l0,11.051c0,0.408 0.093,0.673 0.282,0.798c0.189,0.125 0.454,0.188 0.801,0.188l3.579,0c0.44,0 0.731,-0.054 0.872,-0.164c0.14,-0.11 0.211,-0.383 0.211,-0.822l0,-11.051l-5.745,-0Z" style={{
    fill:color,
    fillRule: "nonzero"
  }} /><path d="M31.139,4.606c-0.111,-0.172 -0.384,-0.258 -0.824,-0.258l-13.236,-0c-1.601,-0 -2.936,0.234 -4.002,0.702c-1.068,0.471 -1.932,1.097 -2.59,1.879c-0.66,0.782 -1.125,1.683 -1.391,2.699c-0.266,1.018 -0.399,2.075 -0.399,3.169l0,9.999l19.874,-0c0.409,-0 0.668,-0.096 0.779,-0.283c0.108,-0.189 0.164,-0.453 0.164,-0.799l-0,-2.675c-0,-0.438 -0.056,-0.727 -0.164,-0.867c-0.111,-0.142 -0.37,-0.213 -0.779,-0.213l-14.129,-0l0,-5.491c0,-1.001 0.244,-1.76 0.732,-2.277c0.485,-0.516 1.324,-0.774 2.518,-0.774l12.623,0c0.408,0 0.674,-0.078 0.8,-0.235c0.127,-0.156 0.188,-0.438 0.188,-0.844l-0,-2.911c-0,-0.377 -0.056,-0.649 -0.164,-0.821" style={{
    fill:color,
    fillRule: "nonzero"
  }} /></Icon>
};