import { Icon } from "@chakra-ui/react";
export const List = props => {
  let {color = '#565656'} = props;
  return <Icon  viewBox="0 0 41 40" {...props}><rect id="list" x={0.079} y={0} width={40} height={40} style={{
    fill: "none"
  }} /><path id="path184" d="M10.269,7.87l19.62,-0c2.005,-0 3.63,1.625 3.63,3.63l-0,21.3c-0,2.005 -1.625,3.63 -3.63,3.63l-19.62,0c-2.005,0 -3.63,-1.625 -3.63,-3.63l-0,-21.3c-0,-2.005 1.625,-3.63 3.63,-3.63Z" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path185" d="M13.769,14.58l12.67,-0" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path186" d="M13.769,21.3l12.67,-0" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path187" d="M13.589,28.02l12.66,-0" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path188" d="M15.039,7.87c-0,-2.784 2.256,-5.04 5.04,-5.04c2.783,-0 5.04,2.256 5.04,5.04" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /></Icon>
};