import { Icon } from "@chakra-ui/react";
export const Baby = props => {
  let {color = '#565656'} = props;
  return <Icon  viewBox="0 0 40 40" {...props}><rect id="baby" x={0} y={0} width={40} height={40} style={{
    fill: "none"
  }} /><path d="M32.271,23.887c-1.182,3.923 -4.634,7.028 -9.042,8.166" style={{
    fill: "none",
    stroke: color,
    strokeWidth: "3.17px"
  }} /><path d="M15.975,32.104c-4.505,-1.094 -8.046,-4.234 -9.246,-8.217" style={{
    fill: "none",
    stroke: color,
    strokeWidth: "3.17px"
  }} /><path d="M6.729,17.836c1.499,-4.976 6.65,-8.636 12.771,-8.636c6.121,0 11.272,3.66 12.771,8.636" style={{
    fill: "none",
    stroke: color,
    strokeWidth: "3.17px"
  }} /><path d="M6.729,23.887l-0.466,0.041c-1.923,0 -3.482,-1.373 -3.482,-3.067c0,-1.694 1.559,-3.067 3.482,-3.067l0.466,0.042" style={{
    fill: "none",
    stroke: color,
    strokeWidth: "3.17px"
  }} /><path d="M32.271,17.836l-0,-0.042c1.923,0 3.948,1.373 3.948,3.067c-0,1.694 -1.559,3.067 -3.482,3.067l-0.466,-0.041" style={{
    fill: "none",
    stroke: color,
    strokeWidth: "3.17px"
  }} /><path d="M18.663,10.518c-0,0.782 0.025,1.274 0.676,1.707c0.923,0.616 2.531,1.276 1.961,2.744c-0.506,1.305 -2.749,1.287 -3.72,0.689" style={{
    fill: "none",
    stroke: color,
    strokeWidth: "3.17px"
  }} /><path d="M24.5,29.046c0,-2.179 -1.769,-3.949 -3.949,-3.949l-2.102,0c-2.18,0 -3.949,1.77 -3.949,3.949c0,2.179 1.769,3.948 3.949,3.948l2.102,0c2.18,0 3.949,-1.769 3.949,-3.948Z" style={{
    fill: "none",
    stroke: color,
    strokeWidth: "3.17px"
  }} /><path id="path284" d="M20.34,29.046c0,0.464 -0.376,0.84 -0.84,0.84c-0.464,-0 -0.84,-0.376 -0.84,-0.84c-0,-0.464 0.376,-0.84 0.84,-0.84c0.464,-0 0.84,0.376 0.84,0.84Z" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "1.68px"
  }} /></Icon>
};