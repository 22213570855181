import { Icon } from "@chakra-ui/react";
export const SendMoney = props => {
  let {color = '#565656'} = props;
  return <Icon  viewBox="0 0 41 40" {...props}><rect id="send_money" x={0.079} y={0} width={40} height={40} style={{
    fill: "none"
  }} /><path id="path282" d="M26.804,17.48l7.38,-0c0.71,-0.005 1.394,0.272 1.901,0.771c0.506,0.499 0.793,1.178 0.799,1.889l-0,13.21c-0.017,1.454 -1.206,2.621 -2.66,2.61l-21.57,-0c-1.454,0.011 -2.644,-1.156 -2.66,-2.61l-0,-19.23" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path283" d="M12.504,12.44l18.52,-0c1.38,-0 2.5,1.119 2.5,2.5l-0,1.43c-0,0.613 -0.497,1.11 -1.11,1.11l-19.91,-0c-1.387,-0 -2.51,-1.124 -2.51,-2.51c-0,-1.386 1.123,-2.51 2.51,-2.51l-0,-0.02Z" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path284" d="M31.834,27.56c-0,0.928 -0.752,1.68 -1.68,1.68c-0.928,-0 -1.68,-0.752 -1.68,-1.68c-0,-0.928 0.752,-1.68 1.68,-1.68c0.928,-0 1.68,0.752 1.68,1.68Z" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path285" d="M20.074,12.44c-0,4.639 -3.761,8.4 -8.4,8.4c-4.639,-0 -8.4,-3.761 -8.4,-8.4c-0,-4.639 3.761,-8.4 8.4,-8.4c4.639,-0 8.4,3.761 8.4,8.4Z" style={{
    fill: "#fff",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path286" d="M15.874,13.36l-4.2,-4.23l-4.23,4.19" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /></Icon>
};