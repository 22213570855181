/** Imports */
import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Button,
  Heading,
  Text,
  useColorModeValue,
  useToast,
  FormErrorMessage,
  InputRightElement,
  InputGroup,
  Divider,
  Center,
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
  Tab,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverArrow,
  PopoverCloseButton,
  PopoverBody,
  HStack
} from '@chakra-ui/react';
import { formatRut, useErrors, validateEmail, validateRut } from '../../helpers/integer';
import { useNavigate, Link as LinkRRD } from "react-router-dom";
import { useState, useEffect, useContext, Fragment, useRef } from 'react';
import { InputField } from '../input/InputField';
import { LogoFIF  } from '../logos/LogoFIF';
import TokenService from "../../helpers/TokenService";
import api from "../../helpers/api";
import { useForm } from 'react-hook-form';
import { GlobalDataContext } from '../context/GlobalData';
import { Icons } from '../templates/Icons';

/**
 * Página de inicio de sesión
 * 
 * @returns JSX
 */
 export const LoginCodeForm = () => {

  /** Hooks */
  const showErrors                           = useErrors();
  const [data, setData]                      = useState({});
  const [globalData, setGlobalData, getContact, login] = useContext(GlobalDataContext);

  const initialFocusRef = useRef()

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting }
  } = useForm();
  const toast  = useToast()
  let navigate = useNavigate();


  /**
   * Inicia sesión
   */
  const onSubmit = async (data) => {
    setData(data);

    try {
      let response = await login(data.login, data.password);
      
      showErrors(response);
      
      if (response.data.result) {
        await getContact();

        toast({
          title: 'Sesión Iniciada',
          status: 'success',
          duration: 2000,
          isClosable: true,
        });

        navigate('/home');
      }

      return response.data;
    } catch (err) {
      showErrors(err);
    }
  }

  return (
    <Fragment>
      <form onSubmit={handleSubmit(onSubmit)}>

        <Stack spacing={4}>

          <InputField label="Código" type="code" name="code" required={true} register={register} errors={errors} />

          <Button
            type="submit"
            isLoading= {isSubmitting}
            loadingText="Iniciando..."
            disabled={!!errors.code || isSubmitting}
          >
            Enviar código
          </Button>


          <LinkRRD
            color={'blue.400'}
            to="/forgot"
            align={'center'}
            >
            Reenviar claves
          </LinkRRD>
        </Stack>
      </form>
    </Fragment>
  );
}
