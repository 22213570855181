import { Icon } from "@chakra-ui/react";
export const Email = props => {
  let {color = '#565656'} = props;
  return <Icon  viewBox="0 0 41 40" {...props}><rect id="email" x={0.079} y={0} width={40} height={40} style={{
    fill: "none"
  }} /><path id="path101" d="M6.539,7.4l27.08,0c1.8,0 3.26,1.46 3.26,3.26l-0,18.68c-0,1.801 -1.46,3.26 -3.26,3.26l-27.08,-0c-1.801,-0 -3.26,-1.459 -3.26,-3.26l-0,-18.68c-0,-1.8 1.459,-3.26 3.26,-3.26Z" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path103" d="M4.959,9.08l15.12,8.4l15.12,-8.4" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /></Icon>
};