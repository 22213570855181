/** Imports */
import {
  Button,
  ListItem,
  OrderedList,
  SimpleGrid,
  Stack,
  useToast,
  VStack
} from '@chakra-ui/react';
import { useState, useContext, useEffect } from 'react';
import { useForm }              from 'react-hook-form';
import { Page }                 from '../../components/templates/Page';
import { Loader }               from '../../components/Loader';
import { InputField }           from '../../components/input/InputField';
import { GlobalDataContext }    from '../../components/context/GlobalData';
import { Success }              from '../../components/Success';
import { Legend }               from '../../components/templates/Legend';
import { Message }              from '../../components/templates/Message';
import moment                   from 'moment';
// import { analytics } from 'firebase-functions/v1';
// import { logEvent } from 'firebase/analytics';

// Helpers
import api                      from "../../helpers/api";
import { useErrors }            from '../../helpers/integer';


const BabyRoomPage = () => {

  /** Hooks */
  const showErrors                          = useErrors();
  const [globalData, setGlobalData]         = useContext(GlobalDataContext);
  const [data, setData]                     = useState({});
  const [success, setSuccess]               = useState(false);
  const [dataSuccess, setdataSuccess]       = useState({});
  const [typeService, setTypeService]       = useState("1");
  const [communeID, setCommune]             = useState();
  const [communeStatus, setCommuneStatus]   = useState(false);
  const [vitamineStatus, setVitamineStatus] = useState(false);
  const [center, setCenters]                = useState([]);
  const toast                               = useToast();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    isDirty,
    control,
    setValue
  } = useForm({});


  const birthDate = moment().format('L', new Date(parseInt(globalData?.contact?.CustomFields?.c?.ano_nacimiento) ,parseInt(globalData?.contact?.CustomFields?.c?.mes_nacimiento), parseInt(globalData?.contact?.CustomFields?.c?.dia_nacimiento)));

  useEffect(() => {
  }, [])

  const onChangeType = async (data) => {
    setTypeService(data.target.value);
    setCommuneStatus(false);
  }

  const onChangeCommune = async (data) => {
    setCommuneStatus(true);
    setCenters([]);
    setCommune(data.target.value);
  }

  const onChangeCommuneVitamine = async (data) => {
    setCommuneStatus(true);
    setCenters([]);
    setCommune(data.target.value);
  }

   /**
   * Enviar encuesta con la información contestada
   */
  const onSubmit = async (data) => {
    data.Incident = {};

    data.Incident.product    = {};
    data.Incident.product.id = 25;

    data.subject = {};
    data.subject = "Inscripción sala cuna";

    data.Incident.category    = {};
    data.Incident.category.id = 59;

    setData(data);

    try {
      const response = await api.post('IncidentServices/createIncident', data);

      if(response.data.result === true){
        toast({
          title: response.data.response.message,
          status: 'success',
          duration: 3000,
          isClosable: true,
        })
      } else {
        toast({
          title: response.data.response.message,
          status: 'warning',
          duration: 3000,
          isClosable: true,
        })
      }
    } catch (err) {
      console.log(err);
    }
  }


  if(!globalData?.contact) {
    return <Loader />;
  }

  if(success) {
    return <Success dataSuccess={dataSuccess?.ref_number}  message={'Solicitud creada exitosamente'}/>;
  }

  return (
    <Page title="Inscripción sala cuna">
      <Message>
        <VStack w={'full'} textAlign={'left'}>
          <OrderedList p={2}>
            <ListItem>Elige una Sala Cuna en la que podrás llevar a tu hijo o hija hasta los 2 años de edad. Este beneficio es para la madre o padre que hiciera uso de post natal. Si el menor no asiste a Sala Cuna puedes solicitar Bono Compensatorio.</ListItem>
            <ListItem>Ingresa todos los datos de la solicitud para alguna de las salas cuna en convenio de la red Vitamina o red Sodexo.</ListItem>
            <ListItem>Te llegará correo de confirmación o rechazo de tu solicitud.</ListItem>
          </OrderedList>
        </VStack>
      </Message>
    <form onSubmit={handleSubmit(onSubmit)} autoComplete={"off"}>
      <Legend>Datos de la empresa</Legend>
        <SimpleGrid columns={{ base: '1', lg: '2', xl: '4' }} spacing={4} p={5}>
          <InputField disabled defaultValue={globalData?.contact?.CustomFields?.CO?.Departamento?.LookupName} label="Sucursal"  />
          <InputField disabled defaultValue={globalData?.contact?.CustomFields?.CO?.Empresa_interna.LookupName} label="Razón social"  />
        </SimpleGrid>
      <Legend>Datos del apoderado</Legend>

        <SimpleGrid columns={{ base: '1', lg: '2', xl: '3' }} spacing={4} p={5}>
          <InputField label="RUT apoderado"               name={'FORMS.representative_rut'} defaultValue={globalData?.contact?.Login} type="text" placeholder="" register={register} errors={errors} required={true} />
          <InputField label="Nombre del apoderado"        name={'FORMS.representative_name'} defaultValue={globalData?.contact?.Name?.First} type="text" placeholder="" register={register} errors={errors} required={true} />
          <InputField label="Apellido paterno apoderado"  name={'FORMS.representative_lastname'} defaultValue={globalData?.contact?.Name?.Last} type="text" placeholder="" register={register} errors={errors} required={true} />
          <InputField label="Fecha nacimiento apoderado"  name={'FORMS.representative_birthdate'} defaultValue={birthDate} type="date" placeholder="" register={register} errors={errors} required={true} control={control} />
          <InputField label="Correo electrónico"          name={'FORMS.representative_email'} defaultValue={globalData?.contact?.Emails[0]} type="text" placeholder="" register={register} errors={errors} required={true} />
          <InputField label="Teléfono móvil"              name={'FORMS.representative_cellphone'} defaultValue={globalData?.contact?.CustomFields?.c?.phone_number} type="text" placeholder="" register={register} errors={errors} required={true} />
          <InputField label="Dirección particular"        name={'FORMS.representative_address'} defaultValue={globalData?.contact?.CustomFields?.GEO?.Address?.LookupName} type="text" placeholder="" register={register} errors={errors} required={true} />
          <InputField label="Dirección comercial"         name={'FORMS.representative_commercial_addr'}  defaultValue={globalData?.contact?.CustomFields?.CO?.Oficina?.LookupName} type="text" placeholder="" register={register} errors={errors} required={true} />
          <InputField label="Teléfono particular/fíjo"    name={'FORMS.representative_commercial_phon'} type="text" placeholder="" register={register} errors={errors} required={true} />
          <InputField label="Teléfono comercial"          name={'FORMS.company_phone'} type="text" placeholder="" register={register} errors={errors} required={true} />
        </SimpleGrid>

        <Legend>Datos del servicio</Legend>

        <SimpleGrid columns={{ base: '1', lg: '2', xl: '3' }} spacing={4} p={5}>
          <InputField label="Tipo" name="FORMS.typeof_nursery" type="select" onChange={onChangeType} register={register} errors={errors} required={true} options=
          {
            globalData?.CustomMenuNursery?.NurseryType.length !== 0 ? globalData?.CustomMenuNursery?.NurseryType[0] :  'Sin Datos'
          }/>

          {typeService === '1'
          ?
          <>
            <InputField label="Comuna" name="_commune" type="select" onChange={onChangeCommuneVitamine} register={register} errors={errors} required={true}
            options={
              globalData?.CustomMenuNursery?.CommunesVitamina[0]
            }/>
          </>
          :
            null
          }
          {typeService === '2'
          ?
          <>
            <InputField label="Comuna" name="_commune" type="select" onChange={onChangeCommune} register={register} errors={errors} required={true}
            options={
              globalData?.CustomMenuNursery?.CommunesSodexo[0]
            }/>
          </>
          :
            null
          }
          {vitamineStatus === true
          ?
          <>
            {
              globalData?.CustomMenuNursery?.IdCenterCommuneVitamina.map((vitamineCenterId, i) => {
                if(vitamineCenterId.label === communeID){
                  globalData?.CustomMenuNursery?.CentersVitamina.map((vitamineCenter, i) => {
                    if(vitamineCenterId.value === vitamineCenter.value){
                      center.push({'value':vitamineCenter.value, 'label':vitamineCenter.label});
                    }
                  })

                }
              })
            }
            <InputField label="Centro" name="FORMS.nursery_center" type="select" register={register} errors={errors} required={true} options={
              center
            }/>
          </>
          :
            null
          }
          {communeStatus === true
          ?
          <>
            {
              globalData?.CustomMenuNursery?.IdCenterCommuneSodexo.map((sodexoCenterId, i) => {
                if(sodexoCenterId.label === communeID){
                  globalData?.CustomMenuNursery?.CentersSodexo.map((sodexoCenter, i) => {
                    if(sodexoCenterId.value === sodexoCenter.value){
                      center.push({'value':sodexoCenter.value, 'label':sodexoCenter.label});
                    }
                  })

                }
              })
            }
            <InputField label="Centro" name="FORMS.nursery_center" type="select" register={register} errors={errors} required={true} options={
              center
            }/>
          </>
          :
            null
          }

           <InputField label="Jornada" name="FORMS.modality_type" type="select" register={register} errors={errors} required={true} options={
              globalData?.CustomMenus?.FORMS?.modality_type
          }/>
          <InputField label="Fecha de ingreso al centro *" name="FORMS.admission_date_center" type="date" placeholder="" register={register} errors={errors} required={true} control={control} />
        </SimpleGrid>

        <Legend>Datos del niño</Legend>
        <SimpleGrid columns={{ base: '1', lg: '2', xl: '3' }} spacing={4} p={5}>
          <InputField label="RUT niño/a" name="FORMS.child_rut" type="text" placeholder="" register={register} errors={errors} required={true} />
          <InputField label="Nombre niño/a" name="FORMS.child_name" type="text" placeholder="" register={register} errors={errors} required={true} />
          <InputField label="Fecha de nacimiento" name="FORMS.child_birthdate" type="date" placeholder="" register={register} errors={errors} required={true} control={control} />
          <InputField label="Nacionalidad" name="FORMS.child_nacionality" type="select" register={register} errors={errors} required={true} control={control} options={
          globalData?.CustomMenus?.FORMS?.child_nacionality
          } />
          <InputField label="Sexo" name="FORMS.child_gender" type="select" register={register} errors={errors} required={true} options={
            globalData?.CustomMenus?.FORMS?.child_gender
          } />
        </SimpleGrid>

        <Stack maxW={'200'} py={'6'}>
          <Button
            type="submit"
            isLoading={isSubmitting}
            loadingText="Solicitando..."
            disabled={isSubmitting}
          >
            Solicitar Inscripción
          </Button>
        </Stack>
      </form>
    </Page>
  );
}

export default BabyRoomPage;