import { Icon } from "@chakra-ui/react";
export const Connect = props => {
  let {color = '#565656'} = props;
  return <Icon  viewBox="0 0 41 40" {...props}><rect id="connect" x={0.079} y={0} width={40} height={40} style={{
    fill: "none"
  }} /><path id="path74" d="M26.829,31.76l-0,1.69c-0,1.85 -1.5,3.35 -3.35,3.35l-13.47,-0c-1.85,-0 -3.35,-1.5 -3.35,-3.35l-0,-26.9c-0,-1.85 1.5,-3.35 3.35,-3.35l13.46,0c1.85,0 3.35,1.5 3.35,3.35l-0,1.69" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path75" d="M31.639,27.79c2.37,-4.9 2.48,-10.592 0.3,-15.58" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path76" d="M27.179,25.78c1.61,-3.553 1.764,-7.595 0.43,-11.26" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path77" d="M23.169,16.9c0.573,2.277 0.419,4.675 -0.44,6.86" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /></Icon>
};