import { Icon } from "@chakra-ui/react";
export const Mon = props => {
  let {color = '#565656'} = props;
  return <Icon  viewBox="0 0 41 40" {...props}><rect id="mon" x={0.079} y={0} width={40} height={40} style={{
    fill: "none"
  }} /><path id="path210" d="M11.039,3.2c-4.426,3.633 -6.97,9.074 -6.92,14.8c0,10.34 8,18.8 17.86,18.8c5.584,-0.027 10.815,-2.736 14.06,-7.28c-0.954,0.191 -1.928,0.268 -2.9,0.23c-12.28,-0 -22.32,-10.57 -22.32,-23.5c-0.036,-1.022 0.038,-2.044 0.22,-3.05Z" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /></Icon>
};