import { Icon } from "@chakra-ui/react";
export const Inform = props => {
  let {color = '#565656'} = props;
  return <Icon  viewBox="0 0 41 40" {...props}><rect id="inform" x={0.079} y={0} width={40} height={40} style={{
    fill: "none"
  }} /><path id="path97" d="M10.269,3.2l19.62,0c2.005,0 3.63,1.625 3.63,3.63l-0,26.34c-0,2.005 -1.625,3.63 -3.63,3.63l-19.62,-0c-2.005,-0 -3.63,-1.625 -3.63,-3.63l-0,-26.34c-0,-2.005 1.625,-3.63 3.63,-3.63Z" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path98" d="M13.749,9.92l12.66,-0" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path99" d="M13.749,16.64l12.66,-0" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path100" d="M13.749,23.36l12.66,-0" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /></Icon>
};