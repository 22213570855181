import { Icon } from "@chakra-ui/react";
export const Lock = props => {
  let {color = '#565656'} = props;
  return <Icon  viewBox="0 0 41 40" {...props}><rect id="lock" x={0.079} y={0} width={40} height={40} style={{
    fill: "none"
  }} /><path id="path106" d="M11.589,15.8l16.98,-0c2.733,-0 4.95,2.216 4.95,4.95l-0,10.26c-0,2.734 -2.217,4.95 -4.95,4.95l-16.98,0c-2.734,0 -4.95,-2.216 -4.95,-4.95l-0,-10.26c-0,-2.734 2.216,-4.95 4.95,-4.95Z" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path107" d="M11.679,14.12c-0,-5.57 3.76,-10.08 8.4,-10.08c4.64,-0 8.4,4.51 8.4,10.08" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path111" d="M20.078,30.03l0.001,-2.649" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path112" d="M21.689,25.63c0.889,-0.89 0.889,-2.332 -0,-3.221c-0.889,-0.889 -2.331,-0.889 -3.221,0c-0.889,0.889 -0.889,2.331 0,3.221c0.424,0.433 1.005,0.677 1.611,0.677c0.606,0 1.186,-0.244 1.61,-0.677Z" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /></Icon>
};