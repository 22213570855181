import { Icon } from "@chakra-ui/react";
export const Message = props => {
  let {color = '#565656'} = props;
  return <Icon  viewBox="0 0 41 40" {...props}><rect id="message" x={0.079} y={0} width={40} height={40} style={{
    fill: "none"
  }} /><path id="path173" d="M25.92,27.491l-9.24,-0l-6.54,6.54c-0.312,0.311 -0.78,0.404 -1.187,0.235c-0.407,-0.168 -0.672,-0.565 -0.673,-1.005l0,-7.45c-3.183,-1.98 -5.084,-5.493 -5,-9.24c0,-6.031 4.889,-10.92 10.92,-10.92l11.76,-0c6.031,-0 10.92,4.889 10.92,10.92c0,2.903 -1.156,5.686 -3.212,7.736c-2.057,2.049 -4.845,3.194 -7.748,3.184Z" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path174" d="M16.68,16.571c0,1.391 -1.128,2.52 -2.52,2.52c-1.392,-0 -2.52,-1.129 -2.52,-2.52c0,-1.392 1.128,-2.52 2.52,-2.52c1.392,-0 2.52,1.128 2.52,2.52Z" style={{
    fill: color,
    fillRule: "nonzero"
  }} /><path id="path175" d="M23.4,16.571c0,1.391 -1.128,2.52 -2.52,2.52c-1.392,-0 -2.52,-1.129 -2.52,-2.52c0,-1.392 1.128,-2.52 2.52,-2.52c1.392,-0 2.52,1.128 2.52,2.52Z" style={{
    fill: color,
    fillRule: "nonzero"
  }} /><path id="path176" d="M30.12,16.571c0,1.391 -1.128,2.52 -2.52,2.52c-1.392,-0 -2.52,-1.129 -2.52,-2.52c0,-1.392 1.128,-2.52 2.52,-2.52c1.392,-0 2.52,1.128 2.52,2.52Z" style={{
    fill: color,
    fillRule: "nonzero"
  }} /></Icon>
};