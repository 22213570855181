import {
    Table,
    Thead,
    Tbody,
    Tr,
    TableCaption,
    TableContainer,
    Button,
    HStack,
    Stack,
    Td,
    Text,
    Th,
  } from '@chakra-ui/react';
  import * as React from 'react'

  export const ReportSymptoColabs = (props) => {

    const { symptomatic } = props

    return (
      <TableContainer>
        <Table>
          <TableCaption>Histórico Encuesta Sintomática </TableCaption>
          <Thead>
            <Tr>
              <Th whiteSpace={'break-spaces'}>Centro de Costo</Th>
              <Th whiteSpace={'break-spaces'}>RUT</Th>
              <Th whiteSpace={'break-spaces'}>Nombre Colaborador</Th>
              <Th whiteSpace={'break-spaces'}>Pregunta</Th>
              <Th whiteSpace={'break-spaces'}>Respuesta</Th>
              <Th whiteSpace={'break-spaces'}>Fecha Respuesta</Th>
            </Tr>
          </Thead>
          <Tbody>
          {symptomatic.map((symptomatic,index) => (
            <Tr key={index} align={'center'}>
              <Td>{symptomatic?.Centro}</Td>
              <Td>{symptomatic?.RUT}</Td>
              <Td>{symptomatic?.Nombre}</Td>
              <Td whiteSpace={'break-spaces'}><Text noOfLines={2}>{symptomatic?.Pregunta}</Text></Td>
              <Td>{symptomatic?.Respuesta}</Td>
              <Td>{symptomatic?.Fecha}</Td>
            </Tr>
          ))}
        </Tbody>
        </Table>
      </TableContainer>
    )
  }