/** Imports */
import {
  Box,
  Text,
  Flex,
  Heading,
  HStack,
  Icon,
  Image,
  Link,
  Skeleton,
  Stack,
  FaArrowRight,
  useColorModeValue,
  VStack,
  Container,
  IconButton,
  useDisclosure,
  Drawer,
  Center,
  DrawerContent,
  Button
} from '@chakra-ui/react';
import { useEffect } from 'react';
import { FiMenu } from "react-icons/fi";
import { Outlet } from 'react-router-dom';

import Sidebar from '../components/navigation/Sidebar';
import { Icons } from '../components/templates/Icons';



function TemplatePage() {

  /** Hooks */

  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
  }, [])

  return (
    <Box
      minH="100vh"
      bg={useColorModeValue('gray.100', 'gray.900')}
      >
      <Sidebar
        onClose={() => onClose}
        display={{ base: "none", md: "block" }}
      />

      <Box
        display={{ base: "flex", md: "none" }}
        onClick={onOpen}
        variant="outline"
        aria-label="open menu"
        w="40px"
        h="40px"
        m="2"
        position={'fixed'}
        p="1.5"
        borderRadius="sm"
      >
        <Icons icon="burger" w="25px" h="25px" />
      </Box>

      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}

        >
        <DrawerContent display={{ md: "none" }}>
          <Sidebar onClose={onClose} boxShadow={'2xl'} w="full" />
        </DrawerContent>
      </Drawer>

      <Center>
      <Flex
        className="main"
        ml={{ base: 0, md: '250px', xl: '300px' }}
        mt={{ base: 1, md: 5 }}
        px={{ base: 1, xl: '12', md: '8' }}
        w="full"
        overflow={'auto'}
      >
        <Outlet />
      </Flex>
      </Center>

      {/* <Footer /> */}
    </Box>
  );
}

export default TemplatePage;
