import { Icon } from "@chakra-ui/react";
export const AddCard = props => {
  let {color = '#565656'} = props;
  return <Icon  viewBox="0 0 41 40" {...props}><rect id="add_card" x={0.079} y={0} width={40} height={40} style={{
    fill: "none"
  }} /><path id="path78" d="M29.319,24.2l-0,6.58c0.002,0.484 -0.188,0.948 -0.53,1.29c-0.342,0.342 -0.807,0.533 -1.29,0.53l-21.56,-0c-0.484,0.003 -0.948,-0.188 -1.29,-0.53c-0.342,-0.342 -0.533,-0.806 -0.53,-1.29l-0,-14.84c-0.003,-0.484 0.188,-0.948 0.53,-1.29c0.342,-0.342 0.806,-0.533 1.29,-0.53l13.3,-0" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path79" d="M4.119,20.84l16.8,0" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path80" d="M36.039,15.8c-0,4.639 -3.761,8.4 -8.4,8.4c-4.639,-0 -8.4,-3.761 -8.4,-8.4c-0,-4.639 3.761,-8.4 8.4,-8.4c4.639,0 8.4,3.761 8.4,8.4Z" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path81" d="M27.639,12.44l-0,6.72" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path82" d="M30.999,15.8l-6.72,0" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /></Icon>
};