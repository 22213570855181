import { Icon } from "@chakra-ui/react";
export const Tickets = props => {
  let {color = '#565656'} = props;
  return <Icon  viewBox="0 0 41 40" {...props}><rect id="tickets" x={0.079} y={0} width={40} height={40} style={{
    fill: "none"
  }} /><path d="M37.394,20.466c-2.663,-0 -4.826,-2.163 -4.826,-4.826l-20.962,-0c-0,2.663 -2.163,4.826 -4.826,4.826l-0,8.858c2.663,-0 4.826,2.162 4.826,4.826l20.962,0c0,-2.664 2.163,-4.826 4.826,-4.826l0,-8.858Z" style={{
    fill: "none",
    stroke: color,
    strokeWidth: "3.09px"
  }} /><path id="path21" d="M26.47,24.895c0,2.421 -1.962,4.383 -4.383,4.383c-2.421,0 -4.384,-1.962 -4.384,-4.383c0,-2.421 1.963,-4.384 4.384,-4.384c2.421,0 4.383,1.963 4.383,4.384Z" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path d="M34.627,17.819l-2.292,-8.556c-2.573,0.689 -5.222,-0.84 -5.911,-3.413l-20.248,5.425c0.689,2.573 -0.84,5.222 -3.413,5.911l2.293,8.556" style={{
    fill: "none",
    stroke: color,
    strokeWidth: "3.09px"
  }} /></Icon>
};