import { Button, HStack, Icon, Text, Box, VStack, useDisclosure, Collapse, Spacer, Stack, Link, textDecoration } from '@chakra-ui/react';
import React, { createElement, forwardRef } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { Fragment, Children, cloneElement } from 'react'

import {Accessibility} from '../icons/Accessibility.js';
import {AddCard}       from '../icons/AddCard.js';
import {Agreements}    from '../icons/Agreements.js';
import {Alert}         from '../icons/Alert.js';
import {Apps}          from '../icons/Apps.js';
import {ArrowDown}     from '../icons/ArrowDown.js';
import {ArrowLeft}     from '../icons/ArrowLeft.js';
import {ArrowRight}    from '../icons/ArrowRight.js';
import {ArrowUp}       from '../icons/ArrowUp.js';
import {Arrow}         from '../icons/Arrow.js';
import {Artist}        from '../icons/Artist.js';
import {Atm}           from '../icons/Atm.js';
import {Baby}          from '../icons/Baby.js';
import {Bag}           from '../icons/Bag.js';
import {Balloons}      from '../icons/Balloons.js';
import {Bank}          from '../icons/Bank.js';
import {Benefits}      from '../icons/Benefits.js';
import {Betterfly}     from '../icons/Betterfly.js';
import {Bike}          from '../icons/Bike.js';
import {Bill}          from '../icons/Bill.js';
import {Bluetooth}     from '../icons/Bluetooth.js';
import {Born}          from '../icons/Born.js';
import {Boxes}         from '../icons/Boxes.js';
import {Call}          from '../icons/Call.js';
import {Card}          from '../icons/Card.js';
import {Cards}         from '../icons/Cards.js';
import {Cards2}        from '../icons/Cards2.js';
import {Cellphone}     from '../icons/Cellphone.js';
import {Chart}         from '../icons/Chart.js';
import {Circle}        from '../icons/Circle.js';
import {Circles}       from '../icons/Circles.js';
import {Clip}          from '../icons/Clip.js';
import {Clock}         from '../icons/Clock.js';
import {CmrPuntos}     from '../icons/CmrPuntos.js';
import {Colors}        from '../icons/Colors.js';
import {Compass}       from '../icons/Compass.js';
import {Compensation}  from '../icons/Compensation.js';
import {Configuration} from '../icons/Configuration.js';
import {Connect}       from '../icons/Connect.js';
import {Credential}    from '../icons/Credential.js';
import {Critic}        from '../icons/Critic.js';
import {Dashboad}      from '../icons/Dashboad.js';
import {Dependent}     from '../icons/Dependent.js';
import {Discount}      from '../icons/Discount.js';
import {DollAlert}     from '../icons/DollAlert.js';
import {Doll}          from '../icons/Doll.js';
import {Donate}        from '../icons/Donate.js';
import {Email}         from '../icons/Email.js';
import {Expend}        from '../icons/Expend.js';
import {Faceid}        from '../icons/Faceid.js';
import {Family}        from '../icons/Family.js';
import {Fingerprint}   from '../icons/Fingerprint.js';
import {Fork}          from '../icons/Fork.js';
import {GetMoney}      from '../icons/GetMoney.js';
import {Gift}          from '../icons/Gift.js';
import {Gym}           from '../icons/Gym.js';
import {Healt}         from '../icons/Healt.js';
import {Heart}         from '../icons/Heart.js';
import {Hearth}        from '../icons/Hearth.js';
import {Here}          from '../icons/Here.js';
import {Hierarchy}     from '../icons/Hierarchy.js';
import {Historical}    from '../icons/Historical.js';
import {Home}          from '../icons/Home.js';
import {Inform}        from '../icons/Inform.js';
import {Insurance}     from '../icons/Insurance.js';
import {Key}           from '../icons/Key.js';
import {List}          from '../icons/List.js';
import {Lock}          from '../icons/Lock.js';
import {MessageAlert}  from '../icons/MessageAlert.js';
import {Message}       from '../icons/Message.js';
import {Minus}         from '../icons/Minus.js';
import {Mon}           from '../icons/Mon.js';
import {Money}         from '../icons/Money.js';
import {More}          from '../icons/More.js';
import {Nfc}           from '../icons/Nfc.js';
import {Notification}  from '../icons/Notification.js';
import {Ok}            from '../icons/Ok.js';
import {Other}         from '../icons/Other.js';
import {Paper}         from '../icons/Paper.js';
import {Pay}           from '../icons/Pay.js';
import {Pencil}        from '../icons/Pencil.js';
import {Person}        from '../icons/Person.js';
import {Phone}         from '../icons/Phone.js';
import {Pig}           from '../icons/Pig.js';
import {Plant}         from '../icons/Plant.js';
import {Question}      from '../icons/Question.js';
import {Recognition}   from '../icons/Recognition.js';
import {Rectangle}     from '../icons/Rectangle.js';
import {Restricted}    from '../icons/Restricted.js';
import {SaveMoney}     from '../icons/SaveMoney.js';
import {SendMoney}     from '../icons/SendMoney.js';
import {Shirt}         from '../icons/Shirt.js';
import {Shirts}        from '../icons/Shirts.js';
import {Singout}       from '../icons/Singout.js';
import {Square}        from '../icons/Square.js';
import {Star}          from '../icons/Star.js';
import {Store}         from '../icons/Store.js';
import {Substitute}    from '../icons/Substitute.js';
import {Sun}           from '../icons/Sun.js';
import {Support}       from '../icons/Support.js';
import {Thermometer}   from '../icons/Thermometer.js';
import {Tickets}       from '../icons/Tickets.js';
import {Transfer2}     from '../icons/Transfer2.js';
import {Transfer}      from '../icons/Transfer.js';
import {Travel}        from '../icons/Travel.js';
import {Tree}          from '../icons/Tree.js';
import {Triangle}      from '../icons/Triangle.js';
import {Truck}         from '../icons/Truck.js';
import {Umbrella}      from '../icons/Umbrella.js';
import {Up}            from '../icons/Up.js';
import {Update}        from '../icons/Update.js';
import {User}          from '../icons/User.js';
import {Wallet}        from '../icons/Wallet.js';
import {Warning}       from '../icons/Warning.js';
import {Web}           from '../icons/Web.js';
import {World}         from '../icons/World.js';
import {Component}     from '../icons/Component.js';
import {Black}         from '../icons/Black.js';
import {Pointer}       from '../icons/Pointer.js';
import {Falabella}     from '../icons/Falabella.js';
import {Blank}         from '../icons/Blank.js';
import {Close}         from '../icons/Close.js';
import {Burger}        from '../icons/Burger.js';
import {CI}            from '../icons/CI.js';
import {Show}          from '../icons/Show.js';
import {Hide}          from '../icons/Hide.js';

export const Icons = forwardRef((props, ref) => {

  const components = {
    accessibility: Accessibility,
    add_card     : AddCard,
    agreements   : Agreements,
    alert        : Alert,
    apps         : Apps,
    arrow_down   : ArrowDown,
    arrow_left   : ArrowLeft,
    arrow_right  : ArrowRight,
    arrow_up     : ArrowUp,
    arrow        : Arrow,
    artist       : Artist,
    atm          : Atm,
    baby         : Baby,
    bag          : Bag,
    balloons     : Balloons,
    bank         : Bank,
    benefits     : Benefits,
    betterfly    : Betterfly,
    bike         : Bike,
    bill         : Bill,
    bluetooth    : Bluetooth,
    born         : Born,
    boxes        : Boxes,
    call         : Call,
    card         : Card,
    cards        : Cards,
    cards2       : Cards2,
    cellphone    : Cellphone,
    chart        : Chart,
    circle       : Circle,
    circles      : Circles,
    clip         : Clip,
    clock        : Clock,
    cmr_puntos   : CmrPuntos,
    colors       : Colors,
    compass      : Compass,
    compensation : Compensation,
    configuration: Configuration,
    connect      : Connect,
    credential   : Credential,
    critic       : Critic,
    dashboad     : Dashboad,
    dependent    : Dependent,
    discount     : Discount,
    doll_alert   : DollAlert,
    doll         : Doll,
    donate       : Donate,
    email        : Email,
    expend       : Expend,
    faceid       : Faceid,
    family       : Family,
    fingerprint  : Fingerprint,
    fork         : Fork,
    get_money    : GetMoney,
    gift         : Gift,
    gym          : Gym,
    healt        : Healt,
    heart        : Heart,
    hearth       : Hearth,
    here         : Here,
    hierarchy    : Hierarchy,
    historical   : Historical,
    home         : Home,
    inform       : Inform,
    insurance    : Insurance,
    key          : Key,
    list         : List,
    lock         : Lock,
    message_alert: MessageAlert,
    message      : Message,
    minus        : Minus,
    mon          : Mon,
    money        : Money,
    more         : More,
    nfc          : Nfc,
    notification : Notification,
    ok           : Ok,
    other        : Other,
    paper        : Paper,
    pay          : Pay,
    pencil       : Pencil,
    person       : Person,
    phone        : Phone,
    pig          : Pig,
    plant        : Plant,
    question     : Question,
    recognition  : Recognition,
    rectangle    : Rectangle,
    restricted   : Restricted,
    save_money   : SaveMoney,
    send_money   : SendMoney,
    shirt        : Shirt,
    shirts       : Shirts,
    singout      : Singout,
    square       : Square,
    star         : Star,
    store        : Store,
    substitute   : Substitute,
    sun          : Sun,
    support      : Support,
    thermometer  : Thermometer,
    tickets      : Tickets,
    transfer_2   : Transfer2,
    transfer     : Transfer,
    travel       : Travel,
    tree         : Tree,
    triangle     : Triangle,
    truck        : Truck,
    umbrella     : Umbrella,
    up           : Up,
    update       : Update,
    user         : User,
    wallet       : Wallet,
    warning      : Warning,
    web          : Web,
    world        : World,
    component    : Component,
    black        : Black,
    pointer      : Pointer,
    falabella    : Falabella,
    blank        : Blank,
    close        : Close,
    burger       : Burger,
    ci           : CI,
    show         : Show,
    hide         : Hide,
  };

  if(components.hasOwnProperty(props.icon)) {
    let Icons = components[props.icon];
    return <Icons {...props} />;
  } else {
    let Icons = components['arrow'];
    return <Icons {...props} />;
  }
});