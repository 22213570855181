/** Imports */
import {
  Box,
  Grid,
  GridItem,
  Heading
} from '@chakra-ui/react';
import { useErrors } from '../../helpers/integer';
import { Loader } from '../../components/Loader';
import { Page } from '../../components/templates/Page';
import { Title } from '../../components/templates/Title';
// import { analytics } from 'firebase-functions/v1';
// import { logEvent } from 'firebase/analytics';

const UniformsPage = () => {

  /** Hooks */
  const showErrors = useErrors();

  return (
    <Page title="Uniformes">
    </Page>
  );
}

export default UniformsPage;
