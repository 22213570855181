import { Grid, SimpleGrid, SimpleGridProps } from '@chakra-ui/react';
import * as React from 'react'

export const ProductGrid = (props) => {
  return (
    <Grid
      className="ProductGrid"
      templateColumns={ {base: '1', sm:'repeat(1, 1fr)',  md:'repeat(2, 1fr)', xl:'repeat(3, 1fr)'} }
      columnGap={6}
      gap={6}
      {...props}
    />
  )
}