import { extendTheme, withDefaultColorScheme } from '@chakra-ui/react';
// import { theme } from '@chakra-ui/pro-theme'

const grayColor = '#3f3f3f';
const config = {
  initialColorMode: 'light',
  useSystemColorMode: false,
  focusBorderColor: '#4b9b2c',
  
  fonts: {
    heading: 'pfbeausans, sans-serif',
    body: 'pfbeausans, sans-serif',
    code: 'source-code-pro, Menlo, Monaco, Consolas, Courier New'
  },

  styles: {
    global: {
      body: {
        bg: 'white',
        color: grayColor,
      },
      
      p:{
        color: grayColor,
      },

      input: {
        bg: 'white',
        color: grayColor,
        _disabled: {
          bg: 'gray.300',
          color:grayColor,
        },
      },

      '.content ul': {
        // listStyleType: 'none',
      },

      '.content li': {
        // listStyleType: 'none',
        marginLeft: '5',
      },

      a: {
        // color: 'green.500',
        px: 1,
        // _hover: {
        //   color: 'green.600',
        // },
        borderRadius: 'md',
        textDecoration: 'underline',
      },

      'a[href$=".pdf"]': {
        mr: 7,
        _after: {
          position: 'absolute',
          content: '".PDF"',
          fontSize: '0.6em',
          color: 'white',
          bg: '#ef044e',
          px: '1',
          py: '0.5',
          mx: '1',
          borderRadius: 'md',
          // _hover: {
          //   textDecoration: 'none',
          // },
        },
      },

      '.tag': {
        color: 'green.500',
        px: 1,
        backgroundColor: '#2B86C5',
        backgroundImage: 'linear-gradient(225deg, #ef044e 0%, #784BA0 50%, #2B86C5 100%)',
        webkitBackgroundClip: 'text',
        backgroundClip: 'text',
        borderRadius: 'md',
      },

      table: {
        // position: 'relative',
        display: 'table',
        margin: '0 auto',

        thead: {
          bg: 'brand.50',
          color: 'white'
        },

        th: {
          _first: {
            pl: 4,
            borderTopLeftRadius: 'md',
          },
          _last: {
            pr: 4,
            borderTopRightRadius: 'md',
          },
          whiteSpace: 'break-spaces',
          color: 'white !important',
          fontSize: 'sm',
          fontWeight: 'normal',
          textTransform: 'uppercase',
          letterSpacing: 'wider',
          textAlign: 'left',
          borderBottom: '1px solid',
          borderColor: 'gray.200',
          padding: '0.5rem',
          bg: 'brand.500',
          _hover: {
            bg: 'brand.600',
          }
        },

        tr: {
          transition: 'background 0.3s',
          whiteSpace: 'break-spaces',
          _even: {
            bg: 'gray.100',
            _hover: {
              bg: 'brand.10',
            },
            td: {
              transition: 'background 0.2s',
              // _hover: {
              //   bg: 'brand.200',
              // }
            }
          },

          _odd: {
            bg: 'white',
            _hover: {
              bg: 'brand.10',
            },
            td: {
              transition: 'background 0.2s',
              // _hover: {
              //   bg: 'brand.200',
              // }
            }
          },

          _hover: {
            bg: 'brand.50',
          },

          td: {
            color: grayColor,
            fontSize: 'sm',
            fontWeight: 'normal',
            lineHeight: 'short',
            px: 4,
            py: 2,
            _first: {
              pl: 4,
            },
            _last: {
              pr: 4,
            },
            padding: '0.5rem',
          },
        },
        tfoot: {
          tr: {
            td: {
              bg: grayColor,
              _first: {
                pl: 4,
                borderBottomLeftRadius: 'md',
              },
              _last: {
                pr: 4,
                borderBottomRightRadius: 'md',
              },
              // _hover: {
              //   bg: 'gray.350 !important',
              // },
            }
          }
        }
      },
    },
  },

  colors: {
    brand: {
      10: '#e5f4df',
      50: '#D2E1D0',
      100: '#e1ebdc',
      200: '#c3d7bb',
      300: '#c7e3c4',
      400: '#c7e3c4',
      500: '#56AF31',
      600: '#4e9e2e',
      700: '#56AF31',
      800: '#56AF31',
      900: '#56AF31',
    },
    gray: {
      50: '#f9fafb',
      100: '#f4f5f7',
      200: '#e5e7eb',
      300: '#d2d6dc',
      350: '#c5cad1',
      400: '#9fa6b2',
      500: '#6b7280',
      600: '#4b5563',
      700: '#374151',
      800: '#1f2937',
      900: '#111827',
    },
  }
}

const custom_theme = extendTheme({
  ...config,
  components: {
    Link: {
      baseStyle: {
        // color: 'brand.500',
        textDecoration: 'underline',
        _hover: {
          // color: 'brand.600',
        },
      },
    },
    Popover: {
      baseStyle: {
        content: {
          bg: '#ef044e',
          color: '#ffffff',
          borderRadius: 'md',
          boxShadow: 'md',
          p: 2,
        },
        header: {
          fontSize: 'lg',
          fontWeight: 'bold',
        },
        body: {
          color: '#ffffff',
        },
      },
    },
    Boxes: {
      baseStyle: {
        bg: 'brand.50',
        color: 'brand.500',
        borderRadius: 'md',
        p: 4,
        mb: 4,
        title: {
          fontSize: '18',
          fontWeight: 'bold',
          color: '#56AF31',
        },
      },
    },
    Accordion: {
      baseStyle: {
        container: {
          border: '1px solid #fff',
          bg: 'brand.100',
          color: 'blue.800',
        },
        button: {
          color: grayColor,
          _hover: {
            bg: 'brand.200',
          },
          _focus: {
            bg: 'brand.100',
          },
          _expanded: {
            bg: 'brand.500',
            color: 'white'
          },
        },
        panel: {
          color: grayColor,
          bg: 'gray.50',
          _expanded: {
            bg: 'gray.50',
          },
        },
      },
    },

    Progress: {
      baseStyle: {
        track: {
          stroke: 'gray.300',
        },
        filledTrack: {
          backgroundColor: 'brand.500',
        },
        label: {
          color: grayColor,
          fontSize: 'sm',
          fontWeight: 'medium',
        },
        defaultProps: {},
      },
    },

    Alert: {
      status: {
        info: {
          color: 'blue.100',
          bg: 'blue.800',
          icon: 'info',
        },
        warning: {
          color: 'yellow.50',
          bg: 'yellow.500',
        },
        success: {
          color: 'white',
          bg: 'brand.500',
        },
        error: {
          color: 'red.50',
          bg: 'red.500',
        }
      },
      variants: {
        solid: (x) => {
          return {container: {
            bg: x.theme.components.Alert.status[x.status].bg,
            color: x.theme.components.Alert.status[x.status].color,
          }}
        },
      },
    },

    Table: {
      variants: {
        simple: {
          th: {
            bg: 'transparent',
            color: '#3f3f3f !important',
            _hover: {
              bg: 'transparent',
            },
            _first: {
              borderRadius: 0,
            },
            _last: {
              borderRadius: 0,
            },
          },
          tbody: {
            tr: {
              td: {
                p: 2,
              },
            },
          },
        },
      },
    },

    Button: {
      baseStyle: {
        fontWeight: 'bold',
        borderRadius: 'md',
        _focus: {
          boxShadow: 'none',
        },
      },
      variants: {
        'solid': {
          bg: 'brand.500',
          color: 'white',
          _hover: {
            bg: 'brand.600',
          },
          _active: {
            bg: 'brand.700',
          },
        },
        'outline': {
          border: '1px',
          borderColor: 'brand.500',
          color: 'brand.500',
          _hover: {
            bg: 'brand.50',
          },
          _active: {
            bg: 'brand.100',
          },
        },
      },
      sizes: {
        'sm': {
          fontSize: 'sm',
          px: 4,
          py: 2,
        },
        'md': {
          fontSize: 'md',
          px: 6,
          py: 3,
        },
        'lg': {
          fontSize: 'lg',
          px: 8,
          py: 4,
        },
      },
      defaultProps: {
        size: 'md',
        variant: 'solid',
      },
    }
  }
})

export default custom_theme