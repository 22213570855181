import { Icon } from "@chakra-ui/react";
export const Hide = props => {
  let {color = '#565656'} = props;
  return <Icon  viewBox="0 0 40 41" {...props}><rect id="hide" x={0} y={0.804} width={40} height={40} style={{
    fill: "none"
  }} /><path id="path52" d="M3.419,20.085c10.341,5.523 22.755,5.523 33.096,0" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke:color,
    strokeWidth: "3.36px"
  }} /><path d="M19.967,24.227l-0,6.641" style={{
    fill: "none",
    stroke:color,
    strokeWidth: "3.36px"
  }} /><path d="M29.599,23.507l2.802,4.854" style={{
    fill: "none",
    stroke:color,
    strokeWidth: "3.36px"
  }} /><path d="M10.401,23.507l-2.802,4.854" style={{
    fill: "none",
    stroke:color,
    strokeWidth: "3.36px"
  }} /></Icon>
};