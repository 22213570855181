class TokenService {

  getLocalRefreshToken() {
    const user = JSON.parse(localStorage.getItem('user'));
    return user?.accessToken;
  }

  getLocalAccessToken() {
    const user = JSON.parse(localStorage.getItem('user'));
    return user;
  }
  
  getTokenStatus() {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user != null) {
      return true;
    } else {
      return false;
    }
  }

  updateLocalAccessToken(token) {
    let user = JSON.parse(localStorage.getItem('user'));
    user.accessToken = token;
    localStorage.setItem('user', JSON.stringify(user));
  }

  getUser() {
    return JSON.parse(localStorage.getItem('user'));
  }
  
  getData(data) {
    return JSON.parse(localStorage.getItem(data));
  }

  setUser(user) {
    localStorage.setItem('user', JSON.stringify(user));
  }
  setPassword(password) {
    localStorage.setItem('password', JSON.stringify(password));
  }
  setData(data) {
    localStorage.setItem('data', JSON.stringify(data));
  }

  removeUser() {
    localStorage.removeItem('user');
  }
  removeData(data) {
    localStorage.removeItem(data);
  }

  setProfiling(profiling) {
    localStorage.setItem('profiling', JSON.stringify(profiling));
  }

  removeProfiling() {
    localStorage.removeItem('profiling');
  }
}

export default new TokenService();