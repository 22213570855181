/** Imports */
import {
  Alert,
  Button,
  HStack,
  Link,
  ListItem,
  Table,
  Thead,
  Tbody,
  Tr,
  TableCaption,
  TableContainer,
  Th,
  Td,
  SimpleGrid,
  Stack,
  Text,
  UnorderedList,
  useToast,
  AlertDescription
} from '@chakra-ui/react';
import { useState, useContext } from 'react';
import { useForm }              from 'react-hook-form';
import { Page }                 from '../../components/templates/Page';
import { Loader }               from '../../components/Loader';
import { InputField }           from '../../components/input/InputField';
import { GlobalDataContext }    from '../../components/context/GlobalData';
import { Success }              from '../../components/Success';
import { Legend }               from '../../components/templates/Legend';
import { Message }              from '../../components/templates/Message';
import { trim }                 from 'lodash';
// import { analytics }         from 'firebase-functions/v1';
// import { logEvent }          from 'firebase/analytics';

// Helpers
import api                      from "../../helpers/api";
import { useErrors }            from '../../helpers/integer';
import { formatRut, validateEmail, validateRut } from '../../helpers/integer';



const ScholarshipInsurancePage = () => {

  /** Hooks */
  const showErrors                          = useErrors();
  const [globalData, setGlobalData]         = useContext(GlobalDataContext);
  const [success, setSuccess]               = useState(false);
  const [dataSuccess, setdataSuccess]       = useState({});
  const [dataApplicants, setDataApplicants] = useState([]);
  const toast                               = useToast();
  let notes           = false;
  let education       = false;
  let socialRegistry  = false;
  let familyBurden    = false;

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    isDirty,
    control,
    setValue,
    watch
  } = useForm({});

  //Watch functions
  const rut                   = trim(watch('rut'));
  const name                  = trim(watch('name'));
  const lastname              = trim(watch('lastname'));
  const level_study           = trim(watch('level_study'));
  const relationship          = trim(watch('relationship'));
  const califications_average = trim(watch('califications_average'));
  const first_file            = watch('EXTRA.Scholarships.arr_files[0]',0);
  const second_file           = watch('EXTRA.Scholarships.arr_files[1]',0);
  const third_file            = watch('EXTRA.Scholarships.arr_files[2]',0);
  const fourth_file           = watch('EXTRA.Scholarships.arr_files[3]',0);

  //Agregar elementos a un array
  const onAddApplicants = async () => {
    if(rut, name, lastname, level_study, relationship, califications_average !== undefined ){
      if(rut.length > 0 && name.length > 0 && lastname.length > 0 && level_study.length > 0 && relationship.length > 0 && califications_average.length > 0){
        setDataApplicants(dataApplicants => [
          ...dataApplicants,
          {rut:formatRut(rut,2), name:name, lastname:lastname, level_study:level_study, relationship:relationship, califications_average:califications_average}
        ])
      }
      else{
        toast({
          title: 'Formulario de postulante incompleto',
          status: 'warning',
          duration: 3000,
          isClosable: true,
        })
      }
    }else{
      toast({
        title: 'Formulario de postulante incompleto',
        status: 'warning',
        duration: 3000,
        isClosable: true,
      })
    }
  }

  //Eliminar elementos a un array
  const onDeleteApplicants = async (data) => {
    let new_arr = dataApplicants.filter(element => element?.rut !== data);
    setDataApplicants(new_arr);
  }

   /**
   * Enviar encuesta con la información contestada
   */
  const onSubmit = async (data) => {
    data.Incident       = {};
    data.contact        = {};
    data.contact.Name   = {};
    data.contact.Emails = {};

    data.contact.Login      = globalData?.contact?.Login;
    data.contact.Emails[0]  = globalData?.contact?.Emails[0];
    data.contact.Name.First = globalData?.contact?.Name?.First;
    data.contact.Name.Last  = globalData?.contact?.Name?.Last;

    data.EXTRA.Scholarships.number_of_participant = dataApplicants.length
    data.EXTRA.Scholarships.persons               =  dataApplicants;
    data.EXTRA.Scholarships.family_vulnerability_percentag = '0'
    data.Incident.product    = {};
    data.Incident.product.id = 25;

    data.Incident.subject = {};
    data.Incident.subject = "Beca Hijos de Funcionarios";

    data.Incident.category    = {};
    data.Incident.category.id = 106;


    try {
      if(dataApplicants.length !== 0){
        const response = await api.post('IncidentServices/createIncident', data);

        if(response.data.result === true){
          toast({
            title: response.data.response.message,
            status: 'success',
            duration: 3000,
            isClosable: true,
          })
        } else {
          toast({
            title: response.data.response.message,
            status: 'warning',
            duration: 3000,
            isClosable: true,
          })
        }
      }else{
        toast({
          title: "Debe añadir al menos un postulante",
          status: 'warning',
          duration: 3000,
          isClosable: true,
        })
      }
    } catch (err) {
      console.log(err);
    }
  }


  if(!globalData?.contact) {
    return <Loader />;
  }

  if(first_file !== 0 && first_file !== undefined && first_file.length  !== 0 ) {
    notes = true
  }else{
    notes = false
  }
  if(second_file !== 0 && second_file !== undefined && second_file.length  !== 0 ) {
    education = true
  }
  else{
    education = false
  }
  if(third_file  !== 0 && third_file !== undefined && third_file.length  !== 0  ) {
    socialRegistry = true
  }
  else{
    socialRegistry = false
  }
  if(fourth_file  !== 0 && fourth_file !== undefined && fourth_file.length  !== 0 ) {
    familyBurden = true
  }
  else{
    familyBurden = false
  }

  if(success) {
    return <Success dataSuccess={dataSuccess?.ref_number}  message={'Solicitud creada exitosamente'}/>;
  }

  return (
    <Page title="Beca estímulo escolar" description ={
      <Stack>
        <Text>Conoce los requisitos de postulación <Link href="https://vivetusbeneficios.custhelp.com/euf/assets/files/becas/Becas_seguros.pdf" isExternal>aquí</Link> y sigue los siguientes pasos:</Text>
      </Stack>
    }>
      <Message>
        <Stack w={'full'} textAlign={'left'}>
          <UnorderedList>
            <ListItem>Ingresa toda la información solicitada en el formulario.</ListItem>
            <ListItem>Adjunta los antecedentes requeridos :
              <UnorderedList>
                <ListItem>Concentración de notas del año anterior (2023)</ListItem>
                <ListItem>Certificado de alumno regular del año en curso (2024)</ListItem>
                <ListItem>Certificado rendimiento Escuela Especial en caso que corresponda</ListItem>
                <ListItem>Registro Social de Hogares (documento voluntario)</ListItem>
              </UnorderedList>
            </ListItem>
            <ListItem>Si necesitas agregar más de un hijo debes pinchar el botón “agregar postulante” al final del formulario y repite los pasos.</ListItem>
            <ListItem>Cada colaborador podrá adjudicarse solo una Beca, independiente de la cantidad de postulaciones de hijos que realice.</ListItem>
          </UnorderedList>
          <Stack w={'full'} textAlign={'left'}>
          <HStack>
            <Text>El formulario de inscripción estará disponible hasta el </Text><Text fontWeight={'bold'}>22 de marzo.</Text>
          </HStack>
            <Text>
              Los resultados de las postulaciones serán informados en el mes de abril.
            </Text>
            <Text>
              El beneficio será cancelado en la remuneración de abril 2024.
            </Text>
            <Text>
              Si tienes dudas contacta al equipo de Beneficios:
            </Text>
            <Text>
              <HStack>
                <Text>Geraldine Pulgar:</Text><Text fontWeight={'bold'}>gpulgart@bancofalabella.cl</Text>
              </HStack>
            </Text>
          </Stack>
        </Stack>
      </Message>
      <Alert status='error'>
        <AlertDescription>Una vez enviada la solicitud esta no podrá ser modificada.</AlertDescription>
      </Alert>
    <form onSubmit={handleSubmit(onSubmit)} autoComplete={"off"}>
      <Legend>Datos colaborador</Legend>
        <SimpleGrid columns={{ base: '1', lg: '2', xl: '3' }} spacing={4} p={5}>
          <InputField disabled defaultValue={globalData?.contact?.Name?.First} label="Nombre"  />
          <InputField disabled defaultValue={globalData?.contact?.Name?.Last} label="Apellido"  />
          <InputField disabled defaultValue={globalData?.contact?.Emails[0]} label="Correo electrónico"  />
          <InputField disabled defaultValue={globalData?.contact?.CustomFields?.CO?.Departamento?.LookupName} label="Sucursal"  />
          <InputField disabled defaultValue={globalData?.contact?.CustomFields?.CO?.Empresa_interna.LookupName} label="Razón social"  />
        </SimpleGrid>

        <Legend>Lista de Postulantes</Legend>
        <Message>
          <Text w={'full'} textAlign={'left'}>Lista de hijos que están postulando, agrega un hijo en la sección "Agregar Hijo Postulante".</Text> 
        </Message>
        <TableContainer>
          <Table>
            <TableCaption>Postulantes</TableCaption>
            <Thead>
              <Tr>
                <Th>RUT</Th>
                <Th>Nombre</Th>
                <Th>Apellidos</Th>
                <Th>Promedio notas</Th>
                <Th>Remover</Th>
              </Tr>
            </Thead>
            <Tbody>
              {dataApplicants?.map((applicant, index) => (
                <Tr key={index}>
                  <Td>{applicant?.rut}</Td>
                  <Td>{applicant?.name}</Td>
                  <Td>{applicant?.lastname}</Td>
                  <Td>{applicant?.califications_average}</Td>
                  <Td><Button size='sm' type="button" onClick={() => onDeleteApplicants(applicant?.rut)}> x </Button></Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
        <Legend>Agregar Hijo Postulante</Legend>
        <Message>
          <Text w={'full'} textAlign={'left'}>En esta sección debes incorporar la información de cada uno de tus hijos postulantes, uno a uno y agregándolo con el botón "Agregar Hijo".</Text>
        </Message>
        <SimpleGrid columns={{ base: '1', lg: '2', xl: '3' }} spacing={4} p={5}>
          <InputField label="RUT" name="rut" type="text" placeholder="" register={register} errors={errors} control={control} />
          <InputField label="Nombre" name="name" type="text" placeholder="" register={register} errors={errors} control={control} />
          <InputField label="Apellidos" name="lastname" type="text" placeholder="" register={register} errors={errors}  control={control} />
          <InputField label="Nivel de estudios" name="level_study" type="select" placeholder="" register={register} errors={errors}  control={control} options={
          globalData?.CustomMenus?.CO?.level_study
          } />
          <InputField label="Parentesco" name="relationship" type="select" register={register} errors={errors} control={control} options=
             {
            [
              {label: "Hijo/a"  , value  : "2"},
              {label: "Otro"    , value  : "3"},
            ]
          }/>
          <InputField label="Promedio notas" name="califications_average" type="text" placeholder="" register={register} errors={errors} control={control} />
        </SimpleGrid>
        <Stack maxW={'200'} py={'6'}>
          <Button
            type="button"
            onClick={onAddApplicants}
          >
            Agregar postulante
          </Button>
        </Stack>

        <Legend>Adjuntos</Legend>
        <Message>
          <Text w={'full'} textAlign={'left'}>Adjuntar solo documentos PDF*</Text>
        </Message>

        <SimpleGrid columns={{ base: '1', lg: '2', xl: '4' }} spacing={1} p={2}>
          <InputField  name="EXTRA.Scholarships.arr_files[0]" label="Concentración de Notas (año anterior) *"          type="file" required={true} register={register}  errors={errors} control={control} setValue={setValue} acceptedFileTypes={['application/pdf']} />
          <InputField  name="EXTRA.Scholarships.arr_files[1]" label="Evidencia Incorporación Educación (año actual) *" type="file" required={true} register={register} errors={errors} control={control} setValue={setValue} acceptedFileTypes={['application/pdf']} />
          <InputField  name="EXTRA.Scholarships.arr_files[2]" label="En caso del postulante no ser hijo: Certificado de carga familiar emitido por la Caja de Compensación. *" type="file" required={false} register={register} errors={errors} control={control} setValue={setValue} acceptedFileTypes={['application/pdf']} />
        </SimpleGrid>

        <Stack maxW={'200'} py={'6'}>
          <Button
            type="submit"
            isLoading={isSubmitting}
            loadingText="Solicitando..."
            disabled={isSubmitting}
          >
            Enviar solicitud
          </Button>
        </Stack>
      </form>
    </Page>
  );
}

export default ScholarshipInsurancePage;