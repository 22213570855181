/** Imports */
import {
    Box,
    Grid,
    Heading,
    Stack,
    Text,
    useToast,
    Link
  } from '@chakra-ui/react';
import { useErrors } from '../../helpers/integer';
import { useContext, useState } from 'react';
import api from "../../helpers/api";
import { useEffect } from 'react';
import { Page } from '../../components/templates/Page';
import { ReportGrid } from '../../components/ReportGrid';
import { Loader } from '../../components/Loader';
import { GiftCard } from '../../components/giftcard/GiftCard';
import { Title } from '../../components/templates/Title';
import { GlobalDataContext } from '../../components/context/GlobalData';
// import { analytics } from 'firebase-functions/v1';
// import { logEvent } from 'firebase/analytics';

const GiftCardsPage = () => {

  /** Hooks */
  const showErrors                  = useErrors();
  const [data, setData]             = useState([]);
  const [dataStatus, setDataStatus] = useState(false);
  const [globalData, setGlobalData] = useContext(GlobalDataContext);
  const toast                       = useToast();

  const url_service = 'GiftCardServices/getReportGiftCard';

  const request = {
    'report_id': 101160,
    'filter_name': '',
    'filter_value': ''
  };

  // Mejorar
  /**
   * Reporte
   */
  const getReportGiftcards = async () => {

    try {
      const response = await api.post(url_service, request)
      if(response?.data?.result === true) {
        setData(response?.data?.response?.data);
        setDataStatus(true);
      }
      else{
        setDataStatus(true);
        toast({
          title: 'No tienes Gift Cards disponibles',
          status: 'warning',
          duration: 3000,
          isClosable: true,
        })
      }
    } catch (err) {
      showErrors(err);
    }
  }

  useEffect(() => {
    getReportGiftcards();
  }, []);

  if (dataStatus === false) {
    return <Loader />;
  }

  return (
    <>
      <Page
        title="Mis Gift Card disponibles"
        color={'transparent'}
        p={null}
        description={'Si necesitas saber el saldo de tu Gift Card, agrégala como medio de pago en falabella.com o consultando directamente en cajas de tiendas Falabella'}

      >{data?.length > 0
        ?
          <Grid
            py={5}
            templateColumns={{ base: '1', md: 'repeat(1, 1fr)', xl: 'repeat(2, 1fr)'}}
            justifyItems={''}
            gap={6}
          >
            {data?.map((giftCard, index) => (
              <GiftCard key={index} giftCard={giftCard}/>
            ))}
            {/* {data?.map((giftCard, index) => (
              <GiftCard key={index} giftCard={giftCard}/>
            ))} */}
          </Grid>
        :
          <Text>No tienes Gift Card disponibles</Text>
      }
      </Page>
    </>
  );
}

export default GiftCardsPage;