import { Icon } from "@chakra-ui/react";
export const Dashboad = props => {
  let {color = '#565656'} = props;
  return <Icon  viewBox="0 0 41 40" {...props}><rect id="dashboad" x={0.079} y={0} width={40} height={40} style={{
    fill: "none"
  }} /><path id="path142" d="M35.199,20c-0,2.784 -2.257,5.04 -5.04,5.04c-2.784,0 -5.04,-2.256 -5.04,-5.04c-0,-2.784 2.256,-5.04 5.04,-5.04c2.783,0 5.04,2.256 5.04,5.04Z" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path143" d="M23.439,35.12l-0,-3.36c-0,-3.711 3.008,-6.72 6.72,-6.72c3.711,0 6.72,3.009 6.72,6.72l-0,3.36" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path227" d="M18.399,16.64l-0,8.4" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path228" d="M13.359,13.28l-0,11.76" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path229" d="M8.319,18.32l-0,6.72" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path230" d="M18.399,30.08l-11.85,0c-1.806,0 -3.27,-1.464 -3.27,-3.27l-0,-18.66c-0,-1.806 1.464,-3.27 3.27,-3.27l27.06,0c0.868,-0.003 1.701,0.341 2.315,0.955c0.614,0.614 0.957,1.447 0.955,2.315l-0,3.45" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /></Icon>
};