import {
    Table,
    Thead,
    Tbody,
    Tr,
    TableCaption,
    TableContainer,
    Button,
    HStack,
    Th,
    Td,
  } from '@chakra-ui/react';
  import * as React from 'react'

  export const ReportSymptoPositive = (props) => {

    const { symptomatic } = props

    return (
      <TableContainer>
        <Table >
          <TableCaption>Histórico Encuesta Sintomática </TableCaption>
          <Thead>
            <Tr>
              <Th>Centro de Costo</Th>
              <Th>RUT</Th>
              <Th>Nombre Colaborador</Th>
              <Th>Pregunta Sí/No</Th>
              <Th>Respuesta</Th>
              <Th>Fecha Respuesta</Th>
              <Th>Telefono Contacto Emergencia</Th>
            </Tr>
          </Thead>
          <Tbody>
          {symptomatic.map((symptomatic,index) => (
            <Tr key={index} align={'center'}>
              <Td>{symptomatic?.Centro}</Td>
              <Td>{symptomatic?.RUT}</Td>
              <Td>{symptomatic?.Nombre}</Td>
              <Td>{symptomatic?.Pregunta}</Td>
              <Td>{symptomatic?.Respuesta}</Td>
              <Td>{symptomatic?.Fecha}</Td>
              <Td>{symptomatic?.Telefono_Emergencia}</Td>
            </Tr>
          ))}
        </Tbody>
        </Table>
      </TableContainer>
    )
  }