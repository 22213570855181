import { Icon } from "@chakra-ui/react";
export const Bank = props => {
  let {color = '#565656'} = props;
  return <Icon  viewBox="0 0 41 40" {...props}><rect id="bank" x={0.079} y={0} width={40} height={40} style={{
    fill: "none"
  }} /><path id="path211" d="M3.279,11.6l33.6,0l-16.8,-8.4l-16.8,8.4Z" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path212" d="M9.999,16.64l-0,10.08" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path213" d="M20.079,16.64l-0,10.08" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path214" d="M30.159,16.64l-0,10.08" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path215" d="M6.639,31.76l26.88,-0" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path216" d="M3.279,36.8l33.6,0" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /></Icon>
};