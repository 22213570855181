import { Icon } from "@chakra-ui/react";
export const Insurance = props => {
  let {color = '#565656'} = props;
  return <Icon  viewBox="0 0 41 40" {...props}><rect id="insurance" x={0.079} y={0} width={40} height={40} style={{
    fill: "none"
  }} /><path id="path289" d="M20.292,14.463c-1.774,0.007 -3.471,0.724 -4.713,1.99c-1.242,1.266 -1.926,2.976 -1.9,4.75c0,4.711 5.974,10.557 9.394,13.707c1.352,1.351 3.543,1.351 4.895,-0c3.406,-3.15 9.38,-8.996 9.38,-13.707c0.03,-1.775 -0.653,-3.487 -1.896,-4.754c-1.242,-1.267 -2.942,-1.983 -4.717,-1.986c-2.149,-0.203 -4.194,0.967 -5.108,2.923c-1.062,-1.926 -3.141,-3.065 -5.335,-2.923Z" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path279" d="M20.079,12.44c-0,4.639 -3.761,8.4 -8.4,8.4c-4.639,-0 -8.4,-3.761 -8.4,-8.4c-0,-4.639 3.761,-8.4 8.4,-8.4c4.639,-0 8.4,3.761 8.4,8.4Z" style={{
    fill: "#fff",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path280" d="M11.679,9.08l-0,6.72" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path281" d="M15.039,12.44l-6.72,-0" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /></Icon>
};