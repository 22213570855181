/** Imports */
import {
  Box,
  Grid,
  Heading
} from '@chakra-ui/react';
import { useErrors } from '../../helpers/integer';
import { useState } from 'react';
import api from "../../helpers/api";
import { useEffect } from 'react';
import { Page } from '../../components/templates/Page';
import { Title } from '../../components/templates/Title';
import { Loader } from '../../components/Loader';
import Procedures from '../../components/procedures/Procedures';
// import { analytics } from 'firebase-functions/v1';
// import { logEvent } from 'firebase/analytics';

const ProceduresPage = () => {

  /** Hooks */
  const showErrors = useErrors();

  useEffect(() => {
  }, [])

  return (
    <Page title="Solicitudes en línea" color="transparent" description={'En esta sección revisa tus anticipos solicitados y el historial de anexos firmados. Si eres jefe un área podrás delegar un reemplazo durante tu ausencia para aprobar solicitudes de tiempo libre.'}>
      <Procedures />
    </Page>
  );
}

export default ProceduresPage;