/** Imports */
import {
  Alert,
  Button,
  HStack,
  Link,
  ListItem,
  Table,
  Thead,
  Tbody,
  Tr,
  TableCaption,
  TableContainer,
  Th,
  Td,
  SimpleGrid,
  Stack,
  Text,
  UnorderedList,
  useToast,
  AlertDescription
} from '@chakra-ui/react';
import { useState, useContext } from 'react';
import { useForm }              from 'react-hook-form';
import { Page }                 from '../../components/templates/Page';
import { Loader }               from '../../components/Loader';
import { InputField }           from '../../components/input/InputField';
import { GlobalDataContext }    from '../../components/context/GlobalData';
import { Success }              from '../../components/Success';
import { Legend }               from '../../components/templates/Legend';
import { Message }              from '../../components/templates/Message';
import { trim }                 from 'lodash';
// import { analytics }         from 'firebase-functions/v1';
// import { logEvent }          from 'firebase/analytics';

// Helpers
import api                      from "../../helpers/api";
import { useErrors }            from '../../helpers/integer';
import { formatRut, validateEmail, validateRut } from '../../helpers/integer';



const SchoolBonusPage = () => {

  /** Hooks */
  const showErrors                          = useErrors();
  const [globalData, setGlobalData]         = useContext(GlobalDataContext);
  const [success, setSuccess]               = useState(false);
  const [dataSuccess, setdataSuccess]       = useState({});
  const [dataApplicants, setDataApplicants] = useState([]);
  const toast                               = useToast();
  let notes           = false;
  let education       = false;
  let socialRegistry  = false;
  let familyBurden    = false;

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    isDirty,
    control,
    setValue,
    watch
  } = useForm({});

  //Watch functions
  const rut                   = trim(watch('rut'));
  const name                  = trim(watch('name'));
  const lastname              = trim(watch('lastname'));
  const level_study           = trim(watch('level_study'));
  const relationship          = trim(watch('relationship'));
  const califications_average = trim(watch('califications_average'));
  const first_file            = watch('EXTRA.Scholarships.arr_files[0]',0);
  const second_file           = watch('EXTRA.Scholarships.arr_files[1]',0);
  const third_file            = watch('EXTRA.Scholarships.arr_files[2]',0);
  const fourth_file           = watch('EXTRA.Scholarships.arr_files[3]',0);

  //Agregar elementos a un array
  const onAddApplicants = async () => {
    if(rut, name, lastname, level_study, relationship, califications_average !== undefined ){
      if(rut.length > 0 && name.length > 0 && lastname.length > 0 && level_study.length > 0 && relationship.length > 0 && califications_average.length > 0){
        setDataApplicants(dataApplicants => [
          ...dataApplicants,
          {rut:formatRut(rut,2), name:name, lastname:lastname, level_study:level_study, relationship:relationship, califications_average:califications_average}
        ])
      }
      else{
        toast({
          title: 'Formulario de postulante incompleto',
          status: 'warning',
          duration: 3000,
          isClosable: true,
        })
      }
    }else{
      toast({
        title: 'Formulario de postulante incompleto',
        status: 'warning',
        duration: 3000,
        isClosable: true,
      })
    }
  }

  //Eliminar elementos a un array
  const onDeleteApplicants = async (data) => {
    let new_arr = dataApplicants.filter(element => element?.rut !== data);
    setDataApplicants(new_arr);
  }

   /**
   * Enviar encuesta con la información contestada
   */
  const onSubmit = async (data) => {
    data.Incident       = {};
    data.contact        = {};
    data.contact.Name   = {};
    data.contact.Emails = {};

    data.contact.Login      = globalData?.contact?.Login;
    data.contact.Emails[0]  = globalData?.contact?.Emails[0];
    data.contact.Name.First = globalData?.contact?.Name?.First;
    data.contact.Name.Last  = globalData?.contact?.Name?.Last;

    data.EXTRA.Scholarships.number_of_participant = dataApplicants.length
    data.EXTRA.Scholarships.persons               = [{"rut": "19724261-3", "name": globalData?.contact?.Name?.First, "lastname": globalData?.contact?.Name?.Last, "relationship": '3', "califications_average": '0'}];

    data.Incident.product    = {};
    data.Incident.product.id = 25;

    data.Incident.subject = {};
    data.Incident.subject = "Beca Hijos de Funcionarios";

    data.Incident.category    = {};
    data.Incident.category.id = 106;

    try {
      const response = await api.post('IncidentServices/createIncident', data);

      if(response.data.result === true){
        toast({
          title: response.data.response.message,
          status: 'success',
          duration: 3000,
          isClosable: true,
        })
      } else {
        toast({
          title: response.data.response.message,
          status: 'warning',
          duration: 3000,
          isClosable: true,
        })
      }
    } catch (err) {
      console.log(err);
    }
  }


  if(!globalData?.contact) {
    return <Loader />;
  }

  if(first_file !== 0 && first_file !== undefined && first_file.length  !== 0 ) {
    notes = true
  }else{
    notes = false
  }
  if(second_file !== 0 && second_file !== undefined && second_file.length  !== 0 ) {
    education = true
  }
  else{
    education = false
  }
  if(third_file  !== 0 && third_file !== undefined && third_file.length  !== 0  ) {
    socialRegistry = true
  }
  else{
    socialRegistry = false
  }
  if(fourth_file  !== 0 && fourth_file !== undefined && fourth_file.length  !== 0 ) {
    familyBurden = true
  }
  else{
    familyBurden = false
  }

  if(success) {
    return <Success dataSuccess={dataSuccess?.ref_number}  message={'Solicitud creada exitosamente'}/>;
  }

  return (
    <Page title="Bono escolaridad" description ={''}>
      <Message>
        <Stack w={'full'} textAlign={'left'}>
          <UnorderedList>
            <ListItem>Ingresa toda la información solicitada en el formulario.</ListItem>
            <ListItem>Adjunta los antecedentes requeridos :
              <UnorderedList>
                <ListItem>Certificado de alumno regular del año en curso (2024)</ListItem>
              </UnorderedList>
            </ListItem>
            <ListItem>No podrán acceder al bono escolaridad aquellos colaboradores, estudiantes que hayan tenido licencia médica por más de dos meses en el ultimo año calendario, siempre que la licencia médica no se origine por maternidad, accidente del trabajo o enfermedad profesional.</ListItem>
          </UnorderedList>
          <Stack w={'full'} textAlign={'left'}>
          <HStack>
            <Text>El formulario estará disponible hasta el </Text><Text fontWeight={'bold'}>10 de marzo</Text>
          </HStack>
            <Text>
              El beneficio será cancelado en la remuneración de marzo 2024.
            </Text>
            <Text>
              Si tienes dudas contacta al equipo de beneficios.
            </Text>
            <Text>
              <HStack>
                <Text>Geraldine Pulgar:</Text><Text fontWeight={'bold'}>gpulgart@bancofalabella.cl</Text>
              </HStack>
            </Text>
          </Stack>
        </Stack>
      </Message>
      <Alert status='error'>
        <AlertDescription>Una vez enviada la solicitud esta no podrá ser modificada.</AlertDescription>
      </Alert>
    <form onSubmit={handleSubmit(onSubmit)} autoComplete={"off"}>
      <Legend>Datos colaborador</Legend>
        <SimpleGrid columns={{ base: '1', lg: '2', xl: '3' }} spacing={4} p={5}>
          <InputField disabled defaultValue={globalData?.contact?.Name?.First} label="Nombre"  />
          <InputField disabled defaultValue={globalData?.contact?.Name?.Last} label="Apellido"  />
          <InputField disabled defaultValue={globalData?.contact?.Emails[0]} label="Correo electrónico"  />
          <InputField disabled defaultValue={globalData?.contact?.CustomFields?.CO?.Departamento?.LookupName} label="Sucursal"  />
          <InputField disabled defaultValue={globalData?.contact?.CustomFields?.CO?.Empresa_interna.LookupName} label="Razón social"  />
        </SimpleGrid>

        <Legend>Adjuntos</Legend>
        <Message>
          <Text w={'full'} textAlign={'left'}>Adjuntar solo documentos PDF*</Text>
        </Message>

        <SimpleGrid columns={{ base: '1', lg: '2', xl: '4' }} spacing={1} p={2}>
          <InputField  name="EXTRA.Scholarships.arr_files[0]" label="Evidencia Incorporación Educación (año actual) *" type="file" required={true} register={register} errors={errors} control={control} setValue={setValue} acceptedFileTypes={['application/pdf']} />
        </SimpleGrid>

        <Stack maxW={'200'} py={'6'}>
          <Button
            type="submit"
            isLoading={isSubmitting}
            loadingText="Solicitando..."
            disabled={isSubmitting}
          >
            Enviar solicitud
          </Button>
        </Stack>
      </form>
    </Page>
  );
}

export default SchoolBonusPage;