import { Icon } from "@chakra-ui/react";
export const Cards2 = props => {
  let {color = '#565656'} = props;
  return <Icon  viewBox="0 0 41 40" {...props}><rect id="cards2" x={0.079} y={0} width={40} height={40} style={{
    fill: "none"
  }} /><path id="path146" d="M6.539,12.415l27.08,0c1.8,0 3.26,1.46 3.26,3.26l-0,15.32c-0,1.8 -1.46,3.26 -3.26,3.26l-27.08,0c-1.801,0 -3.26,-1.46 -3.26,-3.26l-0,-15.32c-0,-1.8 1.459,-3.26 3.26,-3.26Z" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path147" d="M6.639,12.415l-0,-3.67c0.032,-1.643 1.356,-2.968 3,-3l20.78,-0c1.663,0.005 3.017,1.337 3.05,3l-0,3.67" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path148" d="M3.279,20.815l33.6,0" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /></Icon>
};