import { Icon } from "@chakra-ui/react";
export const Alert = props => {
  let {color = '#565656'} = props;
  return <Icon  viewBox="0 0 41 40" {...props}><rect id="alert" x={0.079} y={0} width={40} height={40} style={{
    fill: "none"
  }} /><path id="path6" d="M16.809,4.6c0.851,-0.896 2.033,-1.404 3.27,-1.404c1.236,-0 2.418,0.508 3.27,1.404l12.13,12.1c0.896,0.852 1.404,2.034 1.404,3.27c-0,1.236 -0.508,2.419 -1.404,3.27l-12.13,12.16c-0.852,0.896 -2.034,1.404 -3.27,1.404c-1.237,0 -2.419,-0.508 -3.27,-1.404l-12.13,-12.13c-0.897,-0.851 -1.404,-2.034 -1.404,-3.27c-0,-1.236 0.507,-2.419 1.404,-3.27l12.13,-12.13Z" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path7" d="M19.979,23.36l0.16,-0c0.82,0.001 1.497,-0.641 1.54,-1.46l0.38,-8.73c0.019,-0.418 -0.134,-0.825 -0.423,-1.127c-0.289,-0.303 -0.689,-0.473 -1.107,-0.473l-0.93,0c-0.419,-0 -0.82,0.17 -1.111,0.472c-0.291,0.302 -0.446,0.709 -0.429,1.128l0.39,8.73c0.037,0.818 0.711,1.461 1.53,1.46Z" style={{
    fill: color,
    fillRule: "nonzero"
  }} /><path id="path8" d="M21.759,26.72c-0,0.928 -0.752,1.68 -1.68,1.68c-0.928,0 -1.68,-0.752 -1.68,-1.68c-0,-0.928 0.752,-1.68 1.68,-1.68c0.928,0 1.68,0.752 1.68,1.68Z" style={{
    fill: color,
    fillRule: "nonzero"
  }} /></Icon>
};