import { Icon } from "@chakra-ui/react";
export const Chart = props => {
  let {color = '#565656'} = props;
  return <Icon  viewBox="0 0 41 40" {...props}><rect id="chart" x={0.079} y={0} width={40} height={40} style={{
    fill: "none"
  }} /><path id="path53" d="M30.999,16.64l-0,18.48" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path54" d="M24.279,20l-0,15.12" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path55" d="M17.559,23.36l-0,11.76" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path56" d="M10.839,28.4l-0,6.72" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path57" d="M5.799,20l11.76,-11.76l6.72,3.36l10.08,-6.72" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /></Icon>
};