import { Icon } from "@chakra-ui/react";
export const Ok = props => {
  let {color = '#565656'} = props;
  return <Icon  viewBox="0 0 41 40" {...props}><rect id="ok" x={0.079} y={0} width={40} height={40} style={{
    fill: "none"
  }} /><path id="path138" d="M36.879,20c-0,9.278 -7.522,16.8 -16.8,16.8c-9.279,-0 -16.8,-7.522 -16.8,-16.8c-0,-9.278 7.521,-16.8 16.8,-16.8c9.278,0 16.8,7.522 16.8,16.8Z" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path139" d="M28.479,14.96l-10.06,11.76l-5.06,-5.04" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /></Icon>
};