import { Icon } from "@chakra-ui/react";
export const Thermometer = props => {
  let {color = '#565656'} = props;
  return <Icon  viewBox="0 0 40 40" {...props}><rect id="thermometer" x={0} y={0} width={40} height={40} style={{
    fill: "none"
  }} /><path d="M15.187,22.37l-0,-15.102c-0,-2.656 2.157,-4.813 4.813,-4.813c2.656,-0 4.813,2.157 4.813,4.813l0,15.102c2.14,1.512 3.539,4.006 3.539,6.824c-0,4.609 -3.743,8.351 -8.352,8.351c-4.609,0 -8.352,-3.742 -8.352,-8.351c0,-2.818 1.399,-5.312 3.539,-6.824Z" style={{
    fill: "none",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path d="M20,16.324l0,8.703" style={{
    fill: "none",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><circle cx={20} cy={29.527} r={4.5} style={{
    fill: "#565656"
  }} /></Icon>
};