import { Icon } from "@chakra-ui/react";
export const Notification = props => {
  let {color = '#565656'} = props;
  return <Icon  viewBox="0 0 41 40" {...props}><rect id="notification" x={0.079} y={0} width={40} height={40} style={{
    fill: "none"
  }} /><path id="path102" d="M31.839,14.97l-0,18.64c-0,1.767 -1.433,3.2 -3.2,3.2l-22.16,-0c-1.768,-0 -3.2,-1.433 -3.2,-3.2l-0,-22.18c-0,-1.767 1.432,-3.2 3.2,-3.2l18.64,0" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path104" d="M36.879,9.07c-0,3.247 -2.633,5.88 -5.88,5.88c-3.248,0 -5.88,-2.633 -5.88,-5.88c-0,-3.247 2.632,-5.88 5.88,-5.88c3.247,0 5.88,2.633 5.88,5.88Z" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /></Icon>
};