import { Outlet, Navigate } from 'react-router-dom';
import TokenService from "./TokenService";

const PrivateRoutes = () => {
  let auth = TokenService.getTokenStatus();

  return (
    auth ? <Outlet /> : <Navigate to="/login" />
  )
}
export default PrivateRoutes