import { Icon } from "@chakra-ui/react";
export const Pig = props => {
  let {color = '#565656'} = props;
  return <Icon  viewBox="0 0 41 40" {...props}><rect id="pig" x={0.079} y={0} width={40} height={40} style={{
    fill: "none"
  }} /><path id="path51" d="M8.319,24.21c-2.784,-0 -5.04,-2.256 -5.04,-5.04c-0,-2.783 2.256,-5.04 5.04,-5.04c-0,-0 -1.68,-6.72 -0,-8.4c-0,0 5,0 8.4,3.36c5,-1.68 20.16,-1.68 20.16,8.4c-0,6.72 -3.36,8.4 -5,10.08l-1.1,4.22c-0.36,1.458 -1.669,2.482 -3.17,2.48l-0.12,-0c-1.409,0.002 -2.66,-0.902 -3.1,-2.24l-0.94,-2.8l-3.36,0l-0.93,2.8c-0.448,1.338 -1.7,2.24 -3.11,2.24c-1.558,0.001 -2.899,-1.101 -3.2,-2.63l-1.16,-5.77l-3.37,-1.66Z" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path52" d="M18.399,15.79c3.152,-1.684 6.937,-1.684 10.09,0" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /></Icon>
};