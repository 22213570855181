/** Imports */
import {
  Box,
  Grid,
  Heading,
  Text
} from '@chakra-ui/react';
import { useErrors } from '../../helpers/integer';
import { useState } from 'react';
import api from '../../helpers/api';
import { Page } from '../../components/templates/Page';
import { useEffect } from 'react';
import { ProductGrid } from '../../components/catalog/ProductGrid';
import { Loader } from '../../components/Loader';
import { Title } from '../../components/templates/Title';
import { Card } from '../../components/card/Card';
// import { analytics } from 'firebase-functions/v1';
// import { logEvent } from 'firebase/analytics';

const BenefitBetterflyPage = () => {

  /** Hooks */
  const showErrors = useErrors();
  const [data, setData] = useState(false);

  const url_service = 'ReportServices/getReportHomeBoxes';

  const a_content = {
    'report_id': 101122
  };

  /**
   * Reporte Answer Betterfly
   */
  const getReportBetterfly = async () => {

    try {
      const response = await api.post(url_service, a_content)
      setData(response.data.response.result_father_box);
      showErrors(response, false);
    } catch (err) {
      showErrors(err);
    }
  }

  useEffect(() => {
    getReportBetterfly();
  }, []);

  if (data === false) {
    return <Loader />;
  }

  return (
    <Page
      title="Betterfly"
      color="transparent"
      p={null}
      description={'Betterfly es una App de bienestar que transforma nuestros hábitos saludables en donaciones sociales y nos entrega un Seguro de Vida en el que la cobertura crece cuando mejoramos estos hábitos. Tanto la App como sus beneficios no tienen costo para ti como colaborador. Conoce el detalle de cada uno de los beneficios.'}>
      {!!data && data.length != 0?
      <>
        <ProductGrid py={1} templateColumns={ {base: '1', sm:'repeat(1, 1fr)',  md:'repeat(2, 1fr)', xl:'repeat(4, 1fr)'} }>
          {data.map((benefit, i) => (
            <Card key={i} data={benefit} click={'/betterfly/detail/'} />
          ))}
        </ProductGrid>
      </>
      :
        <Text>Sin Datos</Text>
      }
    </Page>
  );
}

export default BenefitBetterflyPage;