import {
  Text,
  GridItem,
  Link,
  Skeleton,
  Image,
  HStack,
  Stack,
  Grid,
  Table,
  Tbody,
  Tr,
  Th,
  Td,
  useMultiStyleConfig,
  Box,
  Flex,
  VStack,
  Button,
  Spacer
} from '@chakra-ui/react';
import * as React from 'react'
import { Link as LinkRRD, useNavigate } from 'react-router-dom';
import { ShadowBox } from '../templates/ShadowBox';
import { GlobalDataContext } from '../../components/context/GlobalData';
import { useContext } from 'react';
import { formatNumber } from '../../helpers/integer';
import { Icons } from '../templates/Icons';
import { useEffect } from 'react';
import TokenService from '../../helpers/TokenService';
import { useState } from 'react';
import { Fragment } from 'react';

export const BoxRoulette = (props) => {
  const { content } = props;

  // Hooks
  const [globalData, setGlobalData] = useContext(GlobalDataContext);
  const [disabled, setDisabled] = useState(null);

  const styles = useMultiStyleConfig("Boxes", {});
  const navigate = useNavigate();

  useEffect(() => {
    const user = TokenService.getUser();
    if(user?.response?.check_roulette) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, []);
  
  if(disabled === null) {
    return <ShadowBox colSpan={{base: 1, lg: 1, xl: 2}}>Cargando...</ShadowBox>
  }

  return <ShadowBox colSpan={{base: 1, lg: 1, xl: 2}}>
    <Flex
      justifyItems={'center'}
      alignItems={'center'}
      h={'full'}
      direction={'column'}
      minH={'200'}
    >
      <Stack
        direction={{base: 'column', xl: 'row'}}
        align={'center'}
      >
        <VStack>
          <Image src='/images/roulette/icon.png' draggable={false} />
          <Image src='/images/roulette/shadow_icon.png' draggable={false} display={{base: 'none', xl: 'block'}} />
        </VStack>
        <VStack
          justifyItems={'center'}
        >
          {
            (!disabled)?
            <Fragment>
              <Text fontSize={{base: 'md', xl: 'xl'}}>Celebra con nosotros nuestro <span className='tag'>#ViveRenovado</span></Text>
              <Text fontSize={{base: 'md', xl: 'xl'}} fontWeight={'bold'} m={'0 !important'}>y juega por una Gift Card</Text>
              <Spacer />
              <Button
                variant={'outline'}
                onClick={() => {
                  navigate('/roulette');
                }}
              >
                {'Ingresando aquí'}
              </Button>
            </Fragment>
            :
            <Text fontSize={{base: 'md', xl: 'xl'}}>Gracias por concursar en nuestro <span className='tag'>#ViveRenovado</span></Text>
          }
        </VStack>
      </Stack>
    </Flex>
  </ShadowBox>;
}
