import { Icon } from "@chakra-ui/react";
export const Faceid = props => {
  let {color = '#565656'} = props;
  return <Icon  viewBox="0 0 41 40" {...props}><rect id="faceid" x={0.079} y={0} width={40} height={40} style={{
    fill: "none"
  }} /><path id="path126" d="M36.894,30.204l-0,3.5c-0,1.778 -1.442,3.22 -3.22,3.22l-3.5,0" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path127" d="M30.174,3.324l3.5,-0c1.778,-0 3.22,1.442 3.22,3.22l-0,3.5" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path128" d="M3.264,30.204l-0,3.5c-0,1.778 1.441,3.22 3.22,3.22l3.5,0" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path129" d="M10.014,3.324l-3.5,-0c-1.779,-0 -3.22,1.442 -3.22,3.22l-0,3.5" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path130" d="M13.374,13.404l-0,1.68" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path131" d="M26.814,13.404l-0,1.68" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path132" d="M20.094,13.404l-0,6.72l-1.68,1.68" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path133" d="M27.904,25.884c-2.77,2.64 -4.45,3.6 -7.81,3.6c-3.36,0 -5,-1 -7.81,-3.6" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /></Icon>
};