import {
  Image,
  Skeleton,
  Stack,
  Text,

} from '@chakra-ui/react';
import { useNavigate, Link as LinkRRD } from "react-router-dom";
import * as React from 'react'
import { ShadowCard } from '../templates/ShadowCard';
import { formatNumber } from '../../helpers/integer';

export const ProductCard = (props) => {
  const navigate = useNavigate()
  const { product } = props
  const { route_origin = false } = props

  const add = () => {
    route_origin !== false ?
      navigate(`/catalog/${route_origin}/${product.id}`)
    :
      navigate(`/catalog/product/${product.id}`)
  }

  return (
    <ShadowCard className="ProductCard">
      <Stack cursor='pointer' onClick={add} h="full" className="ProductCard-inner">
        <Image
          src={product?.answer_image}
          draggable="false"
          fallback={<Skeleton />}
          borderTopRadius={'20'}
        />
        <Stack
          className="ProductCard-content"
          p="3"
          pb="2"
          spacing="1"
        >
          <Text fontWeight="bold" align={'right'}>
            {product?.idCategory !== '80'
            ?
              product?.points === '0' ? 'Canje Gratuito' : formatNumber(product?.points, 1) + " Puntos"
            :
            null
            }
          </Text>
          <Text fontWeight="bold" align={'left'}>
            {product?.subcategory}
          </Text>
          <Text fontWeight="normal" align={'left'} noOfLines={2}>
            {product?.summary}
          </Text>
        </Stack>
      </Stack>
    </ShadowCard>
  )
}