import {
  Table,
  Thead,
  Tbody,
  Tr,
  TableCaption,
  TableContainer,
  Th,
  Td,
  Text
} from '@chakra-ui/react';
import * as React from 'react'
import { htmlfy } from '../helpers/integer';

export const ReportGrid = (props) => {

  const { exchange } = props

  return (
    <TableContainer>
      <Table>
        <TableCaption>Mi Historial de Canjes</TableCaption>
        <Thead>
          <Tr>
            <Th>Fecha de Solicitud</Th>
            <Th>Número de Referencia</Th>
            <Th>Producto Canjeado</Th>
            <Th>Estado Solicitud</Th>
            <Th>Estado Canje</Th>
            <Th>Fecha Solicitada</Th>
            <Th>Código de Canje</Th>
            <Th>Total Puntos</Th>
          </Tr>
        </Thead>
        <Tbody>
          {exchange?.map((exchange, i_exchange) => (
            <Tr key={i_exchange} align={'center'}>
              <Td>{exchange?.fecha}</Td>
              <Td>{exchange?.n_referencia}</Td>
              <Td>{exchange?.summary}</Td>
              <Td>{exchange?.estado}</Td>
              <Td>{exchange?.exchange_status}</Td>
              <Td>{exchange?.exchange_date.length > 0 ? exchange?.exchange_date : exchange?.fecha}</Td>
              <Td><Text align={'left'} dangerouslySetInnerHTML={{ __html: htmlfy(exchange?.serie?.length > 0 ? exchange?.serie : "No Aplica" ) }} /></Td>
              <Td>{exchange?.total}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  )
}