/** Imports */
import {
  Box,
  Button,
  Grid,
  Heading,
  HStack,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text
} from '@chakra-ui/react';
import { useErrors } from '../../helpers/integer';
import { useContext, useState } from 'react';
import api from "../../helpers/api";
import { useEffect } from 'react';
import { Page } from '../../components/templates/Page';
import { ReportSymptomatic } from '../../components/symptomatic/ReportSymptomatic';
import { ReportSymptoColabs } from '../../components/symptomatic/ReportSymptoColabs';
import { ReportSymptoPositive } from '../../components/symptomatic/ReportSymptoPositive';
import { Loader } from '../../components/Loader';
import { Title } from '../../components/templates/Title';
import { GlobalDataContext } from '../../components/context/GlobalData';
// import { analytics } from 'firebase-functions/v1';
// import { logEvent } from 'firebase/analytics';

const SymptomaticHistoricalPage = () => {

  /** Hooks */
  const showErrors = useErrors();
  const [reportColaborators, setReportColaborators] = useState(false);
  const [reportPositives, setReportPositives]       = useState(false);
  const [reportResponses, setReportResponses]       = useState(false);

  const url_service = 'ReportServices/getReportByFilter';

  /**
   * Reporte de Status Colaborador
   */
  const getReport = async () => {
    try {
      const response = await api.post(url_service, { 'report_id': 101156, 'filter_name': '', 'filter_value': ''})
      showErrors(response, false);
      setReportColaborators(response.data.response.data);
    } catch (err) {
      showErrors(err);
    }
  }
  /**
   * Reporte de Respuestas Detalle
   */
  const getReportResponses = async () => {
    try {
      const response = await api.post(url_service, { 'report_id': 101157, 'filter_name': '', 'filter_value': ''})
      setReportResponses(response.data.response.result);
    } catch (err) {
      showErrors(err);
    }
  }
  /**
   * Reporte Casos Positivos
   */
  const getReportPositives = async () => {
    try {
      const response = await api.post(url_service, { 'report_id': 101158, 'filter_name': '', 'filter_value': ''})
        setReportPositives(response.data.response.result);
    } catch (err) {
      showErrors(err);
    }
  }

  useEffect(() => {
    getReport();
    getReportResponses();
    getReportPositives();
  }, [])

  return (
    <Page title="Histórico de encuestas sintomáticas" color={'transparent'} p="0">
      <Tabs>
        <TabList>
          <Tab _selected={{ bg: 'white' }} borderTopRadius="md">Hoy</Tab>
          <Tab _selected={{ bg: 'white' }} borderTopRadius="md">Positivas</Tab>
          <Tab _selected={{ bg: 'white' }} borderTopRadius="md">Todas</Tab>
        </TabList>
        <TabPanels bg={'white'}>

          <TabPanel>
            {(reportColaborators)?<ReportSymptomatic symptomatic={reportColaborators} />:<Text>Sin Datos</Text>}
          </TabPanel>

          <TabPanel>
            {(reportPositives)?<ReportSymptoPositive symptomatic={reportPositives} />:<Text>Sin Datos</Text>}
          </TabPanel>

          <TabPanel>
            {(reportResponses)?<ReportSymptoColabs symptomatic={reportResponses} />:<Text>Sin Datos</Text>}
          </TabPanel>

        </TabPanels>
      </Tabs>
    </Page>
  );
}

export default SymptomaticHistoricalPage;