/** Imports */
import {
  Box,
  Grid,
  Heading,
  Text
} from '@chakra-ui/react';
import { useErrors } from '../../helpers/integer';
import { useState } from 'react';
import api from '../../helpers/api';
import { Page } from '../../components/templates/Page';
import { useEffect } from 'react';
import { ProductGrid } from '../../components/catalog/ProductGrid';
import { Loader } from '../../components/Loader';
import { Card } from '../../components/card/Card';
import { Title } from '../../components/templates/Title';
// import { analytics } from 'firebase-functions/v1';
// import { logEvent } from 'firebase/analytics';

const AgreementCompensationPage = () => {

  /** Hooks */
  const showErrors = useErrors();
  const [data, setData] = useState(false);

  const url_service = 'ReportServices/getReportHomeBoxes';

  const report = {
    'report_id': 101125
  };

  /**
   * Reporte Answer Caja de Compensación
   */
  const getReportCompensation = async () => {

    try {
      const response = await api.post(url_service, report)
      setData((!!response.data.response.result_father_box)?response.data.response.result_father_box:[]);
      showErrors(response, false);
    } catch (err) {
      showErrors(err);
    }
  }

  useEffect(() => {
    getReportCompensation();
  }, [])

  if(data === false) {
    return <Loader />
  }

  return (
    <Page title="Isapres" p="0" color="transparent">
      {!!data && data.length != 0
        ?
        <ProductGrid>
          {data.map((item, i) => (
            <Card key={i} data={item} src={item.answer_image} click={'/agreements/socialsecurity/'} />
          ))}
        </ProductGrid>
        :
        <Text>Sin Datos</Text>
      }
    </Page>
  );
}

export default AgreementCompensationPage;