import { Icon } from "@chakra-ui/react";
export const Cellphone = props => {
  let {color = '#565656'} = props;
  return <Icon  viewBox="0 0 41 40" {...props}><rect id="cellphone" x={0.079} y={0} width={40} height={40} style={{
    fill: "none"
  }} /><path id="path245" d="M13.459,3.2l13.24,0c1.911,0 3.46,1.549 3.46,3.46l-0,26.68c-0,1.911 -1.549,3.46 -3.46,3.46l-13.24,-0c-1.911,-0 -3.46,-1.549 -3.46,-3.46l-0,-26.68c-0,-1.911 1.549,-3.46 3.46,-3.46Z" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path246" d="M9.999,9.92l20.16,-0" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path247" d="M9.999,30.08l20.16,0" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /></Icon>
};