import { Icon } from "@chakra-ui/react";
export const Home = props => {
  let {color = '#565656'} = props;
  return <Icon  viewBox="0 0 41 40" {...props}><rect id="home" x={0.079} y={0} width={40} height={40} style={{
    fill: "none"
  }} /><path id="path11" d="M33.519,13.629l-0,18.54c-0,1.822 -1.478,3.3 -3.3,3.3l-20.28,-0c-1.823,-0 -3.3,-1.478 -3.3,-3.3l-0,-18.54" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path12" d="M15.039,35.469l-0,-11.76c-0,-0.928 0.752,-1.68 1.68,-1.68l6.72,-0c0.928,-0 1.68,0.752 1.68,1.68l-0,11.76" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path13" d="M3.279,15.289l14.39,-10c1.451,-1.01 3.378,-1.01 4.83,-0l14.38,10" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /></Icon>
};