/** Imports */
import { Alert, AlertIcon, Box, Code, Divider, GridItem, Heading, Stack, Text } from '@chakra-ui/react';
import { filterArray, groupBy, useErrors } from '../../helpers/integer';
import { Fragment, useEffect, useState } from 'react';
import { ProductCard } from '../../components/catalog/ProductCard';
import { ProductGrid } from '../../components/catalog/ProductGrid';
import { Loader } from '../../components/Loader';
import { motion } from "framer-motion";
import api from "../../helpers/api";
import { Page } from '../../components/templates/Page';
import { Title } from '../../components/templates/Title';
import { Subtitle } from '../../components/templates/Subtitle';
import { Message } from '../../components/templates/Message';
// import { analytics } from 'firebase-functions/v1';
// import { logEvent } from 'firebase/analytics';

const TicketsPage = () => {

  /** Hooks */
  const showErrors                        = useErrors();
  const [dataGroupBy, setDataGroupBy]     = useState(false);
  const [reportMessage, setReportMessage] = useState();

  /** Variables */

  /** Configuración */
  const show_grouped    = false;          // Determina si se muestran los productos agrupados según criterio
  const group_attribute = 'subcategory'; // Criterio de agrupación

  /**
   * Obtiene el reporte de productos
   */
  const getCatalogReport = async () => {
    try {
      const response = await api.post('ReportServices/getCatalog')
      if (response.data.result == false) {
        setReportMessage(response.data.response.message);
      } else {
        setDataGroupBy(groupBy(filterArray(filterArray(response.data.response.result, 'idCategory', '5', '!=='), 'idCategory', '80', '!=='), (show_grouped)?group_attribute:null));
      }
    } catch (err) {
      console.log(err);
      showErrors(err);
    }
  }

  useEffect(() => {
    getCatalogReport();
  }, [])

  if(dataGroupBy === false) {
    return <Loader />
  }

  return (
    <Page title="Productos" color="transparent" p={null} description={'Disfruta de productos, momentos dulces y experiencias ti, compartir con amigos o tu familia. Canjea un ítem con tus puntos Vive.'}>
      {(Object.keys(dataGroupBy).length)?<Stack>
        {
          Object.keys(dataGroupBy).map(
            (dataGroupBy_key, dataGroupBy_i) => {
              return <Box key={dataGroupBy_i}>
                {show_grouped && <Subtitle>{dataGroupBy[dataGroupBy_key][0]?.subcategory}</Subtitle>}
                <ProductGrid>
                  {
                    dataGroupBy[dataGroupBy_key].map((product, i) => {
                      return product?.rules === "notCatalog" ? null : <ProductCard key={i} product={product} />
                    })
                  }
                </ProductGrid>
              </Box>
            }
          )
        }
      </Stack>:<Message type="info">Sin stock disponible</Message>}
    </Page>
  );
}

export default TicketsPage;
