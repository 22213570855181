import { HStack, Text } from '@chakra-ui/react';
import * as React from 'react'
import { Page } from './templates/Page';
import { htmlfy } from '../helpers/integer';

export const Success = ({dataSuccess, subTitle, message }) => {

  return (
    <Page title="Tu Solicitud ha sido ingresada con éxito">
      <Text align={'left'}>
        <strong>
          {subTitle}
        </strong>
      </Text>
      <HStack>
        <Text align={'left'} dangerouslySetInnerHTML={{ __html: htmlfy(dataSuccess)}} />
        <Text align={'left'} dangerouslySetInnerHTML={{ __html: htmlfy(message)}} />
      </HStack>
    </Page>
  )
}