import {
    Text,
    CloseButton,
    Flex,
    Select,
    GridItem,
    useColorModeValue,
    Link,
    Skeleton,
    Image,
    Button
  } from '@chakra-ui/react';
import * as React from 'react'
import { Link as LinkRRD } from 'react-router-dom';
import { ShadowBox } from '../templates/ShadowBox';

  export const BoxGym = (props) => {
    const {title}       = props;
    const {description} = props;
    const {link} = props;
    const {button} = props;

    return <ShadowBox colSpan={{base: 1, lg: 1, xl: 1}}>
      Gimnacio: Desarrollo Fase II
    </ShadowBox>;


    return <GridItem borderRadius="md" minH={'50px'} maxH={'250px'} boxShadow={'lg'} colSpan={2} rowSpan={4} border="2px solid #eee">
       <Image
              src={'/catalog/products/p_6.jpg'}
              // alt={name}
              draggable="false"
              fallback={<Skeleton />}
              borderTopRadius={'10'}
            />
      <Text fontSize='25' fontWeight={'semibold'}>{title}</Text>
      <Text>{description}</Text>
      {(link)?
        <Link as={LinkRRD} to={link}>
        {button}
        </Link>:null}
    </GridItem>;
  }
