import { Icon } from "@chakra-ui/react";
export const Family = props => {
  let {color = '#565656'} = props;
  return <Icon  viewBox="0 0 41 40" {...props}><rect id="family" x={0.079} y={0} width={40} height={40} style={{
    fill: "none"
  }} /><path id="path262" d="M25.124,13.28c-0,2.784 -2.257,5.04 -5.04,5.04c-2.784,0 -5.04,-2.256 -5.04,-5.04c-0,-2.784 2.256,-5.04 5.04,-5.04c2.783,-0 5.04,2.256 5.04,5.04Z" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path263" d="M13.364,27.83l-0,-2.79c-0,-3.711 3.008,-6.72 6.72,-6.72c3.711,0 6.72,3.009 6.72,6.72l-0,2.79c-0,2.17 -1.76,3.93 -3.93,3.93l-5.57,0c-1.044,0.003 -2.046,-0.41 -2.786,-1.148c-0.739,-0.737 -1.154,-1.738 -1.154,-2.782Z" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path266" d="M36.174,16.61c-0,1.839 -1.491,3.33 -3.33,3.33c-1.839,-0 -3.33,-1.491 -3.33,-3.33c-0,-1.839 1.491,-3.33 3.33,-3.33c1.839,0 3.33,1.491 3.33,3.33Z" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path267" d="M28.494,25.9l-0,-1.74c-0.003,-1.117 0.44,-2.19 1.23,-2.98c0.79,-0.79 1.862,-1.233 2.98,-1.23c1.115,0 2.185,0.444 2.973,1.234c0.788,0.79 1.229,1.86 1.227,2.976l-0,1.74c-0,1.359 -1.102,2.46 -2.46,2.46l-3.49,-0c-1.359,-0 -2.46,-1.101 -2.46,-2.46Z" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path264" d="M10.974,16.61c-0,1.839 -1.491,3.33 -3.33,3.33c-1.839,-0 -3.33,-1.491 -3.33,-3.33c-0,-1.839 1.491,-3.33 3.33,-3.33c1.839,0 3.33,1.491 3.33,3.33Z" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path265" d="M3.254,25.9l-0,-1.74c-0.003,-1.117 0.44,-2.19 1.23,-2.98c0.79,-0.79 1.862,-1.233 2.98,-1.23c1.115,0 2.185,0.444 2.973,1.234c0.788,0.79 1.229,1.86 1.227,2.976l-0,1.74c-0,1.359 -1.102,2.46 -2.46,2.46l-3.49,-0c-1.359,-0 -2.46,-1.101 -2.46,-2.46Z" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /></Icon>
};