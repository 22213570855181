import { Icon } from "@chakra-ui/react";
export const Shirt = props => {
  let {color = '#565656'} = props;
  return <Icon  viewBox="0 0 40 40" {...props}><rect id="shirt" x={0} y={0} width={40} height={40} style={{
    fill: "none"
  }} /><path d="M13.721,9.118c1.024,2.458 3.45,4.186 6.279,4.186c2.828,0 5.252,-1.726 6.277,-4.181l10.829,5.208l0,7l-6.62,0l0.012,9.876c0.025,0.982 -0.354,1.931 -1.048,2.626c-0.695,0.694 -1.644,1.073 -2.626,1.048l-13.648,0.017c-0.982,0.025 -1.931,-0.354 -2.626,-1.048c-0.694,-0.695 -1.073,-1.644 -1.048,-2.626l0,-9.893l-6.608,0l-0,-7l10.827,-5.213Z" style={{
    fill: "none",
    stroke: color,
    strokeWidth: "3.36px"
  }} /></Icon>
};