
import {
  Box,
  Button,
  Divider,
  Flex,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  Progress,
  Stack,
  CloseButton,
  Text,
  Icon,
  Code,
  SimpleGrid
} from '@chakra-ui/react';
import { NavHashLink } from 'react-router-hash-link';

import { useNavigate, Link as LinkRRD } from "react-router-dom";
import { Fragment, useEffect, useState } from 'react';
import { matrixToCategories } from '../../helpers/integer';
import { Group } from './Group';
import { NavButton } from '../navigation/NavButton';

function Procedures({onClose, ...params}) {
  let navigate = useNavigate();
  const [groups, setGroups] = useState();

  useEffect(() => {
    setGroups(matrixToCategories(null));
  }, []);

  return (
    <Flex
      {...params}>
      <Stack justify="flex-start" h="full" spacing="0" w="full">

        {/* Grupos de solicitudes en línea */}
        <Stack
          p={{base: 1, xl: 2}}
          h="full"
          overflowY="auto"
          overflowX={'hidden'}
          css={{
            '&::-webkit-scrollbar': {
              width: '4px',
            },
            '&::-webkit-scrollbar-track': {
              width: '6px',
            },
            '&::-webkit-scrollbar-thumb': {
              background: 'grey',
              borderRadius: '24px',
            },
          }}
        >
          <SimpleGrid columns={{ base: '1', lg: '2', xl: '2' }} spacing={4}>
            {(groups)?groups.map((item, index) => {
              if(item.children.length > 0) {
                return (
                  <Group
                    key={index}
                    label={item.label}
                    icon={item.icon}
                    {...(item.children.length && {children:item.children.map((d, i) => (<NavButton
                      key={i}
                      icon={d.icon}
                      label={d.label}
                      path={d.path}
                    />))})}
                  />
                )
              }
            }):null}
          </SimpleGrid>
        </Stack>

      </Stack>
    </Flex>
  );
}

export default Procedures;
