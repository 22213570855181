import { Icon } from "@chakra-ui/react";
export const Fork = props => {
  let {color = '#565656'} = props;
  return <Icon  viewBox="0 0 41 40" {...props}><rect id="fork" x={0.079} y={0} width={40} height={40} style={{
    fill: "none"
  }} /><path id="path9" d="M19.567,24.063c0.33,0.17 0.67,0.17 1,0.33c3,1.18 5.37,3.53 10.58,8.74l-4.37,0c-0.927,0 -1.68,0.752 -1.68,1.68c0,0.928 0.753,1.68 1.68,1.68l8.4,0c0.454,0.027 0.896,-0.142 1.217,-0.463c0.321,-0.321 0.49,-0.764 0.463,-1.217l0,-8.4c0,-0.928 -0.752,-1.68 -1.68,-1.68c-0.928,0 -1.68,0.752 -1.68,1.68l0,4.34c-4,-4 -6.72,-6.72 -9.41,-8.4c-0.47,0.291 -0.978,0.516 -1.51,0.67l-3.01,1.04Z" style={{
    fill: "#565656"
  }} /><path id="path10" d="M26.787,6.253l8.4,0m0,0l0,8.4m0,-8.4l-2.69,2.69c-5.37,5.37 -8.06,8.06 -11.42,9.41c-3.36,1.35 -7.22,1.34 -14.78,1.34l-3,0" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /></Icon>
};