import { Button, HStack, Icon, Text, Box, VStack, useDisclosure, Collapse, Spacer, Stack, Link, textDecoration } from '@chakra-ui/react';
import React, { createElement, useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { Fragment, Children, cloneElement } from 'react'
import { Icons } from '../templates/Icons'

export const NavButton = (props) => {
  /** Hooks */
  const [isOver, setIsOver] = useState(false);

  /** Variables */
  const { icon, label, path = null, children, isChild= false, callback = null, ...buttonProps } = props
  const { isOpen, onToggle } = useDisclosure()
  let navigate = useNavigate();

  /** Configuración */
  const style_type = 'green_3'; // Define el estilo del sidebar

  /** Estilos */
  let _styles = {};

  _styles.green_1 = {
    bg                     : (isChild)?'brand.100':'brand.500',
    _hover_bg              : (isChild)?'brand.200':'brand.600',
    _activeLink_fontWeight : isChild?'bold':'normal',
    color                  : isChild?'blackAlpha.700':'white',
    icon_color             : isChild?'grey':'white',
    box_shadow             : Children.count(props.children)?'#4b9b2c':'#00000014',
    arrow_color            : 'white',
    open_color             : 'white',
  };

  _styles.green_2 = {
    bg                     : (isChild)?'brand.100':Children.count(props.children)?'brand.500':'white',
    _hover_bg              : (isChild)?'brand.200':Children.count(props.children)?'brand.600':'brand.100',
    _activeLink_fontWeight : Children.count(props.children)?'normal':'bold',
    color                  : Children.count(props.children)?'white':'blackAlpha.700',
    icon_color             : Children.count(props.children)?'white':'grey',
    box_shadow             : Children.count(props.children)?'#4b9b2c':'#00000014',
    arrow_color            : 'white',
    open_color             : 'white',
  };

  _styles.green_3 = {
    bg                     : (isChild)?'brand.100':Children.count(props.children)?'white':'white',
    _hover_bg              : (isChild)?'brand.200':Children.count(props.children)?'brand.100':'brand.100',
    _activeLink_fontWeight : Children.count(props.children)?'normal':'bold',
    color                  : Children.count(props.children)?'grey':'blackAlpha.700',
    icon_color             : Children.count(props.children)?'grey':'grey',
    box_shadow             : Children.count(props.children)?'#00000014':'#00000014',
    arrow_color            : 'grey',
    open_color             : 'grey',
  };

  _styles.blue_3 = {
    bg                     : (isChild)?'blue.50':Children.count(props.children)?'white':'white',
    _hover_bg              : (isChild)?'blue.100':Children.count(props.children)?'blue.50':'blue.50',
    _activeLink_fontWeight : Children.count(props.children)?'normal':'bold',
    color                  : Children.count(props.children)?'grey':'blackAlpha.700',
    icon_color             : Children.count(props.children)?'grey':'grey',
    box_shadow             : Children.count(props.children)?'#00000014':'#00000014',
    arrow_color            : 'grey',
    open_color             : 'grey',
  };

  return <Fragment>
    <Link
      mb={'3px!important'}
      w="full"
      p={{base: '2', sm: '1.5'}}
      as={NavLink}
      bg={_styles[style_type].bg}
      textDecoration='none'
      _hover={
        {
          textDecoration: 'none',
          bg: _styles[style_type]._hover_bg,
        }
      }
      _activeLink={{
        fontWeight: _styles[style_type]._activeLink_fontWeight,
      }}
      color={_styles[style_type].color}
      borderRadius={'4px'}
      onMouseOver={() => {
        setIsOver(true);
      }}
      onMouseOut={() => {
        setIsOver(false);
      }}
      onClick={(Children.count(props.children))?()=>{
        onToggle();
        if(callback) callback();
      }:()=>{
        if(callback) callback()
      }}
      to={(Children.count(props.children))?false:path}
      alt={path}
      boxShadow={`0px 2px 0px 0px ${_styles[style_type].box_shadow}`}
      {...buttonProps}
    >
      <VStack w="full">
        <HStack
          spacing="2"
          w="full"
        >
          <Icons
            icon={icon}
            w={'20px'}
            h={'20px'}
            color={_styles[style_type].icon_color}
          />

          <Text w="full" fontSize="sm" textAlign="left">{label}</Text>

          {(isOver && !Children.count(props.children)) && <Icons icon="arrow_left" color={_styles[style_type].open_color} w="4" />}
          {/* <Icons icon="blank" color={'grey'} w="4" /> */}

          {(Children.count(props.children))?
            <Icons icon={(isOpen)?'arrow_up':'arrow_down'} w={'20px'} h={'20px'} color={_styles[style_type].arrow_color} />
          :null}
        </HStack>
      </VStack>

    </Link>

    {(Children.count(props.children))?<Collapse in={isOpen} animateOpacity>
        <VStack m="0.5 0.5 0.5 1" spacing="2px" p="0 0 0 5px">
        {
          Children.map(children, (d) => {
            return cloneElement(d, {isChild: true});
          })
        }
        </VStack>

      </Collapse>:null}
  </Fragment>

}