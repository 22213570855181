import { Icon } from "@chakra-ui/react";
export const Donate = props => {
  let {color = '#565656'} = props;
  return <Icon  viewBox="0 0 41 40" {...props}><rect id="donate" x={0.079} y={0} width={40} height={40} style={{
    fill: "none"
  }} /><path id="path158" d="M5.667,27.005l6.721,-1.68l8.689,1.24c0.76,0.109 1.432,0.549 1.835,1.201c0.404,0.653 0.497,1.451 0.255,2.179c-0.397,1.212 -1.621,1.952 -2.88,1.74l-7.899,-1.32" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path159" d="M24.147,30.365l1.68,-0l5.881,-1.05c1.422,-0.352 2.876,0.449 3.339,1.84c0.479,1.434 -0.264,2.989 -1.679,3.52l-9.88,1.81c-1.78,0.399 -3.623,0.419 -5.411,0.06l-14.119,-2.82" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path221" d="M19.791,7.331l0,5.013" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path2211" d="M12.401,11.688l2.407,2.407" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path2212" d="M27.49,11.688l-2.408,2.407" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path2213" d="M31.824,18.312l-5.013,-0" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path222" d="M13.346,18.312l-5.012,-0" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /></Icon>
};
