import {
  Text,
  CloseButton,
  Flex,
  Select,
  GridItem,
  useColorModeValue,
  Link,
  useMultiStyleConfig,
  Skeleton,
  Image,
  AspectRatio,
  Button,
  Box
} from '@chakra-ui/react';
import * as React from 'react'
import { Link as LinkRRD, useNavigate } from 'react-router-dom';
import { ShadowBox } from '../templates/ShadowBox';

export const BoxNews = (props) => {
  const { title } = props;
  const { description } = props;
  const { link } = props;
  const { button } = props;

  const styles = useMultiStyleConfig("Boxes", {});
  const navigate = useNavigate();

  return <ShadowBox
    colSpan={{base: 1, lg: 1}}
  >
  {
    <Flex
      direction={'column'}
      align={'center'}
      justify={'center'}
      h={'100%'}
    >

      <Box w={'full'}>
        <Text textAlign="center" fontSize={styles.title.fontSize} fontWeight={styles.title.fontWeight} color={styles.title.color} p={2}>{props?.content?.summary}</Text>
      </Box>

      <Flex
        w={'full'}
        alignItems={'center'}
        >
        <Box
          borderRadius="md"
          p="2"
          m="2"
          w="full"
          color={'white'}
        >
          <Image maxW='133px' maxH='110px' src={props?.content?.answer_image} draggable="false" fallback={<Skeleton />} />
        </Box>
      </Flex>

      <Button
        variant={'outline'}
        onClick={() => {navigate(props?.content?.URI)}}
      >
        Visitar
      </Button>
    </Flex>

    }
  </ShadowBox>;
}
