import { Icon } from "@chakra-ui/react";
export const DollAlert = props => {
  let {color = '#565656'} = props;
  return <Icon  viewBox="0 0 41 40" {...props}><rect id="doll_alert" x={0.079} y={0} width={40} height={40} style={{
    fill: "none"
  }} /><path id="path192" d="M29.469,19.49c0.399,3.76 1.487,7.414 3.21,10.78c-0,-0 -6.7,3.38 -13.46,3.32c-6.76,-0.06 -13.42,-3.38 -13.42,-3.38c2.23,-4.83 3.36,-8.45 3.36,-13.7l-0,-0.63c-0,-5.88 4.4,-10.84 10.08,-10.84c1.683,-0.003 3.336,0.442 4.79,1.29" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path193" d="M22.979,32.6c-0,2.07 -1.67,4.39 -3.74,4.39c-2.07,0 -3.74,-2.32 -3.74,-4.39" style={{
    fill: color,
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path194" d="M20.919,5.45c-0,-1.35 -0.75,-2.44 -1.68,-2.44c-0.93,-0 -1.68,1.09 -1.68,2.44" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path195" d="M34.359,12.63c-0,3.247 -2.633,5.88 -5.88,5.88c-3.248,-0 -5.88,-2.633 -5.88,-5.88c-0,-3.247 2.632,-5.88 5.88,-5.88c3.247,-0 5.88,2.633 5.88,5.88Z" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /></Icon>
};