import { Icon } from "@chakra-ui/react";
export const Recognition = props => {
  let {color = '#565656'} = props;
  return <Icon  viewBox="0 0 41 40" {...props}><rect id="recognition" x={0.079} y={0} width={40} height={40} style={{
    fill: "none"
  }} /><path d="M14.317,7.861c0.79,-2.203 3.103,-3.861 5.762,-3.861c2.658,0 4.972,1.658 5.761,3.861c2.302,-0.418 4.895,0.757 6.224,3.059c1.33,2.302 1.05,5.135 -0.463,6.92c1.513,1.785 1.793,4.618 0.463,6.92c-1.329,2.302 -3.922,3.477 -6.224,3.059c-0.789,2.203 -3.103,3.861 -5.761,3.861c-2.659,0 -4.972,-1.658 -5.762,-3.861c-2.302,0.418 -4.895,-0.757 -6.224,-3.059c-1.329,-2.302 -1.05,-5.135 0.463,-6.92c-1.513,-1.785 -1.792,-4.618 -0.463,-6.92c1.329,-2.302 3.922,-3.477 6.224,-3.059Z" style={{
    fill: "none",
    stroke: color,
    strokeWidth: "3.42px"
  }} /><path id="path209" d="M27.418,17.84c0,4.053 -3.286,7.339 -7.339,7.339c-4.054,0 -7.34,-3.286 -7.34,-7.339c0,-4.053 3.286,-7.339 7.34,-7.339c4.053,-0 7.339,3.286 7.339,7.339Z" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path d="M18.416,31.68l-2.414,3.073c-0.599,0.803 -1.502,1.325 -2.497,1.443c-0.995,0.118 -1.995,-0.177 -2.766,-0.817l-4.556,-3.587c-0.803,-0.6 -1.324,-1.502 -1.443,-2.497c-0.118,-0.995 0.177,-1.995 0.817,-2.766l1.684,-2.138" style={{
    fill: "none",
    stroke: color,
    strokeWidth: "3.9px"
  }} /><path d="M32.917,24.391l1.683,2.138c0.64,0.771 0.935,1.771 0.817,2.766c-0.118,0.995 -0.64,1.897 -1.443,2.497l-4.555,3.587c-0.771,0.64 -1.771,0.935 -2.766,0.817c-0.995,-0.118 -1.898,-0.64 -2.498,-1.443l-2.413,-3.073" style={{
    fill: "none",
    stroke: color,
    strokeWidth: "3.9px"
  }} /></Icon>
};