import {
    Text,
    GridItem,
    Link,
    Skeleton,
    Image,
    HStack,
    Stack,
    Grid,
    useMultiStyleConfig,
    Box,
    Button,
    Table,
    Tbody,
    Tr,
    Th,
    Td
  } from '@chakra-ui/react';
  import * as React from 'react'
  import { Link as LinkRRD } from 'react-router-dom';
import { ShadowBox } from '../templates/ShadowBox';
import { GlobalDataContext } from '../../components/context/GlobalData';
import { useContext } from 'react';
import { Icons } from '../templates/Icons';

export const BoxCollectiveContract = (props) => {
  // Hooks
  const [globalData, setGlobalData] = useContext(GlobalDataContext);
  const styles = useMultiStyleConfig("Boxes", {});

  const { title = "Mi información laboral" } = props;
  const { answer } = props;

  return <ShadowBox colSpan={{base: 1, md: 2, lg: 2, xl: 2}} minH={'200'}>
    <Image
      src={answer}
      draggable="false"
      fallback={<Skeleton />}
      borderTopRadius={'10'}
    />
    <Box w={'full'}>
        <Text textAlign="center" fontSize={styles.title.fontSize} fontWeight={styles.title.fontWeight} color={styles.title.color}
          p={2}
        >
          {/* <Icons icon="agreements" color={styles.title.color} h="6" w="6" mr="1" /> */}
          {title}
        </Text>
      </Box>


    <Stack
      direction={{base: 'column', xl: 'row'}}
      align={'center'}
    >
      <Image
        src='/images/collective_contract/icon.png'
        maxW={'100%'}
        maxH={'100%'}
        draggable="false"
        fallback={<Skeleton />}
        mx={'10'}

      />
      <Table
          variant="simple"
        >
          <Tbody>
            <Tr>
              <Th textAlign={'right'}>
                Razón social
              </Th>
              <Td>
                {globalData?.contact?.CustomFields?.CO?.Empresa_interna.LookupName}
              </Td>
            </Tr>
            <Tr>
              <Th textAlign={'right'}>
                RUT
              </Th>
              <Td>
                {globalData?.contact?.Empresa_interna?.RUT}
              </Td>
            </Tr>
            <Tr>
              <Th textAlign={'right'}>
                Convenio colectivo
              </Th>
              <Td>
                {globalData?.contact?.CustomFields?.CO?.ContratoColectivo?.LookupName}
              </Td>
            </Tr>
          </Tbody>
        </Table>
    </Stack>

    {/* <Link as={LinkRRD} to={link+'/'+answer?.id}>
      {button}
    </Link> */}
    {/* <Text>conoce más detalles de tus beneficios por empresa</Text>
    <Button
      variant={'outline'}
    >
      Revísalas aquí
    </Button> */}
  </ShadowBox>;
}
