/**
 * @version 1.0.0
 * @description Template de página
 * 
 * @param {Object} props - props
 * 
 * @returns {Object} - Page
 * 
 * @example <Page>...</Page>
 * @example <Page title="Título de página">...</Page>
 */
import {
  Box, Stack, Text
} from '@chakra-ui/react';
import { BreadcrumbNav } from './BreadcrumbNav';
import { useState, useEffect, Fragment } from 'react';
import { htmlfy, useErrors } from '../../helpers/integer';
import { Title } from './Title';

export const Page = ({...params}) => {

  /** Variables */
  const { color = 'white' } = params;
  const { title } = params;
  const { path_name } = params;
  const { description } = params;
  const { p = {base: 1, sm: 5} } = params;

  useEffect(() => {
  }, []);

  return (
    <Box
      w="full"
      maxW={'1200px'}
      minH="full"
      className="page"
      mb="10"
    >
      <Stack direction={'column'} w="full" minH="full">
        <Fragment>
          <BreadcrumbNav path_name={path_name} />
          {
            title && <Title
              {...description && {pb:"0"}}
            >{title}</Title>
          }
          {description && 
            <Box 
              pb={2}
            >
              {description}
            </Box>
          }
        </Fragment>
        <Box
          w="100%"
          mx="auto"
          align={'center'}
          bgColor={color}
          p={p}
          {...!p && {pb:10}}
          borderRadius={{base: 1, sm: 10}}
          minH="full"
          className="content"
        >
          {params.children}
        </Box>
      </Stack>
    </Box>
  );
}

