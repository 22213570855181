import { Icon } from "@chakra-ui/react";
export const Travel = props => {
  let {color = '#565656'} = props;
  return <Icon  viewBox="0 0 41 40" {...props}><rect id="travel" x={0.079} y={0} width={40} height={40} style={{
    fill: "none"
  }} /><path id="path91" d="M8.314,23.377l-0,0.84c-0,1.391 -1.129,2.52 -2.52,2.52c-1.392,-0 -2.52,-1.129 -2.52,-2.52l-0,-8.4c0.011,-1.388 1.142,-2.506 2.53,-2.5c1.391,-0 2.52,1.128 2.52,2.52l-0,0.84l6.72,-0l-2.84,-10c-0.298,-1.045 0.189,-2.153 1.16,-2.64c0.974,-0.491 2.161,-0.208 2.81,0.67l8.95,11.93l8.4,-0c0.892,-0.003 1.748,0.35 2.379,0.981c0.63,0.631 0.983,1.487 0.981,2.379c0.002,0.892 -0.351,1.748 -0.981,2.379c-0.631,0.63 -1.487,0.983 -2.379,0.981l-8.4,-0l-9,12c-0.613,0.819 -1.726,1.081 -2.64,0.62c-1.002,-0.498 -1.441,-1.692 -1,-2.72l4.25,-9.93l-8.42,0.05Z" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /></Icon>
};