import { Icon } from "@chakra-ui/react";
export const Nfc = props => {
  let {color = '#565656'} = props;
  return <Icon  viewBox="0 0 41 40" {...props}><rect id="nfc" x={0.079} y={0} width={40} height={40} style={{
    fill: "none"
  }} /><path id="path36" d="M27.979,33.405c4.226,-8.416 4.322,-18.314 0.26,-26.81" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.12px"
  }} /><path id="path37" d="M21.999,30.695c1.641,-3.435 2.495,-7.193 2.5,-11c-0.013,-3.441 -0.728,-6.844 -2.1,-10" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.12px"
  }} /><path id="path38" d="M15.999,27.975c1.162,-2.596 1.768,-5.406 1.78,-8.25c0.001,-2.35 -0.408,-4.682 -1.21,-6.89" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.12px"
  }} /><path id="path39" d="M10.599,16.035c0.777,3.063 0.567,6.293 -0.6,9.23" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.12px"
  }} /></Icon>
};