/**
 * @version 1.0.0
 * @description Template sombra de tarjeta
 * 
 * @param {Object} props - props
 * 
 * @returns {Object} - Title
 * 
 * @example <Title>...</Title>
 */
import {
  Box, Heading
} from '@chakra-ui/react';
import { useState, useEffect } from 'react';

export const Title = ({...params}) => {

  /** Variables */

  useEffect(() => {
  }, []);

  return (
    <Heading
      fontSize="2xl"
      fontWeight={params.fontWeight?params.fontWeight:"semibold"}
      textAlign={params.textAlign?params.textAlign:{base: 'center', md: 'left'}}
      py={params.py?params.py:5}
      mx={params.mx?params.mx:{base: 10, sm: 0}}
      {...params}
    >
      {params.children}
    </Heading>
  );
}
