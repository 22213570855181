import {
  Box,
  Text,
  GridItem,
  Link,
  Grid,
  VStack,
  HStack,
  Stack,
  Flex,
  useMultiStyleConfig,
  Button,
  Image,
  Skeleton
} from '@chakra-ui/react';
import * as React from 'react'
import { Link as LinkRRD, useNavigate } from 'react-router-dom';
import { ShadowBox } from '../templates/ShadowBox';
import { Icons } from '../templates/Icons';
import moment from 'moment';
import { Loader } from '../Loader';
import { useState } from 'react';
import { useErrors } from '../../helpers/integer';
import api from "../../helpers/api";
import { useEffect } from 'react';


export const BoxGiftCard = (props) => {

  const navigate = useNavigate();

  const styles = useMultiStyleConfig("Boxes", {});

  /** Hooks */
  const [data, setData] = useState(false);
  const showErrors      = useErrors();

  /** Variables */
  const url_giftcard_box = 'ReportServices/getReportByFilter';
  const request = {
    "report_id"   : 101160,
    "filter_name" : "",
    "filter_value": ""
  };

  /**
   * Reporte Boxes Home
   */
  const getGiftCards = async () => {
    try {
      const response = await api.post(url_giftcard_box, request);
      showErrors(response, false);
      setData(response?.data?.response?.data);
    } catch (err) {
      showErrors(err);
    }
  }

  useEffect(() => {

  }, [])

  // if(data === false) {
  //   return <Loader/>;
  // }

  return <ShadowBox
    colSpan={{base: 1, lg: 2}}
  >
  {
    <Flex
      direction={'column'}
      align={'center'}
      justify={'center'}
      h={'100%'}
    >

      <Box w={'full'}>
        {/* <Text textAlign="center" fontSize={styles.title.fontSize} fontWeight={styles.title.fontWeight} color={'white'}
          p={2}
        >
          Gift Card
        </Text> */}
        <Text textAlign="center" fontSize={styles.title.fontSize} fontWeight={styles.title.fontWeight} color={styles.title.color} p={2}>Gift Card</Text>
      </Box>

      <Flex
        w={'full'}
        alignItems={'center'}

        >
        <Box
          borderRadius="md"
          p="2"
          m="2"
          w="full"
          color={'white'}
        >
          <Image src="/images/giftcard/icon.png" maxW={'100%'} maxH={'100%'} draggable="false" fallback={<Skeleton />} />
        </Box>
      </Flex>

      <Button
        variant={'outline'}
        onClick={() => {
          navigate('/giftcards');
        }}
      >
        {(data?.length > 1)?'Revísalas aquí':'Revísala aquí'}
      </Button>
    </Flex>

    }
  </ShadowBox>;
}