import {
  Text,
  GridItem,
  Link,
  Skeleton,
  Image,
  HStack,
  Stack,
  Grid,
  Table,
  Tbody,
  Tr,
  Th,
  Td,
  useMultiStyleConfig,
  Box,
  Spacer
} from '@chakra-ui/react';
import * as React from 'react'
import { Link as LinkRRD, useNavigate } from 'react-router-dom';
import { ShadowBox } from '../templates/ShadowBox';
import { GlobalDataContext } from '../../components/context/GlobalData';
import { useContext } from 'react';
import { formatNumber } from '../../helpers/integer';
import { Icons } from '../templates/Icons';

export const BoxPoints = (props) => {
  const { content } = props;

  const styles = useMultiStyleConfig("Boxes", {});
  const navigate = useNavigate()

  const [globalData, setGlobalData] = useContext(GlobalDataContext);
  
  const { link = `/mypoints/${content?.id}`} = props;

  return <ShadowBox
    colSpan={{base: 1, md: 2, lg: 2, xl: 2}}
    align={'center'}
    justify={'center'}
    h={'100%'}
  >
    <Stack
      direction={{base: 'column', xl: 'row'}}
      align={'center'}
      justify={'center'}
      h={'100%'}
    >
      <Image
        src='/images/points/icon.png'
        draggable={false}
      />
      <Box
        minW={'200'}
      >
        <Text textAlign="center" lineHeight={'1.0'} fontSize={'2xl'} fontWeight={'semibold'}>
          {`Tienes`}
        </Text>
        <Text textAlign="center" lineHeight={'1.0'} fontSize={'5xl'} fontWeight={'semibold'} color={styles.title.color}>
          {`${formatNumber(globalData?.contact?.CustomFields?.c?.puntos, 1)}`}
        </Text>
        <Text textAlign="center" lineHeight={'1.0'} fontSize={'2xl'} fontWeight={'semibold'}>
          {`puntos`}
        </Text>
        <br />
        <Link as={LinkRRD} to={link}>
          {`¿Qué son mis puntos Vive?`}
        </Link>
      </Box>
    </Stack>
  </ShadowBox>;
}
