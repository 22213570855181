/** Imports */
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  AspectRatio,
  Box,
  Text
} from '@chakra-ui/react';
import { useErrors, htmlfy } from '../../helpers/integer';
import { Loader } from '../../components/Loader';
import { motion } from "framer-motion";
import { useNavigate, useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import api from '../../helpers/api';
import { Page } from '../../components/templates/Page';
import { Icons } from '../../components/templates/Icons';
// import { analytics } from 'firebase-functions/v1';
// import { logEvent } from 'firebase/analytics';

const GymPage = () => {
  /** Hooks */
  const showErrors = useErrors();
  const [answerPrimary, setAnswerPrimary] = useState(false);
  const [reportMessage, setReportMessage] = useState();

  let navigate = useNavigate();

  const url_service = 'ReportServices/getReportHomeBoxes';

  const option_request = {
    'report_id': 101182
  };

  /**
   * Reporte de Answers Sección Deporte
   */
  const getReportAnswer = async () => {
    try {
      const response = await api.post(url_service, option_request);

      if (response.data.result == false) {
        setReportMessage(response);
      } else {
        setAnswerPrimary(response?.data?.response?.result_father_box);
      }
    } catch (err) {
      showErrors(err);
    }
  }

  useEffect(() => {
    getReportAnswer();
  }, [])

  if(answerPrimary === false) {
    return <Loader />;
  }

  return (
    <Page title={'Gimnasio Casa Matriz'} description="Te invitamos a conocer Gimnasio Gennials y cómo inscribirte, ¡Vive tus Beneficios!.">
      <>
        <Accordion py={2} allowToggle>
        {
          answerPrimary?.map((answer, i) => (
            <AccordionItem key={i}>
              {
                ({ isExpanded }) => (
                  <>
                    <AccordionButton
                      borderBottom={'1px solid white'}
                      bg="brand.100"
                      _expanded={{ bg: 'brand.500', color: 'white' }}
                      _hover={{ bg: 'brand.200' }}
                    >
                      <Box flex='1' textAlign='left' fontSize="md">
                        {answer?.summary}
                      </Box>
                      <Icons icon={(isExpanded)?'arrow_up':'arrow_down'} color={(isExpanded)?'white':'black'} />
                    </AccordionButton>
                    <AccordionPanel pb={4}>
                    <Text align={'left'} dangerouslySetInnerHTML={{ __html: htmlfy(answer?.solution) }} />
                    </AccordionPanel>
                  </>
                )
              }
            </AccordionItem>
          ))
        }
        </Accordion>
      </>
    </Page>
  );
}

export default GymPage;