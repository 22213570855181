import { Icon } from "@chakra-ui/react";
export const Artist = props => {
  let {color = '#565656'} = props;
  return <Icon  viewBox="0 0 41 40" {...props}><rect id="artist" x={0.079} y={0} width={40} height={40} style={{
    fill: "none"
  }} /><path id="path196" d="M8.384,31.338c-2.885,-3.334 -4.482,-7.591 -4.5,-12c-0,-9.24 6.41,-15.12 15.59,-15.12c9.18,-0 16.8,5.88 16.8,15.12c-0,4.83 -2.6,8.15 -6.3,6.93c-2.188,-0.906 -4.681,-0.67 -6.66,0.63c-1.43,0.84 -2.07,2.25 -2.87,3.36c-4.34,5.93 -9.18,4.79 -12.06,1.08Z" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path208" d="M29.554,15.118c-0,1.392 -1.129,2.52 -2.52,2.52c-1.392,-0 -2.52,-1.128 -2.52,-2.52c-0,-1.392 1.128,-2.52 2.52,-2.52c1.391,-0 2.52,1.128 2.52,2.52Z" style={{
    fill: color,
    fillRule: "nonzero"
  }} /><path id="path197" d="M14.629,18c-0,1.392 -1.129,2.52 -2.52,2.52c-1.392,-0 -2.52,-1.128 -2.52,-2.52c-0,-1.392 1.128,-2.52 2.52,-2.52c1.391,-0 2.52,1.128 2.52,2.52Z" style={{
    fill: color,
    fillRule: "nonzero"
  }} /><path id="path198" d="M21.349,12.96c-0,1.392 -1.129,2.52 -2.52,2.52c-1.392,-0 -2.52,-1.128 -2.52,-2.52c-0,-1.392 1.128,-2.52 2.52,-2.52c1.391,0 2.52,1.128 2.52,2.52Z" style={{
    fill: color,
    fillRule: "nonzero"
  }} /></Icon>
};