import { Icon } from "@chakra-ui/react";
export const Burger = props => {
  let {color = '#565656'} = props;
  return <Icon viewBox="0 0 40 41" {...props}><rect id="burger" x={0} y={0.8} width={40} height={40} style={{fill: "none"}} /><clipPath id="_clip1"><rect x={0} y={0.8} width={40} height={40} /></clipPath><g clipPath="url(#_clip1)"><g><rect x={0} y={0.8} width={40} height={40} style={{
    fill: "none",
    fillRule: "nonzero"
  }} /><path id="path137" d="M35.649,10.8l-31.298,0" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path1371" d="M35.649,20.8l-31.298,0" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path1372" d="M35.649,30.8l-31.298,0" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /></g></g></Icon>
}