import { Icon } from "@chakra-ui/react";
export const Historical = props => {
  let {color = '#565656'} = props;
  return <Icon  viewBox="0 0 41 40" {...props}><rect id="historical" x={0.079} y={0} width={40} height={40} style={{
    fill: "none"
  }} /><path id="path231" d="M35.199,36.66l-28.29,0.14c-2.003,-0.005 -3.625,-1.628 -3.63,-3.63l-0,-26.34c-0,-2.005 1.625,-3.63 3.63,-3.63l19.62,0c0.963,-0.003 1.888,0.379 2.569,1.06c0.682,0.682 1.063,1.606 1.061,2.57l-0,14.73" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path232" d="M9.999,9.92l11.76,-0" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path233" d="M9.999,16.64l11.76,0" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path234" d="M9.999,23.36l11.76,-0" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path235" d="M30.159,21.56l5,0c0.95,0 1.72,0.77 1.72,1.72l-0,11.77c-0,0.9 -0.73,1.63 -1.63,1.63l-0.15,0c-2.729,0 -4.94,-2.212 -4.94,-4.94l-0,-10.18Z" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /></Icon>
};