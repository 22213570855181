import {
  AspectRatio,
  Box,
  Button,
  FormControl,
  Grid,
  GridItem,
  HStack,
  Image,
  Input,
  Link,
  SimpleGrid,
  Skeleton,
  Stack,
  StackDivider,
  StackProps,
  Text,
  useBreakpointValue,
  useColorModeValue,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { useNavigate, Link as LinkRRD } from "react-router-dom";
import * as React from 'react'
import { useState, useContext } from 'react';
import api from "../helpers/api";
import { Loader } from '../components/Loader';
import { useEffect } from 'react';
import { InputField } from './input/InputField';
import { useForm } from 'react-hook-form';
import { GlobalDataContext } from '../components/context/GlobalData';
import { Page } from './templates/Page';
import { Title } from './templates/Title';
import { Legend } from './templates/Legend';
import { Fragment } from 'react';
import { Success }              from '../components/Success';

export const Maternity = (props) => {
  /** Hooks */
  const [data, setData] = useState({});
  const [success, setSuccess] = useState(false);
  const [dataSuccess, setdataSuccess] = useState({});
  const [globalData, setGlobalData] = useContext(GlobalDataContext);
  const toast = useToast();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    control,
    setValue
  } = useForm({});

  // Props
  const { title }       = props
  const { product_id }  = props
  const { category_id } = props

  /**
   * Manejo de errores del formulario
   */
   const onError = (errors, e) => {
    console.log(errors, e);
  }

  /**
   * 
   */
  const onSubmit = async (data) => {
    data.subject  = title;
    data.Incident = {};

    if(product_id) {
      data.Incident.product    = {};
      data.Incident.product.id = product_id;
    }

    if(category_id) {
      data.Incident.category    = {};
      data.Incident.category.id = category_id;
    }
    // if(data._attachments) {
    //   data.Incident._attachments = data._attachments
    // }
    setData(data);
    try {
      const response = await api.post('IncidentServices/createIncident', data);
      if(response.data.result == true){
        toast({
          title: response.data.response.message,
          status: 'success',
          duration: 3000,
          isClosable: true,
        })
        setdataSuccess(response.data.response.message);
        setSuccess(true);
      } else {
        toast({
          title: response.data.response.message,
          status: 'warning',
          duration: 3000,
          isClosable: true,
        })
      }
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
  }, []);


  if(success) {
    return <Success dataSuccess={dataSuccess?.ref_number}  message = {'Solicitud creada Exitosamente'}/>;
  }

  return (
    <Fragment>
      <Legend>Datos del solicitante</Legend>
      <SimpleGrid columns={{ base: '1', lg: '2', xl: '3' }} spacing={4} p={5}>
        <InputField disabled defaultValue={globalData?.contact?.Name?.First} label="Nombre" />
        <InputField disabled defaultValue={globalData?.contact?.Name?.Last} label="Apellido paterno" />
        <InputField disabled defaultValue={globalData?.contact?.Emails?.length && globalData?.contact.Emails[0]} label="Correo electrónico" />
        <InputField disabled defaultValue={globalData?.contact?.CustomFields?.CO?.Gerencia?.LookupName} label="Gerencia" />
        <InputField disabled defaultValue={globalData?.contact?.CustomFields?.CO?.Departamento?.LookupName} label="Sucursal" />
        <InputField disabled defaultValue={globalData?.contact?.CustomFields?.CO?.Empresa_interna?.LookupName} label="Empresa" />
        <InputField disabled defaultValue={globalData?.contact?.JefeDirecto.length !== 0 ? globalData?.contact?.JefeDirecto?.Name?.First : "(Sin Jefe Directo)"} label="Nombre jefe directo"/>
        <InputField disabled defaultValue={globalData?.contact?.JefeDirecto.length !== 0  ? globalData?.contact?.JefeDirecto?.Name?.Last : "(Sin Jefe Directo)"} label="Apellido jefe directo" />
      </SimpleGrid>

      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <Legend>Documentos adjuntos</Legend>
        <Text align={'left'} p={2} >Adjunta certificado de nacimiento </Text>
        <SimpleGrid columns={{ base: '1', lg: '2', xl: '3' }} spacing={4} p={5}>
          <GridItem>
            <HStack>
              <InputField name="_attachments" label="" type="file" required={true} register={register} errors={errors} control={control} setValue={setValue} acceptedFileTypes={['image/jpeg', 'image/png', 'application/pdf']} />
            </HStack>
          </GridItem>
        </SimpleGrid>

        <Stack maxW={'200'} py={'6'}>
          <Button
            type='submit'
            isLoading={isSubmitting}
            loadingText="Enviando..."
            disabled={isSubmitting}
          >
            Enviar
          </Button>
        </Stack>
      </form>
    </Fragment>
  )
}