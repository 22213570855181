/** Imports */
import { Box, Heading, Stack, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';
import { useErrors } from '../../helpers/integer';
import { useEffect, useState } from 'react';
import { ProductCard } from '../../components/catalog/ProductCard';
import { ProductGrid } from '../../components/catalog/ProductGrid';
import { Loader } from '../../components/Loader';
import { motion } from "framer-motion";
import api from "../../helpers/api";
import { Page } from '../../components/templates/Page';
import { Title } from '../../components/templates/Title';
import { Legend } from '../../components/templates/Legend';
// import { analytics } from 'firebase-functions/v1';
// import { logEvent } from 'firebase/analytics';

const DonationPage = () => {

  /** Hooks */
  const showErrors = useErrors();
  const [data, setData] = useState(false);


  useEffect(() => {

  }, []);

  if(data === false) {
    return <Loader />
  }

  return (
    <Page title="Donación">
      <Stack>
        <Text px={2} align={'left'}>
          A continuación puedes donar tus puntos al colaborador que desees indicando el RUT de destino:
        </Text>
        <Legend>Transferencia de puntos</Legend>
        {/* DATOS DEL BICICLETERO*/}
        <TableContainer>
          <Table variant='unstyled'>
            <Thead>
              <Tr>
                <Th>Desde</Th>
                <Th>Hacia</Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr>
                <Td>197242612</Td>
                <Td>197242612</Td>
              </Tr> 
            </Tbody>
          </Table>
        </TableContainer>
      </Stack>
    </Page>
  );
}

export default DonationPage;
