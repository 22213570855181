/** Imports */
import {
  Box,
  Grid,
  Heading,
  Text,
  useToast
} from '@chakra-ui/react';
import { useErrors } from '../../helpers/integer';
import { useState } from 'react';
import api from "../../helpers/api";
import { useEffect } from 'react';
import { Page } from '../../components/templates/Page';
import { ProductGrid } from '../../components/catalog/ProductGrid';
import { Loader } from '../../components/Loader';
import { Card } from '../../components/card/Card';
import { Title } from '../../components/templates/Title';
// import { analytics } from 'firebase-functions/v1';
// import { logEvent } from 'firebase/analytics';

const OtherBenefitsPage = () => {

  /** Hooks */
  const showErrors = useErrors();
  const [data, setData] = useState(false);

  const url_service = 'ReportServices/getReportHomeBoxes';
  const toast       = useToast();

  const request = {
    'report_id': 101153
  };

  /**
   * Reporte Otros Beneficios
   */
  const getReportExchange = async () => {
    try {
      const response = await api.post(url_service, request);
      setData(response.data.response.result_father_box);
      showErrors(response, false);
    } catch (err) {
      showErrors(err);
    }
  }

  useEffect(() => {
    getReportExchange();
  }, [])


  if(data === false) {
    return <Loader />;
  }

  return (
    <Page title="Otros beneficios" p="0" color="transparent">
      {!!data && data !== false
        ?
        <ProductGrid>
          {data.map((benefit) => (
            <Card key={benefit.id} data={benefit} src= {benefit.answer_image} />
          ))}
        </ProductGrid>
        :
        <Text>Sin Datos</Text>
      }
    </Page>
  );
}

export default OtherBenefitsPage;