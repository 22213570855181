/** Imports */
import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Button,
  Heading,
  Text,
  useColorModeValue,
  useToast,
  FormErrorMessage,
  InputRightElement,
  InputGroup
} from '@chakra-ui/react';
import { useErrors } from '../../helpers/integer';
import { useNavigate, Link as LinkRRD } from "react-router-dom";
import { useState, useEffect } from 'react';
// import { ReactComponent as Logo } from '../../components/logo.svg'
import { validateRut, formatRut, clarInvalidCharRuts } from '../../helpers/integer.js'
import TokenService from "../../helpers/TokenService";
import api from "../../helpers/api";
import { Loader } from '../../components/Loader';
// import { analytics } from 'firebase-functions/v1';
// import { logEvent } from 'firebase/analytics';

/**
 * Página de inicio de sesión
 * 
 * @returns JSX
 */
const LogoutPage = () => {

  /** Hooks */
  const showErrors = useErrors();

  const toast = useToast()
  let navigate = useNavigate();


  useEffect(() => {
    doLogout();
  }, [])

  /**
   * Inicia sesión
   */
  const doLogout = async () => {
    try {
      TokenService.removeUser();
      TokenService.removeProfiling();
      TokenService.removeData("data");
      TokenService.removeData("password");
      toast({
        title: 'Sesión Cerrada',
        status: 'success',
        duration: 2000,
        isClosable: true,
      })
      navigate('/login');
    }catch (err) {
      showErrors(err);
    }
  }

  return (
    <div>

    </div>
  );
}

export default LogoutPage;
