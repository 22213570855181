import { Icon } from "@chakra-ui/react";
export const Betterfly = props => {
  let {color = '#565656'} = props;
  return <Icon  viewBox="0 0 41 40" {...props}><rect id="betterfly" x={0.079} y={0} width={40} height={40} style={{
    fill: "none"
  }} /><path d="M14.826,17.728l-9.826,-5.264l0,16.072l30,-16.072l0,16.072l-9.893,-5.3" style={{
    fill: "none",
    stroke: color,
    strokeWidth: "3.36px"
  }} /></Icon>
};