/** Imports */
import {
  Box,
  Grid,
  Heading,
  Text,
  Link
} from '@chakra-ui/react';
import { useErrors } from '../../helpers/integer';
import { useState, useContext } from 'react';
import api from "../../helpers/api";
import { useEffect } from 'react';
import { Page } from '../../components/templates/Page';
import { ReportDependent } from '../../components/ReportDependent';
import { Loader } from '../../components/Loader';
import { Title } from '../../components/templates/Title';
import { GlobalDataContext } from '../../components/context/GlobalData';
// import { analytics } from 'firebase-functions/v1';
// import { logEvent } from 'firebase/analytics';

const DependentPage = () => {

  /** Hooks */
  const showErrors = useErrors();
  const [data, setData] = useState(false);

  const url_service = 'ReportServices/getReportByFilter';

  const request = {
    'report_id': 101155
  };

  useEffect(() => {
    getReport();
  }, [])

  /**
   * Reporte
   */
  const getReport = async () => {

    try {
      const response = await api.post(url_service, request)
      showErrors(response, false);
      setData(response.data.response.data);
    } catch (err) {
      showErrors(err);
    }
  }

  if(data === false) {
    return <Loader />;
  }

  return (
    <Page
      title="Mis dependientes"
      description={<Text> Revisa el instructivo para gestionar las solicitudes de tus dependientes, revisa el manual <Link href="https://vivetusbeneficios.custhelp.com/euf/assets/files/vive2/dependientes/Manual_de_jefaturas.pdf" isExternal >aquí</Link></Text>}
    >
      {!!data && data.lenght !== 0?<ReportDependent dependent={data} />:<Text>No existen Dependientes</Text>}
    </Page>
  );
}

export default DependentPage;