import { Icon } from "@chakra-ui/react";
export const MessageAlert = props => {
  let {color = '#565656'} = props;
  return <Icon  viewBox="0 0 41 40" {...props}><rect id="message_alert" x={0.079} y={0} width={40} height={40} style={{
    fill: "none"
  }} /><path id="path177" d="M34.27,11.258c1.677,1.969 2.596,4.473 2.59,7.06c0,6.031 -4.889,10.92 -10.92,10.92l-9.24,0l-6.54,6.54c-0.312,0.311 -0.78,0.404 -1.187,0.236c-0.407,-0.169 -0.672,-0.566 -0.673,-1.006l0,-7.45c-3.183,-1.979 -5.084,-5.493 -5,-9.24c0,-6.031 4.889,-10.92 10.92,-10.92l11.76,0" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path178" d="M15.02,18.318c0,1.392 -1.128,2.52 -2.52,2.52c-1.392,0 -2.52,-1.128 -2.52,-2.52c0,-1.392 1.128,-2.52 2.52,-2.52c1.392,0 2.52,1.128 2.52,2.52Z" style={{
    fill: color,
    fillRule: "nonzero"
  }} /><path id="path179" d="M21.74,18.318c0,1.392 -1.128,2.52 -2.52,2.52c-1.392,0 -2.52,-1.128 -2.52,-2.52c-0,-1.392 1.128,-2.52 2.52,-2.52c1.392,0 2.52,1.128 2.52,2.52Z" style={{
    fill: color,
    fillRule: "nonzero"
  }} /><path id="path180" d="M28.46,18.318c0,1.392 -1.128,2.52 -2.52,2.52c-1.392,0 -2.52,-1.128 -2.52,-2.52c0,-1.392 1.128,-2.52 2.52,-2.52c1.392,0 2.52,1.128 2.52,2.52Z" style={{
    fill: color,
    fillRule: "nonzero"
  }} /><path id="path181" d="M35.18,8.238c0,2.32 -1.88,4.2 -4.2,4.2c-2.32,0 -4.2,-1.88 -4.2,-4.2c0,-2.32 1.88,-4.2 4.2,-4.2c2.32,0 4.2,1.88 4.2,4.2Z" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /></Icon>
};