import { Icon } from "@chakra-ui/react";
export const Hearth = props => {
  let {color = '#565656'} = props;
  return <Icon  viewBox="0 0 41 40" {...props}><rect id="hearth" x={0.079} y={0} width={40} height={40} style={{
    fill: "none"
  }} /><path id="path287" d="M17.189,35.491l-7.25,0c-1.823,0 -3.3,-1.477 -3.3,-3.3l-0,-18.54" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path288" d="M3.279,15.331l14.38,-10.07c1.451,-1.009 3.378,-1.009 4.83,0l14.39,10.07" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path289" d="M23.649,20.371c-1.25,0.006 -2.446,0.511 -3.322,1.403c-0.875,0.892 -1.357,2.098 -1.338,3.347c-0,3.32 4.21,7.44 6.62,9.66c0.953,0.953 2.497,0.953 3.45,0c2.4,-2.22 6.61,-6.34 6.61,-9.66c0.021,-1.25 -0.46,-2.457 -1.336,-3.35c-0.876,-0.893 -2.073,-1.397 -3.324,-1.4c-1.515,-0.143 -2.956,0.682 -3.6,2.06c-0.749,-1.357 -2.214,-2.159 -3.76,-2.06Z" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /></Icon>
};