/** Imports */
import {
  Box,
  Text,
  HStack,
  Image,
  Stack,
  useToast,
  useNumberInput,
  Button,
  Input,
  ModalCloseButton,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  useDisclosure,
  Modal,
  Skeleton,
  ModalBody,
  Flex,
} from '@chakra-ui/react';
import { formatNumber, htmlfy, useErrors } from '../../helpers/integer';
import { Loader } from '../../components/Loader';
import { useNavigate, useParams } from 'react-router-dom';
import { useState, useEffect, useContext, createRef } from 'react';
import api from "../../helpers/api";
import { Page } from '../../components/templates/Page';
import Moment from 'moment';
import { InputField } from '../../components/input/InputField';
import { useForm } from 'react-hook-form';
import { GlobalDataContext } from '../../components/context/GlobalData';
// import { analytics } from 'firebase-functions/v1';
// import { logEvent } from 'firebase/analytics';
import { analytics, logEvent } from '../../firebase';

const CatalogDetailPage = () => {

  /** Hooks */
  const [globalData, setGlobalData] = useContext(GlobalDataContext);
  const showErrors = useErrors();
  const [data, setData] = useState({});
  const [sucursal, setSucursal] = useState();
  const [isValidForm, setValidForm] = useState(false);
  const [isFreeTime, setIsFreeTime] = useState(false);

  const [product, setProduct] = useState();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    isDirty,
    control,
    setValue,
    watch
  } = useForm({});
  const formRef = createRef();

  const toast = useToast()
  let navigate = useNavigate();
  const { id } = useParams('id');
  const dispatch = watch('dispatch');
  const branch = watch('branch');

  useEffect(() => {
    let date_start = '';
    let time_start = '';
    let time_end = '';

    if(data?._time?.date_start &&
    data?._time?.time_start &&
    data?._time?.time_end) {
      date_start = Moment(data._time);

      time_start = Moment(data._time).set({
        date: date_start.get('date'),
        month: date_start.get('month'),
        year: date_start.get('year')
      });

      time_end = Moment(data._time).set({
        date: date_start.get('date'),
        month: date_start.get('month'),
        year: date_start.get('year')
      });
    }
  }, [data]);

  const { getInputProps, getIncrementButtonProps, getDecrementButtonProps } = useNumberInput({
    step: 1,
    defaultValue: 1,
    min: 1,
    max: 1,
    precision: 0,
  });

  const inc = getIncrementButtonProps()
  const dec = getDecrementButtonProps()
  const input = getInputProps()

  /**
   * Reporte de Centros de Costos
   */
    const getReportCoastCenter = async () => {
      try {
        const response = await api.post("ReportServices/getReport", {'report_id': 101232})
        setSucursal(response.data.response);
        showErrors(response, false);
      } catch (err) {
        showErrors(err);
      }
    }

  /**
   * Obtenemos producto por ID
   */
  const getProduct = async () => {
    try {
      const response = await api.post('ReportServices/getReportHomeBoxesByID', {
        'report_id': 101139, 'filter_name': 'answer_id', 'filter_value':id
      });

      showErrors(response, false);
      setProduct(response.data?.response?.result_father_box[0]);

      if (response.data.response.result_father_box[0].idCategory === '5') {
        setIsFreeTime(true);
      }
    } catch (err) {
      showErrors(err);
    }
  }

  /**
   * Realizamos el canje
   */
  const onSubmit = async (data) => {
    if(data.dispatch == "1"){
      data.branch = '835';
    }
    data.product_id   = parseInt(id) ;
    data.category_id  = parseInt(product?.idCategory);
    data.quantity     = input.value;
    data.exchange_pdf = product?.exchange_pdf;

    const exchange = {
      'product_id': parseInt(id),
      'category_id': parseInt(product?.idCategory),
      'quantity': input.value,
      'exchange_pdf': product?.exchange_pdf
    };

    const exchangeFreeTime = {
      'product_id': parseInt(id),
      'category_id': parseInt(product?.idCategory),
      'quantity': input.value,
      '_time': data._time,
      'exchange_pdf': product?.exchange_pdf
    };

    setData(data);

    try {
      setValidForm(false);
      if(id == 1145){
        const response = await api.post('ExchangeServices/createExchangeProduct',{product_id:data?.product_id, category_id: data?.category_id, quantity: data?.quantity, branch: data?.branch, dispatch: data?.dispatch })
        showErrors(response, false);

        if(response.data.result) {
          toast({
            title: 'Producto canjeado correctamente',
            status: 'success',
            duration: 4000,
            isClosable: true,
          });
          //Generación de evento Google Analytics
          logEvent(analytics, 'event_free_time', {solicitudes_canje_puntos: product.summary});
          navigate('/catalog/history');
        }
      }else{
        const response = await api.post('ExchangeServices/createExchangeProduct', isFreeTime != true ? exchange : exchangeFreeTime)
        showErrors(response, false);

        if(response.data.result) {
          toast({
            title: 'Producto canjeado correctamente',
            status: 'success',
            duration: 4000,
            isClosable: true,
          });
          //Generación de evento Google Analytics
          logEvent(analytics, 'event_free_time', {solicitudes_canje_puntos: product.summary});
          navigate('/catalog/history');
        }
      }
    } catch (err) {
      showErrors(err);
    }
  }

  useEffect(() => {
    getReportCoastCenter();
    getProduct();
    setValidForm((!isValidForm));
  }, []);

  if(!product) {
    return <Loader />;
  }

  return (
    <Page title={product?.summary} path_name={`/catalog/${isFreeTime?'freetime':'tickets'}`}>
      <form onSubmit={handleSubmit(onSubmit)} ref={formRef}>
      <Stack
        direction={{ base: 'column', md: 'row' }}
        spacing={{ base: '8', lg: '16' }}
      >
        <Flex
          flex={'1'}
          align={'center'}
          justify={'center'}
        >
          <Image
            src={product?.answer_image}
            draggable="false"
            fallback={<Skeleton />}
          />
        </Flex>

        <Box flex={'1'}>
          <Stack py={1}>
            <Text fontWeight="extrabold" align={'center'}>
              {product?.idCategory !== '80'
              ?
                product?.points === '0' ? '' : 'Valor: ' + formatNumber(product?.points, 1) + ' Puntos'
              :
              null
              }
            </Text>
          </Stack>
          <Stack py={1}>
            <Text align={'justify'} dangerouslySetInnerHTML={{ __html: htmlfy(product?.solution) }} />
          </Stack>
          <Flex
            mt="2"
            gap={{ base: '1', md: '2' }}
            bg={'gray.100'}
            p="2"
            borderRadius="md"
            flexWrap={'wrap'}
            justify={'center'}
            >

          {isFreeTime?
            <>
              <InputField label="Fecha a solicitar" type="date" name="_time.date_start" dateFormat="dd-MM-yyyy" errors={errors} required={true} control={control} minDate={new Date()} />
              {product?.product_id !== "782"?
              <InputField label="Hora inicio" type="time" name="_time.time_start" dateFormat="HH:mm" errors={errors} required={true} control={control} />
              :null}
              {product?.product_id !== "782"?
              <InputField label="Hora término" type="time" name="_time.time_end" dateFormat="HH:mm" errors={errors} required={true} control={control} />
              :null}
            </>
          :null}
          {!isFreeTime?
            <>
             {product?.product_id === "1145"              ?
                <Stack>
                  <HStack align={'center'}>
                    <Text fontWeight="extrabold">Retiro de canje:</Text>
                    <InputField label="" name='dispatch' defaultValue={''} type="select" placeholder="" register={register} errors={errors} required={true} options={[{value:1, label: 'Casa Matriz : Moneda 970 piso 8'},{value: 2, label: "Sucursales"}]} />
                  </HStack>
                  {dispatch === '2'
                  ?
                    <HStack align={'center'}>
                      <Text fontWeight="extrabold">Selecciona tu sucursal:</Text>
                      <InputField label="" name='branch' defaultValue={''} type="select" placeholder="" register={register} errors={errors} required={true}
                      options= {sucursal} />
                    </HStack>
                  :
                    null
                  }
                </Stack>
              :
              null
            }
            {product?.idCategory !== "80"
              ?
              <HStack maxW='600px' py={4} px={14} align={'center'}>
                <Text fontWeight="extrabold">
                  Stock Disponible: {product?.stock}
                </Text>
                {/* <Button {...inc}>+</Button>
                <Input w="25%" px={8}
                  {...input} />
                <Button {...dec}>-</Button> */}
              </HStack>
              :
              null
            }
            </>
          :null}

            <Box
              py={1}
              align={'center'}
              w="100%"
            >
              <Button onClick={onOpen} type="button">Canjear</Button>
            </Box>

          </Flex>

          <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Confirmación de tu canje</ModalHeader>
              <ModalBody>
                <Text>¿Estás seguro que deseas canjear este ítem?</Text>
              </ModalBody>
              <ModalCloseButton />
              <ModalFooter>
                <Button
                  onClick={onClose}
                  mr={2}
                >Cancelar</Button>
                <Button
                  isLoading={isSubmitting}
                  loadingText="Solicitando..."
                  onClick={() => {
                    formRef.current.dispatchEvent(
                      new Event("submit", { bubbles: true, cancelable: true })
                    )
                  }}
                >
                  Realizar canje
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </Box>
      </Stack>
      </form>
    </Page>
  );
}

export default CatalogDetailPage;