/** Imports */
import {
    Stack,
    Button,
    useToast,
    SimpleGrid
} from '@chakra-ui/react';
import { useContext, useState, useEffect }  from 'react';
import { GlobalDataContext }                from '../../components/context/GlobalData';
import { useErrors }                        from '../../helpers/integer';
import { Page }                             from '../../components/templates/Page';
import { Loader }                           from '../../components/Loader';
import { InputField }                       from '../../components/input/InputField';
import { useForm }                          from 'react-hook-form';
import { Legend }                           from '../../components/templates/Legend';
import TokenService                         from '../../helpers/TokenService';
import api                                  from "../../helpers/api";

const SatisfactionSurvey = () => {
  /** Hooks */
  const showErrors                            = useErrors();
  const toast                                 = useToast();
  const [globalData]                          = useContext(GlobalDataContext);
  const [dataSurvey, setdataSurvey]           = useState([]);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    control,
    watch
  } = useForm({});

  //Eventos Watch
  const questionFive = watch('PreguntaEncuesta.ID_43',0);
  var questionStatus = true;

  if(questionFive === '91'){
    questionStatus = true;
  } else if (questionFive === undefined) {
    questionStatus = true;
  } else {
    questionStatus = false;
  }

  /**
   * Obtiene la encuesta que se solicita por ID
  */
  const getSurvey = async () => {
    try {
    const response = await api.post('SurveyServices/getSurvey', {'survey_id' : 7});
      if(response.data.result === true){
        response?.data?.response?.PreguntaEncuesta.sort((a, b) => (a.Texto > b.Texto ? 1 : a.Texto < b.Texto ? -1 : 0))
        setdataSurvey(response.data.response);
      } else {
        toast({
          title     : 'Hubo un problema al cargar la encuesta',
          status    : 'warning',
          duration  : 5000,
          isClosable: true,
        })
      }
    } catch (err) {
      showErrors(err);
    }
  }
  useEffect(() => {
    getSurvey();
  }, [])


  /**
   * Envía encuesta con la información contestada
   * @param {data} data contiene todos los datos de la encuesta
  */
  const onSubmit = async (data) => {
    try {
      //Creación del objeto contact
      data.contact        = {};
      data.contact.Login  = {};
      data.contact.Login  = globalData?.contact?.Login;

      //Conversión de datos
      let ID_30 = parseInt(data.PreguntaEncuesta.ID_30);
      let ID_33 = parseInt(data.PreguntaEncuesta.ID_33);

      //Agregamos un valor por defecto en caso de no responder nada
      if(data.PreguntaEncuesta.ID_34.length === 0){
        data.PreguntaEncuesta.ID_34 = "Sin comentarios"
      }

      if(/^([0-9])*$/.test(ID_30) && /^([0-9])*$/.test(ID_33) ){
        //Petición a la API
        const response = await api.post('SurveyServices/saveGenericSurvey', data);
        if(response.data.result === true){
          toast({
            title     : response.data.message,
            status    : 'success',
            duration  : 4000,
            isClosable: true,
          })
          TokenService.setProfiling(response.data.response);
          window.location.href = '/home';
        } else {
          toast({
            title     : 'Hubo un problema al enviar tu encuesta',
            status    : 'warning',
            duration  : 4000,
            isClosable: true,
          })
        }
      } else {
        toast({
          title     : 'Una o más preguntas no fueron respondidas, verifica nuevamente',
          status    : 'warning',
          duration  : 4000,
          isClosable: true,
        })
      }
    } catch (err) {
      showErrors(err);
    }
  }

  if(!globalData?.contact) {
    return <Loader />;
  }

  return (
    <Page
      title={dataSurvey?.Nombre}
      description="Queremos conocer tu experiencia al haber utilizado la plataforma Vive tus Beneficios. Esta información nos permitirá seguir mejorando esta herramienta diseñada para entregarte información de tus beneficios y convenios."
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Legend>Preguntas</Legend>
        <SimpleGrid columns={{ base: '1', lg: '1', xl: '1' }} spacing={5} py={3}>
          {dataSurvey?.PreguntaEncuesta?.map((question, index) => (
            <Stack key={index} >
              {questionFive === undefined ||  questionFive === '90' ||  questionFive === '91'
              ?
                question?.ID !== 44 &&  questionFive === '90' || questionFive === undefined || questionStatus === true
                ?
                  <InputField required={question?.TipoPregunta === "textolibre" && question?.ID !== 44 ? false : true}  name={'PreguntaEncuesta.ID_' + question?.ID} size={{ base: '1', lg: '2', xl: '3' }} label={question?.Texto} type={question?.TipoPregunta} register={register} errors={errors} control={control} options={question?.opciones}/>
                :
                question?.ID === 44 && questionStatus === true
                ?
                  <InputField required={true}  name={'PreguntaEncuesta.ID_' + question?.ID} size={{ base: '1', lg: '2', xl: '3' }} label={question?.Texto} type={question?.TipoPregunta} register={register} errors={errors} control={control} options={question?.opciones}/>
                :
                  null
              :
                null
              }
            </Stack>
          ))}
        </SimpleGrid>
        <Stack maxW={'200'} py={'6'}>
          <Button
            type="submit"
            isLoading={isSubmitting}
            loadingText="Enviando..."
            disabled={isSubmitting}
          >
            Enviar Encuesta
          </Button>
        </Stack>
      </form>
    </Page>
  );
}

export default SatisfactionSurvey;