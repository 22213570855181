import {
    Table,
    Thead,
    Tbody,
    Tr,
    TableCaption,
    TableContainer,
    Th,
    Td
  } from '@chakra-ui/react';
  import * as React from 'react'

  export const ReportApproval = (props) => {

    const { approval } = props

    if(!approval) {
      return 'Sin Registros'
    }

    return (
      <TableContainer>
        <Table>
          <TableCaption>Historial de Aprobaciones</TableCaption>
          <Thead>
            <Tr>
              <Th>Número de Referencia</Th>
              <Th>Colaborador</Th>
              <Th>Nombre de Canje</Th>
              <Th>Estado de Solicitud</Th>
              <Th>Fecha Creación</Th>
            </Tr>
          </Thead>
          <Tbody>
          {approval.map((approval,index) => (
            <Tr key={index} align={'center'}>
              <Td>{approval?.n_referencia}</Td>
              <Td>{approval?.NombreColaborador}</Td>
              <Td>{approval?.Producto}</Td>
              <Td>{approval?.Estado}</Td>
              <Td>{approval?.FechaCreacion}</Td>
            </Tr>
          ))}
          </Tbody>
        </Table>
      </TableContainer>
    )
  }