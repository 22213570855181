/** Imports */
import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Button,
  Heading,
  Text,
  useColorModeValue,
  useToast,
  FormErrorMessage,
  InputRightElement,
  InputGroup,
  Divider,
  Center,
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
  Tab,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverArrow,
  PopoverCloseButton,
  PopoverBody,
  HStack
} from '@chakra-ui/react';
import { Fragment }  from 'react';
import { AuthPage }  from '../../components/templates/AuthPage';
import { LoginCodeForm } from '../../components/auth/LoginCodeForm';
// import { analytics } from 'firebase-functions/v1';
// import { logEvent } from 'firebase/analytics';

/**
 * Página de inicio de sesión
 * 
 * @returns JSX
 */
const LoginCodePage = () => {

  /** Hooks */

  return (
    <Fragment>
      <AuthPage>
        <LoginCodeForm />
      </AuthPage>
    </Fragment>
  );
}

export default LoginCodePage;
