import { Icon } from "@chakra-ui/react";
export const Money = props => {
  let {color = '#565656'} = props;
  return <Icon  viewBox="0 0 41 40" {...props}><rect id="money" x={0.079} y={0} width={40} height={40} style={{
    fill: "none"
  }} /><path id="path47" d="M4.959,26.27l-0,-12.54c-0,-1.176 0.953,-2.13 2.13,-2.13l25.98,-0c1.176,-0 2.13,0.954 2.13,2.13l-0,12.54c-0,1.176 -0.954,2.13 -2.13,2.13l-25.98,-0c-1.177,-0 -2.13,-0.954 -2.13,-2.13Z" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path48" d="M23.319,20c-0,1.74 -1.451,3.15 -3.24,3.15c-1.79,-0 -3.24,-1.41 -3.24,-3.15c-0,-1.74 1.45,-3.15 3.24,-3.15c1.789,-0 3.24,1.41 3.24,3.15Z" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /></Icon>
};