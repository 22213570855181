import {
    Text,
    GridItem,
    Link,
    Grid,
    VStack,
    HStack,
    Stack,
    Button,
    Divider,
    Center
  } from '@chakra-ui/react';
import * as React from 'react'
import { Link as LinkRRD } from 'react-router-dom';
import { ShadowBox } from '../templates/ShadowBox';
import { Icons } from '../templates/Icons';
import moment from 'moment';
import { Loader } from '../Loader';
import Barcode from './Barcode';
import { GlobalDataContext } from  '../context/GlobalData';
import { Fragment, useContext, useState } from 'react';
import { useEffect } from 'react';
import api from "../../helpers/api";
import { downloadBase64File, useErrors } from '../../helpers/integer';

export const GiftCard = (props) => {

  /** Hooks */
  const showErrors                        = useErrors();
  const [globalData, setGlobalData]       = useContext(GlobalDataContext);
  const [reportData, setReportData]       = useState([]);
  const [error_data, setErrorData]        = useState([]);
  const [isDownloading, setIsDownloading] = useState(false);

  /** Variables */
  const {giftCard} = props;
  const {link}     = props;
  const {button}   = props;
  const url_service = 'https://vivetusbeneficios.custhelp.com/cc/API/GiftCardServices/downloadGiftCardPDF';

  /**
   * Reporte
   */
  const getGiftcardPDF = async () => {

    try {
      setIsDownloading(true);
      const response = await api.post(url_service,{"gc_id": giftCard?.ID});

      if(response?.data?.result) {
        await downloadBase64File(response?.data?.response?.gc_content, 'giftcard.pdf');
        setIsDownloading(false);
      }
    } catch (err) {
      setIsDownloading(false);
      showErrors(err);
    }

    setIsDownloading(false);
  }

  useEffect(() => {
  }, [])

  return <ShadowBox>
    {
      giftCard?
      <Stack p="3">
        <Center w="full">
          <HStack>
              <Icons icon={'cards'} w={'30px'} h={'30px'} />
              {<Text fontSize='xl' fontWeight={'semibold'}>
                {(giftCard?.TipoGiftCard)?`Gift Card de ${giftCard?.TipoGiftCard}`:'Gift Card'}
              </Text>}
          </HStack>
        </Center>
        <Center>
          <Text maxW="600" mb="5">
            {
              giftCard?.TemplateGC
            }
          </Text>
        </Center>

        <Grid templateColumns={{base: 'repeat(1, 1fr)', lg: 'repeat(2, 1fr)', xl: 'repeat(2, 1fr)'}}>
          <GridItem>
            <Stack>
              <Text fontWeight={'semibold'}>Código </Text>
              <Barcode code={giftCard?.Codigo}/>
            </Stack>
          </GridItem>
          {giftCard?.CodigoMultiformato.length > 0
          ?
            <GridItem>
              <Stack>
                <Text fontWeight={'semibold'}>Código Multiformato</Text>
                <Barcode code={giftCard?.CodigoMultiformato}/>
              </Stack>
            </GridItem>
          :
            null
          }
          <GridItem
            alignItems={'center'}
            display={'flex'}
            colSpan={giftCard?.CodigoMultiformato.length > 0?{base: 1, lg: 2, xl: 2}:1}
            justifyContent={'center'}
            bg={'gray.100'}
            borderRadius={'md'}
          >
            <VStack align={'center'} w={'full'}>
              <Text fontSize={20}>Código de seguridad</Text>
              <Text fontSize={20} fontWeight={'bold'}>{giftCard?.CodigoDeSeguridad}</Text>
            </VStack>
          </GridItem>
        </Grid>

        <Stack align={'center'} pt="5" pb="2">
          <Button onClick={getGiftcardPDF} isLoading={isDownloading} loadingText="Descargando...">
            Descárgala aquí
          </Button>
        </Stack>
      </Stack>
      :
      <Loader/>
    }
  </ShadowBox>;
}