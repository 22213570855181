import { Icon } from "@chakra-ui/react";
export const Balloons = props => {
  let {color = '#565656'} = props;
  return <Icon  viewBox="0 0 40 40" {...props}><rect id="balloons" x={0} y={0} width={40} height={40} style={{
    fill: "none"
  }} /><path id="path226" d="M13.392,25.064c-4.074,-1.594 -7.086,-7.123 -7.086,-12.29c0,-6.118 4.222,-10.105 9.43,-10.105c5.207,-0 9.429,3.987 9.429,10.105c-0,5.167 -3.012,10.696 -7.085,12.29c0.002,0.009 0.554,2.317 0.554,2.317c0.057,0.27 0.03,0.572 -0.071,0.802c-0.102,0.23 -0.265,0.358 -0.434,0.341l-4.787,-0c-0.169,0.017 -0.332,-0.111 -0.434,-0.341c-0.101,-0.23 -0.128,-0.532 -0.071,-0.802c0,-0 0.552,-2.308 0.555,-2.317Z" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.12px"
  }} /><path id="path2261" d="M22.996,6.048c0.832,-0.233 1.712,-0.355 2.621,-0.355c5.208,0 9.429,3.987 9.429,10.105c0,5.167 -3.011,10.697 -7.085,12.29c0.003,0.01 0.555,2.317 0.555,2.317c0.057,0.271 0.03,0.572 -0.072,0.802c-0.101,0.23 -0.264,0.358 -0.433,0.341l-4.788,0c-0.169,0.017 -0.331,-0.111 -0.433,-0.341c-0.102,-0.23 -0.128,-0.531 -0.071,-0.802c-0,0 0.551,-2.307 0.554,-2.317c-1.608,-0.629 -3.051,-1.872 -4.208,-3.464" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.12px"
  }} /><path d="M15.923,29.349c0,1.515 1.099,1.234 0.68,2.26c-0.299,0.734 -1.829,1.124 -1.83,2.413c-0.001,0.892 0.676,1.673 1.355,2.109" style={{
    fill: "none",
    stroke: color,
    strokeWidth: "3.12px"
  }} /><path d="M25.352,31.55c0,1.515 1.099,1.234 0.68,2.26c-0.299,0.734 -1.829,1.124 -1.83,2.413c-0.001,0.892 0.677,1.673 1.355,2.108" style={{
    fill: "none",
    stroke: color,
    strokeWidth: "3.12px"
  }} /></Icon>
};