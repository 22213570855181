/** Imports */
import {
  Box,
  Button,
  Grid,
  Heading,
  SimpleGrid,
  Stack,
  Text,
  useToast,
  VStack
} from '@chakra-ui/react';
import { useErrors } from '../../helpers/integer';
import { useContext, useState } from 'react';
import { useEffect } from 'react';
import { formatRut, validateEmail, validateRut } from '../../helpers/integer';
import { Page } from '../../components/templates/Page';
import { Loader } from '../../components/Loader';
import { Title } from '../../components/templates/Title';
import { useForm }              from 'react-hook-form';
import { GlobalDataContext }    from '../../components/context/GlobalData';
import { Success }              from '../../components/Success';
// import { analytics } from 'firebase-functions/v1';
// import { logEvent } from 'firebase/analytics';

// Helpers
import api from "../../helpers/api";
import { Legend } from '../../components/templates/Legend';
import { InputField } from '../../components/input/InputField';


const SubstitutePage = () => {

  /** Hooks */
  const showErrors = useErrors();
  const [data, setData] = useState({});
  const [success, setSuccess] = useState(false);
  const [dataSuccess, setdataSuccess] = useState({});

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    isDirty,
    control,
    setValue
  } = useForm({});

  const toast = useToast();
  const updateBossUrl = '/';

  useEffect(() => {
  }, [])

   /**
    * Envio del formulario
    */
   const onSubmit = async (data) => {
    data.subject           = 'Solicitud de Reemplazo';
    data.Incident          = {};
    data.Incident.product  = {};
    data.Incident.category = {};

    data.Incident.product.id  = 25
    data.Incident.category.id = 181
    setData(data);
    try {
      const response = await api.post('IncidentServices/createIncident', data);
      if(response.data.result == true){
        toast({
          title: response.data.response.message,
          status: 'success',
          duration: 3000,
          isClosable: true,
        })
        setdataSuccess(response.data.response);
        setSuccess(true);
      } else {
        toast({
          title: response.data.response.message,
          status: 'warning',
          duration: 3000,
          isClosable: true,
        })
      }
    } catch (err) {
      console.log(err);
    }
  }

  if(success) {
    return <Success dataSuccess={dataSuccess?.incident?.ref_number}  message={'Solicitud creada exitosamente'}/>;
  }

  return (
    <Page
      title="Delegar reemplazo"
      description="En esta sección podrás delegar un reemplazante en tus periodos de ausencia que apruebe las solicitudes de tiempo libre de tus reportes directos. Una vez realizada la solicitud, se pedirá a tu BP que autorice este cambio."
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Legend>Información del sustituto</Legend>
        <SimpleGrid columns={{ base: '1', lg: '2', xl: '3' }} spacing={4} p={5}>
          <InputField label="RUT"
              name="CustomFields.CO.OtherContactInIncident"
              type="text"
              placeholder="RUT"
              required={true}
              register={register}
              errors={errors}
              validate={
                (value) => {
                  value = value.replace(/\s/gi, '')
                  if(/^[^\d]/.test(value)) {
                    return validateRut(value) ? true : 'RUT inválido';
                  } else {
                    return validateRut(value) ? true : 'RUT inválido';
                  }
                }
              }
              setValueAs = {
                (value) => {
                  value = value.replace(/\s/gi, '')

                  if(/^[^\d]/.test(value)) {
                    return value;
                  } else {
                    return formatRut(value, 2);
                  }
                }
              }
          />
        </SimpleGrid>
        <Legend>Periodo de reemplazo</Legend>
        <SimpleGrid columns={{ base: '1', lg: '2', xl: '3' }} spacing={4} p={5}>
        <InputField label="Desde" type="date" name="_time.start_date" dateFormat="dd-MM-yyyy" errors={errors} required={true} control={control} />
        <InputField label="Hasta" type="date" name="_time.end_date" dateFormat="dd-MM-yyyy" errors={errors} required={true} control={control} />
        </SimpleGrid>
        <Stack maxW={'200'} py={'6'}>
          <Button
            type="submit"
            isLoading={isSubmitting}
            loadingText="Solicitando..."
            disabled={isSubmitting}
          >
            Solicitar Sustituto
          </Button>
        </Stack>
        </form>
    </Page>
  );
}

export default SubstitutePage;