import { Icon } from "@chakra-ui/react";
export const Arrow = props => {
  let {color = '#565656'} = props;
  return <Icon  viewBox="0 0 41 40" {...props}><rect id="arrow" x={0.079} y={0} width={40} height={40} style={{
    fill: "none"
  }} /><path id="path136" d="M18.967,6.67l14.18,14.18l-14.18,14.18" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path137" d="M30.269,20.885l-23.298,-0" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /></Icon>
};