import { Icon } from "@chakra-ui/react";
export const Component = props => {
  let {color = '#565656'} = props;
  return <Icon  viewBox="0 0 40 41" {...props}><rect id="component" x={0} y={0.8} width={40} height={40} style={{
    fill: "none"
  }} /><path id="path262" d="M26.876,36.236c-0.017,0.846 -0.708,1.523 -1.555,1.523c-4.312,0.001 -16.44,0.001 -20.765,0.001c-0.412,-0 -0.808,-0.164 -1.1,-0.456c-0.292,-0.292 -0.455,-0.688 -0.455,-1.1c-0,-4.31 -0,-16.366 -0,-20.675c-0,-0.413 0.163,-0.809 0.455,-1.1c0.292,-0.292 0.688,-0.456 1.1,-0.456c1.794,0 4.548,0 4.937,0c0.495,-0 0.953,-0.261 1.206,-0.687c0.252,-0.426 0.262,-0.953 0.025,-1.388c-0.563,-0.92 -0.856,-1.986 -0.856,-2.992c-0,-2.798 2.268,-5.066 5.066,-5.066c2.797,0 5.065,2.268 5.065,5.066c0,1.006 -0.293,2.072 -0.801,3.022c-0.227,0.415 -0.218,0.919 0.024,1.326c0.241,0.407 0.68,0.657 1.153,0.657c0.551,0.062 0.96,0.062 0.96,0.062c0.026,-0.001 2.345,-0.005 3.975,-0.008c0.415,-0.002 0.813,0.161 1.107,0.454c0.293,0.292 0.458,0.69 0.458,1.104c-0.004,1.797 -0.008,4.553 -0.008,4.942c0,0.495 0.261,0.953 0.687,1.206c0.426,0.253 0.953,0.262 1.388,0.025c0.92,-0.562 1.985,-0.856 2.992,-0.856c2.797,0 5.065,2.268 5.065,5.066c0,2.798 -2.268,5.066 -5.065,5.066c-1.007,-0 -2.072,-0.293 -3.022,-0.802c-0.415,-0.226 -0.919,-0.217 -1.326,0.024c-0.407,0.242 -0.657,0.68 -0.657,1.153c-0.019,0.168 -0.041,3.046 -0.053,4.889Z" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke:color,
    strokeWidth: "3.36px"
  }} /></Icon>
};