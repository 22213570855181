/** Imports */
import {
  Box,
  Grid,
  Heading
} from '@chakra-ui/react';
import { useErrors } from '../../helpers/integer';
import { useState } from 'react';
import api from "../../helpers/api";
import { useEffect } from 'react';
import { Page } from '../../components/templates/Page';
import { ReportGrid } from '../../components/ReportGrid';
import { Loader } from '../../components/Loader';
import { Title } from '../../components/templates/Title';
// import { analytics } from 'firebase-functions/v1';
// import { logEvent } from 'firebase/analytics';

const HystoryExchangePage = () => {

  /** Hooks */
  const showErrors = useErrors();
  const [data, setData] = useState(false);

  const url_service = 'ReportServices/getHistoryExchangesByContact';

  /**
   * Reporte
   */
  const getReportExchange = async () => {
    try {
      const response = await api.post(url_service)
      setData(response.data.response.data);
    } catch (err) {
      console.log(err);
      showErrors(err);
    }
  }

  useEffect(() => {
    getReportExchange();
  }, [])

  if(data === false) {
    return <Loader />;
  }

  return (
    <Page title="Historial de canjes">
      <Grid
        templateColumns={{ base: '1', md: 'repeat(1, 1fr)', xl: '' }}
        justifyItems={''}
        gap={6}
      >
        <ReportGrid exchange={data} />
      </Grid>
    </Page>
  );
}

export default HystoryExchangePage;