import { Icon } from "@chakra-ui/react";
export const Compensation = props => {
  let {color = '#565656'} = props;
  return <Icon  viewBox="0 0 41 40" {...props}><rect id="compensation" x={0.079} y={0} width={40} height={40} style={{
    fill: "none"
  }} /><path id="path45" d="M24.829,24.38c-0.182,-0.435 -0.467,-0.819 -0.83,-1.12c-0.375,-0.316 -0.797,-0.573 -1.25,-0.76c-0.514,-0.211 -1.038,-0.394 -1.57,-0.55l-1.39,-0.47c-0.37,-0.108 -0.711,-0.296 -1,-0.55c-0.247,-0.236 -0.379,-0.569 -0.36,-0.91c-0.009,-0.3 0.095,-0.592 0.29,-0.82c0.223,-0.226 0.503,-0.388 0.81,-0.47c0.372,-0.092 0.756,-0.133 1.14,-0.12c0.46,0.012 0.916,0.096 1.35,0.25c0.43,0.151 0.845,0.342 1.24,0.57c0.375,0.214 0.73,0.462 1.06,0.74l-0,-3.24c-0.393,-0.202 -0.801,-0.373 -1.22,-0.51c-0.409,-0.132 -0.827,-0.232 -1.25,-0.3l-0.53,-0.06l-0,-1.54l-2.37,0l-0,1.62c-0.34,0.05 -0.675,0.13 -1,0.24c-0.623,0.207 -1.193,0.549 -1.67,1c-0.436,0.388 -0.778,0.87 -1,1.41c-0.203,0.52 -0.308,1.072 -0.31,1.63c-0.016,0.539 0.076,1.076 0.27,1.58c0.165,0.41 0.414,0.781 0.73,1.09c0.295,0.295 0.632,0.544 1,0.74c0.383,0.188 0.781,0.345 1.19,0.47l1.72,0.59c0.437,0.142 0.852,0.347 1.23,0.61c0.301,0.199 0.478,0.539 0.47,0.9c0.03,0.433 -0.162,0.851 -0.51,1.11c-0.439,0.266 -0.948,0.391 -1.46,0.36c-0.776,-0.009 -1.546,-0.141 -2.28,-0.39c-0.784,-0.255 -1.515,-0.648 -2.16,-1.16l-0,3.19c0.454,0.218 0.926,0.399 1.41,0.54c0.517,0.145 1.046,0.249 1.58,0.31c0.22,-0 0.47,0.05 0.72,0.06l-0,1.79l2.37,-0l-0,-1.87c0.526,-0.072 1.036,-0.23 1.51,-0.47c0.705,-0.366 1.288,-0.929 1.68,-1.62c0.391,-0.692 0.591,-1.475 0.58,-2.27c0.046,-0.541 -0.019,-1.085 -0.19,-1.6Z" style={{
    fill: color,
    fillRule: "nonzero"
  }} /><path id="path49" d="M35.199,26.72c-0,7.42 -6.77,10.08 -15.12,10.08c-8.35,-0 -15.12,-2.66 -15.12,-10.08c-0,-7.42 6.77,-15.1 15.12,-15.1c8.35,-0 15.12,7.68 15.12,15.1Z" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path50" d="M25.119,7.4c-0,2.32 -2.257,4.2 -5.04,4.2c-2.784,-0 -5.04,-1.88 -5.04,-4.2c-0,-2.32 2.256,-4.2 5.04,-4.2c2.783,0 5.04,1.88 5.04,4.2Z" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /></Icon>
};