import { Icon } from "@chakra-ui/react";
export const CI = props => {
  let {color = '#565656'} = props;
  return <Icon  viewBox="0 0 40 41" {...props}><rect id="ci" x={0} y={0.8} width={40} height={40} style={{
    fill: "none"
  }} /><path id="path144" d="M6.46,9.88l27.08,0c1.8,0 3.26,1.46 3.26,3.26l0,15.32c0,1.8 -1.46,3.26 -3.26,3.26l-27.08,0c-1.8,0 -3.26,-1.46 -3.26,-3.26l-0,-15.32c-0,-1.8 1.46,-3.26 3.26,-3.26Z" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke:color,
    strokeWidth: "3.36px"
  }} /><path d="M16.8,16c0,-1.657 -1.343,-3 -3,-3l-4.8,0c-1.657,0 -3,1.343 -3,3l0,4.8c0,1.657 1.343,3 3,3l4.8,0c1.657,0 3,-1.343 3,-3l0,-4.8Zm-3,0l-4.8,0l0,4.8l4.8,0l0,-4.8Z" style={{
    fill: "#565656"
  }} /></Icon>
};