import { Icon } from "@chakra-ui/react";
export const Discount = props => {
  let {color = '#565656'} = props;
  return <Icon  viewBox="0 0 40 40" {...props}><rect id="discount" x={0} y={0} width={40} height={40} style={{
    fill: "none"
  }} /><path id="path20" d="M14.12,30.184l11.76,-20.368" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path21" d="M15.763,11.011c1.312,1.312 1.312,3.44 -0,4.752c-1.312,1.312 -3.44,1.312 -4.752,-0c-1.312,-1.312 -1.312,-3.44 0,-4.752c1.312,-1.312 3.44,-1.312 4.752,0Z" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path211" d="M28.989,24.237c1.312,1.312 1.312,3.44 -0,4.752c-1.312,1.312 -3.44,1.312 -4.752,-0c-1.312,-1.312 -1.312,-3.44 0,-4.752c1.312,-1.312 3.44,-1.312 4.752,0Z" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /></Icon>
};