import { Icon } from "@chakra-ui/react";
export const Card = props => {
  let {color = '#565656'} = props;
  return <Icon  viewBox="0 0 41 40" {...props}><rect id="card" x={0.079} y={0} width={40} height={40} style={{
    fill: "none"
  }} /><path id="path144" d="M6.539,9.08l27.08,0c1.8,0 3.26,1.46 3.26,3.26l-0,15.32c-0,1.8 -1.46,3.26 -3.26,3.26l-27.08,-0c-1.801,-0 -3.26,-1.46 -3.26,-3.26l-0,-15.32c-0,-1.8 1.459,-3.26 3.26,-3.26Z" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path145" d="M3.279,17.48l33.6,-0" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /></Icon>
};