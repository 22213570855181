import {
    Table,
    Thead,
    Tbody,
    Tr,
    TableCaption,
    TableContainer,
    Button,
    Stack,
    ModalCloseButton,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    useDisclosure,
    Modal,
    HStack,
    Th,
    Td
  } from '@chakra-ui/react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useErrors } from '../helpers/integer';
import { ApprovalButton } from './ApprovalButton';

  export const Approvals = (props) => {

    // Props
    const { approvals } = props

    return (
      <TableContainer>
        <Table>
          <TableCaption>Aprobaciones</TableCaption>
          <Thead>
            <Tr>
              <Th>N° Referencia</Th>
              <Th>Colaborador</Th>
              <Th>Estado Solicitud</Th>
              <Th>Estado Canje</Th>
              <Th>Producto</Th>
              <Th>Fecha de Solicitud</Th>
              <Th>Fecha de Creación</Th>
              <Th>Acción</Th>
            </Tr>
          </Thead>
          <Tbody>
            {approvals?.map((approvals) => (
              <Tr key={approvals?.ID}>
                <Td>{approvals?.n_referencia}</Td>
                <Td>{approvals?.Colaborador}</Td>
                <Td>{approvals?.Estado}</Td>
                <Td>{approvals?.EstadoCanje}</Td>
                <Td>{approvals?.Producto}</Td>
                <Td>{approvals?.FechaSolicitada}</Td>
                <Td>{approvals?.FechaCreacion}</Td>
                <Td>
                    <Stack>
                      <ApprovalButton
                        name={'Aprobar'}
                        modalnamebutton={'Aprobar'}
                        modaldescription={"Estás a punto de Actualizar una Solicitud Selecciona una opción"}
                        incident_id={approvals?.ID}
                        status_id={104}
                        id_exchange={approvals?.id_exchange}
                        is_free_time={approvals?.free_time}
                      />
                      <ApprovalButton
                        name={'Rechazar'}
                        modalnamebutton={'Rechazar'}
                        modaldescription={"Estás a punto de Actualizar una Solicitud Selecciona una opción"}
                        incident_id={approvals?.ID}
                        id_exchange={approvals?.id_exchange}
                        is_free_time={approvals?.free_time}
                        status_id={105}
                        variant="outline"
                      />
                    </Stack>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    )
  }