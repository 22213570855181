import React, { createRef, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import JsBarcode from 'jsbarcode';
import { useRef } from 'react';
import { Box } from '@chakra-ui/react';

/**
 * Componente `Barcode`
 */
const Barcode = (props) => {
  const { code }                  = props;
  const { format = 'EAN13' }      = props;
  const { displayValue = true }   = props;
  const { fontSize = 20 }         = props;
  const { lineColor = '#000000' } = props;
  const { width = 2.5 }           = props;
  const { height = 100 }          = props;

  let renderBarcode = useRef(null);

  useEffect(() => {
    try {
      new JsBarcode(renderBarcode.current, code, {
        // format,
        displayValue,
        fontSize,
        lineColor,
        width,
        height
      });
    } catch(e) {
      console.log(e);
    }
  }, [])

  return(
    <Box>
      <svg w="100px" ref={renderBarcode} />
    </Box>
  )
}

export default Barcode;