import { Icon } from "@chakra-ui/react";
export const Circle = props => {
  let {color = '#565656'} = props;
  return <Icon  viewBox="0 0 40 40" {...props}><rect id="circle" x={0} y={0} width={40} height={40} style={{
    fill: "none"
  }} /><path id="path226" d="M36.8,20c-0,9.278 -7.522,16.8 -16.8,16.8c-9.278,0 -16.8,-7.522 -16.8,-16.8c0,-9.278 7.522,-16.8 16.8,-16.8c9.278,-0 16.8,7.522 16.8,16.8Z" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /></Icon>
};