import { Icon } from "@chakra-ui/react";
export const Phone = props => {
  let {color = '#565656'} = props;
  return <Icon  viewBox="0 0 41 40" {...props}><rect id="phone" x={0.079} y={0} width={40} height={40} style={{
    fill: "none"
  }} /><path id="path168" d="M26.728,35.354c-5.308,-1.764 -10.134,-4.736 -14.1,-8.68c-3.977,-3.898 -6.981,-8.676 -8.77,-13.95c-1.185,-3.034 -0.515,-6.48 1.72,-8.85c3.09,-3.06 6,-1 8.94,1.87c2.94,2.87 5,6 1.89,8.85c-0.957,0.859 -2.061,1.537 -3.26,2c2.368,3.719 5.539,6.859 9.28,9.19c0.352,-1.258 1.073,-2.383 2.07,-3.23c3.09,-3.07 6,-1 8.94,1.87c2.94,2.87 5,5.95 1.89,8.84c-2.058,2.572 -5.592,3.431 -8.6,2.09Z" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /></Icon>
};