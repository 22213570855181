/** Imports */
import {
  Box,
  Grid,
  Heading
} from '@chakra-ui/react';
import { useErrors } from '../../helpers/integer';
import { useState } from 'react';
import api from "../../helpers/api";
import { useEffect } from 'react';
import { Page } from '../../components/templates/Page';
import { Title } from '../../components/templates/Title';
import { ReportProcedures } from '../../components/ReportProcedures';
import { Loader } from '../../components/Loader';
// import { analytics } from 'firebase-functions/v1';
// import { logEvent } from 'firebase/analytics';

const ProcedureAdvancePage = () => {

  /** Hooks */
  const showErrors = useErrors();
  const [data, setData] = useState(false);
  const [error_data, setErrorData] = useState([]);

  const url_service = 'ReportServices/getReportByFilter';
  const request = {
    'report_id'    : 101140,
    'filter_name'  : '',
    'filter_value' : ''
  }

  /**
   * Reporte
   */
  const getReportExchange = async () => {
    try {
      const response = await api.post(url_service, request)
      showErrors(response, false);
      setData(response.data.response.data);
    } catch (err) {
      showErrors(err);
    }
  }

  useEffect(() => {
    getReportExchange();
  }, [])

  if(data === false) {
    return <Loader />;
  }

  return (
    <Page title="Mis anticipos">
      <Grid
        templateColumns={{ base: '1', md: 'repeat(1, 1fr)', xl: '' }}
        justifyItems={''}
        gap={6}
      >
        <ReportProcedures procedure={data} />
      </Grid>
    </Page>
  );
}

export default ProcedureAdvancePage;