/** Imports */
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Code,
  Divider,
  GridItem,
  HStack,
  Image,
  Link,
  ListItem,
  OrderedList,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  SimpleGrid,
  Skeleton,
  Stack,
  Tab,
  Table,
  TableCaption,
  TableContainer,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  UnorderedList,
  useToast,
  VStack
} from '@chakra-ui/react';
import { useErrors } from '../../helpers/integer';
import { useState, useContext, Fragment } from 'react';
import { useEffect }            from 'react';
import { useForm }              from 'react-hook-form';
import { Page }                 from '../../components/templates/Page';
import { Loader }               from '../../components/Loader';
import { InputField }           from '../../components/input/InputField';
import { Title }                from '../../components/templates/Title';
import { GlobalDataContext }    from '../../components/context/GlobalData';
import { Success }              from '../../components/Success';
import moment from 'moment';
// import { analytics } from 'firebase-functions/v1';
// import { logEvent } from 'firebase/analytics';

// Helpers
import api                      from "../../helpers/api";
import { Legend } from '../../components/templates/Legend';
import { Message } from '../../components/templates/Message';
import { Icons } from '../../components/templates/Icons';

const LoansForm = () => {

  /** Hooks */
  const showErrors                                = useErrors();
  const [globalData, setGlobalData]               = useContext(GlobalDataContext);
  const [formData, setFormData]                   = useState([]);
  const [data, setData]                           = useState(false);
  const [simulation, setSimulation]               = useState(false);
  const [amountRequest, setAmountRequest]         = useState(0);
  const [duesValue, setDuesValue]                 = useState(0);
  const [duesNumber, setDuesNumber]               = useState(0);
  const [generalConditions, setgeneralConditions] = useState(true);
  const [confirmation, setConfirmation]           = useState(true);
  const [tabIndex, setTabIndex]                   = useState(0)
  const fechaInicio = new Date().toLocaleDateString();
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    isDirty,
    control,
    setValue
  } = useForm({});

  const toast = useToast();

  const handleTabsChange = (index) => {
    setTabIndex(index)
  }
  
  const onChangeAmountRequest = async (data) => {
    setAmountRequest(data.target.value);

    let amountr = data.target.value;
    setDuesValue(amountr / duesNumber);
  }
  
  const onChangeDues = async (data) => {
    setDuesNumber(data.target.value);
    let amountr = amountRequest;
    setDuesValue(amountr / data.target.value);
  }

  /**
  * Manejo de errores del formulario
  */
  const onError = (errors, e) => {
    console.log(errors, e);
  }

   /**
    * Enviar encuesta con la información contestada
    */
  const onSubmit = async (data) => {
    data.Incident = {};
     
    data.Incident.product    = {};
    data.Incident.product.id = 25;
    
    data.subject = {};
    data.subject = "Solicitud de Prestamos";
    
    data.Incident.category    = {};
    data.Incident.category.id = 66;
    
    data.FORMS.Loan.due_value = duesValue;
    data.FORMS.Loan.dues_numbers = parseInt(data.FORMS.Loan.dues_numbers);

    setFormData(data);
    setTabIndex(1)
    setSimulation(true);
    setgeneralConditions(false);
  }
  
  const onSend = async () => {
    try {
    const response = await api.post('IncidentServices/createIncident', formData);

      if(response.data.result === true){
        toast({
          title: response.data.response.message,
          status: 'success',
          duration: 3000,
          isClosable: true,
        })
        setTabIndex(2)
        setgeneralConditions(true);
        setConfirmation(false);
        setData(response);
      } else {
        toast({
          title: response.data.response.message,
          status: 'warning',
          duration: 3000,
          isClosable: true,
        })
      }
      setData(response);
    } catch (err) {
      console.log(err);
    }
    setTabIndex(2)
    setgeneralConditions(true);
    setConfirmation(false);
  }

  useEffect(() => {
  }, [])
  
  if(!globalData?.contact) {
    return <Loader />;
  }
  
  return (
    <Page title="Solicitud de préstamos">
      
    <Tabs index={tabIndex} onChange={handleTabsChange}>
    <TabList>
      <Tab isDisabled={simulation}>
        Simular préstamo
      <Icons icon="arrow_left" color={'black'} h="7" w="7" />
      </Tab>
      <Tab isDisabled={generalConditions} >
        Condiciones generales
        <Icons icon="arrow_left" color={'black'} h="7" w="7" />
        </Tab>
      <Tab isDisabled={confirmation} >
        Detalle de solicitud
        <Icons icon="here" color={'black'} h="7" w="7" />
        </Tab>
    </TabList>
    <TabPanels>
      <TabPanel>
        <form onSubmit={handleSubmit(onSubmit)} autoComplete={"off"}>
          <Legend>Simula antes de solicitar tu préstamo</Legend>
          <SimpleGrid columns={{ base: '1', lg: '2', xl: '3' }} spacing={4} p={5}>
            <InputField label={
              <HStack>
                <Text>Sueldo base</Text>
                <Popover>
                  <PopoverTrigger>
                    <Box>
                      <Icons icon="question" w="6" h="6" />
                    </Box>
                  </PopoverTrigger>
                  <PopoverContent>
                    <PopoverArrow bg="#ef044e" />
                    <PopoverCloseButton />
                    <PopoverBody>
                      <Image
                        src='https://vivetusbeneficios.custhelp.com/euf/assets/images/liquidacion_sueldo_base.png'
                        draggable="false"
                        fallback={<Skeleton />}
                      />
                    </PopoverBody>
                  </PopoverContent>
                </Popover>
              </HStack>} name={'FORMS.Loan.base_salary'} type="text" placeholder="" register={register} errors={errors} required={true} 
            />
            <InputField label={
              <HStack>
                <Text>Total haberes imponibles</Text>
                <Popover>
                  <PopoverTrigger>
                    <Box>
                      <Icons icon="question" w="6" h="6" />
                    </Box>
                  </PopoverTrigger>
                  <PopoverContent>
                    <PopoverArrow bg="#ef044e" />
                    <PopoverCloseButton />
                    <PopoverBody>
                      <Image
                        src='https://vivetusbeneficios.custhelp.com/euf/assets/images/liquidacion_haberes_imponibles.png'
                        draggable="false"
                        fallback={<Skeleton />}
                      />
                    </PopoverBody>
                  </PopoverContent>
                </Popover>
              </HStack>} name={'FORMS.Loan.taxable_amount'} type="text" placeholder="" register={register} errors={errors} required={true} 
            />
            <InputField label="Monto solicitado"  name={'FORMS.Loan.amount_request'}      onChange={onChangeAmountRequest} type="text" placeholder="" register={register} errors={errors} required={true} />
            <InputField label="Numero de cuotas"  name={'FORMS.Loan.dues_numbers'}        onChange={onChangeDues} type="text" placeholder="" register={register} errors={errors} required={true}/>
            <InputField label="Valor cuota"       name={'FORMS.Loan.due_value'}           disabled defaultValue={duesValue !== Infinity ? duesValue: 0} type="text" placeholder=""/>
          </SimpleGrid>

          <Legend>Otros datos</Legend>
          <SimpleGrid columns={{ base: '1', lg: '2', xl: '3' }} spacing={4} p={5}>
            {/* <InputField label="Motivo"                name={'FORMS.Loan.loan_subject'} type="text" placeholder="" register={register} errors={errors} required={true} /> */}
            <InputField label="Dirección particular"  name={'FORMS.Loan.particular_address'} type="text" placeholder="" register={register} errors={errors} required={true} />
          </SimpleGrid>

          <Legend>Documentos adjuntos</Legend>
          <SimpleGrid columns={{ base: '1', lg: '2', xl: '3' }} spacing={4} p={5}>
            <GridItem>
              <HStack>
                <InputField name="_attachments" label="" type="file" register={register} errors={errors} control={control} setValue={setValue} acceptedFileTypes={['image/jpeg', 'image/png', 'application/pdf']} />
              </HStack>
            </GridItem>
          </SimpleGrid>

          <Stack maxW={'200'} py={'6'}>
            <Button
              type="submit"
              isLoading={isSubmitting}
              loadingText="Solicitando..."
              disabled={isSubmitting}
            >
              Continuar
            </Button>
          </Stack>
        </form>
      </TabPanel>
      <TabPanel>
      <Text align={'left'} fontSize={25}>
          Anexo 1
        </Text>
      <Legend>Solicitud de préstamo empresa, anticipo en cuotas</Legend>
        <SimpleGrid columns={{ base: '1', lg: '2', xl: '3' }} spacing={4} p={5}>
          <InputField label="RUT"                 disabled name={'contact.Login'} defaultValue={globalData?.contact?.Login} type="text" placeholder="" register={register} />
          <InputField label="Nombre colaborador	" disabled name={'contact.Name.First'} defaultValue={globalData?.contact?.Name?.First} type="text" placeholder="" register={register} />
          <InputField label="Apellido paterno"    disabled name={'contact.Name.Last'} defaultValue={globalData?.contact?.Name?.Last} type="text" placeholder="" register={register}/>
          {/* <InputField label="Fecha de Ingreso"    name={'_date'} type="date" placeholder=""  errors={errors} required={true} control={control} /> */}
          <InputField label="Salario base"        disabled name={'FORMS.Loan.base_salary'} defaultValue={formData?.FORMS?.Loan?.base_salary} type="text" placeholder="" />
          {/* <InputField label="Tipo de préstamo"    disabled name={'FORMS.Loan.loan_subject'} defaultValue={formData?.FORMS?.Loan?.loan_subject} type="text" placeholder="" /> */}
          <InputField label="Monto solicitado"    disabled name={'FORMS.Loan.amount_request'} defaultValue={formData?.FORMS?.Loan?.amount_request} type="text" placeholder="" />
        </SimpleGrid>
        <Text align={'left'}>Faculto expresamente a mi empelador para cargar en mi liquidación de sueldo la suma del anticipo en cuotas solicitado en</Text>
        <TableContainer>
          <Table>
            <TableCaption>Resúmen de crédito</TableCaption>
            <Thead>
              <Tr>
                <Th>N° de Cuota</Th>
                <Th>Monto</Th>
                <Th>Vencimiento</Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr key={1} >
                <Td>{1}</Td>
                <Td>{formData?.FORMS?.Loan?.due_value}</Td>
                <Td>{fechaInicio}</Td>
              </Tr>
            </Tbody>
          </Table>
        </TableContainer>
        <Text align={'left'} fontSize={25}>
          Anexo 2
        </Text>
        <Legend>Reconocimiento de deuda de préstamo (anticipo en cuotas)</Legend>
        <SimpleGrid align={'left'} columns={{ base: '1', lg: '2', xl: '1' }} spacing={4} p={5}>
          <GridItem>
            <Text as='b'>Primero: </Text>
            Reconozco adeudar a Banco Falabella la cantidad de ${formData?.FORMS?.Loan?.amount_request} La suma adeudada la pagaré en {formData?.FORMS?.Loan?.dues_numbers} cuota/s mensuales de ${formData?.FORMS?.Loan?.due_value} pesos cada una, venciendo la primera el día 30 de Noviembre de 2022 y la última el día 31 de Marzo de 2023.
            </GridItem>
          <GridItem>
            <Text as='b'>Segundo: </Text>
            Faculto expresamente a mi empleador para descontar de mi remuneración mensual las cuotas señaladas en la cláusula primero precedente.
            </GridItem>
          <GridItem>
            <Text as='b'>Tercero: </Text>
            Me comprometo que en caso de que existiera alguna licencia médica prolongada por mi parte a pagar las cuotas respectivas depositando o transfiriendo a la cuenta corriente de la empresa según corresponda.
            </GridItem>
          <GridItem>
            <Text as='b'>Cuarto: </Text>
            Si este préstamo lo solicitara para cubrir gastos médicos, de exámenes u hospitalarios cubiertos por el Seguro de Salud Complementario, tomado por mi empleador, en favor mío y de mi grupo familiar, me comprometo a pagar este préstamo al día siguiente de recibir el reembolso del seguro de salud hasta por dicho monto y a solicitar un nuevo préstamo por la diferencia entre el préstamo aquí solicitado y el valor del reembolso del seguro de salud que me da mi empleador.
            </GridItem>
          <GridItem>
            <Text as='b'>Quinto: </Text>
            Si por cualquier motivo terminare el contrato de trabajo declaro conocer y aceptar que el saldo insoluto de la deuda que da cuenta la cláusula primera, se hará exigible como si fuere de plazo vencido.
          </GridItem>
          <GridItem>
            <Text as='b'>Sexto: </Text>
            Además autorizo a mi empleador a cargar en la última liquidación de mi sueldo y/o finiquito de trabajo, el total del capital insoluto que faltare pagar, si hubiera entre las partes término de contrato de trabajo y el saldo resultante de esto último si existiera, rebajarlo de mis gratificaciones pendientes o de cualquier otro haber a mi favor, y de quedar un saldo, me comprometo a firmar los documentos de respaldo que me solicite mi empleador (Ejemplo: un pagaré, letra, etc.).
          </GridItem>
          <GridItem>
            <Text as='b'>Séptimo: </Text>
            Para todos los efectos derivados del presente reconocimiento de deuda, Don(ña) Esteban Ruiz, fija su domicilio en esta ciudad, sometiéndose a la competencia de sus tribunales ordinarios de justicia.
          </GridItem>
        </SimpleGrid>
        <Stack maxW={'200'} py={'6'}>
            <Button
              type="submit"
              isLoading={isSubmitting}
              loadingText="Solicitando..."
              disabled={isSubmitting}
              onClick={onSend}
            >
              Acepto las Condiciones
            </Button>
        </Stack>
      </TabPanel>
      <TabPanel>
        <Text as={'b'} fontSize={20}> {data.data?.response?.incident?.ref_number} </Text>
        <Text as={'b'} fontSize={20}> {data.data?.response?.message} </Text>
      </TabPanel>
    </TabPanels>
  </Tabs>  
  </Page>
  );
}

export default LoansForm;