import { Icon } from "@chakra-ui/react";
export const More = props => {
  let {color = '#565656'} = props;
  return <Icon  viewBox="0 0 40 40" {...props}><rect id="more" x={0} y={0} width={40} height={40} style={{
    fill: "none"
  }} /><path id="path137" d="M31.649,20l-23.298,0" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path1371" d="M20,8.351l0,23.298" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /></Icon>
};