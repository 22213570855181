import { Icon } from "@chakra-ui/react";
export const Umbrella = props => {
  let {color = '#565656'} = props;
  return <Icon  viewBox="0 0 41 40" {...props}><rect id="umbrella" x={0.079} y={0} width={40} height={40} style={{
    fill: "none"
  }} /><path id="path26" d="M36.079,21.68c-0,-8.81 -7.14,-18.48 -16,-18.48c-8.86,0 -16,9.67 -16,18.48c5.339,-2.315 11.111,-3.461 16.93,-3.36c10,0 15.07,3.36 15.07,3.36Z" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /><path id="path27" d="M19.279,18.32l-0,14.19c-0,2.32 1.88,4.2 4.2,4.2c2.319,0 4.2,-1.88 4.2,-4.2l-0,-2.43" style={{
    fill: "none",
    fillRule: "nonzero",
    stroke: color,
    strokeWidth: "3.36px"
  }} /></Icon>
};