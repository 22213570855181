/* Imports */
import { Fragment } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import ForgotForm from '../../components/auth/ForgotForm';
import { AuthPage } from '../../components/templates/AuthPage';
// import { analytics } from 'firebase-functions/v1';
// import { logEvent } from 'firebase/analytics';

/**
 * Página recuperación de clave
 * TODO: Integrar con API
 * @returns JXS
 */
const ForgotPage = () => {

  return (
    <Fragment>
      <AuthPage>
        <GoogleReCaptchaProvider reCaptchaKey="6Ld9bU8jAAAAAM_ipnkoAmGugsqQz7uXutkKxqt6">
          <ForgotForm />
        </GoogleReCaptchaProvider>
      </AuthPage>
    </Fragment>
  );
}

export default ForgotPage;
